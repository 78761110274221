import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { AppHeaderContent } from '@components/app-header-content'
import { ReservationDetails } from '@modules/reservation/models'
import { Resort } from '@models/app-data'
import { DateFormats, formatDate } from '@helpers/date-formatter'
interface Props {
  onScrollToBill: () => void
  reservationDetails: ReservationDetails
  reservationResort: Resort | undefined
}

export const ReservationHeaderSectionContent = ({
  onScrollToBill,
  reservationDetails,
  reservationResort,
}: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <>
        Podsumowanie rezerwacji <br /> {reservationDetails.reservation_number}
      </>
    }
    subtitle={
      <span className="font-lg">
        <strong>Dziękujemy {reservationDetails.name} za dokonanie rezerwacji</strong>
        <span className="d-block">
          w Ośrodku {reservationResort?.name} w dniach {formatDate(reservationDetails.date_from, DateFormats.DAY)} -{' '}
          {formatDate(reservationDetails.date_to, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
        </span>
      </span>
    }
  >
    <div className="mt-4 d-flex justify-content-center justify-content-sm-start flex-wrap col-12">
      <IconWithText
        text="Zobacz podsumowanie"
        iconClassName="uil-plus font-xxxl me-2"
        className="text-gold font-size-xl cursor-pointer fw-bold"
        onClick={onScrollToBill}
      />
    </div>
  </AppHeaderContent>
)
