import { ReservationDetails } from '@modules/reservation/models'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { usePaymentSuccessProductDetails } from '@hooks/gtm/use-payment-success-product-details'
import { Seller } from '@models/base'
import { useReservationGtmEvents } from '@modules/reservation/use-reservation-gtm-events'
import { useSubscriptionProductGtmEvents } from '@modules/subscription-with-benefit/use-subscription-with-benefit-gtm-events'
import { useSubscriptionContractGtmEvents } from '@modules/subscription-contract/use-subscription-contract-gtm-events'
import { useCodesConversionGtmEvents } from '@modules/codes-conversion/use-codes-conversion-gtm-events'
import { CodeOption } from '@modules/codes-conversion/models'

export const usePaymentSuccessEcommerceOrderGtmEvent = (kind: string | undefined, token: string | undefined): void => {
  const { sendPurchaseEvent: sendReservationPurchaseEvent } = useReservationGtmEvents()
  const { sendPurchaseEvent: sendSubscriptionProductPurchaseEvent } = useSubscriptionProductGtmEvents()
  const { sendPurchaseEvent: sendSubscriptionContractPurchaseEvent } = useSubscriptionContractGtmEvents()
  const { sendPurchaseEvent: sendCodesConversionPurchaseEvent } = useCodesConversionGtmEvents()

  const sendPurchaseOrder = (
    productDetails: ReservationDetails | SubscriptionDetails | SubscriptionContractDetails | CodeOption,
  ) => {
    if (kind === ProductKinds.RESERVATION_DETAILS) {
      sendReservationPurchaseEvent(productDetails as ReservationDetails)
    }

    if (kind === ProductKinds.CODES_CONVERSION) {
      sendCodesConversionPurchaseEvent(productDetails as CodeOption)
    }

    if ((productDetails as { seller: Seller | null }).seller?.department !== 'ecommerce') return

    if (kind === ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS) {
      sendSubscriptionProductPurchaseEvent(productDetails as SubscriptionDetails, 'pakiet-korzysci')
    }

    if (kind === ProductKinds.GO_HOLIDAY) {
      sendSubscriptionProductPurchaseEvent(productDetails as SubscriptionDetails, 'go-holiday')
    }

    if (kind === ProductKinds.SUBSCRIPTION_CONTRACT) {
      sendSubscriptionContractPurchaseEvent(productDetails as SubscriptionContractDetails)
    }
  }

  usePaymentSuccessProductDetails(kind, token, sendPurchaseOrder)
}
