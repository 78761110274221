import * as React from 'react'
import clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { HolidayBenefitInvitationFormInputs } from '@modules/holiday-benefit/employee-invitation/index'
import { ConfettiRef, ConfettiWrapper } from '@components/confetti-wrapper'
import { HolidayBenefitKind } from '@modules/holiday-benefit/employee-invitation/models'

interface Props {
  title: string
  image: string
  children: React.ReactNode
  price: string
  variant: 'is-primary' | 'is-secondary' | 'is-tertiary'
  plan: HolidayBenefitKind
}
export const HolidayBenefitInvitationKindSelectionCard = ({
  title,
  image,
  children,
  price,
  variant,
  plan,
}: Props): React.ReactNode => {
  const { setValue, control } = useFormContext<HolidayBenefitInvitationFormInputs>()

  const confettiRef = React.useRef<ConfettiRef>(null)

  const selectedKind = useWatch({ control, name: 'kind' })

  const handleClick = () => {
    setValue('kind', plan)

    if (!selectedKind || selectedKind !== plan) {
      confettiRef.current?.goToAndPlay(0)
    }
  }

  const isActive = selectedKind === plan

  const color = {
    'is-primary': 'text-holiday-benefit-primary',
    'is-secondary': 'text-holiday-benefit-secondary',
    'is-tertiary': 'text-holiday-benefit-tertiary',
  }[variant]

  return (
    <button
      className={clsx('btn-unstyled col-lg-6 col-12 holiday-benefit__invitation__card position-relative', variant, {
        'is-active': isActive,
      })}
      onClick={handleClick}
    >
      <div className="holiday-benefit__invitation__card__selection">
        <div className="holiday-benefit__invitation__card__selection__check uil-check" />
      </div>
      <img
        src={image}
        height={160}
        width="100%"
        alt="Plan"
        className="rounded-top pe-none"
        style={{ objectFit: 'cover' }}
      />
      <ConfettiWrapper ref={confettiRef}>
        <section className="p-3 border-start border-end border-bottom rounded-bottom">
          <h5 className={clsx('my-3 font-xxl', color)}>{title}</h5>
          <p className="text-holiday-benefit-secondary">{children}</p>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                src={require('@assets/images/holiday-benefit/landing-page/bar.svg')}
                alt="Holiday Benefit"
                height={40}
              />
              <span className="ms-3 font-sm">
                Twoja opłata <br /> miesięczna wyniesie
              </span>
            </div>
            <strong className={clsx('font-xxxl', color)}>
              {price}
              <span className="font-xl ms-1">zł</span>
            </strong>
          </div>
        </section>
      </ConfettiWrapper>
    </button>
  )
}
