import * as React from 'react'
import { CardContentBase } from '@components/card-content-base'
import { BookingOptionsDetails } from '@modules/booking-options/models'
import { ProductStatusLabel } from '@components/product-status-label'

interface Props {
  bookingOption: BookingOptionsDetails
  onScrollToPayment: () => void
}

export const BookingOptionContent = ({ bookingOption, onScrollToPayment }: Props): JSX.Element => (
  <>
    <CardContentBase
      title="Potwierdzenie zakupu opcji do rezerwacji"
      client={bookingOption?.client}
      seller={bookingOption?.seller}
      created={bookingOption?.created || ''}
      onScrollToPayment={onScrollToPayment}
      productInvoice={bookingOption}
    />
    <hr className="mx-n5" />
    <strong className="text-gold text-center text-sm-start d-block">Wybrane opcje:</strong>
    <ul className="list-unstyled mb-0 mt-3">
      {bookingOption.option_kinds_display.map(option => (
        <OptionRow key={option} name={option} isPaid={bookingOption.is_paid} />
      ))}
    </ul>
  </>
)

const OptionRow = ({ name, isPaid }) => (
  <li className="d-flex mb-2 text-darker-gray font-md">
    <div className="col-6">{name}</div>
    <div>
      <ProductStatusLabel isPaid={isPaid} />
    </div>
  </li>
)
