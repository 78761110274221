import * as React from 'react'
import { PackageWholesaleProduct, PackageWholesaleProductKind } from '@modules/package-wholesale/models'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'

import { PackageWholesaleOfferSubscriptionCard } from '@modules/package-wholesale/details/subscription/package-wholesale-offer-subscription-card'

const isSubscription = (kind: PackageWholesaleProductKind) => kind === 'subscription'

interface Props {
  packageWholesaleProducts: PackageWholesaleProduct[]
  children?: React.ReactNode
}

export const PackageWholesaleCardsSubscriptionSection = ({
  packageWholesaleProducts,
  children,
}: Props): JSX.Element => {
  const { control } = useFormContext<PackageWholesaleFormInput>()

  const { fields: products, append } = useFieldArray({
    control: control,
    name: 'products',
  })

  React.useEffect(() => {
    ;[...packageWholesaleProducts]
      .sort((a, b) => Number(a.with_zoo_borysew_tickets) - Number(b.with_zoo_borysew_tickets))
      .reverse()
      .filter(e => isSubscription(e.kind))
      .forEach(product =>
        append({ amount: 0, product, active: false, with_zoo_borysew_tickets: false, options: { zoo_borysew: false } }),
      )
  }, [packageWholesaleProducts])

  const isHidden = (product: PackageWholesaleProduct) =>
    !packageWholesaleProducts.some((productToShow: PackageWholesaleProduct) => productToShow.id === product.id)

  return (
    <>
      <div className="package-wholesale__cards">
        {products.map(({ product }, index) => (
          <PackageWholesaleOfferSubscriptionCard isHidden={isHidden} index={index} product={product} key={product.id} />
        ))}
      </div>
      {children}
    </>
  )
}
