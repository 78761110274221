import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  dates: React.ReactNode
}

export const PackageWholesaleSubscriptionExpandableVariantSubtitle = ({ dates }: Props): JSX.Element => (
  <IconWithText
    className="mt-1"
    iconClassName="uil-calender text-darker-gray me-1 align-self-start"
    text={
      <span className="text-darker-gray font-md">
        Do wykorzystania w dniach: <strong className="fw-bold d-block d-md-inline">{dates}</strong>
      </span>
    }
  />
)
