import * as React from 'react'
import { PackageWholesaleSingleKindSideCard } from '@modules/package-wholesale/details/package-wholesale-single-kind-side-card'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleGastroCardBoostSideCard = ({ packageWholesaleDetails }: Props): JSX.Element => (
  <PackageWholesaleSingleKindSideCard packageWholesaleDetails={packageWholesaleDetails} kind="gastro_card_boost" />
)
