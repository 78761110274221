import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { motion } from 'framer-motion'

interface Props extends BaseModalProps {
  url: string
}

export const RodoAgreementContentModal = ({ toggleIsVisible, url }: Props): JSX.Element => (
  <ModalBody className="p-sm-5 p-4">
    <motion.button
      className="btn-unstyled d-block ms-auto rodo-agreements__qr-modal__close-button"
      whileTap={{ scale: 0.9 }}
      onClick={toggleIsVisible}
    >
      <i className="uil-times text-muted" />
    </motion.button>
    <div>
      <h3 className="text-darker-gray mb-3">Treść dokumentu</h3>
      <iframe src={`${url}#toolbar=0&navpanes=0`} title="Regulamin" width="100%" height={500} />
    </div>
  </ModalBody>
)
