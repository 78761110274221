import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  image?: React.ReactElement
  className?: string
  children?: React.ReactNode
}

export const SideCard = ({ image, children, className }: Props): JSX.Element => (
  <div className={clsx('col-3', className)}>
    <div className="card border-0 rounded overflow-hidden">{image}</div>
    {children}
  </div>
)
