import * as React from 'react'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'
import * as clsx from 'clsx'

export const HolidayBenefitDeployment = (): React.ReactNode => (
  <div className="container d-flex mt-5 pt-5 gap-5 col-lg-10 col-xl-7 col-12 flex-wrap flex-lg-nowrap">
    <div className="col-lg-6 col-12 rounded-4 bg-holiday-benefit-tertiary p-5">
      <h4 className="font-xxxl text-white mb-5 text-center text-lg-start">Jak wygląda wdrożenie?</h4>
      <div className="position-relative">
        <DeploymentStepCard
          opacity={0.85}
          stepNumber={1}
          title="Nawiązujemy kontakt i przygotowujemy ofertę specjalnie dla Twojej firmy."
          description="Wybierz Plan i zaangażowanie finansowe zgodnie z potrzebami biznesowymi."
        />
        <img
          src={require('@assets/images/holiday-benefit/landing-page/arrow-right-to-left.svg')}
          height={70}
          alt="arrow"
          className="holiday-benefit__deployment__arrow__step-1-2"
        />
      </div>
      <div className="position-relative">
        <DeploymentStepCard
          className="ms-n4 me-4"
          opacity={0.95}
          stepNumber={2}
          title="Podpisujemy umowę i rozpoczynamy proces uruchomienia produktu w Twojej organizacji."
          description="Przekażemy Ci wszystkie niezbędne materiały marketingowe oraz dostęp do gotowych narzędzi."
        />
        <img
          src={require('@assets/images/holiday-benefit/landing-page/arrow-left-to-right.svg')}
          height={70}
          alt="arrow"
          className="holiday-benefit__deployment__arrow__step-2-3"
        />
      </div>

      <DeploymentStepCard
        opacity={1}
        stepNumber={3}
        title="Twoi pracownicy przystępują do programu i cieszą się pierwszymi pobytami w Holiday Park & Resort!"
        description="Dodatkowo, Ty również otrzymujesz w prezencie voucher na 3-dniowy wypoczynek za każde 10 podpisanych umów."
      />
    </div>

    <div
      className="col-lg-6 col-12 rounded-4 bg-holiday-benefit-primary pt-5 px-5 text-white position-relative overflow-hidden"
      style={{ minHeight: 600 }}
    >
      <h4 className="font-xxxl text-white text-center text-lg-start">Gotowe narzędzia</h4>
      <p className="font-xl mt-5">
        Kluczowym narzędziem jest specjalnie skonfigurowany{' '}
        <strong>Panel do samodzielnego zarządzania abonamentami</strong> w strukturach Twojej organizacji, do którego od
        razu przekażemy Ci dostęp.
      </p>
      <p className="font-lg fw-light mt-5 mb-5">
        Ale to nie wszystko! Otrzymasz od nas również szereg spersonalizowanych materiałów na start, dzięki którym łatwo
        rozpowszechnisz informacje o programie wśród pracowników.
      </p>
      <img
        src="https://via.placeholder.com/300x200"
        alt="holidaypark logo"
        className="holiday-benefit__deployment__tools__image"
        height={380}
      />
    </div>
  </div>
)

interface DeploymentStepCardProps {
  stepNumber: number
  title: string
  description: string
  opacity: number
  className?: string
}

const DeploymentStepCard = ({ stepNumber, title, description, opacity, className }: DeploymentStepCardProps) => (
  <div className={clsx('rounded-3 bg-white p-4 mt-3', className)} style={{ opacity }}>
    <HolidayBenefitBenefitUnderlineText
      text={`Krok ${stepNumber}`}
      underlineColor="#951B81"
      textClass="text-holiday-benefit-tertiary font-xxl fw-bold lh-1"
    />
    <p className="font-xl mt-3">{description}</p>
    <p className="font-lg mb-0">{title}</p>
  </div>
)
