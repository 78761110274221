import * as React from 'react'
import clsx from 'clsx'
import { EndlessHolidayConfirmationKind, EndlessHolidayConfirmationSubscription } from '@modules/product-package/models'
import { formatPrice } from '@helpers/price-helper'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { EndlessHolidayConfirmationProductBaseRow } from '@modules/product-package/confirmation/products/confirmation-product-base-row'
import { getProductPackageCardName } from '@modules/product-package/helpers'

interface Props {
  subscription: EndlessHolidayConfirmationSubscription
  className: string
  kind: EndlessHolidayConfirmationKind
}

export const EndlessHolidayConfirmationSubscriptionProductRow = ({
  subscription,
  className,
  kind,
}: Props): React.ReactNode => {
  const isEndlessHoliday = kind === 'endless_holiday'

  const name = getProductPackageCardName(subscription, kind)

  const usageDates = {
    gold: 'od 01.01 do 07.07 oraz od 26.08 do 31.12',
    silver: 'od 01.01 do 30.06 oraz od 01.09 do 31.12*',
    brown: '01.01 do 31.05 oraz od 01.10 do 31.12*',
  }[subscription.kind]

  const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(subscription.accommodation_type)
    ? require('@assets/images/house.webp')
    : require('@assets/images/apartment.webp')

  const totalDepositPrice = asDecimal(subscription.deposit_amount).mul(subscription.number_of_codes).toString()
  const stayDateOptionPrice = asDecimal(
    sumDecimalArray(subscription.options.map(option => asDecimal(option.price_brutto))),
  )
    .mul(subscription.number_of_codes)
    .toString()

  const totalPrice = asDecimal(subscription.price_brutto)
    .add(isEndlessHoliday ? totalDepositPrice : 0)
    .add(stayDateOptionPrice)
    .toString()

  return (
    <EndlessHolidayConfirmationProductBaseRow
      className={className}
      kind={subscription.kind}
      title={name}
      image={accommodationType}
      subtitle={
        <span className="text-muted font-sm lh-base">
          Wykorzystaj w terminie: <br />
          {usageDates}
        </span>
      }
    >
      <div>
        <Row title="Koszt pakietu" content={formatPrice(subscription.price_brutto)} />
        {isEndlessHoliday ? (
          <Row
            title="Kaucja zwrotna do przyszłej rezerwacji"
            content={formatPrice(totalDepositPrice)}
            className="my-2 my-sm-0"
          />
        ) : (
          <Row title="Ilość voucherów" content={subscription.number_of_codes} className="my-2 my-sm-0" />
        )}
        {!!subscription.options.length && (
          <Row title="Jednorazowa zmiana daty" content={formatPrice(stayDateOptionPrice)} />
        )}
      </div>
      <Row title="Łączny koszt" content={formatPrice(totalPrice)} className="fw-bold text-darker-gray mt-2 font-lg" />
    </EndlessHolidayConfirmationProductBaseRow>
  )
}

const Row = ({ title, content, className }: { title: string; content: string | number; className?: string }) => (
  <div className={clsx('d-flex flex-wrap font-sm text-muted', className)}>
    <div className="col-sm-8 col-12 text-sm-end text-center">{title}:</div>
    <div className="col-sm-4 col-12 text-sm-end text-center">{content}</div>
  </div>
)
