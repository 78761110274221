import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleExtraFields: React.FC<Props> = ({ packageWholesaleDetails }) =>
  packageWholesaleDetails.extra_fields.map(field => (
    <CustomFormGroup
      key={field.key}
      label={field.label}
      inputName={`extra_fields[${field.key}]`}
      formLabelProps={{ className: 'fw-bold col-6' }}
      formControlProps={{ type: field.type, className: 'col-6', placeholder: '' }}
      formGroupProps={{ className: 'package-wholesale__data-input' }}
    />
  ))
