import * as React from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { RodoAgreementsDescriptionPage } from '@modules/rodo-agreements/description/description-page'
import { RodoAgreementsFormPage } from '@modules/rodo-agreements/form/form-page'
import { useToggle } from '@hooks/use-toggle'
import { useDevicesSize } from '@hooks/use-devices-size'
import { useAcquiringPointDetails } from '@modules/rodo-agreements/use-acquiring-point-details'

type Params = 'slug'

export const RodoAgreementsView = (): React.ReactNode => {
  const { slug } = useParams<Params>()
  const { isReady, isDesktop } = useDevicesSize()
  const [isFormVisible, toggleIsFormVisible, setIsFormVisible] = useToggle(false)

  const { rodoAcquiringPoint } = useAcquiringPointDetails(slug)

  if (!isReady || !rodoAcquiringPoint) return null

  const variants = {
    hidden: isDesktop ? { marginLeft: -350, opacity: 0 } : {},
    visible: isDesktop ? { marginLeft: 0, opacity: 1 } : {},
  }

  return (
    <motion.div
      className="rodo-agreements__view d-flex flex-sm-row flex-column"
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <div className="d-flex justify-content-between w-100 overflow-hidden align-items-center">
        <RodoAgreementsDescriptionPage
          rodoAcquiringPoint={rodoAcquiringPoint}
          isFormVisible={isFormVisible}
          onVoucherSubmitClick={toggleIsFormVisible}
        />
        <motion.img
          className="rodo-agreements__description-section__image d-sm-block d-none"
          src={rodoAcquiringPoint?.image}
          initial={{ width: '50%' }}
          animate={{
            width: isFormVisible ? '100px' : '50%',
            objectPosition: isFormVisible ? 'right' : 'center',
            borderRadius: isFormVisible ? '0 40px 40px 0' : '50px',
          }}
          transition={{ duration: 0.2, ease: 'linear' }}
        />
        <RodoAgreementsFormPage
          rodoAcquiringPoint={rodoAcquiringPoint}
          isFormVisible={isFormVisible}
          onBack={() => setIsFormVisible(false)}
        />
      </div>
    </motion.div>
  )
}
