import * as React from 'react'
import { CircleCheck } from '@components/circle-check'
import { formatPriceShort } from '@helpers/price-helper'
import { SubscriptionDetails, SubscriptionProductCodeFeature } from '@modules/subscription/models'
import { addYears, compareAsc, getYear } from 'date-fns'
import { parseISODate, toYearDateFormat } from '@helpers/date-formatter'
import * as clsx from 'clsx'
import { asDecimal } from '@helpers/utils'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionWithBenefitsDetailsContentBenefitList = ({ subscriptionDetails }: Props): JSX.Element => {
  const createDateWithOffset = (offset: string) =>
    addYears(parseISODate(subscriptionDetails.created), parseInt(offset, 10))

  const getCodeYear = (yearsOffsets: string[]) =>
    yearsOffsets.reduce(
      (offsets, offset) => `${offsets.length ? `${offsets}, ` : ''}${toYearDateFormat(createDateWithOffset(offset))}`,
      '',
    )

  const getFirstCodeDatesRange = React.useCallback(() => {
    const offsets = subscriptionDetails.product.code_features.reduce(
      (years, feature) => [...years, ...feature.years.flat()],
      [],
    )

    const expirationYear = getYear(parseISODate(subscriptionDetails.expire_after)) - getYear(new Date())

    const lastYear = [...offsets, expirationYear.toString()].map(createDateWithOffset).sort(compareAsc).at(-1) as Date

    return `${toYearDateFormat(subscriptionDetails.created)} - ${toYearDateFormat(lastYear)}`
  }, [subscriptionDetails.created, subscriptionDetails.product.code_features])

  const hasGastroGift = (feature: SubscriptionProductCodeFeature) => asDecimal(feature.gastro_gift).gt(0)

  const accommodationTypeDisplay = ACCOMMODATION_TYPES.APARTMENTS.includes(subscriptionDetails.accommodation_type)
    ? 'w apartamencie'
    : 'w domku'

  return (
    <>
      <p className="mt-4">Kupując pakiet otrzymasz:</p>
      <ul className="ps-0 font-sm">
        {subscriptionDetails.product.code_features.map((feature, index) => (
          <li key={feature.id} className={clsx('border-bottom py-1 ps-2', { 'border-bottom': index === 0 })}>
            <CircleCheck className="mb-1 mb-lg-0">
              {feature.years.length ? (
                <span className="fw-semibold d-flex align-items-center lh-1">
                  Voucher na pobyt {accommodationTypeDisplay} w {getCodeYear(feature.years)} roku
                  {hasGastroGift(feature) && ` + dodatkowe ${formatPriceShort(feature.gastro_gift)} na zakupy opaską`}
                  {feature.package_vip && (
                    <div className="d-flex align-items-center ms-1">
                      + pakiet{' '}
                      <div className="bg-black border rounded d-inline-block px-2 ms-2" style={{ height: 20 }}>
                        <img src={require('@assets/images/vip-white.svg')} alt="vip" height={14} />
                      </div>
                    </div>
                  )}
                </span>
              ) : (
                <span className="fw-semibold lh-1">
                  Voucher na pobyt {accommodationTypeDisplay} w latach {getFirstCodeDatesRange()}
                </span>
              )}
            </CircleCheck>
          </li>
        ))}
      </ul>
    </>
  )
}
