import * as React from 'react'
import { AppHeaderSection } from '@components/app-header-section'
import { AppHeaderContent } from '@components/app-header-content'
import { CodesDeactivation } from '@modules/codes-deactivation/common'

export const SuntagoCodesDeactivationView = (): JSX.Element => (
  <CodesDeactivation title="Dezaktywacja kodów wstępu do Suntago" codeMask="S-******">
    <AppHeaderSection images={GalleryImages} imageDescription="Holiday Park & Resort - Suntatgo">
      <AppHeaderContent
        title="Zarządzanie kodami wstępu"
        subtitle={
          <span>
            Niniejsza strona pozwala na zarządzanie kodami wstępu do obiektu Suntago.
            <br />
            Wprowadzenie kodu w sekcji poniżej powoduję jego dezaktywację. <br />
            <strong>Uwaga!</strong> Akcja jest nieodwracalna.
          </span>
        }
      />
    </AppHeaderSection>
  </CodesDeactivation>
)

export const GalleryImages = [
  require('@assets/images/suntago/suntago-1.webp'),
  require('@assets/images/suntago/suntago-2.webp'),
  require('@assets/images/suntago/suntago-3.webp'),
]
