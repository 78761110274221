import * as React from 'react'
import { OfferSeller } from '@components/offer-seller'
import { Seller } from '@models/base'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { ReservationDetailsPrices } from '@modules/reservation/details/bill/prices/reservation-details-prices'
import { ReservationDetails } from '@modules/reservation/models'
import { useSelector } from 'react-redux'
import { selectAccommodationTypes } from '@store/selectors/app-data-selector'
import { AccommodationType, Resort } from '@models/app-data'
import { ReservationDetailsInformation } from '@modules/reservation/details/reservation-details-information'
import { ReservationDetailsExpirationTimeLabel } from '@modules/reservation/details/reservation-details-expiration-time-label'
import { ClientInvoicePreview } from '@components/client-invoice/client-invoice-preview'
import { useCountries } from '@hooks/use-countries'

interface Props {
  seller: Seller | null
  created: string
  reservationDetails: ReservationDetails
  reservationResort: Resort | undefined
  onScrollToBill: () => void
}

export const ReservationDetailsContent = React.forwardRef(
  (
    { seller, created, reservationDetails, reservationResort, onScrollToBill }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => {
    const accommodationTypes = useSelector(selectAccommodationTypes)
    const { getCountryName } = useCountries()

    const reservationAccommodationType = accommodationTypes.find(
      (accommodationType: AccommodationType) => accommodationType.id === reservationDetails.accommodation_type_id,
    )

    return (
      <>
        <div className="d-flex justify-content-between">
          <div>
            <h3 className="text-gold fw-bold text-center text-sm-start">
              {reservationDetails.name}, to jest podsumowanie Twojej rezerwacji
            </h3>
            <p className="text-darker-gray font-sm text-center text-sm-start">
              Prosimy o sprawdzenie poprawności poniższych danych i dokonanie płatności.
            </p>
          </div>
          {seller && (
            <OfferSeller seller={seller} created={created} className="d-lg-block d-none" showBasePhoneNumber />
          )}
        </div>
        <div className="d-sm-none d-block">
          {seller && <OfferSeller seller={seller} created={created} showBasePhoneNumber />}
          {reservationDetails.offer_expiration_date && (
            <ReservationDetailsExpirationTimeLabel
              expirationDate={reservationDetails.offer_expiration_date}
              reservationNumber={reservationDetails.reservation_number}
            />
          )}
        </div>

        <ReservationDetailsInformation
          onScrollToBill={onScrollToBill}
          reservationDetails={reservationDetails}
          reservationResort={reservationResort}
          reservationAccommodationType={reservationAccommodationType}
        />
        <hr className="mx-n5 text-muted mt-sm-3 mt-0" />
        <strong className="text-gold text-center text-sm-start d-block">Dane osoby rezerwującej</strong>
        <div className="text-darker-gray mt-3 d-flex flex-column gap-1 font-md">
          <CardContentBaseDataRow name="Imię i nazwisko:" value={reservationDetails.name} />
          <CardContentBaseDataRow name="Adres e-maiil:" value={reservationDetails.email} />
          <CardContentBaseDataRow name="Numer telefonu:" value={reservationDetails.phone} />
          <CardContentBaseDataRow name="Adres zamieszkania:" value={reservationDetails.street} />
          <CardContentBaseDataRow name="" value={`${reservationDetails.postcode}, ${reservationDetails.city}`} />
          <CardContentBaseDataRow name="" value={getCountryName(reservationDetails.country)} />
        </div>

        {reservationDetails.invoice && <ClientInvoicePreview product={reservationDetails} />}

        {reservationDetails.requests && (
          <>
            <hr className="mx-n5 text-muted" />
            <div className="d-flex flex-wrap">
              <strong className="text-gold mb-3 mb-sm-0 col-sm-4 col-12 text-center text-sm-start d-block">
                Specjalne prośby
              </strong>
              <span className="col-sm-5 col-12 text-darker-gray">{reservationDetails.requests}</span>
            </div>
          </>
        )}
        {reservationDetails && <ReservationDetailsPrices ref={ref} reservationDetails={reservationDetails} />}
      </>
    )
  },
)
