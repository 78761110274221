import * as React from 'react'
import { ExpirationTimeLabel } from '@components/expiration-time-label'

interface Props {
  expirationDate: string
  reservationNumber: string
}

export const ReservationDetailsExpirationTimeLabel = ({
  expirationDate,
  reservationNumber,
}: Props): React.ReactNode => (
  <ExpirationTimeLabel
    expirationDate={expirationDate}
    messageText={<>pozostało na opłacenie rezerwacji {reservationNumber}</>}
    descriptionText="Po tym czasie rezerwacja zostanie automatycznie anulowana"
  />
)
