import * as React from 'react'

const HOLIDAY_MAIN = 'https://holidaypark.pl'

export const NotFound = (): JSX.Element => {
  const handleRedirect = () => {
    window.location.href = HOLIDAY_MAIN
  }

  return (
    <div className="not-found__wrapper">
      <div className="not-found__content">
        <div className="not-found__logo-section">
          <div className="not-found__number-wrapper">
            <strong className="not-found__number-msg">BŁĄD</strong>
            <strong className="not-found__number">404</strong>
            <strong className="not-found__number__shadow">404</strong>
          </div>
          <span className="not-found__text">
            Strona, której szukasz <strong className="not-found__text--bold">nie istnieje</strong>
          </span>
          <button className="btn not-found__button" onClick={handleRedirect}>
            Wróć do strony głównej
          </button>
        </div>
        <img src={require('@assets/images/404-bg.webp')} alt="Holidaypark 404" className="not-found__image" />
      </div>
    </div>
  )
}
