import * as React from 'react'
import * as clsx from 'clsx'
import { PackageWholesaleOfferSubscriptionCardOption } from '@modules/package-wholesale/details/subscription/card-additional-options/package-wholesale-offer-subscription-card-option'
import { PackageWholesaleProductOption } from '@modules/package-wholesale/models'

interface Props {
  isEnabled: boolean
  index: number
  options: PackageWholesaleProductOption[]
}

export const PackageWholesaleOfferSubscriptionCardOptions = ({ index, isEnabled, options }: Props): JSX.Element => (
  <div
    className={clsx('px-2 pt-4 mt-n2 text-darker-gray', {
      'border-end border-bottom border-start rounded': isEnabled,
    })}
  >
    <p className="font-sm mb-1">Opcje dodatkowe:</p>
    {options.map((option: PackageWholesaleProductOption) => (
      <PackageWholesaleOfferSubscriptionCardOption key={option.id} index={index} option={option} />
    ))}
  </div>
)
