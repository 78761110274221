import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { PackageWholesaleProductOption } from '@modules/package-wholesale/models'

interface Props {
  index: number
  option: PackageWholesaleProductOption
}

export const PackageWholesaleOfferSubscriptionCardOption = ({ index, option }: Props): JSX.Element => (
  <>
    <Checkbox
      id={`products.${index}.options.${option.kind}`}
      name={`products.${index}.options.${option.kind}`}
      label={option.kind_display}
      className="fw-bold checkbox--small font-sm"
    />
    <p className="package-wholesale__cards__card__option-description">{option.description}</p>
  </>
)
