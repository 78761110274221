import * as React from 'react'

interface Props {
  children: React.ReactNode
  productName: string
}

export const ManualBox = ({ children, productName }: Props): React.ReactNode => (
  <div className="mt-5">
    <p className="font-xxl text-primary text-center text-lg-start">
      Jak wykorzystać <span className="font-500 text-nowrap">{productName}?</span>
    </p>
    <div className="manual-box">{children}</div>
  </div>
)
