import * as React from 'react'
import * as clsx from 'clsx'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'

export const HolidayBenefitFooter = (): JSX.Element => (
  <div className="container py-5 my-lg-5">
    <div className="d-flex flex-wrap">
      <div className="col-lg-4 col-12 text-center text-lg-start">
        <strong className="holiday-benefit__section-title text-holiday-benefit-primary">Masz pytania?</strong>
        <p className="font-xxl mt-3">Skontaktuj się z Doradcą:</p>
      </div>
      <div className="d-flex col-lg-8 flex-column flex-lg-row">
        <Contact image={require('@assets/images/avatar-2-female.webp')} name="Anna Kowalska" />
        <Contact
          image={require('@assets/images/avatar-2-male.webp')}
          name="Andrzej Nowak"
          className="ms-lg-5 ps-lg-5 mt-3 mt-lg-0"
        />
      </div>
    </div>
    <p className="font-xl mt-5 holiday-benefit__footer__opening-hours col-lg-8 col-12 ms-auto text-center text-lg-start">
      Czekamy na Twój kontakt od poniedziałku do piątku, 9:00-17:00.
    </p>
  </div>
)

interface ContactProps {
  name: string
  className?: string
  image: string
}

const Contact = ({ name, className, image }: ContactProps) => (
  <div className={clsx('d-flex', className)}>
    <img src={image} alt="holidaypark logo" width={72} height={72} className="rounded-circle me-4" />
    <div>
      <HolidayBenefitBenefitUnderlineText
        text={name}
        underlineColor="#d50c51"
        textClass="text-holiday-benefit-primary font-xxl fw-bold"
      />

      <a href="tel:123 456 789" className="text-decoration-none d-block mt-3 text-holiday-benefit-secondary font-xl">
        tel: 123 456 789
      </a>
      <a
        href="mailto:anna.kowalska@holidaypark.pl"
        className="text-decoration-none text-holiday-benefit-secondary font-xl"
      >
        e-mail: anna.kowalska@holidaypark.pl
      </a>
    </div>
  </div>
)
