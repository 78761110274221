import { PaymentMethod } from '@models/app-data'
import { GTMItemCategory } from '@models/gtm'
import { createGtmCartItem, useGtmEvents } from '@hooks/gtm/use-gtm-events'
import { CodeOption } from '@modules/codes-conversion/models'

interface Response {
  sendPurchaseEvent: (codeOption: CodeOption) => void
  sendAddPaymentInfoEvent: (paymentMethod: PaymentMethod, codeOption: CodeOption) => void
  sendBeginCheckoutEvent: (step: number, codeOption: CodeOption) => void
}

export const useCodesConversionGtmEvents = (): Response => {
  const { purchase, addPaymentInfo, beginCheckout } = useGtmEvents()

  const sendAddPaymentInfoEvent = (paymentMethod: PaymentMethod, codeOption: CodeOption) => {
    addPaymentInfo({
      paymentMethod,
      clientId: codeOption.client,
      price: codeOption.price_brutto,
      items: createCodeOptionProductCartItem(codeOption),
    })
  }

  const sendPurchaseEvent = (codeOption: CodeOption) => {
    purchase({
      clientId: codeOption.client,
      transactionId: codeOption.token,
      price: codeOption.price_brutto,
      items: createCodeOptionProductCartItem(codeOption),
    })
  }

  const sendBeginCheckoutEvent = (step: number, codeOption: CodeOption) => {
    beginCheckout({
      clientId: codeOption.client,
      price: codeOption.price_brutto,
      items: createCodeOptionProductCartItem(codeOption),
      step,
    })
  }

  return {
    sendPurchaseEvent,
    sendAddPaymentInfoEvent,
    sendBeginCheckoutEvent,
  }
}

const createCodeOptionProductCartItem = (codeOption: CodeOption) => [
  createGtmCartItem({
    item_id: codeOption.option_kinds[0],
    item_name: codeOption.option_kinds[0],
    item_category: GTMItemCategory.Ecommerce,
    item_variant: codeOption.option_kinds[0],
    item_list_name: 'ulepszenie-kodu',
    price: codeOption.price_brutto,
    quantity: 1,
  }),
]
