import * as React from 'react'
import { HolidayBenefitCompanies } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-companies'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'

export const HolidayBenefitBenefitsNumbers = (): JSX.Element => (
  <div className="holiday-benefit__numbers">
    <div className="container d-flex gap-5 align-items-center flex-column-reverse flex-lg-row flex-wrap flex-lg-nowrap">
      <div className="col-lg-5 col-12">
        <img
          src={require('@assets/images/holiday-benefit/landing-page/holiday-dream.webp')}
          alt="Marzenia o wakacjach"
          height="650"
          className="image-responsive"
          style={{ maxWidth: '100%', objectFit: 'contain' }}
        />
      </div>
      <div className="col-lg-7 col-12">
        <div className="holiday-benefit__section-title text-center text-lg-start">
          <strong className="d-block text-holiday-benefit-primary">
            Spełnij marzenia{' '}
            <HolidayBenefitBenefitUnderlineText
              text="o wakacjach"
              underlineColor="#d50c51"
              textClass="text-holiday-benefit-primary"
            />
          </strong>
          <strong className="text-holiday-benefit-secondary mt-1 d-block">
            - zwiększ motywację swoich pracowników
          </strong>
        </div>
        <p className="text-holiday-benefit-secondary font-xl mt-4 text-center text-lg-start">
          Benefity w miejscu pracy są nieodzownym elementem nowoczesnej strategii zarządzania zasobami ludzkimi. Jest to
          kluczowe narzędzie wpływające na motywację, zdrowie oraz ogólną satysfakcję pracowników.
        </p>
        <div className="d-flex flex-column gap-2">
          {boxes.map(({ value, text }) => (
            <div className="d-flex holiday-benefit__numbers__box gap-3" key={value}>
              <div className="holiday-benefit__numbers__box__value text-holiday-benefit-primary col-lg-2 col-4 text-nowrap">
                {value} <span className="font-xxxl">%</span>
              </div>
              <div className="col-lg-10 col-8 font-xl">{text}</div>
            </div>
          ))}
        </div>
        <div className="font-xl mt-4">- badania ICAN Research na zlecenie VanityStyle</div>
      </div>
    </div>
    <HolidayBenefitCompanies />
  </div>
)

const boxes = [
  { value: 58, text: 'aż tylu pracowników uważa dofinansowanie wypoczynku za najbardziej pożądany benefit' },
  { value: 94, text: 'zarządzających uważa, że benefity to nie koszt a inwestycja' },
  {
    value: 33,
    text: 'zarządzających przyznaje, że ich firma potrzebuje wsparcia w budowaniu polityki employer brandingowej',
  },
  {
    value: 35,
    text: 'jedynie tylu pracodawców oferuje dofinansowanie wypoczynku wśród posiadanych benefitów',
  },
]
