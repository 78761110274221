import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'

interface Props {
  onScrollToDetails: () => void
}

export const SubscriptionBookingImprovementHeaderSectionContent = ({ onScrollToDetails }: Props): React.ReactNode => (
  <AppHeaderContent
    title="Voucher Super Start"
    subtitle={
      <span className="font-lg">
        Skorzystaj ze specjalnej promocji na voucher <strong>„Super Start”</strong> <br /> do wykorzystania na pobyt
        6-dniowy w domku lub apartamencie
      </span>
    }
  >
    <button className="btn package-wholesale__details-button mt-5" onClick={onScrollToDetails}>
      <strong className="text-white">Szczegóły oferty</strong>
    </button>
  </AppHeaderContent>
)
