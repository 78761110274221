import * as React from 'react'
import { SubscriptionDetails, SubscriptionOption } from '@modules/subscription/models'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import * as clsx from 'clsx'
import { CardContentProductInfo } from '@components/card-content-product-info'
import { formatPrice } from '@helpers/price-helper'
import { asDecimal, sumDecimalArray } from '@helpers/utils'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionWithBenefitsDetailsContentProductInfo: React.FC<Props> = ({ subscriptionDetails }) => (
  <CardContentProductInfo
    number={subscriptionDetails.number}
    price={subscriptionDetails.product.price_brutto}
    footer={<Footer options={subscriptionDetails.options} />}
  >
    <CardContentBaseDataRow
      name="Nazwa pakietu:"
      value={subscriptionDetails.product.name}
      valueClassName={clsx({
        'subscription-variant__header is-lux': subscriptionDetails.kind === 'gold',
        'subscription-variant__header is-comfort': subscriptionDetails.kind === 'silver',
        'subscription-variant__header is-optimum': subscriptionDetails.kind === 'brown',
      })}
    />
  </CardContentProductInfo>
)

const Footer = ({ options }: { options: SubscriptionOption[] }) => {
  if (!options.length) return null

  const totalOptionsCost = sumDecimalArray(options.map(option => asDecimal(option.price_brutto))).toString()

  return (
    <CardContentBaseDataRow
      nameClassName="align-self-start"
      name="Opcje dodatkowe:"
      value={
        <>
          <span className="d-block">{formatPrice(totalOptionsCost)}</span>
          <ul className="mb-0 p-0 font-500 font-xs">
            {options.map(option => (
              <li key={option.id}>
                - {option.kind_display} ({formatPrice(option.price_brutto)})
              </li>
            ))}
          </ul>
        </>
      }
    />
  )
}
