import * as React from 'react'
import { PackageWholesaleSubscriptionExpandableVariant } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-expandable-variant'
import { PackageWholesaleSubscriptionExpandableVariantSubtitle } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-expandable-variant-subtitle'
import { PackageWholesaleCardsSubscriptionSection } from '@modules/package-wholesale/details/subscription/package-wholesale-cards-subscription-section'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { PackageWholesaleLeafletButton } from '@modules/package-wholesale/details/subscription/package-wholesale-leaflet-button'

interface Props {
  products: PackageWholesaleProduct[]
  leafletUrl: string | null
  isDefaultExpanded?: boolean
}

export const PackageWholesaleSubscriptionVariantGold = ({
  products,
  leafletUrl,
  isDefaultExpanded,
}: Props): JSX.Element => (
  <PackageWholesaleSubscriptionExpandableVariant
    isDefaultExpanded={isDefaultExpanded}
    variant="gold"
    title={<h5 className="subscription-variant__header is-gold fw-bolder">Pakiet złoty</h5>}
    icon={<img src={require('@assets/images/subscription-variants/gold.svg')} alt="Pakiet złoty" height="40" />}
    subtitle={
      <PackageWholesaleSubscriptionExpandableVariantSubtitle
        dates={
          <span>
            od 01.01 do 07.07 oraz <span className="text-nowrap">od 26.08 do 31.12</span>
          </span>
        }
      />
    }
  >
    <PackageWholesaleCardsSubscriptionSection packageWholesaleProducts={products} />
    <PackageWholesaleLeafletButton leafletUrl={leafletUrl} wrapperClassName="mt-3 d-block" />
  </PackageWholesaleSubscriptionExpandableVariant>
)
