import { GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatToWordsWithUppercaseLetterStart } from '@helpers/utils'
import * as React from 'react'

export const getGastroCard2026OctoberData = (gastroProduct: GastroProduct): GastroProductData => {
  const labelImageSrc = 'gastro-voucher-label.jpg'

  const productName = formatToWordsWithUppercaseLetterStart(gastroProduct.product_name)

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher “{productName}” </strong>do
      wykorzystania <span className="text-nowrap"> w Kawiarni, Bistro,</span> Beach Barze i Słodkim Kąciku podczas
      pobytów w Resortach.
    </span>
  )

  const benefitsPoints = [
    'Zakup vouchera umożliwia dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o 95%.',
    'Voucher jest wielokrotnego użytku, w różnych rezerwacjach.',
    'Ilość voucherów jakie możesz kupić jest nieograniczona!',
  ]

  const benefitsList = [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2026 - 31.12.2027',
  ]

  const alertText =
    'Voucher nie łączy się z promocją Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Podwójna Radość 2026/2027 można podpiąć kolejny dowolny voucher gastronomiczny.'

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
