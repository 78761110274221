import * as React from 'react'
import * as clsx from 'clsx'
import { Form, FormCheckProps } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

interface Props extends FormCheckProps {
  wrapperClassName?: string
  name?: string
  controlled?: boolean
}

export const CircleOutlineRadioButton = ({ name, wrapperClassName, controlled, ...props }: Props): JSX.Element => {
  const { register } = useFormContext()

  return (
    <div className={clsx('position-relative', wrapperClassName)}>
      <div className="position-relative cursor-pointer">
        <Form.Check
          {...props}
          {...((!controlled && name && register(name)) || {})}
          type="radio"
          className={clsx('circle-outline-radio-button', props.className)}
        />
        <i className="uil-check circle-outline-radio-button__check-icon" />
      </div>
    </div>
  )
}
