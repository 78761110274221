import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'

interface Props {
  basicPrice: string
  bonusPrice: string
  promotionPercentage: string
}

export const GastroGenericPreview = ({ basicPrice, bonusPrice, promotionPercentage }: Props): JSX.Element => (
  <div className="gastro-coupon__preview my-3">
    <img
      className="gastro-coupon__preview__image"
      src={require('@assets/images/gastro-card-slides/gastro-card-slide-5.jpg')}
      alt="Kupon gastronomiczny Holiday Park & Resort"
    />
    <div className="gastro-coupon__preview__description">
      <div className="mt-3 d-flex flex-sm-row flex-column w-100 justify-content-center">
        <div className="gastro-coupon__preview__declared-price">
          <strong>Wpłacana kwota:</strong>
          <strong className="gastro-coupon__preview__price">{formatPriceShort(basicPrice)}</strong>
        </div>

        <div className="gastro-coupon__preview__bonus">
          <img
            src={require('@assets/images/gastr-coupon-bg.svg')}
            alt="Kupon gastronomiczny"
            className="gastro-coupon__preview__bonus__bg"
          />
          <div className="d-flex align-items-center">
            <span className="font-xl me-1">+</span>
            <span className="font-xxl lh-sm fw-semibold">{promotionPercentage}</span>
            <span className="font-xl ms-1 align-self-end">%</span>
          </div>
        </div>
        <div className="gastro-coupon__preview__extra-price">
          <strong>Wartość kuponu:</strong>
          <strong className="gastro-coupon__preview__price">{formatPriceShort(bonusPrice)}</strong>
        </div>
      </div>
    </div>
  </div>
)
