import * as React from 'react'

type ToggleVisibility = [boolean, () => void, (value: boolean) => void]

export const useToggle = (defaultValue = false): ToggleVisibility => {
  const [isActive, setIsActive] = React.useState(defaultValue)

  const toggleState = React.useCallback(() => {
    setIsActive(state => !state)
  }, [setIsActive])

  const setValue = React.useCallback((value: boolean) => {
    setIsActive(value)
  }, [])

  return [isActive, toggleState, setValue]
}
