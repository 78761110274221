import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onScrollToDetails: () => void
}

export const SubscriptionWithBenefitsDetailsHeaderSectionContent = ({ onScrollToDetails }: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <span>
        Wakacyjny Pakiet Korzyści <span className="text-nowrap">od Holiday Park & Resort</span>
      </span>
    }
    subtitle={
      <span className="font-lg col-sm-8 d-block">
        Skorzystaj z oferty Pakietów Pobytów wraz z dodatkowymi benefitami do wykorzystania w{' '}
        <strong>ciągu 5 lat</strong> we wszystkich dostępnych resortach{' '}
        <strong className="text-nowrap">Holiday Park & Resort</strong>
      </span>
    }
  >
    <button
      className="btn btn-unstyled mt-5 d-flex justify-content-center justify-content-sm-start w-100"
      onClick={onScrollToDetails}
    >
      <IconWithText
        text="Szczegóły oferty"
        iconClassName="uil-plus me-2 font-xxl"
        className="text-primary fw-bold"
        textClassName="font-xl"
      />
    </button>
  </AppHeaderContent>
)
