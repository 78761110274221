import * as React from 'react'
import { PackageWholesaleSingleKindSideCard } from '@modules/package-wholesale/details/package-wholesale-single-kind-side-card'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleProductKind } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  kind: PackageWholesaleProductKind
}

export const PackageWholesaleGastroCardSideCard = ({ packageWholesaleDetails, kind }: Props): JSX.Element => (
  <PackageWholesaleSingleKindSideCard packageWholesaleDetails={packageWholesaleDetails} kind={kind} />
)
