import { ReservationDetailsPricesSection } from '@modules/reservation/details/bill/prices/reservation-details-prices-section'
import { ReservationDetailsPriesSectionItem } from '@modules/reservation/details/bill/prices/reservation-details-pries-section-item'
import * as React from 'react'
import { sumDecimalArray } from '@helpers/utils'
import Decimal from 'decimal.js'
import { BookingGuest, Feeding, PriceItem } from '@modules/reservation/models'

interface FeedingGuestPrices {
  guestId: number
  price: string
}

interface Props {
  feeding: PriceItem<Feeding>
  guests: BookingGuest[]
}

export const ReservationDetailsPricesFeeding = ({ feeding, guests }: Props): JSX.Element | null => {
  const [feedingGuestPrices, setFeedingGuestPrices] = React.useState<FeedingGuestPrices[]>([])

  React.useEffect(() => {
    setFeedingGuestPrices(
      guests.map((guest: BookingGuest) => ({
        guestId: guest.id,
        price: sumDecimalArray(
          feeding.items
            .filter((feeding: Feeding) => feeding.guest_id === guest.id)
            .map((feeding: Feeding) => new Decimal(feeding.price_brutto)),
        ).toString(),
      })),
    )
  }, [guests])

  if (feedingGuestPrices.every(el => el.price === '0')) return null

  return (
    <>
      <ReservationDetailsPricesSection title="Wyżywienie:" totalPrice={feeding.total_price_brutto}>
        {guests.map((guest: BookingGuest) => (
          <ReservationDetailsPriesSectionItem
            key={guest.id}
            title={guest.type_display}
            price={feedingGuestPrices.find((prices: FeedingGuestPrices) => prices.guestId === guest.id)?.price || '0'}
          />
        ))}
      </ReservationDetailsPricesSection>
      <hr className="text-muted reservation-details__payment-bill__separator" />
    </>
  )
}
