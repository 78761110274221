import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { AppHeaderContent } from '@components/app-header-content'

interface Props {
  onScrollToBill: () => void
}

export const BookingOptionHeaderSectionContent = ({ onScrollToBill }: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <>
        Podsumowanie zakupu <br />
        opcji do rezerwacji
      </>
    }
  >
    <div className="mt-4 d-flex justify-content-center justify-content-sm-start flex-wrap col-12">
      <IconWithText
        text="Zobacz podsumowanie"
        iconClassName="uil-plus font-xxxl me-2"
        className="text-gold font-size-xl cursor-pointer fw-bold"
        onClick={onScrollToBill}
      />
    </div>
  </AppHeaderContent>
)
