import * as React from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

export type ConfettiRef = LottieRefCurrentProps

interface Props {
  children: React.ReactNode
}

export const ConfettiWrapper = React.forwardRef(
  ({ children }: Props, ref: React.MutableRefObject<LottieRefCurrentProps | null>): React.ReactNode => {
    ref.current?.setSpeed(1.3)

    return (
      <>
        <Lottie
          onComplete={() => ref.current?.stop()}
          animationData={require('@assets/animations/confetti.json')}
          lottieRef={ref}
          autoplay={false}
          loop={false}
          className="confetti-wrapper"
        />
        {children}
      </>
    )
  },
)
