import * as React from 'react'
import { GeneralTerms } from '@components/general-terms'

export const AppFooter = (): JSX.Element => {
  const scrollTop = React.useCallback(() => window.scrollTo(0, 0), [])

  return (
    <div className="app-footer">
      <div className="bg-dark-blue app-footer__background py-5">
        <div className="container text-white d-flex align-items-center">
          <div className="col-4">
            <a href="https://holidaypark.pl">
              <img
                src={require('@assets/images/logo_holiday_white@3x.svg')}
                alt="Holiday Park & Resort logo"
                height="30"
              />
            </a>
          </div>
          <div className="col-4 justify-content-center d-inline-flex">
            <button onClick={scrollTop} className="btn-unstyled">
              <img
                src={require('@assets/images/arrowTop.svg')}
                height="33"
                alt="Scroll to top"
                className="cursor-pointer"
              />
            </button>
          </div>
          <div className="d-flex gap-2 align-items-center col-4 d-flex flex-sm-row flex-column justify-content-end font-sm">
            <span className="me-3">Dołącz do nas:</span>
            <div className="d-flex gap-2">
              {socialMedia.map(item => (
                <a key={item.url} href={item.url}>
                  <img src={item.image} height="25" alt={item.description} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <GeneralTerms />
    </div>
  )
}

const socialMedia = [
  {
    url: 'https://pl-pl.facebook.com/HolidayParkAndResort/',
    image: require('@assets/images/icon_facebook@2x.svg'),
    description: 'Facebook Holiday Park & Resort',
  },
  {
    url: 'https://www.instagram.com/holiday_park_resort/',
    image: require('@assets/images/icon_insta@2x.svg'),
    description: 'Instagram Holiday Park & Resort',
  },
  {
    url: 'https://www.youtube.com/channel/UCNpBXyb05GhbDRM2WoiUdBg/about',
    image: require('@assets/images/icon_youtube@2x.svg'),
    description: 'Youtube Holiday Park & Resort',
  },
]
