import * as React from 'react'
import { ReservationDetailsPaymentScheduleBar } from '@modules/reservation/details/bill/payment/reservation-details-payment-schedule-bar'
import { BasePaymentSummary, PaymentSummary } from '@modules/reservation/models'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import Decimal from 'decimal.js'
import { useReservationPaymentData } from '@modules/reservation/details/use-reservation-payment-data'

const getPaymentSummaryPrice = (paymentItem: BasePaymentSummary) =>
  new Decimal(paymentItem.deposit).plus(paymentItem.installment).toNumber()

interface Props {
  paymentSummary: PaymentSummary
}

export const ReservationDetailsPaymentSchedule = ({ paymentSummary }: Props): JSX.Element => {
  const alreadyPaid = getPaymentSummaryPrice(paymentSummary.paid)
  const restToPay = getPaymentSummaryPrice(paymentSummary.rest)
  const requiredPayment = getPaymentSummaryPrice(paymentSummary.required)

  const requiredPaymentDate = formatDate(
    paymentSummary.required.required_date,
    DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT,
  )

  const { paymentDate } = useReservationPaymentData(paymentSummary)

  const totalPrice = new Decimal(requiredPayment).plus(alreadyPaid).plus(restToPay).toNumber()
  const getPercent = (price: number) => new Decimal(price).mul(100).div(totalPrice).toDecimalPlaces(1).toString()

  return (
    <div className="mt-4">
      <strong className="text-darker-gray">Harmonogram płatności (pobyt + kaucja)</strong>
      <div className="d-flex reservation-details__payment-schedule__wrapper">
        {alreadyPaid > 0 && (
          <ReservationDetailsPaymentScheduleBar
            percentageIndicator={`opłacono ${getPercent(alreadyPaid)}%`}
            percentage={getPercent(alreadyPaid)}
            className="is-rest-value is-paid"
            price={alreadyPaid}
          />
        )}
        {requiredPayment > 0 && (
          <ReservationDetailsPaymentScheduleBar
            percentageIndicator={`wpłata ${getPercent(requiredPayment)}%`}
            percentage={getPercent(requiredPayment)}
            className="is-base-value"
            price={requiredPayment}
            barLabel={`do ${requiredPaymentDate}`}
          />
        )}

        {restToPay > 0 && (
          <ReservationDetailsPaymentScheduleBar
            percentageIndicator={`pozostała kwota ${getPercent(restToPay)}%`}
            percentage={getPercent(restToPay)}
            className="is-rest-value"
            price={restToPay}
            barLabel={`do ${formatDate(paymentDate, DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)}`}
          />
        )}
      </div>
    </div>
  )
}
