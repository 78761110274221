import * as React from 'react'
import Decimal from 'decimal.js'
import { DateFormats, formatDate, getDifferenceInDays } from '@helpers/date-formatter'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { ReservationDetailsPricesDiscounts } from '@modules/reservation/details/bill/prices/reservation-details-prices-discounts'
import { ReservationDetailsPricesItem } from '@modules/reservation/details/bill/prices/reservation-details-prices-item'
import { ReservationDetailsPricesFeeding } from '@modules/reservation/details/bill/prices/reservation-details-prices-feeding'
import { ReservationDetailsPricesImprovements } from '@modules/reservation/details/bill/prices/reservation-details-prices-improvements'
import { ReservationDetails } from '@modules/reservation/models'
import { ReservationDetailsPaymentSchedule } from '@modules/reservation/details/bill/payment/reservation-details-payment-schedule'
import { selectResorts } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { Resort } from '@models/app-data'
import { CustomTooltip } from '@components/custom-tooltip'
import { declination } from '@helpers/declination'
import { asDecimal } from '@helpers/utils'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationDetailsPrices = React.forwardRef(
  ({ reservationDetails }: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const resorts = useSelector(selectResorts)

    const reservationResort = React.useMemo(
      () => resorts.find((resort: Resort) => resort.id === reservationDetails.resort_id),
      [reservationDetails.resort_id],
    )

    const lowestClimaticPrice = React.useMemo(() => {
      const prices = reservationDetails.prices.climatic.items.reduce((previousValue, climaticItem) => {
        const price = asDecimal(climaticItem.single_price_brutto)
        return price.gt(0) ? [...previousValue, price] : previousValue
      }, [])

      return formatPriceShort(Decimal.min(...(prices.length ? prices : [new Decimal('0')])).toString())
    }, [reservationResort, reservationDetails])

    const daysDiff = getDifferenceInDays(reservationDetails.date_from, reservationDetails.date_to)

    const shouldShowServicePrice = asDecimal(reservationDetails.prices.single_service_price_brutto_from).gt(0)

    return (
      <div className="bg-shadow pt-1 mt-3" ref={ref}>
        <div className="bg-light-azure mx-n4">
          <div className="bg-light-blue rounded pt-4 px-4">
            <p className="text-primary fw-bold font-lg text-center text-sm-start d-block">
              Całkowity koszt rezerwacji:
            </p>
            <ReservationDetailsPricesItem
              name={
                <>
                  Pobyt w dniach {formatDate(reservationDetails.date_from)} - {formatDate(reservationDetails.date_to)} (
                  {daysDiff} {declination.hotelDay(daysDiff)})
                </>
              }
              price={reservationDetails.prices.residence.total_price_brutto_before_discount}
              removeSeparator
            />
            <hr className="text-muted reservation-details__payment-bill__separator" />

            {!!reservationDetails.prices.improvements.items.length && (
              <ReservationDetailsPricesImprovements improvements={reservationDetails.prices.improvements} />
            )}

            {!!reservationDetails.guests.length && (
              <ReservationDetailsPricesFeeding
                feeding={reservationDetails.prices.feeding}
                guests={reservationDetails.guests}
              />
            )}

            {reservationDetails.warranty && (
              <ReservationDetailsPricesItem
                name="Opcja rezygnacji"
                price={reservationDetails.prices.warranty_price_brutto}
              />
            )}

            <div className="text-darker-gray d-flex justify-content-between font-lg my-2">
              <strong className="d-block">Należność razem:</strong>
              <strong className="d-block">
                {formatPrice(reservationDetails.prices.stay_charge_without_discount_without_climatic)}
              </strong>
            </div>
            <hr className="reservation-details__payment-bill__separator" />

            {!!reservationDetails.prices.discounts.items.length && (
              <ReservationDetailsPricesDiscounts discounts={reservationDetails.prices.discounts} />
            )}

            {!!reservationDetails.prices.tourist_vouchers.items.length && (
              <ReservationDetailsPricesItem
                name="Bon turystyczny"
                price={reservationDetails.prices.tourist_vouchers.items[0].price_brutto}
                pricePrefix="-"
              />
            )}

            <div className="bg-white py-2 mx-n4 px-4">
              <div className="font-lg text-darker-gray d-flex justify-content-between">
                <strong className="d-block">Do zapłaty łącznie:</strong>
                <strong className="d-block text-danger">
                  {formatPrice(reservationDetails.prices.stay_charge_without_climatic)}
                </strong>
              </div>
              <div className="font-sm text-muted d-flex gap-3 justify-content-between mt-2">
                <strong className="d-block opacity-50">
                  Kaucja zwrotna (nie jest zawarta w cenie){' '}
                  {reservationDetails.prices.payments_summary.rest.required_date_deposit && (
                    <span>
                      płatna do dnia{' '}
                      {formatDate(
                        reservationDetails.prices.payments_summary.rest.required_date_deposit,
                        DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT,
                      )}
                    </span>
                  )}
                </strong>
                <strong className="d-block opacity-50">
                  {formatPrice(reservationDetails.prices.deposit_amount)} / pobyt{' '}
                </strong>
              </div>
              <div className="font-sm text-muted d-flex gap-3 justify-content-between mt-2">
                <strong className="d-block opacity-50">
                  Do zapłaty w obiekcie: opłata klimatyczna (nie jest zawarta w cenie)
                </strong>
                <CustomTooltip
                  tooltipClassName="p-0"
                  content={
                    <ul className="px-3 py-2 mb-0 font-500">
                      {reservationDetails.prices.climatic.items.map(climaticItem => (
                        <li key={climaticItem.name} className="text-nowrap">
                          {climaticItem.name}: {formatPriceShort(climaticItem.single_price_brutto)}
                        </li>
                      ))}
                    </ul>
                  }
                  showOnHover
                >
                  <strong className="d-block opacity-50">od {lowestClimaticPrice} / os. za dzień </strong>
                </CustomTooltip>
              </div>
              {shouldShowServicePrice && !reservationDetails.skip_service_charge && (
                <div className="font-sm text-muted d-flex gap-3 justify-content-between mt-2">
                  <strong className="d-block opacity-50">Opłata eksploatacyjna (nie jest zawarta w cenie) </strong>
                  <strong className="d-block opacity-50">
                    od {formatPrice(reservationDetails.prices.single_service_price_brutto_from)} / os. za dzień{' '}
                  </strong>
                </div>
              )}
            </div>
            <hr className="reservation-details__payment-bill__separator" />
          </div>
        </div>
        <ReservationDetailsPaymentSchedule paymentSummary={reservationDetails.prices.payments_summary} />
      </div>
    )
  },
)
