import * as React from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { fetchAppData } from '@requests/app-data-requests'
import { setClientDetails } from '@store/slices/client-slice'
import { ClientDetails } from '@models/client'
import { AppData } from '@models/app-data'

interface Props {
  fetchAppData: () => Promise<AppData>
  setClientDetails: typeof setClientDetails
  children: React.ReactNode
}

interface State {
  loading: boolean
}

export class StartupViewComponent extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  public componentDidMount(): void {
    this.initializeData()
  }

  public render(): React.ReactNode {
    return this.state.loading ? null : this.props.children
  }

  public componentDidCatch(error: Error): void {
    Sentry.captureException(error)
  }

  public initializeData = async (): Promise<void> => {
    const data = await this.props.fetchAppData()
    this.props.setClientDetails(data.user)
    this.setState({ loading: false })
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAppData: () => dispatch(fetchAppData()),
  setClientDetails: (clientDetails: ClientDetails | null) => dispatch(setClientDetails(clientDetails)),
})

const StartupView = connect(null, mapDispatchToProps)(StartupViewComponent)

export default StartupView
