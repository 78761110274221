import * as React from 'react'
import clsx from 'clsx'

interface Props {
  onScrollToPlansSection: () => void
  title: React.ReactNode
  subtitle: React.ReactNode
  actionButton: React.ReactNode
  points: React.ReactNode[]
  className?: string
}

export const HolidayBenefitHero = ({
  onScrollToPlansSection,
  title,
  subtitle,
  points,
  actionButton,
  className,
}: Props): React.ReactNode => (
  <div className="holiday-benefit__hero__wrapper">
    <div className="container">
      <div className={clsx('holiday-benefit__hero rounded-4', className)}>
        <div className="col-md-6 col-12 mx-auto mx-lg-0">
          <h1 className="holiday-benefit__section-title text-white text-center text-lg-start">{title}</h1>
          <p className="mt-5 mb-lg-5 mb-4 text-white font-xl text-center text-lg-start">{subtitle}</p>
          <ul className="list-unstyled">
            {points.map((point, index) => (
              <ListItem key={index}>{point}</ListItem>
            ))}
          </ul>
          <div className="d-flex flex-wrap gap-lg-4 gap-3 mt-lg-5 mt-4">
            <button className="btn-holiday-benefit is-primary btn-responsive" onClick={onScrollToPlansSection}>
              Zobacz ofertę
            </button>
            {actionButton}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ListItem = ({ children }) => (
  <li className="d-flex align-items-center mt-2">
    <span className="uil-check holiday-benefit__benefits__point bg-white text-holiday-benefit-secondary" />
    <span className="text-white">{children}</span>
  </li>
)
