import * as React from 'react'
import { EndlessHolidayConfirmationDetails } from '@modules/product-package/models'
import { CardContentBase } from '@components/card-content-base'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidayConfirmationBill } from '@modules/product-package/confirmation/confirmation-bill'
import { EndlessHolidayConfirmationProducts } from '@modules/product-package/confirmation/products/confirmation-products'
import clsx from 'clsx'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
  onScrollToBill: () => void
}

export const EndlessHolidayConfirmationDetailsContent = React.forwardRef(
  ({ productDetails, onScrollToBill }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    return (
      <>
        <CardContentBase
          isProductPaid={asDecimal(productDetails.required_payment).lte(0)}
          client={productDetails.client}
          titleClassName={clsx({ 'mt-5': productDetails.kind === 'voucher' })}
          title="Podsumowanie Twojego zamówienia"
          seller={productDetails.seller ?? undefined}
          created={productDetails.created}
          onScrollToPayment={onScrollToBill}
          productInvoice={productDetails}
          withClientData={false}
        >
          <CardContentBaseDataRow
            name="Numer zamówienia:"
            value={productDetails.number}
            nameClassName="text-gold text-center text-sm-start d-block fw-bold col-12 col-sm-4 text-center text-sm-start"
            valueClassName="text-darker-gray col-12 col-lg-auto text-center text-sm-start mb-3 mb-lg-0"
          />
          <CardContentBaseDataRow
            name="Adres e-mail:"
            value={productDetails.client.email}
            nameClassName="text-gold text-center text-sm-start d-block fw-bold col-12 col-sm-4 text-center text-sm-start mt-2"
            valueClassName="text-darker-gray col-12 col-lg-auto text-center text-sm-start mt-2"
          />
          {productDetails.kind === 'voucher' && (
            <CardContentBaseDataRow
              name="Termin wykorzystania:"
              value="3 lata od dnia zakupu"
              nameClassName="text-gold text-center text-sm-start d-block fw-bold col-12 col-sm-4 text-center text-sm-start mt-2"
              valueClassName="text-darker-gray col-12 col-lg-auto text-center text-sm-start mt-2"
            />
          )}
        </CardContentBase>
        <div className="overflow-hidden mx-n4 px-4">
          <hr className="mx-n5" />
          <EndlessHolidayConfirmationProducts productDetails={productDetails} />
          <hr className="mx-n5" />
          <EndlessHolidayConfirmationBill productDetails={productDetails} ref={ref} />
        </div>
      </>
    )
  },
)
