import * as React from 'react'
import { Spinner } from '@components/spinner'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { differenceInDays } from 'date-fns'

interface Props {
  expireAfter: string
}

export const PackageWholesaleSideCardExpirationBanner = ({ expireAfter }: Props): JSX.Element => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(expireAfter)

  const isOnlyWeekLeft = differenceInDays(parseISODate(expireAfter), new Date()) < 7

  return (
    <div className="rounded bg-danger d-flex flex-column justify-content-center align-items-center text-white mt-2 py-3">
      {isOnlyWeekLeft ? (
        isRemainingTimeCalculating ? (
          <Spinner className="text-white spinner-border-sm" />
        ) : (
          <strong className="d-block font-sm">Oferta wygasa za {remainingTime}</strong>
        )
      ) : (
        <>
          <strong className="d-block font-sm">Oferta ważna tylko do</strong>
          <div className="d-flex align-items-end mt-1">
            <span className="font-xxl font-500 me-1 lh-1">{formatDate(expireAfter, DateFormats.DAY)}</span>
            <span className="font-xl font-500 me-1 lh-initial">
              {formatDate(expireAfter, DateFormats.FULL_MONTH_FULL_YEAR)}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
