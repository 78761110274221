import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'

export const ClientInvoiceEditCompanyData = (): JSX.Element => {
  const { watch } = useFormContext<PackageWholesaleFormInput>()

  const invoiceType = watch('invoice_type')

  return (
    <div>
      <CustomFormGroup
        label={invoiceType === 'company' ? 'Nazwa firmy:' : 'Imię i nazwisko:'}
        inputName="invoice_company"
        formLabelProps={{ className: 'fw-bold col-12 col-sm-6' }}
        formControlProps={{ type: 'text', className: 'col-12 col-sm-6', placeholder: 'Andrzej Nowak' }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
      />
      <CustomFormGroup
        label={invoiceType === 'company' ? 'Adres firmy:' : 'Adres:'}
        inputName="invoice_street"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'text', className: 'col-6', placeholder: 'Ulica' }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
      />

      <div className="col-sm-6 col-12 ms-auto px-0">
        <div className="row">
          <CustomFormGroup
            inputName="invoice_postcode"
            maskedFormControlProps={{
              mask: '**-***',
              alwaysShowMask: true,
              className: 'letter-spacing-2',
            }}
            formControlProps={{ type: 'text' }}
            formGroupProps={{ className: 'package-wholesale__data-input col-4' }}
          />
          <CustomFormGroup
            inputName="invoice_city"
            formControlProps={{ type: 'text', placeholder: 'Miasto' }}
            formGroupProps={{ className: 'package-wholesale__data-input col-8 ps-0' }}
          />
        </div>
      </div>
    </div>
  )
}
