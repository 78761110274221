import { PaymentMethod } from '@models/app-data'
import { getDay, getHours } from 'date-fns'
import { useSelector } from 'react-redux'
import { selectPaymentMethods } from '@store/selectors/app-data-selector'

const UNAVAILABLE_PAYMENT = 'unavailable'

export const useActivePaymentMethods = (): PaymentMethod[] => {
  const paymentMethods = useSelector(selectPaymentMethods)

  return paymentMethods.filter((paymentMethod: PaymentMethod) => {
    const currentDate = new Date()
    const currentDay = getDay(currentDate)
    const currentHour = getHours(currentDate)

    const { monday_to_friday, sunday, saturday } = paymentMethod.availability_hours
    const availabilityHours = currentDay <= 4 ? monday_to_friday : currentDay === 5 ? saturday : sunday

    if (availabilityHours === UNAVAILABLE_PAYMENT) {
      return false
    }

    if (typeof availabilityHours === 'boolean') {
      return availabilityHours
    }

    const [from, to] = availabilityHours.split('-').map(hour => parseInt(hour, 10))

    return currentHour >= from && currentHour <= to
  })
}
