import * as React from 'react'
import { AgreementSection } from '@components/agreement/agreement-section'
import { Rule } from '@modules/reservation/models'
import { useRules } from '@hooks/use-rules'
import { AvailableRule, AvailableRuleKeyword } from '@models/app-data'
import { AgreementCheckbox } from '@components/agreement/agreement-checkbox'
import { MARKETING_RULES_KEYWORDS } from '@components/agreement/marketing-agreement-section'

interface Props {
  rules?: Rule[]
  productRules?: AvailableRuleKeyword[]
  getOverwrittenRule?: (rule: AvailableRule) => AvailableRule
  title?: React.ReactNode
  fullWidth?: boolean
}

export const DynamicAgreementsSection = ({
  rules,
  productRules,
  getOverwrittenRule,
  title,
  fullWidth,
}: Props): React.ReactNode => {
  const { getRules } = useRules()

  const rulesKeywords = rules
    ? rules.reduce(
        (previousValue, currentValue) =>
          (currentValue.required && !currentValue.accepted) || currentValue.keyword === 'code_option_purchase_rules'
            ? [...previousValue, currentValue.keyword]
            : previousValue,
        [],
      )
    : (productRules ?? []).filter(keyword => !MARKETING_RULES_KEYWORDS.includes(keyword))

  const rulesToShow = getRules(rulesKeywords)

  return (
    <AgreementSection
      fullWidth={fullWidth}
      title={title}
      className="mt-3"
      additionalRulesNames={rules ? rulesKeywords : undefined}
      productRules={productRules ? productRules : undefined}
    >
      {rulesToShow.map(rule => (
        <AgreementCheckbox key={rule?.keyword} rule={rule} getOverwrittenRule={getOverwrittenRule} />
      ))}
    </AgreementSection>
  )
}
