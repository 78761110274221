import { SideCard } from '@components/side-card'
import * as React from 'react'
import { PackageWholesaleSideCardExpirationBanner } from '@modules/package-wholesale/details/package-wholesale-side-card-expiration-banner'
import { setHours } from 'date-fns'
import { DateFormats, formatDate } from '@helpers/date-formatter'

interface Props {
  relatedBooking: { date_to: string } | null
}

export const SubscriptionBookingImprovementSideCard = ({ relatedBooking }: Props): JSX.Element => {
  const expireAfter = relatedBooking
    ? formatDate(setHours(new Date(relatedBooking.date_to), 15), DateFormats.DefaultWithTime)
    : null

  return (
    <SideCard
      className="d-lg-block d-none"
      image={<img src={require('@assets/images/pool.webp')} alt="Subscription voucher" height={400} />}
    >
      {expireAfter && <PackageWholesaleSideCardExpirationBanner expireAfter={expireAfter} />}
    </SideCard>
  )
}
