import * as React from 'react'

interface Props {
  gradientStartColor: string
  gradientEndColor: string
}

export const HolidayBenefitStickyLabelButtonShape = ({
  gradientStartColor,
  gradientEndColor,
}: Props): React.ReactNode => {
  return (
    <svg width="45px" height="443px" viewBox="0 0 45 443" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="21.6694085%" y1="50.4462339%" x2="69.5224476%" y2="47.6100204%" id="linearGradient-1">
          <stop stopColor={gradientStartColor} offset="0%" />
          <stop stopColor={gradientEndColor} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Group-3-Copy-4"
          fill="url(#linearGradient-1)"
          transform="translate(-12.6, 180) scale(-1, 1) rotate(-90) translate(12.6, -180)translate(-275.6, 122.4)"
        >
          <path
            d="M376.140724,33.2775901 L368.030114,11.988681 C365.350575,4.95631717 357.717577,0.2 349.109163,0.2 L176.891715,0.2 C168.283301,0.2 160.650303,4.95631717 157.970764,11.988681 L149.860154,33.2775901 C147.180615,40.3099539 139.547616,45.0662711 130.939202,45.0662711 L0,45.0662711 L0,115.2 L526,115.2 L526,45.0662711 L395.061676,45.0662711 C386.453262,45.0662711 378.820263,40.3099539 376.140724,33.2775901"
            id="ui-/-button_side"
          />
        </g>
      </g>
    </svg>
  )
}
