import axios from 'axios'
import { PackageWholesaleSubscription } from '@modules/package-wholesale/models'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { getAddedProductOptions } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-options'

export const packageWholesaleCreate = async (
  url: string,
  payload: PackageWholesaleFormInput,
): Promise<PackageWholesaleSubscription> => {
  const { data } = await axios.post(url, {
    ...payload,
    products: payload.products
      .filter(row => row.active)
      .map(row => ({
        product: row.product.id,
        amount: row.amount,
        options: getAddedProductOptions(row).map(option => option.id),
      })),
  })

  return data
}
