import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  children: React.ReactNode
  className?: string
}

export const CircleCheck = ({ children, className }: Props): JSX.Element => (
  <div className={clsx('py-1', className)}>
    <div className="d-inline-flex align-items-center">
      <div className="me-2 circle-check align-self-start">
        <i className="uil-check text-white font-size-xxl" />
      </div>
      {children}
    </div>
  </div>
)
