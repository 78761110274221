import { OperationResult } from '@components/payment-operation-results/operation-result'
import * as React from 'react'
import { ReservationDetails } from '@modules/reservation/models'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ContentLoader } from '@components/content-loader'
import { LinkExpired } from '@components/payment-operation-results/link-expired'

interface Props {
  token: string
}

export const ReservationDetailsPaymentSuccess = ({ token }: Props): JSX.Element => {
  const [reservationDetails, setReservationDetails] = React.useState<ReservationDetails>()

  const [isPageExpired, setIsPageExpired] = React.useState(false)

  const { reservation } = useSelector(selectAppDataUrls)

  const { isLoading, action: fetchReservationDetails } = useApiRequest(async () => {
    try {
      const reservationDetails = await commonObjectGet<ReservationDetails>(
        reservation.details.replace('replace-me', String(token)),
      )
      setReservationDetails(reservationDetails)
    } catch (e) {
      setIsPageExpired(true)
    }
  })
  React.useEffect(() => {
    fetchReservationDetails()
  }, [])

  return (
    <ContentLoader isLoading={isLoading}>
      {isPageExpired ? (
        <LinkExpired />
      ) : (
        <OperationResult title="Dziękujemy, płatność zakończona sukcesem" image="success.svg">
          <div className="text-center font-lg text-darker-gray">
            <p>Na wybrany adres e-mail wysłaliśmy potwierdzenie dokonania rezerwacji.</p>
            <p>
              Zachecamy również do założenia{' '}
              <a
                href="https://mojekonto.holidaypark.pl/"
                target="_blank"
                className="text-darker-gray text-decoration-none"
              >
                <strong>Konta Gościa</strong>
              </a>
              , w którym będziesz mógł wygodnie zarządzać swoimi rezerwacjami.
            </p>
          </div>
          <a href={reservationDetails?.urls.sale} className="d-block text-decoration-none">
            <button className="btn btn-responsive bg-secondary px-5 py-3 text-white mt-5 d-block mx-auto">
              <strong>Zobacz podsumowanie swojej rezerwacji</strong>
            </button>
          </a>
        </OperationResult>
      )}
    </ContentLoader>
  )
}
