import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'

const checks = [
  'Podwajamy Twoją wpłatę',
  'Wygodna bezgotówkowa forma płatności',
  'Wykorzystuj w wielu rezerwacjach',
  'Kontroluj wydatki w aplikacji mobilnej',
]

export const GastroCardHeaderSectionContent = (): JSX.Element => (
  <AppHeaderContent
    title={
      <>
        Potwierdzenie zakupu Vouchera <span className="text-nowrap">"Podwójna Radość"</span>
      </>
    }
    subtitle={
      <span className="font-lg">
        W ramach <strong>Promocji Podwójna Radość</strong> podwajamy wpłaconą przez Ciebie kwotę, którą wykorzystasz w
        Kawiarni, Bistro oraz Restauracji w trakcie pobytu.
      </span>
    }
  >
    <div className="mt-4 d-flex flex-wrap col-sm-12 col-10 mx-auto">
      {checks.map(check => (
        <IconWithText
          key={check}
          text={check}
          iconClassName="uil-check  me-2 font-xxxl"
          className="text-gold font-size-xl fw-bold col-sm-6 col-12"
        />
      ))}
    </div>
  </AppHeaderContent>
)
