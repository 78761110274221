import * as React from 'react'
import { useFormRequest } from '@hooks/use-form-request'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { FormProvider, useForm } from 'react-hook-form'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { commonObjectPost } from '@requests/basic-requests'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ResortImages } from '@modules/package-wholesale/details'
import { SubscriptionDetails } from '@modules/subscription/models'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'

import { SubscriptionCardSideCard } from '@modules/subscription/details/subscription-card-side-card'
import { GoHolidayDetailsHeaderSectionContent } from '@modules/go-holiday/details/go-holiday-header-section-content'
import { GoHolidayDetailsContent } from '@modules/go-holiday/details/go-holiday-details-content'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useSubscriptionProductData } from '@modules/subscription/use-subscription-product-data'
import { useProductDetails } from '@hooks/use-product-details'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { yearsUsageVariation } from '@helpers/declination'
import { shouldCallRulesCheck } from '@helpers/rules'
import { useGtmEcommerceBeginCheckoutGtmEvent } from '@hooks/gtm/ecommerce/use-begin-checkout-ecommerce-gtm-event'

export interface FormInputs extends Agreements {
  subscription_go_holiday_rules: boolean
}

export const GoHolidayDetailsView = (): JSX.Element => {
  const { subscription } = useSelector(selectAppDataUrls)

  const paymentSectionRef = React.useRef<HTMLDivElement>(null)
  const detailsRef = React.useRef<HTMLDivElement>(null)

  const { showPaymentSelectionView } = usePaymentSelection()
  const { scrollTo } = useScrollIntoView()

  const {
    productDetails: subscriptionDetails,
    token,
    PageWrapper,
  } = useProductDetails<SubscriptionDetails>(subscription.details)

  const { staysCount, accommodationTypeName, accommodationType } = useSubscriptionProductData(subscriptionDetails)

  useGtmEcommerceBeginCheckoutGtmEvent(2, ProductKinds.GO_HOLIDAY, subscriptionDetails)

  const methods = useForm<FormInputs>({ reValidateMode: 'onChange' })

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!subscriptionDetails || !token) return

    if (shouldCallRulesCheck(subscriptionDetails.rules)) {
      await commonObjectPost(subscriptionDetails?.urls.rules, payload)
    }

    showPaymentSelectionView(token, ProductKinds.GO_HOLIDAY)
  }, methods.setError)

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="GO!Holiday">
        <GoHolidayDetailsHeaderSectionContent
          staysCount={staysCount}
          accommodationTypeName={accommodationTypeName}
          onScrollToDetails={scrollTo(detailsRef)}
          expireYears={subscriptionDetails?.product.default_expire_years ?? 5}
        />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {subscriptionDetails && (
                  <GoHolidayDetailsContent
                    ref={detailsRef}
                    onScrollToPayment={scrollTo(paymentSectionRef)}
                    accommodationType={accommodationType}
                    staysCount={staysCount}
                    subscriptionDetails={subscriptionDetails}
                    accommodationTypeName={accommodationTypeName}
                    expiration={`przez ${subscriptionDetails.product.default_expire_years} ${yearsUsageVariation(
                      subscriptionDetails.product.default_expire_years,
                    )}`}
                  />
                )}
                <hr className="mx-n5" />
                {subscriptionDetails && !subscriptionDetails?.is_paid && (
                  <DynamicAgreementsSection rules={subscriptionDetails.rules} />
                )}
                <PaymentActionRow
                  isPaymentDisabled={subscriptionDetails?.is_paid}
                  ref={paymentSectionRef}
                  isLoading={isSaving}
                  className="mt-4"
                  amount={subscriptionDetails?.final_brutto || '0'}
                />
              </form>
            </FormProvider>
          </div>
          <SubscriptionCardSideCard />
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
