import * as React from 'react'
import * as clsx from 'clsx'
import { Spinner } from '@components/spinner'

interface Props {
  className?: string
  isLoading: boolean
  isActionDisabled?: boolean
  children?: React.ReactNode
  buttonContent?: React.ReactElement | undefined
  rightSide?: React.ReactElement | undefined
}

export const ActionRow = React.forwardRef(
  (
    { isLoading, className, isActionDisabled, children, buttonContent, rightSide }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => (
    <div
      className={clsx('bg-darker-gray p-3 d-flex flex-lg-row flex-column rounded align-items-lg-center', className)}
      ref={ref}
    >
      {children}
      <div className="d-flex gap-lg-4 gap-2 flex-lg-row flex-column mt-3 mt-lg-0">
        {rightSide}
        <button
          className="btn btn-danger text-lg-end ps-lg-5 ps-4 pe-4 py-2 d-flex align-items-center justify-content-center"
          disabled={isLoading || isActionDisabled}
          type="submit"
        >
          <div>
            {buttonContent || (
              <>
                <span className="font-500 d-block font-sm lh-initial">Potwierdzenie zakupu</span>
                <strong className="font-xl">Kupuję i płacę</strong>
              </>
            )}
          </div>

          {isLoading && (
            <div className="ms-3">
              <Spinner className="text-white spinner-border-sm" />
            </div>
          )}
        </button>
      </div>
    </div>
  ),
)
