import * as React from 'react'
import Decimal from 'decimal.js'
import { formatDate, parseISODate } from '@helpers/date-formatter'
import { compareAsc } from 'date-fns'
import { PaymentSummary } from '@modules/reservation/models'

interface UseReservationPaymentDataResponse {
  amount: string
  paymentDate: string | undefined
  nextPaymentDate: Date | undefined
  isAlreadyPaid: boolean
}

export const useReservationPaymentData = (
  paymentSummary: PaymentSummary | undefined,
): UseReservationPaymentDataResponse => {
  const amount = React.useMemo(() => {
    if (!paymentSummary) return '0'

    const requiredAmount = new Decimal(paymentSummary.required.deposit).plus(paymentSummary.required.installment)
    const restAmount = new Decimal(paymentSummary.rest.deposit).plus(paymentSummary.rest.installment)

    return requiredAmount.gt(0) ? requiredAmount.toString() : restAmount.toString()
  }, [paymentSummary])

  const isAlreadyPaid = new Decimal(amount).eq(0)

  const restPaymentDates = [
    ...(paymentSummary?.rest?.required_date ? [parseISODate(paymentSummary.rest.required_date)] : []),
    ...(paymentSummary?.rest?.required_date_deposit ? [parseISODate(paymentSummary.rest.required_date_deposit)] : []),
  ]

  const paymentDate = React.useMemo(() => {
    if (!paymentSummary || isAlreadyPaid) return undefined

    const dates = restPaymentDates.sort(compareAsc)

    return formatDate(dates[0])
  }, [paymentSummary])

  const nextPaymentDate = React.useMemo(() => {
    if (!paymentSummary) return undefined

    const dates = [
      ...restPaymentDates,
      ...(paymentSummary.required.required_date ? [parseISODate(paymentSummary.required.required_date)] : []),
    ].sort(compareAsc)

    return dates[0]
  }, [paymentSummary])

  return {
    amount,
    paymentDate,
    isAlreadyPaid,
    nextPaymentDate,
  }
}
