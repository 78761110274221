import { PaymentMethod } from '@models/app-data'
import { useGtm } from '@hooks/gtm/use-gtm'
import { EcommerceEventItem, GTMEvents, PaymentInfoEventData, PurchaseEventData } from '@models/gtm'

export const GtmEcommerceEventCommonFields = { item_brand: 'Holiday Park & Resort' }

type CartItem = Omit<EcommerceEventItem, 'item_brand'>

interface PaymentInfoPayload {
  paymentMethod: PaymentMethod
  clientId: number
  price: string
  items: EcommerceEventItem[]
}

interface PurchasePayload {
  clientId: number
  numberOfDays?: number
  dateRange?: string
  transactionId: string | number
  price: string
  items: EcommerceEventItem[]
}

interface BeginCheckoutPayload {
  clientId: number
  price: string
  items: CartItem[]
  step: number
}

interface Response {
  purchase: (data: PurchasePayload) => void
  addPaymentInfo: (data: PaymentInfoPayload) => void
  beginCheckout: (data: BeginCheckoutPayload) => void
}

export const useGtmEvents = (): Response => {
  const { sendEvent } = useGtm()

  const sendEventWithEcommerceCleanup = <T>(...params: Parameters<typeof sendEvent<T>>) => {
    sendEvent({ ecommerce: null })
    return sendEvent<T>(...params)
  }

  const addPaymentInfo = ({ paymentMethod, clientId, price, items }: PaymentInfoPayload) => {
    sendEventWithEcommerceCleanup<PaymentInfoEventData>({
      event: GTMEvents.AddPaymentInfo,
      userID: clientId,
      ecommerce: {
        currency: 'PLN',
        value: price,
        payment_method: paymentMethod.name,
        items,
      },
    })
  }

  const purchase = ({ clientId, numberOfDays = 0, dateRange = '-', transactionId, price, items }: PurchasePayload) => {
    sendEventWithEcommerceCleanup<PurchaseEventData>({
      event: GTMEvents.Purchase,
      userID: clientId,
      date_range: dateRange,
      number_of_days: numberOfDays,
      ecommerce: {
        currency: 'PLN',
        transaction_id: transactionId,
        value: price,
        items: items,
      },
    })
  }

  const beginCheckout = ({ step, clientId, price, items }: BeginCheckoutPayload) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.BeginCheckout,
      userId: clientId,
      ecommerce: {
        step,
        value: price,
        items: items,
      },
    })
  }

  return {
    purchase,
    addPaymentInfo,
    beginCheckout,
  }
}

type GtmCartItemPayload = Omit<EcommerceEventItem, 'item_brand' | 'item_category2' | 'discount' | 'index'> & {
  item_category2?: string
  discount?: number
  index?: number
}

export const createGtmCartItem = (data: GtmCartItemPayload): EcommerceEventItem => ({
  ...data,
  index: data.index ?? 0,
  discount: 0,
  ...GtmEcommerceEventCommonFields,
})
