import * as React from 'react'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { AppLink } from '@components/modals/client-app-details-modal'

export const HolidayBenefitMobileAppBanner = (): JSX.Element => (
  <div className="container d-flex py-5 my-5 flex-wrap flex-xl-nowrap">
    <div className="col-lg-3 col-12 p-0 p-lg-3">
      <div className="bg-holiday-benefit-tertiary holiday-benefit__employee__mobile-banner__card p-5 h-100">
        <strong className="text-white font-xxxl lh-1 text-center text-lg-start d-block">
          Jakie systemy honorujemy?
        </strong>
        <div className="rounded-5 mt-5">
          <img
            src={require('@assets/images/holiday-benefit/landing-page/motivizer-logo.webp')}
            alt="Motivizer benefit logo"
            height={75}
            className="w-100 object-fit-contain"
          />
        </div>
        <div className="rounded-5 mt-3">
          <img
            height={75}
            src={require('@assets/images/holiday-benefit/landing-page/mybenefit-logo.webp')}
            alt="MyBenefit benefit logo"
            className="w-100 object-fit-contain"
          />
        </div>
      </div>
    </div>
    <div className="col-lg-9 col-12  p-0 p-lg-3">
      <div className="position-relative bg-holiday-benefit-primary holiday-benefit__employee__mobile-banner__card is-app-card ms-lg-3 p-5">
        <div className="col-lg-5 col-12 text-white text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start">
          <h1 className="fw-bold mb-0">Konto Gościa</h1>
          <p className="fw-bold mb-0 mt-3">Pobierz aplikację mobilną</p>
          <p className="mb-4">i monitoruj swoje wakacje.</p>

          <ul className="list-unstyled">
            {benefits.map((benefit, index) => (
              <li key={index} className="d-flex align-items-center mt-2">
                <span className="uil-check holiday-benefit__benefits__point bg-white text-holiday-benefit-primary" />
                {benefit}
              </li>
            ))}
          </ul>

          <div className="d-flex gap-4 mt-5">
            <AppLink
              className="text-holiday-benefit-primary bg-white"
              href={CLIENT_APP_URLS.GOOGLE_PLAY_LINK}
              icon="uil-google-play"
              text="Google Play"
            />
            <AppLink
              className="text-holiday-benefit-primary bg-white"
              href={CLIENT_APP_URLS.APP_STORE_LINK}
              icon="uil-apple"
              text="App Store"
            />
          </div>
        </div>
        <img
          height={450}
          src={require('@assets/images/mobile-app-banner.png')}
          alt="Aplikacja Konto gościa"
          className="holiday-benefit__employee__mobile-banner__image"
        />
      </div>
    </div>
  </div>
)

const benefits = [
  'Dostęp do pełnej historii rezerwacji',
  'Meldowanie i wymeldowanie on-line',
  'Kontrola rachunku podczas pobytu',
]
