import * as React from 'react'
import { useFormRequest } from '@hooks/use-form-request'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { ReservationDetailsContent } from '@modules/reservation/details/reservation-details-content'
import { ReservationHeaderSectionContent } from '@modules/reservation/details/reservation-header-section-content'
import { ReservationDetails } from '@modules/reservation/models'
import { ReservationCardSideCard } from '@modules/reservation/details/reservation-card-side-card'
import { selectAppDataUrls, selectResorts } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { commonObjectPost } from '@requests/basic-requests'
import { Resort } from '@models/app-data'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { useReservationPaymentData } from '@modules/reservation/details/use-reservation-payment-data'
import { isEachRequiredRuleAccepted } from '@helpers/rules'
import { useProductDetails } from '@hooks/use-product-details'

export const ReservationDetailsView = (): JSX.Element => {
  const { reservation } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails: reservationDetails } = useProductDetails<ReservationDetails>(reservation.details)

  const billSectionRef = React.useRef<HTMLDivElement>(null)

  const resorts = useSelector(selectResorts)

  const { showPaymentSelectionView } = usePaymentSelection()
  const { scrollTo } = useScrollIntoView()

  const reservationResort = resorts.find((resort: Resort) => resort.id === reservationDetails?.resort_id)

  const methods = useForm<Agreements>({ reValidateMode: 'onChange' })

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!reservationDetails) return

    if (!isEachRequiredRuleAccepted(reservationDetails.rules)) {
      const acceptedRules = reservationDetails.rules.reduce((rules, rule) => {
        if (rule.accepted) return { ...rules, [rule.keyword]: true }
        return rules
      }, {})

      await commonObjectPost(reservationDetails.urls.rules, {
        ...payload,
        ...acceptedRules,
      })
    }

    showPaymentSelectionView(reservationDetails.token, ProductKinds.RESERVATION_DETAILS)
  }, methods.setError)

  const { amount, nextPaymentDate, isAlreadyPaid } = useReservationPaymentData(
    reservationDetails?.prices.payments_summary,
  )

  return (
    <PageWrapper>
      <AppHeaderSection images={[require('@assets/images/resorts/ustronie.jpg')]} imageDescription="pakiety prywatne">
        {reservationDetails && (
          <ReservationHeaderSectionContent
            onScrollToBill={scrollTo(billSectionRef)}
            reservationDetails={reservationDetails}
            reservationResort={reservationResort}
          />
        )}
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4  card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {reservationDetails && (
                  <ReservationDetailsContent
                    reservationResort={reservationResort}
                    reservationDetails={reservationDetails}
                    seller={reservationDetails.seller}
                    created={reservationDetails.created_at}
                    ref={billSectionRef}
                    onScrollToBill={scrollTo(billSectionRef)}
                  />
                )}
                <hr className="mx-n5" />
                {reservationDetails && !isEachRequiredRuleAccepted(reservationDetails.rules) && (
                  <DynamicAgreementsSection rules={reservationDetails.rules} />
                )}
                <PaymentActionRow
                  isPaymentDisabled={isAlreadyPaid}
                  isLoading={isSaving}
                  className="mt-4"
                  paymentDate={nextPaymentDate}
                  amount={amount}
                  buttonContent={
                    reservationDetails?.status !== 'initial' ? (
                      <strong className="font-500 d-block font-xl lh-initial py-2 pe-4">Zapłać</strong>
                    ) : undefined
                  }
                />
              </form>
            </FormProvider>
          </div>
          {reservationDetails && <ReservationCardSideCard reservationDetails={reservationDetails} />}
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
