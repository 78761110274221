import * as React from 'react'
import clsx from 'clsx'
import {
  EndlessHolidayConfirmationDetails,
  EndlessHolidayConfirmationSubscription,
} from '@modules/product-package/models'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayConfirmationBillGastroCards } from '@modules/product-package/confirmation/confirmation-bill-gastro-cards'
import { asDecimal } from '@helpers/utils'
import { useGroupedEndlessSubscriptionProducts } from '@modules/product-package/confirmation/use-grouped-endless-subscription-products'
import { getProductPackageBillName } from '@modules/product-package/helpers'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
}

export const EndlessHolidayConfirmationBill = React.forwardRef(
  ({ productDetails }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    const { groupedList } = useGroupedEndlessSubscriptionProducts(productDetails)

    const isEndlessHoliday = productDetails.kind === 'endless_holiday'

    const getAmount = (subscription: EndlessHolidayConfirmationSubscription) =>
      subscription.number_of_codes > 1 ? `x${subscription.number_of_codes}` : ''

    const getTotalDepositPrice = (subscription: EndlessHolidayConfirmationSubscription) =>
      asDecimal(subscription.deposit_amount).mul(subscription.number_of_codes).toString()

    return (
      <div>
        <div ref={ref} className="bg-light-gray mx-n4 my-n3 p-4">
          <p className="text-gold fw-bold font-xl">Całkowity koszt zamówienia</p>
          {groupedList.map(([year, subscriptions], index) => (
            <div key={year}>
              {productDetails.kind === 'endless_holiday' && (
                <p className="text-secondary fw-bold mb-1 font-lg">Od roku {year}</p>
              )}
              <ul className="list-unstyled mb-0">
                {subscriptions.map((subscription: EndlessHolidayConfirmationSubscription, index: number) => (
                  <li key={subscription.id} className={clsx({ 'mb-3': index < subscriptions.length - 1 })}>
                    <div className="text-darker-gray d-flex justify-content-between">
                      <div className="fw-bold">{getProductPackageBillName(subscription, productDetails.kind)}</div>
                      <div className="fw-bold font-lg">{formatPriceShort(subscription.price_brutto)}</div>
                    </div>

                    {isEndlessHoliday && (
                      <div key={index} className="text-muted d-flex justify-content-between ms-3 font-500">
                        <div>Kaucja zwrotna do przyszłej rezerwacji {getAmount(subscription)}</div>
                        <div>+ {formatPriceShort(getTotalDepositPrice(subscription))}</div>
                      </div>
                    )}

                    {subscription.options.map(option => (
                      <div key={option.kind} className="text-muted d-flex justify-content-between ms-3 font-500">
                        <div>
                          {option.kind_display} {getAmount(subscription)}
                        </div>
                        <div>+ {formatPriceShort(option.price_brutto)}</div>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
              <EndlessHolidayConfirmationBillGastroCards gastroCards={productDetails.gastro_cards} year={year} />
              {index < groupedList.length - 1 && <hr className="mx-n5" />}
            </div>
          ))}
        </div>
        <hr className="mx-n5" />
        <div className="font-xl d-flex justify-content-between align-items-center">
          <p className="text-darker-gray fw-bold mb-0">Do zapłaty łącznie:</p>
          <div className="fw-bold text-danger">{formatPrice(productDetails.required_payment)}</div>
        </div>
      </div>
    )
  },
)
