import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { RodoAgreementFormInputs } from '@modules/rodo-agreements/form/form-page'
import { RodoAcquiringPoint } from '@modules/rodo-agreements/models'
import { useModal } from '@components/modals/use-modal'

interface Response {
  submit: () => void
  isLoading: boolean
}

export const useRodoAgreementFormSubmit = (
  methods: UseFormReturn<RodoAgreementFormInputs>,
  rodoAcquiringPoint: RodoAcquiringPoint,
): Response => {
  const navigate = useNavigate()
  const [showDataAlreadyExistModal] = useModal('RodoAgreementDataExistModal')

  const handleError = (...params) => {
    const [field, error] = params

    if (field === 'non_field_errors') {
      showDataAlreadyExistModal()
      return
    }
    methods.setError(field, error)
  }

  const { action: submit, isLoading } = useFormRequest(async () => {
    methods.clearErrors()
    await commonObjectPost(rodoAcquiringPoint.urls.details, methods.getValues())
    navigate(NavigationPath.RodoAgreementsSuccess.replace(':slug', rodoAcquiringPoint.slug))
  }, handleError)

  return {
    submit,
    isLoading,
  }
}
