import * as React from 'react'
import { ModalBody, ModalHeader } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { Gallery } from '@components/gallery'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { HolidayBenefitBenefitsPlansPrice } from '@modules/holiday-benefit/common/holiday-benefit-benefits-plans-price'
import { IconWithText } from '@components/icon-with-text'
import { useDevicesSize } from '@hooks/use-devices-size'

interface Props extends BaseModalProps {
  accommodationId: number
  price: string
}

export const AccommodationTypeDetailsModal = ({ toggleIsVisible, accommodationId, price }: Props): React.ReactNode => {
  const { isDesktop } = useDevicesSize()
  const { accommodation_types } = useSelector(selectAppData)

  const accommodationType = accommodation_types.find(({ id }) => id === accommodationId)
  const accommodationTypeImages = accommodationType?.accommodation_type_images || []

  const accommodationTypeDeclination = (accommodationType?.short_name_language_miejscownik ?? '').split(',')[0]

  return (
    <>
      <ModalHeader className="font-xxl text-primary font-500 p-0 align-items-lg-start">
        <span className="py-3 px-5">Apartament</span>
        <button
          className="bg-darker-gray btn-unstyled py-3 px-4 endless-holiday__description-modal__close-button"
          onClick={toggleIsVisible}
        >
          <i className="uil-times text-white font-xxl lh-1" />
        </button>
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="package-wholesale__resorts bg-white shadow-none p-0 mb-0 image-gallery__apartments-gellery-wrapper">
          <Gallery
            showThumbnails={isDesktop}
            showIndex={accommodationTypeImages.length > 1}
            items={useResponsivenessImages(accommodationTypeImages)}
            additionalClass="image-gallery--rounded-lg-left apartment-details-gallery"
          />
        </div>
        <section className="p-sm-5 p-3 text-darker-gray d-flex justify-content-between flex-wrap flex-row-reverse flex-sm-row">
          <div className="col-sm-9 col-12">
            <h3 className="text-holiday-benefit-primary">
              <strong>
                {accommodationType?.name} dla {accommodationType?.display_max_guests} osób
              </strong>{' '}
              <span className="fw-normal text-nowrap">
                (max. {accommodationType?.display_max_adult_guests} dorosłych)
              </span>
            </h3>
            <p className="mt-4">
              W {accommodationTypeDeclination} znajdziesz przestronny salon, w pełni wyposażoną kuchnię z jadalnią,
              łazienkę z prysznicem oraz balkonem z widokiem. Całość o łącznej powierzchni {accommodationType?.size}m²
              pozwala cieszyć się komfortem i daje mnóstwo radości całej rodzinie.{' '}
            </p>
            <div className="d-flex flex-wrap " style={{ gap: '5px 25px' }}>
              <IconWithText
                iconClassName="uil-users-alt font-xl me-2"
                text={`dla ${accommodationType?.display_max_guests} osób`}
              />
              <IconWithText
                iconClassName="uil-arrow-resize-diagonal font-xl me-2"
                text={`powierzchnia ${accommodationType?.size}²`}
              />
              <IconWithText iconClassName="uil-wifi font-xl me-2" text="wifi" />
              <IconWithText iconClassName="uil-tv-retro font-xl me-2" text='tv 43"' />
              <IconWithText iconClassName="uil-restaurant font-xl me-2" text="wyposażenie kuchni" />
              <IconWithText iconClassName="uil-bed-double font-xl me-2" text="podwójne łóżko" />
              <IconWithText iconClassName="uil-bed-double font-xl me-2" text="sofa z funkcją spania" />
              <IconWithText iconClassName="uil-grids font-xl me-2" text="szafa" />
            </div>
          </div>
          <div className="col-sm-3 col-8 me-auto mt-4 mt-sm-0">
            <HolidayBenefitBenefitsPlansPrice
              wrapperClassName="flex-row flex-sm-column justify-content-between align-items-sm-end align-items-center"
              price={price}
              color="primary"
              forEmployee
            />
          </div>
        </section>
      </ModalBody>
    </>
  )
}
