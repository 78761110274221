import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { useFormContext, useWatch } from 'react-hook-form'
import { ConsentAcquisitionUser } from '@modules/acquisition-of-consents/models'

interface Props {
  user: ConsentAcquisitionUser
}

export const AcquisitionOfConsentsForm = ({ user }: Props): JSX.Element => {
  const methods = useFormContext()

  const formPhoneValue = useWatch({ control: methods.control, name: 'phone' })

  const handleFocus = event => {
    const fieldName = event.target.name
    if (user[fieldName] === methods.getValues(fieldName)) methods.setValue(fieldName, '')
  }

  const handleBlur = event => {
    const fieldName = event.target.name
    let newValue = event.target.value || user[fieldName]
    if (fieldName === 'phone') {
      newValue = event.target.value !== '(+48) ___-___-___' ? event.target.value : user[fieldName]
    }
    methods.setValue(fieldName, newValue)
  }

  return (
    <div className="d-flex flex-column gap-2 row mx-0">
      <CustomFormGroup
        label="Imię:"
        inputName="first_name"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{
          type: 'text',
          className: 'col-6',
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
      />

      <CustomFormGroup
        label="Nazwisko:"
        inputName="last_name"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{
          type: 'text',
          className: 'col-6',
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
      />

      <CustomFormGroup
        label="Numer telefonu:"
        inputName="phone"
        maskedFormControlProps={
          formPhoneValue !== user.phone
            ? {
                autoFocus: true,
                mask: '(+48) ***-***-***',
                className: 'letter-spacing-2',
                alwaysShowMask: true,
                onFocus: handleFocus,
                onBlur: handleBlur,
              }
            : undefined
        }
        formGroupProps={{ className: 'package-wholesale__data-input' }}
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'tel', className: 'col-6', onFocus: handleFocus, onBlur: handleBlur }}
      />
      <CustomFormGroup
        label="E-mail:"
        inputName="email"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'email', className: 'col-6', placeholder: 'adres@email.pl', disabled: true }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
      />
    </div>
  )
}
