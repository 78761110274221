import { GastroGenericProductDetails, GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatPrice } from '@helpers/price-helper'
import { asDecimal } from '@helpers/utils'
import * as React from 'react'

export const getGastroCardBoostData = (
  productDetails: GastroGenericProductDetails,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const isSubscriptionOffer = productDetails.tags.includes('subscription_offer')

  const labelImageSrc = 'gastro-card-boost-label.webp'

  const productName = isSubscriptionOffer
    ? `${gastroProduct.product_name} dla Subskrybentów`
    : gastroProduct.product_name

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher “{productName}”</strong>do
      wykorzystania we wszystkich otwartych punktach gastronomicznych.
    </span>
  )

  const percentage = `${asDecimal(productDetails.promotion_multiplier).minus(1).mul(100).toString()}%`

  const benefitsPoints = isSubscriptionOffer
    ? [
        `Aktywna umowa subskrypcji pozwoli Ci nabyć voucher umożliwiający dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o ${percentage}!`,
        'Kupon do wykorzystania we wszystkich otwartych punktach gastronomicznych na terenie Resortu',
        'Ilość kuponów jakie możesz kupić jest nieograniczona!',
      ]
    : [
        `Zakup vouchera umożliwia dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o ${percentage}.`,
        'Voucher jest wielokrotnego użytku, w różnych rezerwacjach.',
        'Ilość voucherów jakie możesz kupić jest nieograniczona!',
      ]

  const benefitsList = [
    `Minimalna kwota, za jaką możesz kupić voucher to ${formatPrice(gastroProduct.minimal_value)}`,
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2024 - 31.12.2025',
  ]

  const alertText =
    'Kupon nie łączy się z voucherem Podwójna Radość i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Doładowanie smaku można podpiąć kolejny dowolny voucher gastronomiczny.'

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
