import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { FormProvider, useForm } from 'react-hook-form'
import { Spinner } from '@components/spinner'

interface FormInputs {
  password: string
}

interface Props {
  onSubmit: (password: string) => void
  isLoading: boolean
  hasError: boolean
}

export const LinkProtected = ({ onSubmit, hasError, isLoading }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: { password: '' },
  })

  const handleSubmit = () => {
    onSubmit(methods.getValues('password'))
  }

  React.useEffect(() => {
    hasError ? methods.setError('password', { message: 'Nieprawidłowe hasło' }) : methods.clearErrors()
  }, [hasError])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className="protected__form">
        <img
          src={require('@assets/images/resorts/rowy.jpg')}
          alt="Holiday Park & Resort Rowy"
          className="protected__background"
        />
        <div className="protected__card">
          <div className="protected__card__content">
            <p className="protected__card__title">Wymagane hasło</p>
            <div className="protected__card__login-box">
              <p className="text-darker-gray">
                <strong>Uwaga!</strong> Oferta dostępna wyłącznie dla pracowników posiadających
                <strong> prawidłowe hasło.</strong>
              </p>

              <div className="d-md-flex bg-alice-blue p-4 rounded mb-5">
                <CustomFormGroup
                  label="Hasło do oferty"
                  inputName="password"
                  isFailed={hasError}
                  formLabelProps={{ className: 'font-500' }}
                  formControlProps={{ type: 'password' }}
                  formGroupProps={{ className: 'protected__card__input' }}
                />
                <button className="protected__card__submit" disabled={isLoading}>
                  Wchodzę
                  {isLoading && (
                    <div className="ms-3">
                      <Spinner className="text-white spinner-border-sm" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <img
            src={require('@assets/images/modal-image.webp')}
            alt="Holiday Park & Resort Rowy"
            className="protected__side-image"
          />
        </div>
      </form>
    </FormProvider>
  )
}
