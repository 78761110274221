import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useApiRequest, useFormRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'
import {
  PackageWholesaleDetails,
  PackageWholesaleProduct,
  PackageWholesaleProductKinds,
  PackageWholesaleProductOptionKind,
} from '@modules/package-wholesale/models'
import * as clsx from 'clsx'
import { AppFooter } from '@components/app-footer'
import { ContentLoader } from '@components/content-loader'
import { LinkExpired } from '@components/payment-operation-results/link-expired'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { Agreements, PackageWholesaleAgreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { packageWholesaleCreate } from '@requests/package-wholesale'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ResortsGallery } from '@components/resorts-gallery'
import { ClientDataForms } from '@components/client-data/client-data-forms'
import { usePackageWholesaleVariant } from '@modules/package-wholesale/use-package-wholesale-variant'
import { usePackageWholesalePrices } from '@modules/package-wholesale/use-package-wholesale-prices'
import { LinkProtected } from '@components/payment-operation-results'
import { AxiosRequestHeaders } from 'axios'
import * as md5 from 'md5'
import { ClientFormData, ClientInvoiceData } from '@models/models'
import { PackageWholesaleExtraFields } from '@modules/package-wholesale/details/extra-fields'
import { usePackageWholesaleAnalysis } from '@modules/package-wholesale/use-package-wholesale-analysis'

export const ResortImages = [
  require('@assets/images/resorts/ustronie.jpg'),
  require('@assets/images/resorts/cieplice.jpg'),
  require('@assets/images/resorts/grzybowo.jpg'),
  require('@assets/images/resorts/mielno.jpg'),
  require('@assets/images/resorts/niechorze.jpg'),
  require('@assets/images/resorts/pobierowo.jpg'),
  require('@assets/images/resorts/rowy.jpg'),
]

export interface PackageWholesaleProductFormInput {
  amount: number
  product: PackageWholesaleProduct
  active: boolean
  with_zoo_borysew_tickets: boolean
  options: Partial<Record<PackageWholesaleProductOptionKind, boolean>>
}

export interface PackageWholesaleFormInput extends Agreements, ClientFormData, ClientInvoiceData {
  products: PackageWholesaleProductFormInput[]
}

export const PackageWholesaleDetailsView = (): JSX.Element => {
  const { token } = useParams<'token'>()

  const [isExpired, setIsExpired] = React.useState(false)
  const [hasAuthValidationError, setHasAuthValidationError] = React.useState(false)
  const [isPasswordViewVisible, setIsPasswordViewVisible] = React.useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false)
  const [packageWholesaleDetails, setPackageWholesaleDetails] = React.useState<PackageWholesaleDetails>()

  usePackageWholesaleAnalysis(packageWholesaleDetails)

  const {
    package_wholesale: { details },
  } = useSelector(selectAppDataUrls)

  const { showPaymentSelectionView } = usePaymentSelection()
  const { getTotalPrice } = usePackageWholesalePrices()

  const methods = useForm<PackageWholesaleFormInput>({
    defaultValues: { invoice: false, invoice_type: 'company', products: [] },
    reValidateMode: 'onChange',
  })

  const products = useWatch({ control: methods.control, name: 'products' })

  const { isLoading, action: fetch } = useApiRequest(async (password?: string) => {
    try {
      setHasAuthValidationError(false)
      setPackageWholesaleDetails(
        await commonObjectGet<PackageWholesaleDetails>(details.replace('replace-me', String(token)), {
          headers: { ...(!!password && { 'X-Password': md5(password) }) } as AxiosRequestHeaders,
        }),
      )
      setIsPasswordViewVisible(false)
    } catch (error) {
      if (error.response?.status === 401) {
        setHasAuthValidationError(isPasswordViewVisible)
        setIsPasswordViewVisible(true)
        return
      }
      setIsExpired(true)
    }
  })

  const { isLoading: isChecking, action: onSubmit } = useFormRequest(
    async (payload: PackageWholesaleFormInput) => {
      if (!packageWholesaleDetails || payload.products.every(row => !row.active)) return

      const packageWholesaleSubscription = await packageWholesaleCreate(packageWholesaleDetails.urls.create, payload)
      showPaymentSelectionView(packageWholesaleSubscription.token, ProductKinds.PACKAGE_WHOLESALE)
    },
    methods.setError,
    { excludeFields: ['product', 'product_amount'] },
  )

  React.useEffect(() => {
    fetch()
  }, [])

  const price = getTotalPrice(products)

  const { headerSectionContent, content, sideCard } = usePackageWholesaleVariant(packageWholesaleDetails)

  const kinds = [...new Set(packageWholesaleDetails?.products.map(product => product.kind))]

  const isGastroCardOnly = kinds.every(kind => PackageWholesaleProductKinds.includes(kind))

  const handleVideoLoad = () => {
    setIsVideoLoaded(true)
  }

  return (
    <div>
      {isPasswordViewVisible ? (
        <LinkProtected isLoading={isLoading} onSubmit={fetch} hasError={hasAuthValidationError} />
      ) : (
        <ContentLoader isLoading={isLoading || !isVideoLoaded}>
          {isExpired && <LinkExpired email="biznes@holidaypark.pl" />}
          {!isPasswordViewVisible && !isExpired && packageWholesaleDetails && (
            <>
              <AppHeaderSection
                video={{ src: require('@assets/video/package-wholesale-header-video.mp4'), onLoadEnd: handleVideoLoad }}
                imageDescription="vip"
                imageClassName={clsx({ 'is-gastro-card-slider': isGastroCardOnly })}
              >
                {headerSectionContent}
              </AppHeaderSection>
              <div className="container app-header-section__after-content">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="row gx-xl-5">
                      <div className="card col-lg-9 col-12 py-4  card-shadow border-0 overflow-hidden">
                        {content}
                        <ClientDataForms
                          additionalClientData={
                            <PackageWholesaleExtraFields packageWholesaleDetails={packageWholesaleDetails} />
                          }
                        />
                        <hr className="mx-n5" />
                        <PackageWholesaleAgreements packageWholesaleDetails={packageWholesaleDetails} />
                        <PaymentActionRow isLoading={isChecking} amount={price} className="mt-4" />
                      </div>

                      <div className="d-flex flex-column col-3">{sideCard}</div>
                    </div>
                  </form>
                </FormProvider>
              </div>
              <ResortsGallery title="Gdzie wykorzystać voucher?" />
              <AppFooter />
            </>
          )}
        </ContentLoader>
      )}
    </div>
  )
}
