import * as React from 'react'
import * as clsx from 'clsx'
import { CardContentBase } from '@components/card-content-base'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionWithBenefitsDetailsContentProductInfo } from '@modules/subscription-with-benefit/details/subscription-with-benefits-details-content-product-info'
import { IconWithText } from '@components/icon-with-text'
import { SubscriptionWithBenefitsDetailsContentBenefitList } from '@modules/subscription-with-benefit/details/subscription-with-benefits-details-content-benefit-list'
import { SubscriptionWithBenefitsDetailsContentDescription } from '@modules/subscription-with-benefit/details/subscription-with-benefits-details-content-description'
import { PackageVariantIcon } from '@components/package-variant'
import { toDefaultDateFormat } from '@helpers/date-formatter'

interface Props {
  subscriptionDetails: SubscriptionDetails
  onScrollToPayment: () => void
}

export const SubscriptionWithBenefitsDetailsContent = React.forwardRef(
  ({ subscriptionDetails, onScrollToPayment }: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    return (
      <div className="subscription-with-benefits">
        <CardContentBase
          title={subscriptionDetails.is_paid ? 'Potwierdzenie zakupu produktu' : 'Podsumowanie zamówienia'}
          client={subscriptionDetails.client}
          seller={subscriptionDetails.seller}
          created={subscriptionDetails.created || ''}
          onScrollToPayment={onScrollToPayment}
          isProductPaid={subscriptionDetails.is_paid}
          productInvoice={subscriptionDetails}
        >
          <SubscriptionWithBenefitsDetailsContentProductInfo subscriptionDetails={subscriptionDetails} />
        </CardContentBase>
        <div className="" ref={ref}>
          <hr className="mx-n5" />
          <strong className="text-gold text-center text-sm-start d-block mb-3">Szczegóły oferty</strong>
          <div
            className={clsx('border rounded d-flex p-3 subscription-with-benefits__details', {
              'is-lux': subscriptionDetails.kind === 'gold',
              'is-comfort': subscriptionDetails.kind === 'silver',
              'is-optimum': subscriptionDetails.kind === 'brown',
            })}
          >
            <div className="me-5 d-sm-block d-none">
              <PackageVariantIcon color={IconColor[subscriptionDetails.kind] ?? '#000000'} />
            </div>
            <div className="text-darker-gray col-sm-9 col-12">
              <strong className="font-xxl subscription-variant__header">{subscriptionDetails.product.name}</strong>
              <IconWithText
                iconClassName="uil-calender font-xl me-1"
                text={
                  <span>
                    Ważny do: <strong>{toDefaultDateFormat(subscriptionDetails.expire_after)}</strong>
                  </span>
                }
              />
              <SubscriptionWithBenefitsDetailsContentBenefitList subscriptionDetails={subscriptionDetails} />
              <SubscriptionWithBenefitsDetailsContentDescription subscriptionDetails={subscriptionDetails} />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

const IconColor = {
  gold: '#ffb63c',
  silver: '#f27c85',
  brown: '#a884c2',
}
