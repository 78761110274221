import * as React from 'react'
import { PackageVipDetails, VipImprovement } from '@modules/vip/models'
import { CircleCheck } from '@components/circle-check'
import { VipContentBase } from '@modules/vip/details/vip-content-base'

interface Props {
  packageVipDetails: PackageVipDetails
  onScrollToPayment: () => void
}

export const VipStandardContent = React.forwardRef(
  ({ packageVipDetails, onScrollToPayment }: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => (
    <VipContentBase
      improvementsTitle="Co zyskujesz kupując pakiet VIP?"
      packageVipDetails={packageVipDetails}
      onScrollToPayment={onScrollToPayment}
      fineText="usługa dostępna wyłącznie w Mielnie - w okresie otwarcia ośrodka"
      ref={ref}
    >
      {vipImprovements.map((improvement: VipImprovement) => (
        <CircleCheck key={improvement.title}>
          <span className="font-md lh-initial">
            <span className="text-muted">{improvement.title} </span>
            {!!improvement.discount && <span className="text-primary">{improvement.discount}</span>}
          </span>
        </CircleCheck>
      ))}
    </VipContentBase>
  ),
)

const vipImprovements: VipImprovement[] = [
  { title: 'Bezpłatna możliwość wybrania domku' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13:00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: '4 reczniki plażowe VIP' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce - banany, pomarańcze, kiwi, owoce sezonowe' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Wynajem rowerów' },
  { title: 'Rabat w bistro HPR w godzinach 11:00 – 12:30' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Okulary VR (5 godz.) *', discount: '(rabat 50%)' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Parking VIP po opłaceniu standardowego miejsca parkingowego (w miarę dostępności)' },
  { title: 'Wynajem loży VIP przy basenie, raz na pobyt', discount: '(rabat 50%)' },
]
