import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { useModal } from '@components/modals/use-modal'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { useLogin } from '@hooks/use-login'
import { useFormContext, useWatch } from 'react-hook-form'
import { HolidayBenefitInvitationFormInputs } from '@modules/holiday-benefit/employee-invitation/index'
import { ButtonWithLoader } from '@components/button-with-loader'
import clsx from 'clsx'
import { useHolidayBenefitInvitationSelectedPlan } from '@modules/holiday-benefit/employee-invitation/use-holiday-benefit-invitation-selected-plan'
import { FadeInView } from '@components/fade-in-view'

interface Props {
  onCreateAccountClick: () => void
}

export const HolidayBenefitInvitationAccountLogin = ({ onCreateAccountClick }: Props): React.ReactNode => {
  const { control } = useFormContext<HolidayBenefitInvitationFormInputs>()

  const [showClientAppDetailsModal] = useModal('ClientAppDetailsModal')

  const { login, isLoginPending } = useLogin()

  const [username, password] = useWatch({ control, name: ['username', 'password'] })

  const handleLogin = async () => {
    await login(username, password)
  }

  const { color } = useHolidayBenefitInvitationSelectedPlan()

  return (
    <FadeInView className="my-3">
      <div className="bg-light-blue rounded-3 p-lg-5 p-3">
        <h5 className={clsx('font-xxl fw-bold text-center', color)}>
          Masz już Konto Gościa <span className="text-nowrap">Holiday Park & Resort?</span> <br />
          Zaloguj się
        </h5>
        <p className="text-center mt-4 mb-3 pb-0 font-lg mx-auto col-lg-9 col-12 text-darker-gray">
          Benefit zostanie dodany do <span className="text-nowrap">Twojego Konta Gościa</span> w systemie{' '}
          <span className="text-nowrap">Holiday Park & Resort</span>.{' '}
        </p>

        <IconWithText
          text="Czym jest Konto Gościa?"
          iconClassName="uil-info-circle font-xxl me-1"
          className="text-center fw-bold text-muted justify-content-center"
          textClassName="font-md fw-semibold"
          onClick={showClientAppDetailsModal}
        />

        <CustomFormGroup
          formGroupProps={{ className: 'package-wholesale__data-input mt-4' }}
          inputName="username"
          label="Adres e-mail:"
          formLabelProps={{ className: 'col-4 fw-semibold' }}
          formControlProps={{
            type: 'email',
            placeholder: 'Wpisz adres e-mail',
            className: 'text-holiday-benefit-secondary col-8',
          }}
        />
        <CustomFormGroup
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          formLabelProps={{ className: 'col-4 fw-semibold' }}
          inputName="password"
          label="Hasło:"
          formControlProps={{
            type: 'password',
            placeholder: 'Wpisz hasło',
            className: 'text-holiday-benefit-secondary col-8',
          }}
        />
        <a
          href={CLIENT_APP_URLS.RESET_PASSWORD}
          target="_blank"
          className="text-decoration-none d-block text-muted fw-semibold opacity-75 text-end mt-1"
        >
          Przypomnij hasło
        </a>

        <ButtonWithLoader
          isLoading={isLoginPending}
          text="Zaloguj się"
          className="btn-holiday-benefit is-primary px-5 mt-5 btn-responsive d-block mx-auto"
          disabled={!username || !password || isLoginPending}
          onClick={handleLogin}
        />
      </div>
      <div className="holiday-benefit__invitation__account__separator">
        <div className="holiday-benefit__invitation__account__separator__text">lub</div>
      </div>
      <div className="bg-light-blue rounded-3 py-5 px-lg-4 px-2">
        <button
          className="btn-holiday-benefit is-dark px-5 btn-responsive d-block mx-auto"
          onClick={onCreateAccountClick}
        >
          Utwórz Konto Gościa
        </button>
        <p className="text-center mt-4 mb-3 pb-0 font-lg col-lg-9 col-12 mx-auto text-darker-gray">
          Pozwoli Ci to na zarządzanie wybranym benefitem i tworzenie rezerwacji w ośrodkach{' '}
          <span className="text-nowrap">Holiday Park & Resort</span>.
        </p>
      </div>
    </FadeInView>
  )
}
