import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { EndlessHolidayConfirmationKind, EndlessHolidayConfirmationSubscription } from '@modules/product-package/models'

export const declinateSubscriptionAmount = (number: number): string => {
  const units = number % 10

  if (number === 1) {
    return `${number} pakiet`
  } else if (units > 1 && units < 5) {
    return `${number} pakiety`
  } else {
    return `${number} pakietów`
  }
}

export const isProductPackage = <T extends { kind?: string }>(product: T | undefined): boolean =>
  !!product?.kind && ['voucher', 'endless_holiday'].includes(product?.kind)

export const getProductPackageCardName = (
  subscription: EndlessHolidayConfirmationSubscription,
  kind: EndlessHolidayConfirmationKind,
): string => {
  if (kind === 'endless_holiday') {
    return {
      gold: 'Pakiet złoty',
      silver: 'Pakiet srebrny',
      brown: 'Pakiet brązowy',
    }[subscription.kind]
  }

  return ACCOMMODATION_TYPES.HOUSES.includes(subscription.accommodation_type) ? 'Domek' : 'Apartament'
}

export const getProductPackageBillName = (
  subscription: EndlessHolidayConfirmationSubscription,
  kind: EndlessHolidayConfirmationKind,
): string => {
  const amount = subscription.number_of_codes > 1 ? `x${subscription.number_of_codes}` : ''

  const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(subscription.accommodation_type)
    ? 'Domek'
    : 'Apartament'

  const variant = {
    gold: 'Pakiet złoty',
    silver: 'Pakiet srebrny',
    brown: 'Pakiet brązowy',
  }[subscription.kind]

  if (kind === 'endless_holiday') {
    return `${accommodationType} - ${variant} ${amount}`
  }

  return `Voucher Świąteczny - ${accommodationType} ${amount}`
}
