export type EventData<T = unknown> = {
  event: string
  pagePath?: string
  userID?: number | string
  date_range?: string
  number_of_days?: number
} & T

export interface EcommerceEventItem {
  item_id: string | number
  item_name: string
  index: number
  discount: string | number
  item_brand: string
  item_variant: string
  item_category: string
  item_category2?: string
  item_list_name: string
  price: string
  quantity: number
}

export type EcommerceEventData<T> = {
  currency: string
  value: string
  items: Partial<EcommerceEventItem>[]
} & T

export type PaymentInfoEventData = EventData<{
  ecommerce: EcommerceEventData<{ payment_method: string }>
}>

export type PurchaseEventData = EventData<{
  ecommerce: EcommerceEventData<{ transaction_id: string | number }>
}>

export enum GTMEvents {
  PageView = 'pageview',
  Purchase = 'purchase',
  AddPaymentInfo = 'add_payment_info',
  BeginCheckout = 'begin_checkout',
}

export enum GTMItemCategory {
  Ecommerce = 'e-sklep',
  Resort = 'ośrodek',
  Feeding = 'wyżywienie',
  Improvement = 'ulepszenia',
}

export interface TradeDoublerEventData {
  order: {
    number: string
    value: string
    extid: string
  }
}
