import * as React from 'react'
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery'

export const Gallery = (props: ReactImageGalleryProps): JSX.Element => (
  <div className="image-gallery">
    <ImageGallery
      showPlayButton={false}
      showThumbnails={false}
      lazyLoad={true}
      indexSeparator=" z "
      showFullscreenButton={false}
      onErrorImageURL={require('@assets/images/image-placeholder.webp')}
      {...props}
    />
  </div>
)
