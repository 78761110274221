import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { useModal } from '@components/modals/use-modal'
import { MarketingAgreementSection } from '@components/agreement/marketing-agreement-section'
import { HolidayBenefitContactFormInputs } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-contact-form'

export const HolidayBenefitBenefitsContactForm = React.forwardRef(
  (_, ref: React.Ref<HTMLFormElement>): React.ReactNode => {
    const { benefit_program } = useSelector(selectAppDataUrls)
    const methods = useForm<HolidayBenefitContactFormInputs>({
      defaultValues: { name: '', phone: '', email: '', email_rules: false, sms_rules: false, phone_call_rules: false },
    })

    const [showConfirmationModal] = useModal(
      'ConfirmationModal',
      {
        title: 'Dziękujemy za Twoje zgłoszenie',
        message: 'Nasz zespół skontaktuje się z Tobą w najbliższym możliwym czasie.',
        btnClass: 'btn-success text-white d-block mx-auto btn-responsive col-6 mt-3 font-500 py-3',
      },
      { onClose: methods.reset },
    )

    const { action: onSubmit, isLoading } = useFormRequest(async (payload: HolidayBenefitContactFormInputs) => {
      await commonObjectPost(benefit_program.lead, { type: 'benefit_program_employer', ...payload })
      showConfirmationModal()
    }, methods.setError)

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="holiday-benefit__contact-form py-5" ref={ref}>
          <div className="container my-5">
            <div className="holiday-benefit__section-title text-center">
              <strong className="text-holiday-benefit-primary me-2">Umów się na rozmowę</strong>
              <strong className="text-holiday-benefit-secondary">z naszym konsultantem</strong>
            </div>
            <p className="mt-3 mb-5 font-xl text-center">
              Wypełnij i wyślij formularz a my odezwiemy się w najbliższym możliwym terminie.
            </p>

            <div className="d-flex mb-5 justify-content-center flex-wrap col-lg-10 col-12 mx-auto">
              <div className="col-lg-6 col-12 d-flex flex-column gap-3">
                <CustomFormGroup
                  formGroupProps={{ className: 'col-12' }}
                  inputName="name"
                  label="Imię i nazwisko zgłaszającego:"
                  formControlProps={{ placeholder: 'Wpisz imię i nazwisko' }}
                />
                <CustomFormGroup
                  formGroupProps={{ className: 'col-12' }}
                  inputName="phone"
                  label="Numer telefonu:"
                  formControlProps={{ placeholder: 'Wpisz telefon kontaktowy' }}
                />
                <CustomFormGroup
                  formGroupProps={{ className: 'col-12' }}
                  inputName="email"
                  label="Adres e-mail:"
                  formControlProps={{
                    placeholder: 'Wpisz adres e-mail do kontaktu',
                    className: 'text-holiday-benefit-secondary',
                  }}
                />
              </div>
              <div className="ps-lg-5 col-12 col-lg-6">
                <MarketingAgreementSection fullWidth allSelectionClassName="align-items-start" />
              </div>
            </div>

            <button className="btn-holiday-benefit is-primary d-block mx-auto btn-responsive" disabled={isLoading}>
              {isLoading ? 'Wysyłanie...' : 'Wyślij formularz'}
            </button>
          </div>
        </form>
      </FormProvider>
    )
  },
)
