import * as React from 'react'
import { Spinner } from '@components/spinner'
import { IconWithText } from '@components/icon-with-text'
import { useRemainingTime } from '@hooks/use-remaining-time'

interface Props {
  expireAfter: string
  text?: React.ReactNode
}

export const ProductExpirationCounter = ({ expireAfter, text = 'Oferta wygasa za:' }: Props): React.ReactNode => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(expireAfter)

  if (isRemainingTimeCalculating) return <Spinner className="text-white spinner-border-sm" />

  return (
    <>
      <strong className="d-block font-xl">{text}</strong>
      <IconWithText
        text={isRemainingTimeCalculating ? <Spinner className="text-white spinner-border-sm" /> : remainingTime}
        iconClassName="uil-clock-five text-danger me-2"
        className="fw-bold text-white mt-1"
      />
    </>
  )
}
