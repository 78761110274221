import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { selectAppData } from '@store/selectors/app-data-selector'
import { useApiRequest } from '@hooks/use-form-request'
import { USER_CHECK_MESSAGE } from '@components/personal-data/use-user-check'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { ErrorMessage } from '@components/form-controls/form-error'
import { OfferDetailsPersonalDataAccountCreation } from '@components/personal-data/personal-data-account-creation'
import { useModal } from '@components/modals/use-modal'

export const PersonalDataNewClient = (): JSX.Element => {
  const { urls } = useSelector(selectAppData)

  const { control, clearErrors, reset } = useFormContext()

  const email = useWatch({ control, name: 'email' })

  const isInactiveErrorMessage = (message: string | undefined) => message === USER_CHECK_MESSAGE.EXISTING_INACTIVE_USER

  const [showConfirmationModal] = useModal(
    'ConfirmationModal',
    {
      title: 'Aktywacja konta',
      message: 'Sprawdź swoją skrzynkę pocztową i podążaj za przedstawionymi instrukcjami.',
      btnClass: 'btn-success text-white d-block mx-auto btn-responsive col-6 mt-3 font-500 py-3',
    },
    { onClose: reset },
  )
  const { action: handleAccountActivation } = useApiRequest(async () => {
    await commonObjectPost(urls.account.reset_password_link, { email })
    clearErrors('email')
    showConfirmationModal()
  })

  return (
    <div className="col-md-7 col-12">
      <div className="border rounded bg-lighter-gray p-4 h-100 text-center text-md-start">
        <strong className="text-primary font-xxl">Uzupełnij swoje dane</strong>
        <p>Pozwoli to na zarządzanie kupowanym produktem</p>

        <div className="col-xl-8 col-12">
          <CustomFormGroup
            inputName="first_name"
            formControlProps={{ placeholder: 'np. Andrzej' }}
            label="Wpisz imię:"
            formLabelProps={{ className: 'font-md font-500' }}
          />
          <CustomFormGroup
            inputName="last_name"
            formControlProps={{ placeholder: 'np. Nowak' }}
            label="Wpisz nazwisko:"
            formLabelProps={{ className: 'font-md font-500' }}
          />
          <CustomFormGroup
            inputName="email"
            formControlProps={{ placeholder: 'email@nazwa.pl', type: 'email' }}
            label="Wpisz adres e-mail:"
            formLabelProps={{ className: 'font-md fw-semi-bold' }}
            renderError={({ message }) => (
              <div className="d-inline-block align-items-center mt-2">
                <ErrorMessage message={message ?? ''} flexClassName="d-inline" />
                {isInactiveErrorMessage(message) && (
                  <button
                    className="btn-unstyled font-500 font-sm mt-2 text-decoration-none text-primary"
                    onClick={handleAccountActivation}
                  >
                    , aktywuj swoje konto.
                  </button>
                )}
              </div>
            )}
          />

          <IconWithText
            text={
              <span className="font-xs">
                <strong>Pamiętaj!</strong> Sprawdź poprawność adresu e-mail, ponieważ wyślemy na nie potwierdzenie
                Twojego zakupu.
              </span>
            }
            iconClassName="uil-exclamation-circle text-primary font-xl me-2 align-self-start lh-initial"
            className="font-sm text-darker-gray px-0 mt-3"
          />
        </div>
        <hr className="mx-n4" />
        <OfferDetailsPersonalDataAccountCreation />
      </div>
    </div>
  )
}
