import * as React from 'react'
import * as clsx from 'clsx'
import { motion } from 'framer-motion'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'

interface Props {
  className?: string
  url: string
  isFormVisible?: boolean
}

export const RodoAgreementsQrCodeButton = ({ className, url, isFormVisible }: Props): JSX.Element => {
  const [showQrCodeModal, hideModal] = useModal('RodoAgreementQrCodeModal', { text: url })

  React.useEffect(() => {
    if (!isFormVisible) {
      hideModal()
    }
  }, [isFormVisible])

  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      className={clsx('btn-unstyled d-sm-block d-none', className)}
      onClick={showQrCodeModal}
    >
      <IconWithText
        className="my-5 rodo-agreements__description-section__clickable-button"
        text={<div className="text-start lh-initial">lub uzupełnij na swoim telefonie</div>}
        iconClassName="uil-qrcode-scan font-xxxl text-darker-gray me-3"
        textClassName="text-darker-gray font-500"
      />
    </motion.button>
  )
}
