import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import { SubscriptionKind } from '@modules/package-wholesale/models'
import clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { SpinInput } from '@components/spin-input'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayProduct } from '@modules/product-package/models'
import { EndlessHolidayFormInputs } from '@modules/product-package/details'
import { useEndlessHolidayProductPrice } from '@modules/product-package/details/products-selection/product-variant/use-endless-holiday-product-price'

interface Props {
  icon: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
  variant: SubscriptionKind
  subscriptionProduct: EndlessHolidayProduct
  gastroProduct: EndlessHolidayProduct
  showDiscountedPrice: boolean
  priceSurplusWithoutGastro: string
}

export const ProductVariant = ({
  variant,
  title,
  subtitle,
  children,
  icon,
  subscriptionProduct,
  gastroProduct,
  showDiscountedPrice,
  priceSurplusWithoutGastro,
}: Props): React.ReactNode => {
  const [activeKey, setActiveKey] = React.useState('')

  const { control, setValue } = useFormContext<EndlessHolidayFormInputs>()

  const { price } = useEndlessHolidayProductPrice(showDiscountedPrice, subscriptionProduct, priceSurplusWithoutGastro)

  const [products, accommodation] = useWatch({ control, name: ['products', 'accommodation'] })

  const value = products.find(product => product.product.id === subscriptionProduct.id)?.amount || 0
  const isProductAdded = value > 0

  React.useEffect(() => {
    setActiveKey(isProductAdded ? '0' : '')
  }, [accommodation])

  const getUpdatedProducts = (newValue: number) => {
    if (newValue === 0)
      return products.filter(
        product => product.product.id !== subscriptionProduct.id && product.related_product !== subscriptionProduct.id,
      )

    if (isProductAdded) {
      return products.map(product => {
        if (product.product.id === subscriptionProduct.id || product.related_product === subscriptionProduct.id) {
          return { ...product, amount: newValue }
        }
        return product
      })
    }

    return [
      ...products,
      { product: subscriptionProduct, amount: newValue, related_product: null, options: [] },
      { product: gastroProduct, amount: newValue, related_product: subscriptionProduct.id, options: [] },
    ]
  }

  const handleIncrement = (newValue: number) => {
    if (newValue > 0) setActiveKey('0')
    setValue('products', getUpdatedProducts(newValue))
  }

  const handleDecrement = (newValue: number) => {
    if (newValue === 0) setActiveKey('')
    setValue('products', getUpdatedProducts(newValue))
  }

  const handleCardClick = () => {
    if (value === 0) {
      handleIncrement(1)
    }
  }

  return (
    <Accordion className="endless-holiday__accordion no-toggle" activeKey={activeKey}>
      <Accordion.Item eventKey="0" className={clsx({ [`is-${variant}`]: variant })}>
        <Accordion.Header onClick={handleCardClick}>
          <div className="d-flex flex-lg-row flex-column align-items-center w-100">
            {icon && <div className="me-lg-4">{icon}</div>}
            <div className="d-flex flex-lg-row flex-column justify-content-between w-100">
              <div className="d-flex flex-column justify-content-center align-items-lg-start text-center text-lg-start my-4 my-lg-0">
                {title}
                {subtitle}
              </div>
              <div className="d-flex align-items-center text-darker-gray flex-lg-row flex-column">
                <div className="font-xxxl text-nowrap">{formatPriceShort(price)}</div>
                <div className="vr mx-4 d-lg-block d-none" />
                <div className="me-lg-4 mt-3 mb-2 mt-lg-0 mb-lg-0">Ilość pakietów:</div>
                <SpinInput disabled onIncrement={handleIncrement} onDecrement={handleDecrement} min={0} value={value} />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <section className="col-12">{children}</section>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
