import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientInvoiceTaxNumber } from '@components/client-invoice/tax-number'
import { ClientInvoiceEditCompanyData } from '@components/client-invoice/edit-company-data'
import { CircleOutlineRadioButton } from '@components/form-controls/circle-outline-radio-button'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'

export const ClientInvoiceForm = (): JSX.Element => {
  const { control, clearErrors } = useFormContext<PackageWholesaleFormInput>()

  const [invoiceEnabled, invoiceNip] = useWatch({ control, name: ['invoice', 'invoice_nip'] })

  React.useEffect(() => {
    clearErrors('invoice_nip')
  }, [invoiceNip])

  return (
    <div className="mt-3 mb-5 package-wholesale__invoice col-12 col-sm-10">
      <div className="row align-items-start">
        <div className="col-sm-6 col-12">
          <strong className="text-gold">Dane do faktury:</strong>
          <span className="d-block col-12 col-sm-9 text-darker-gray font-md mt-2">
            Niepodanie numeru NIP jest jednoznaczne z brakiem możliwości wystawienia FV w późniejszym terminie
          </span>
        </div>
        <div className="col-sm-6 col-12">
          <Checkbox name="invoice" label="Chcę otrzymać fakturę VAT" className="fw-bold" />
          {invoiceEnabled && (
            <div className="d-sm-flex mt-2">
              <CircleOutlineRadioButton
                label="Faktura na firmę"
                id="invoice_type_company"
                name="invoice_type"
                value="company"
                wrapperClassName="col-sm-6 col-6 mt-lg-0 mt-2"
              />
              <CircleOutlineRadioButton
                label="Faktura na osobę indywidualną"
                id="invoice_type_individual"
                name="invoice_type"
                value="individual"
                wrapperClassName="col-sm-6 col-6 mt-2 mt-lg-0"
              />
            </div>
          )}
        </div>
      </div>

      {invoiceEnabled && (
        <>
          <ClientInvoiceTaxNumber />
          <ClientInvoiceEditCompanyData />
        </>
      )}
    </div>
  )
}
