import { useOrderGtmEvents } from '@hooks/use-order-gtm-events'
import { ReservationDetails } from '@modules/reservation/models'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { usePaymentSuccessProductDetails } from '@hooks/gtm/use-payment-success-product-details'

interface CodeUpgradeDetails {
  email: string
  price_brutto: string
  token: string
}

// Tradedeoubler integration orders data

export const usePaymentSuccessOrderTradedeoublerEvent = (kind: string | undefined, token: string | undefined): void => {
  const { sendOrderData } = useOrderGtmEvents()

  const sendReservationOrderData = (reservationDetails: ReservationDetails) => {
    sendOrderData(
      reservationDetails.token,
      reservationDetails.prices.stay_charge_without_climatic,
      reservationDetails.email,
    )
  }

  const sendSubscriptionWithBenefitsOrderData = (subscription: SubscriptionDetails) => {
    sendOrderData(subscription.number, subscription.product.price_brutto, subscription.client.email)
  }

  const sendGoHolidayOrderData = (subscription: SubscriptionDetails) => {
    sendOrderData(subscription.number, subscription.product.price_brutto, subscription.client.email)
  }

  const sendSubscriptionContractOrderData = (subscription: SubscriptionContractDetails) => {
    sendOrderData(subscription.token, subscription.single_price_brutto, subscription.client.email)
  }

  const sendCodesConversionOrderData = (code: CodeUpgradeDetails) => {
    sendOrderData(code.token, code.price_brutto, code.email)
  }

  const sendPaymentSuccessEvent = (
    productDetails: ReservationDetails | SubscriptionDetails | SubscriptionContractDetails | CodeUpgradeDetails,
  ) => {
    if (kind === ProductKinds.RESERVATION_DETAILS) {
      sendReservationOrderData(productDetails as ReservationDetails)
    }

    if (kind === ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS) {
      sendSubscriptionWithBenefitsOrderData(productDetails as SubscriptionDetails)
    }

    if (kind === ProductKinds.GO_HOLIDAY) {
      sendGoHolidayOrderData(productDetails as SubscriptionDetails)
    }

    if (kind === ProductKinds.SUBSCRIPTION_CONTRACT) {
      sendSubscriptionContractOrderData(productDetails as SubscriptionContractDetails)
    }

    if (kind === ProductKinds.CODES_CONVERSION) {
      sendCodesConversionOrderData(productDetails as CodeUpgradeDetails)
    }
  }

  usePaymentSuccessProductDetails(kind, token, sendPaymentSuccessEvent)
}
