import { useSelector } from 'react-redux'
import * as React from 'react'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { RootState } from '@store/index'

const localizations = { sea: 'nad morzem', mountains: 'w górach' }

interface Response {
  getSubscriptionProductName: (product: PackageWholesaleProduct) => React.ReactNode
}

export const useSubscriptionProductName = (): Response => {
  const accommodationTypes = useSelector((state: RootState) => state.appDataState.appData.accommodation_types)

  const getAccommodationType = (product: PackageWholesaleProduct) =>
    accommodationTypes.find(row => row.id === product.accommodation_type_id)

  const getSubscriptionProductName = (product: PackageWholesaleProduct) => (
    <>
      {getAccommodationType(product)?.name} {localizations[product.localization_kind]}
    </>
  )

  return {
    getSubscriptionProductName,
  }
}
