import * as React from 'react'
import { PackageWholesaleDetails, PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { PackageWholesaleGastroCardBoostVariantCustom } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-variant-custom'
import { PackageWholesaleGastroCardBoostVariantPredefined } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-variant-predefined'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { GastroCardPrices } from '@modules/package-wholesale/details/gastro-card/gastro-card-prices'

export type CustomAmount = 'products.1.amount'

const getCustomAmountKey = index => `products.${index}.amount` as CustomAmount

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleGastroCardBoostCardsSection = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const gastroCardProducts = packageWholesaleDetails.products.filter(product => product.kind === 'gastro_card_boost')

  const { control, getValues } = useFormContext<PackageWholesaleFormInput>()
  const { fields: products, append, remove } = useFieldArray({ control: control, name: 'products' })

  const addedProducts = useWatch({ control, name: 'products' })
  const isProductAdded = product => addedProducts.some(el => el.product.id === product.id)

  const { predefined, custom } = React.useMemo(
    () =>
      gastroCardProducts.reduce(
        (acc, curr) =>
          curr.price_brutto
            ? { predefined: [...acc.predefined, curr], custom: acc.custom }
            : { predefined: acc.predefined, custom: [...acc.custom, curr] },
        { predefined: [], custom: [] },
      ),
    [gastroCardProducts],
  )

  const handleGastroCardBoostClick = (product: PackageWholesaleProduct) => () => {
    if (isProductAdded(product)) {
      remove(products.findIndex(el => el.product.id === product.id))
      return
    }

    const customProductPrice = getValues(getCustomAmountKey(getArrayIndex(product)))

    append({
      product,
      amount: product.price_brutto ? asDecimal(product?.price_brutto ?? 0).toNumber() : customProductPrice ?? 0,
      active: true,
      with_zoo_borysew_tickets: false,
      options: { zoo_borysew: false },
    })
  }

  const price = React.useMemo(
    () =>
      sumDecimalArray(
        addedProducts.filter(el => el.product.kind === 'gastro_card_boost').map(el => asDecimal(el.amount)),
      ).toString(),
    [addedProducts],
  )

  const multiplied = asDecimal(price).mul(1.4).toString()

  const getArrayIndex = product => products.findIndex(el => el.product.id === product.id)

  return (
    <>
      <div className="col-sm-10 col-12">
        <div>
          <h5 className="text-secondary font-xxl my-3 fw-bold">
            Oferujemy vouchery Doładowanie Smaku{' '}
            <span className="underline-shadow text-gold">w specjalnych cenach:</span>
          </h5>
          <strong className="text-primary mb-1 d-block">Wybierz rodzaj vouchera:</strong>
          {predefined.map(product => (
            <PackageWholesaleGastroCardBoostVariantPredefined
              key={product.id}
              product={product}
              isActive={isProductAdded(product)}
              onCardClick={handleGastroCardBoostClick(product)}
            />
          ))}

          {!!custom.length && (
            <strong className="mt-4 mb-1 d-block text-darker-gray">lub wpisz własną kwotę vouchera:</strong>
          )}
          {custom.map(product => (
            <PackageWholesaleGastroCardBoostVariantCustom
              key={product.id}
              inputName={getCustomAmountKey(getArrayIndex(product))}
              isActive={isProductAdded(product)}
              onCardClick={handleGastroCardBoostClick(product)}
            />
          ))}
        </div>
      </div>
      <GastroCardPrices basicPrice={price} bonusPrice={multiplied} />
    </>
  )
}
