import * as React from 'react'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'

export const HolidayBenefitEmployeeBenefitsSection = React.forwardRef(
  (_, ref: React.Ref<HTMLDivElement>): React.ReactNode => (
    <div className="holiday-benefit__benefits" ref={ref}>
      <div className="container d-flex gap-5">
        <div className="col-lg-5 col-12 mt-lg-5">
          <div className="holiday-benefit__section-title text-center text-sm-start">
            <strong className="d-block text-holiday-benefit-secondary">Czym jest</strong>
            <strong className="text-holiday-benefit-primary d-block mt-1">My Holiday Benefits?</strong>
          </div>
          <p className="my-5">
            To innowacyjny system benefitów pracowniczych, który pozwala Ci odkładać na wypoczynek w wygodnym modelu
            abonamentowym. Co miesiąc gromadzisz środki na wymarzone pobyty, a koszty są dzielone między Ciebie a
            pracodawcę. To prosty i elastyczny sposób na spełnianie marzeń o podróżach bez konieczności dużych
            jednorazowych wydatków.
          </p>
          <div className="mt-5">
            <span className="font-xxl font-500 text-holiday-benefit-secondary">
              Co wchodzi w{' '}
              <HolidayBenefitBenefitUnderlineText
                text="skład benefitu?:"
                underlineColor="#d50c51"
                textClass="text-holiday-benefit-primary"
              />
            </span>

            <ul className="list-unstyled">
              {whatsIncluded.map((benefit, index) => (
                <li key={index} className="d-flex align-items-center mt-2">
                  <span className="uil-check holiday-benefit__benefits__point bg-holiday-benefit-primary" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <span className="font-xxl font-500 text-holiday-benefit-secondary">
              Co{' '}
              <HolidayBenefitBenefitUnderlineText
                text="zyskujesz:"
                underlineColor="#951c81"
                textClass="text-holiday-benefit-tertiary"
              />
            </span>

            <ul className="list-unstyled">
              {yourGains.map((benefit, index) => (
                <li key={index} className="d-flex align-items-center mt-2">
                  <span className="uil-check holiday-benefit__benefits__point bg-holiday-benefit-tertiary" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-7 d-none d-lg-block">
          <img
            src={require('@assets/images/holiday-benefit/landing-page/benefits-employee.webp')}
            alt="holidaypark logo"
            height="650"
            className="mw-100"
          />
        </div>
      </div>
    </div>
  ),
)

const whatsIncluded = [
  '3-dniowy pobyt tuż po przystąpieniu do benefitu w prezencie w pierwszym roku',
  '3-dniowy pobyt po 6 miesiącach od przystąpienia do benefitu',
  '6-dniowy pobyt po 12 miesiącach od przystąpienia do benefitu',
]

const yourGains = [
  'Lepszą równowagę między życiem zawodowym a prywatnym',
  'Lepsze samopoczucie i więcej energii do działania',
  'Dostęp do wysokiej klasy ośrodków wypoczynkowych',
  'Możliwość współfinansowania pobytów z pracodawcą',
]
