import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  codes: string[]
}

export const CodesDeactivationList = ({ codes }: Props): JSX.Element => (
  <>
    <div className="row codes-deactivation__list__title">
      <div className="col-4 ps-4 font-500">Status</div>
      <div className="col-8 font-500">Kod</div>
    </div>
    <ul className="codes-deactivation__list">
      {codes.map(code => (
        <li key={code} className="codes-deactivation__list__item">
          <div className="row">
            <div className="col-4">
              <IconWithText
                text="wykorzystany"
                iconClassName="uil-check me-2 font-xxl lh-1"
                className="text-success fw-500"
              />
            </div>
            <div className="col-8 font-500">{code}</div>
          </div>
        </li>
      ))}
    </ul>
  </>
)
