export const ACCOMMODATION_TYPES = {
  HOUSES: [1, 4],
  APARTMENTS: [2, 3],
}

export const CLIENT_APP_URLS = {
  RESET_PASSWORD: `${process.env.CLIENT_URL ?? ''}/reset-password/`,
  ACCOUNT_CREATE: `${process.env.CLIENT_URL ?? ''}/account-create/`,
  MAIN_PAGE: `${process.env.CLIENT_URL ?? ''}/`,
  APP_STORE_LINK: 'https://apps.apple.com/pl/app/holiday-park-resort/id1624680125',
  GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=pl.holidaypark.holidaypark&hl=pl',
}

export const RESERVATION_APP_URL = 'https://rezerwacje.holidaypark.pl/'

export const API_ERROR_HEADERS = {
  API_ERROR_CODE: 'api-error-code',
}

export const API_ERROR_CODES = {
  NO_USER: 'no_user',
  NO_ACTIVE_USER: 'no_active_user',
}
