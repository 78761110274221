import * as React from 'react'
import { getGastroProductPrice } from '@modules/gastro-card/details/utils'
import { GastroGenericProductDetails, GastroProductMultiplier } from '@modules/gastro-generic/models'
import { GastroDynamicOfferExpirationTime } from '@modules/gastro-generic/details/gastro-dynamic/gastro-dynamic-offer-expiration-time'

interface Props {
  gastroCardDetails: GastroGenericProductDetails
  gastroProductMultiplier: GastroProductMultiplier
}

export const GastroCardDynamicSideCardOfferExpirationBanner = ({
  gastroCardDetails,
  gastroProductMultiplier,
}: Props): JSX.Element => {
  const { promotionPercentage } = getGastroProductPrice(gastroCardDetails)

  return (
    <div className="rounded bg-danger d-none d-lg-flex flex-column justify-content-center align-items-center text-white mt-2 py-3">
      <span className="d-block font-md">Oferta z {promotionPercentage}% dopłatą zmniejszy się za:</span>
      <GastroDynamicOfferExpirationTime
        gastroProductMultiplier={gastroProductMultiplier}
        spinnerClassName="text-white"
      />
    </div>
  )
}
