import * as React from 'react'
import { HolidayBenefitInvitationAccountLogin } from '@modules/holiday-benefit/employee-invitation/account-login'
import { HolidayBenefitInvitationAccountCreation } from '@modules/holiday-benefit/employee-invitation/account-creation'
import { useToggle } from '@hooks/use-toggle'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/client-slice'
import { HolidayBenefitInvitationAccountLoggedIn } from '@modules/holiday-benefit/employee-invitation/account-logged-in'
import { useFormRequest } from '@hooks/use-form-request'
import { HolidayBenefitInvitation } from '@modules/holiday-benefit/employee-invitation/models'
import { useFormContext } from 'react-hook-form'
import { HolidayBenefitInvitationFormInputs } from '@modules/holiday-benefit/employee-invitation/index'
import { commonObjectPost } from '@requests/basic-requests'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'

interface Props {
  onPreviousStepClick: () => void
  benefitInvitation: HolidayBenefitInvitation
  token: string
}

export const HolidayBenefitInvitationData = ({
  onPreviousStepClick,
  benefitInvitation,
  token,
}: Props): React.ReactNode => {
  const navigate = useNavigate()
  const clientDetails = useSelector(selectClientDetails)

  const [isAccountCreationViewVisible, toggleIsAccountCreationViewVisible] = useToggle(false)

  const { getValues, setError } = useFormContext<HolidayBenefitInvitationFormInputs>()

  const { action: handleSubmit, isLoading } = useFormRequest<void>(
    async () => {
      await commonObjectPost(benefitInvitation.urls.create, getValues())
      navigate(NavigationPath.OperationSuccess.replace(':token', token).replace(':kind', 'holiday-benefit'))
    },
    setError,
    { nonFieldErrorsAs: 'email_create' },
  )

  if (clientDetails) {
    return (
      <HolidayBenefitInvitationAccountLoggedIn
        onPreviousStepClick={onPreviousStepClick}
        onSubmit={handleSubmit}
        benefitInvitation={benefitInvitation}
        isLoading={isLoading}
      />
    )
  }

  if (isAccountCreationViewVisible)
    return (
      <HolidayBenefitInvitationAccountCreation
        isLoading={isLoading}
        benefitInvitation={benefitInvitation}
        onLoginClick={toggleIsAccountCreationViewVisible}
        onSubmit={handleSubmit}
        onPreviousStepClick={onPreviousStepClick}
      />
    )

  return <HolidayBenefitInvitationAccountLogin onCreateAccountClick={toggleIsAccountCreationViewVisible} />
}
