import { AccommodationType, AppData, AppDataStatus, AppDataUrls, PaymentMethod, Resort } from '@models/app-data'
import { RootState } from '@store/index'

export const selectAppData = (state: RootState): AppData => state.appDataState.appData

export const selectAppDataStatus = (state: RootState): AppDataStatus => state.appDataState.status

export const selectPaymentMethods = (state: RootState): PaymentMethod[] => state.appDataState.appData.payment_methods

export const selectAppDataUrls = (state: RootState): AppDataUrls => state.appDataState.appData.urls

export const selectResorts = (state: RootState): Resort[] => state.appDataState.appData.resorts

export const selectAccommodationTypes = (state: RootState): AccommodationType[] =>
  state.appDataState.appData.accommodation_types
