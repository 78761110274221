import * as React from 'react'
import { OfferSeller } from '@components/offer-seller'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { Client, Seller } from '@models/base'
import { ProductStatusLabel } from '@components/product-status-label'
import * as clsx from 'clsx'
import { ClientInvoicePreview } from '@components/client-invoice/client-invoice-preview'
import { ClientInvoiceData } from '@models/models'

interface Props {
  seller?: Seller
  client: Client
  title: React.ReactNode
  titleClassName?: string
  created: string
  mobileLabel?: React.ReactElement
  isProductPaid?: boolean
  onScrollToPayment?: () => void
  children?: React.ReactNode
  productInvoice?: ClientInvoiceData
  withClientData?: boolean
}

export const CardContentBase = ({
  seller,
  created,
  client,
  title,
  titleClassName,
  mobileLabel,
  isProductPaid,
  onScrollToPayment,
  children,
  productInvoice,
  withClientData = true,
}: Props): React.ReactElement => (
  <>
    <div className="d-flex flex-column flex-sm-row justify-content-sm-between justify-content-center">
      <div>
        <h3 className={clsx('text-gold fw-bold text-center text-sm-start d-block', titleClassName)}>{title}</h3>
        {!isProductPaid && (
          <p className="text-darker-gray font-sm text-center text-sm-start d-block">
            Prosimy o sprawdzenie poprawności poniższych danych i dokonanie płatności.
          </p>
        )}
      </div>
      {seller && <OfferSeller seller={seller} created={created} className="col-sm-3" showBasePhoneNumber />}
      <div className="d-sm-none">
        <div className="mb-3 mx-auto">{mobileLabel}</div>
      </div>
    </div>
    {children}
    {isProductPaid !== undefined && (
      <CardContentBaseDataRow
        className="my-2 flex-column flex-sm-row"
        valueClassName="col-12 col-sm-5"
        name={<strong className="text-gold text-center text-sm-start d-block">Status płatności:</strong>}
        value={
          <ProductStatusLabel
            className="is-mobile-full-width"
            isPaid={isProductPaid}
            onLabelClick={onScrollToPayment}
          />
        }
      />
    )}
    {withClientData && (
      <>
        <strong className="text-gold text-center text-sm-start d-block">Dane osoby kupującej:</strong>
        <div className="text-darker-gray mt-3 d-flex flex-column gap-2 font-md">
          <CardContentBaseDataRow name="Imię i nazwisko:" value={`${client.first_name} ${client.last_name}`} />
          <CardContentBaseDataRow name="Adres e-mail:" value={client.email || '-'} />
          {client.phone && <CardContentBaseDataRow name="Numer telefonu:" value={client.phone} />}
        </div>
      </>
    )}
    {productInvoice?.invoice_nip && <ClientInvoicePreview product={productInvoice} />}
  </>
)
