import * as React from 'react'
import { ProductAccommodationSelection } from '@modules/product-package/details/products-selection/accommodation-type-selection'
import { ProductsVariantsSelection } from '@modules/product-package/details/products-selection/product-variant/product-variants-selection'
import { EndlessHolidayDetails } from '@modules/product-package/models'

interface Props {
  productDetails: EndlessHolidayDetails
}

export const EndlessHolidayDetailsProductsSelection = ({ productDetails }: Props): React.ReactNode => (
  <div className="mt-4">
    <ProductAccommodationSelection />
    <ProductsVariantsSelection productDetails={productDetails} />
  </div>
)
