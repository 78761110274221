import * as React from 'react'
import { selectAppData } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { Country } from '@models/app-data'

interface Response {
  countries: Country[]
  getCountryName: (countryCode: string) => string
}

export const useCountries = (): Response => {
  const { countries } = useSelector(selectAppData)

  const getCountryName = React.useCallback(
    (countryCode: string) => countries.find((country: Country) => country.id === countryCode)?.name ?? '',
    [countries],
  )

  return {
    countries,
    getCountryName,
  }
}
