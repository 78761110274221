import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { IconWithText } from '@components/icon-with-text'
import { DateFormats, formatDate } from '@helpers/date-formatter'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionBookingImprovementAcceptanceDate = ({ subscriptionDetails }: Props): JSX.Element => (
  <div>
    {subscriptionDetails.related_booking && (
      <CardContentBaseDataRow
        nameClassName="col-12"
        valueClassName="col-12 text-center text-sm-start mt-1 mb-sm-2 mb-3"
        name={
          <strong className="text-gold text-center text-sm-start d-block col-12">Termin akceptacji regulaminów:</strong>
        }
        value={
          <>
            <div className="badge-danger d-inline-block">
              <IconWithText
                iconClassName="uil-clock-five me-1 font-16"
                textClassName="fw-semi-bold"
                text={formatDate(
                  subscriptionDetails.related_booking.date_to,
                  DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR,
                )}
              />
            </div>
            <small className="fw-normal ms-2 d-none d-sm-inline">(data wymeldowania)</small>
          </>
        }
      />
    )}
  </div>
)
