import * as React from 'react'
import { ResortsGallery } from '@components/resorts-gallery'
import Carousel from 'react-multi-carousel'

interface Props {
  title: string
  subtitle: React.ReactNode
  galleryTitle: string
  gallerySubtitle: string
  galleryImages: { original: string }[]
}

export const HolidayBenefitResorts = ({
  title,
  subtitle,
  gallerySubtitle,
  galleryTitle,
  galleryImages,
}: Props): JSX.Element => (
  <div className="holiday-benefit__resorts py-5">
    <div className="container">
      <div className="mt-5">
        <div className="holiday-benefit__section-title text-center">
          <strong className="d-block text-holiday-benefit-secondary">{title}</strong>
          <strong className="text-holiday-benefit-primary d-block mt-1">{subtitle}</strong>
        </div>
        <p className="font-xxl mt-5 text-center">
          Holiday Park & Resort to unikatowa sieć obiektów rekreacyjno-wypoczynkowych, w których komfort i relaks łączą
          się w jedność. Ośrodki powstały z myślą o rodzinach z dziećmi, zapewniając zarówno dorosłym, jak i najmłodszym
          moc niezapomnianych atrakcji.
        </p>
        <div className="d-flex gap-4 justify-content-center mt-lg-5 flex-wrap">
          <div className="holiday-benefit__resorts__pill ">
            <strong>8 lat</strong> na rynku
          </div>
          <div className="holiday-benefit__resorts__pill ">
            <strong>7 resortów</strong> nad morzem i w górach
          </div>
          <div className="holiday-benefit__resorts__pill ">
            <strong>14 tys.</strong> miejsc noclegowych
          </div>
        </div>
        <div className="holiday-benefit__resorts__gallery mt-5 d-lg-grid d-none">
          {galleryImages.map(item => (
            <Image key={item.original} src={item.original} />
          ))}
        </div>
        <div className="bocian-voucher__gallery d-lg-none">
          <div className="package-wholesale__resorts">
            <Carousel
              showDots
              responsive={breakpoints}
              autoPlay={false}
              shouldResetAutoplay={false}
              itemClass="package-wholesale__resorts__item"
            >
              {galleryImages.map(item => (
                <img
                  key={item.original}
                  src={item.original}
                  alt="Holiday Park & Resort"
                  height={300}
                  style={{ maxWidth: 400, width: '100%', objectFit: 'cover', objectPosition: 'top' }}
                  className="mx-auto rounded-4"
                />
              ))}
            </Carousel>
          </div>
        </div>
        <div className="d-flex justify-content-lg-between justify-content-center mt-lg-5 col-lg-10 mx-auto flex-wrap col-12 gap-5 ">
          <Icon src={require('@assets/images/holiday-benefit/landing-page/pool.svg')} text="Baseny" />
          <Icon src={require('@assets/images/holiday-benefit/landing-page/playground-2.svg')} text="Place zabaw" />
          <Icon src={require('@assets/images/holiday-benefit/landing-page/playground.svg')} text="Małpie gaje" />
          <Icon src={require('@assets/images/holiday-benefit/landing-page/machines.svg')} text="Salon gier" />
          <Icon src={require('@assets/images/holiday-benefit/landing-page/animations.svg')} text="Animacje" />
        </div>
      </div>

      <div className="mt-5">
        <div className="holiday-benefit__section-title text-center pt-5">
          <strong className="d-block text-holiday-benefit-secondary">{galleryTitle}</strong>
          <strong className="text-holiday-benefit-primary d-block mt-1">{gallerySubtitle}</strong>
        </div>
        <ResortsGallery
          cardClassName="my-0"
          customBreakpoints={breakpoints}
          wrapperClassName="bocian-voucher__gallery"
          subtitle=""
          title={
            <div className="fw-normal font-xl my-4 text-holiday-benefit-secondary">
              Mamy <strong>aż 7 lokalizacji</strong> do wyboru <strong>nad morzem</strong> lub <strong>w górach</strong>
            </div>
          }
        />
      </div>
    </div>
  </div>
)

const Image = ({ src }) => <img src={src} alt="holidaypark logo" className="holiday-benefit__resorts__gallery__image" />

const Icon = ({ text, src }) => (
  <div className="text-center col-lg-auto col-5">
    <img src={src} alt="holidaypark logo" />
    <strong className="font-xl d-block mt-2">{text}</strong>
  </div>
)

const breakpoints = {
  extraLarge: { breakpoint: { max: 3000, min: 1770 }, items: 5 },
  large: { breakpoint: { max: 1770, min: 1200 }, items: 5 },
  semiLarge: { breakpoint: { max: 1200, min: 768 }, items: 3 },
  medium: { breakpoint: { max: 768, min: 468 }, items: 2 },
  small: { breakpoint: { max: 468, min: 0 }, items: 1 },
}
