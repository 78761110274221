import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { motion } from 'framer-motion'

export const RodoAgreementDataExistModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => (
  <ModalBody className="px-5 pt-5 pb-0">
    <motion.button
      className="btn-unstyled d-block ms-auto rodo-agreements__qr-modal__close-button"
      whileTap={{ scale: 0.9 }}
      onClick={toggleIsVisible}
    >
      <i className="uil-times text-muted" />
    </motion.button>
    <div className="d-flex flex-column flex-sm-row">
      <div className="col-sm-5 col-12 text-center">
        <img src={require('@assets/images/woman.webp')} height="300" alt="Holiday Park & Resort" />
      </div>

      <div className="col-sm-6 col-12 pb-5 ps-sm-5">
        <h3 className="text-muted font-500 font-xxl mt-5 text-center text-sm-start lh-sm">
          Wygląda na to, że Twoje dane są już w naszej bazie, dlatego nie możemy przyjąć tego formularza.
        </h3>
        <p className="font-500 font-xxl text-center text-primary mt-5">Cieszymy się, że jesteś z nami!</p>
      </div>
    </div>
  </ModalBody>
)
