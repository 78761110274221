import * as React from 'react'
import * as clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { PackageWholesaleGastroCardVariant } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-variant'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { asDecimal } from '@helpers/utils'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'

interface Props {
  isActive: boolean
  onCardClick: () => void
  product: PackageWholesaleProduct
}

export const PackageWholesaleGastroCardVariantPredefined = ({
  isActive,
  onCardClick,
  product,
}: Props): JSX.Element | null => {
  const gastroProduct = useGastroProductProvider(product.kind)
  if (!gastroProduct) return null
  const multipliedPrice = asDecimal(product.price_brutto).mul(gastroProduct.multiplier).toString()

  return (
    <PackageWholesaleGastroCardVariant
      isActive={isActive}
      onCardClick={onCardClick}
      title={
        <span>
          {' '}
          Voucher {gastroProduct.product_name} w kwocie {formatPriceShort(product?.price_brutto ?? 0)}
        </span>
      }
    >
      <div className="package-wholesale__gastro-card__values">
        <div className="text-white package-wholesale__gastro-card__amount-paid">
          <p className="mb-1 font-sm">Wpłacana kwota:</p>
          <span className="font-xxl">{formatPriceShort(product?.price_brutto ?? 0)}</span>
        </div>
        <div className={clsx('cursor-pointer package-wholesale__gastro-card__bonus', { 'is-active': isActive })}>
          <img
            src={require('@assets/images/gastr-coupon-bg.svg')}
            alt="Kupon gastronomiczny"
            className="package-wholesale__gastro-card__bonus__bg"
          />
          <div>
            <strong className="font-xxxl">=</strong>
          </div>
        </div>
        <div className="text-darker-gray package-wholesale__gastro-card__amount-final">
          <p className="mb-1 font-sm">Wartość kuponu:</p>
          <strong className="font-xxl text-end d-block">{formatPriceShort(multipliedPrice)}</strong>
        </div>
      </div>
    </PackageWholesaleGastroCardVariant>
  )
}
