import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { InvoiceForm } from '@components/invoice-form/invoice-form'
import { useFormContext, useWatch } from 'react-hook-form'
import * as clsx from 'clsx'
import { Collapse } from 'react-bootstrap'

interface Props {
  formWrapperClassName?: string
  invoiceCheckClassName?: string
}

export const Invoice = ({ formWrapperClassName, invoiceCheckClassName }: Props): JSX.Element => {
  const { control } = useFormContext()

  const isInvoiceSelected = useWatch({ control, name: 'invoice' })

  return (
    <div className="col-12 mt-4">
      <div className="border rounded bg-lighter-gray px-4 py-3 h-100">
        <div className={clsx(invoiceCheckClassName)}>
          <Checkbox
            id="invoice"
            name="invoice"
            label={<span className="fw-bold text-darker-gray text-nowrap font-md">Chcę otrzymać fakturę VAT</span>}
            className="d-inline-flex align-items-center fw-bold checkbox--small"
          />
        </div>
        <Collapse in={isInvoiceSelected}>
          <div className="col-xl-10">
            <InvoiceForm wrapperClassName={formWrapperClassName} />
          </div>
        </Collapse>
      </div>
    </div>
  )
}
