import * as React from 'react'
import { OperationResult } from '@components/payment-operation-results/operation-result'
import { useParams } from 'react-router-dom'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { ReservationDetailsPaymentSuccess } from '@modules/reservation/details/reservation-details-payment-success'
import { usePaymentSuccessOrderTradedeoublerEvent } from '@hooks/gtm/use-payment-success-order-tradedeoubler-event'
import { usePaymentSuccessEcommerceOrderGtmEvent } from '@hooks/gtm/ecommerce/use-payment-success-ecommerce-order-gtm-event'

type Params = 'kind' | 'token'

export const PaymentSuccess = (): JSX.Element => {
  const { kind, token } = useParams<Params>()

  usePaymentSuccessOrderTradedeoublerEvent(kind, token)
  usePaymentSuccessEcommerceOrderGtmEvent(kind, token)

  if (kind === ProductKinds.RESERVATION_DETAILS && token) {
    return <ReservationDetailsPaymentSuccess token={token} />
  }

  return (
    <OperationResult title="Dziękujemy, płatność zakończona sukcesem" image="success.svg">
      <p className="text-center font-lg text-darker-gray">
        Szybko poszło! Cały proces przebiegł poprawnie i płatność została zrealizowana
      </p>
      <a href="https://holidaypark.pl/" className="text-decoration-none">
        <button className="btn btn-responsive bg-secondary px-5 py-3 text-white mt-5 mx-auto d-block">
          <strong>Przejdź do strony holidaypark.pl</strong>
        </button>
      </a>
    </OperationResult>
  )
}
