import * as React from 'react'
import { ModalConfig, ModalParams, ModalsMap, ModalTypes } from '@components/modals/types'
import { useDispatch } from 'react-redux'
import { setHideModal, setModalIsOpen } from '@store/slices/modal-slice'
import { Modal } from 'react-bootstrap'

interface ModalComponentRowProps {
  modal: ModalTypes
  params: ModalParams
  config?: ModalConfig
  isOpen: boolean
}

export const ModalComponentRow: React.FC<ModalComponentRowProps> = ({ modal, isOpen, params, config }) => {
  const dispatch = useDispatch()

  const [ModalContent, options] = ModalsMap[modal]

  const onClose = React.useCallback(() => {
    config?.onClose?.()
    return dispatch(setHideModal({ modal }))
  }, [modal, config])

  const toggleIsOpen = React.useCallback(() => dispatch(setModalIsOpen({ modal, isOpen: !isOpen })), [modal, isOpen])

  return (
    <Modal key={modal} show={isOpen} onHide={toggleIsOpen} onExited={onClose} {...options}>
      <ModalContent {...params} toggleIsVisible={toggleIsOpen} />
    </Modal>
  )
}
