import * as React from 'react'
import { MobileAppBanner } from '@components/mobile-app-banner'
import { ContentLoader } from '@components/content-loader'

interface Props {
  title: string
  image: string
  children: React.ReactElement | React.ReactElement[]
}

export const OperationResult = ({ title, image, children }: Props): JSX.Element => (
  <div className="position-fixed top-0 left-0 bg-white w-100 h-100 overflow-auto">
    <ContentLoader>
      <div className="container-lg d-flex flex-column align-items-center justify-content-center py-4">
        <img
          src={require('@assets/images/logo_holiday_color.svg')}
          alt="holidaypark logo"
          height="48"
          className="mb-md-5 mb-3"
        />
        <img src={require(`@assets/images/${image}`)} alt="" width="220" height="250" />
        <h3 className="operation-result__title">{title}</h3>
        <div className="operation-result__content">{children}</div>
        <MobileAppBanner />
      </div>
    </ContentLoader>
  </div>
)
