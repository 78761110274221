import * as React from 'react'
import { OperationResult } from '@components/payment-operation-results/operation-result'
import { commonObjectGet } from '@requests/basic-requests'
import { useApiRequest } from '@hooks/use-form-request'
import { Button } from 'react-bootstrap'
import { useQueryParams } from '@hooks/use-query-params'
import { PaymentRequestPayload } from '@components/web-socket-handler/use-payment-redirect'

const CHECKED_MESSAGE = (
  <p className="text-center">
    Nadal trwa przetwarzanie płatności, proszę czekać <br />
    lub wywołać ponownie sprawdzenie statusu.
  </p>
)

export const PaymentProcessing = (): JSX.Element => {
  const query = useQueryParams()
  const token = query.get('token')
  const [checkedMessage, setCheckedMessage] = React.useState<React.ReactNode>(null)

  const { action: checkPaymentStatus, isLoading } = useApiRequest(async () => {
    const response = await commonObjectGet<PaymentRequestPayload>(`/api/payment/payment-requests/${token}/`)

    if (response && response.status !== 'initial') {
      window.location.href = response.status === 'success' ? response.success_url : response.error_url
    }
  })

  React.useEffect(() => {
    checkPaymentStatus()
  }, [])

  const handleCheckStatus = async () => {
    setCheckedMessage(null)
    await checkPaymentStatus()
    setCheckedMessage(CHECKED_MESSAGE)
  }

  return (
    <OperationResult title="Przetwarzanie płatności" image="processing.svg">
      <p className="text-center font-lg text-darker-gray">
        Twoja płatność nie została jeszcze przetworzona. <br /> Status płatności za chwilę powinien się zmienić.
      </p>
      <Button
        className="my-4 px-5 py-3 mx-auto d-block"
        variant="secondary"
        onClick={handleCheckStatus}
        disabled={isLoading}
      >
        <strong className="text-white">Sprawdź stan płatności</strong>
      </Button>
      <p className="font-sm text-darker-gray">{checkedMessage}</p>
    </OperationResult>
  )
}
