import * as React from 'react'
import * as clsx from 'clsx'
import { SubscriptionOption, SubscriptionStatus } from '@modules/subscription/models'
import { asDecimal } from '@helpers/utils'
import { ProductStatusLabel } from '@components/product-status-label'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  options: SubscriptionOption[]
  status: SubscriptionStatus
}

export const SubscriptionDetailsContentOptions = ({ options, status }: Props): JSX.Element => {
  const isOptionPaid = (option: SubscriptionOption) => asDecimal(option.rest_to_pay).lte(0)
  const isInitial = status === 'initial'

  return (
    <>
      <strong className="text-darker-gray d-block mt-3 mb-1">Dodatkowe opcje:</strong>
      <ul className="ps-0">
        {options.map(option => (
          <li key={option.id} className="d-flex align-items-center ps-0">
            <IconWithText
              text={option.kind_display}
              textClassName="text-muted font-md font-500"
              className="col-sm-3 col-8"
              iconClassName={clsx(
                'me-2',
                isOptionPaid(option) || isInitial ? 'uil-check text-success' : 'uil-times text-danger',
              )}
            />

            {!isInitial && !isOptionPaid(option) && (
              <div className="col-sm-2 col-4 me-3">
                <ProductStatusLabel isPaid={isOptionPaid(option)} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}
