import * as React from 'react'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleLeafletButton } from '@modules/package-wholesale/details/subscription/package-wholesale-leaflet-button'
import { GastroProduct } from '@modules/gastro-generic/models'
import { formatPriceShort } from '@helpers/price-helper'

interface Props {
  isCollapsable?: boolean
  packageWholesaleDetails: PackageWholesaleDetails
  gastroProduct: GastroProduct | null
}

export const PackageWholesaleGastroCardInformation = ({
  isCollapsable,
  packageWholesaleDetails,
  gastroProduct,
}: Props): JSX.Element => {
  const originalExampleAmount = 500
  const multipliedExampleAmount = originalExampleAmount * (gastroProduct?.multiplier || 1)

  return (
    <PackageWholesaleInformationWrapper
      isCollapsable={isCollapsable}
      bottomElement={
        <PackageWholesaleLeafletButton
          leafletUrl={packageWholesaleDetails[`leaflet_${gastroProduct?.kind}`]}
          wrapperClassName="ms-3"
        />
      }
    >
      {gastroProduct?.date_from && gastroProduct?.date_to && (
        <p>
          Środki z vouchera można wykorzystywać podczas dowolnej ilości rezerwacji podczas pobytów w terminie{' '}
          <strong className="text-nowrap text-primary">
            od {toDefaultDateFormat(gastroProduct?.date_from)} do {toDefaultDateFormat(gastroProduct?.date_to)}.
          </strong>
        </p>
      )}
      {['normal', 'gastro_card_boost'].includes(gastroProduct?.kind || '') ? (
        <>
          <p>
            W przypadku chęci wręczenia zakupionych voucherów osobom trzecim, zaleca się wpłacić kilka mniejszych kwot,
            a nie jedną sumaryczną kwotę. Wpłata kilku mniejszych kwot spowoduje wygenerowanie osobnych kodów na
            wpłacone kwoty, które będzie można wręczyć osobom trzecim. Wpłata jednej sumarycznej kwoty spowoduje
            wygenerowanie jednego kodu na całość.
          </p>
          <p>
            Dla przykładu, jeśli kupujący pragnie obdarować 5 osób voucherem na {multipliedExampleAmount} zł, musi
            zakupić 5 sztuk po {originalExampleAmount} zł, wpłaca wtedy {5 * originalExampleAmount} zł, a otrzymuje 5
            kodów na {multipliedExampleAmount} zł każdy.
          </p>
        </>
      ) : (
        <p>
          Minimalna kwota, za jaką możesz kupić voucher to {formatPriceShort(gastroProduct?.minimal_value || 500)}. Z
          vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego
          część aż do momentu całkowitego wykorzystania. Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku.
          Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji. Ilość voucherów jakie możesz kupić
          jest nieograniczona.
        </p>
      )}
      <p>
        <strong>Uwaga!</strong> Jeśli potrzebujesz fakturę, koniecznie podaj NIP poniżej.
      </p>
    </PackageWholesaleInformationWrapper>
  )
}
