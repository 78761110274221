import * as React from 'react'
import * as clsx from 'clsx'

type Variant = 'info'

interface Props {
  variant: Variant
  children: React.ReactNode
  icon?: string
  className?: string
}

export const Alert = ({ variant, icon, className, children }: Props): JSX.Element => (
  <div className={clsx('custom-alert rounded', `custom-alert--${variant}`, className)} role="alert">
    {icon && <i className={clsx(icon, 'me-2')} />}
    {children}
  </div>
)
