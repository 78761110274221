import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  forEmployee?: boolean
  color: string
  price: string
  wrapperClassName?: string
}

export const HolidayBenefitBenefitsPlansPrice = ({
  forEmployee,
  color,
  price,
  wrapperClassName = 'flex-column align-items-end',
}: Props): React.ReactNode => (
  <div className={clsx('d-flex', wrapperClassName)}>
    <div className="d-flex">
      <img
        src={require('@assets/images/holiday-benefit/landing-page/bar.svg')}
        alt="Holiday Benefit"
        height={45}
        className="me-2"
      />
      <div className="text-darker-gray">
        <strong
          className={clsx('holiday-benefit__section-title holiday-benefit__price', `text-holiday-benefit-${color}`, {
            'is-crossed': forEmployee,
          })}
        >
          {price}
        </strong>
        <span className={clsx('font-xl fw-500', `text-holiday-benefit-${color}`)}> zł</span>
        <div className="mt-n2">miesięcznie</div>
      </div>
    </div>
    {forEmployee && (
      <div className="font-xxs text-holiday-benefit-secondary text-end font-500">
        Zapytaj o cenę w dziale <br /> Kadr Twojego Pracodawcy
      </div>
    )}
  </div>
)
