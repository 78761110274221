import * as React from 'react'
import { OperationResult } from '@components/payment-operation-results/operation-result'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { ContactData } from '@components/contact-data'

export const PaymentFailure = (): JSX.Element => {
  const appData = useSelector(selectAppData)

  return (
    <OperationResult title="Płatność nieudana!" image="error.svg">
      <p className="text-center font-lg">
        Twoja płatność nie została potwierdzona przez{' '}
        {appData.payment_methods?.[0]?.provider_name ?? 'system płatności'}. Skontaktuj się z BOK w celu wyjaśnienia
        sytuacji.
      </p>

      <ContactData />

      <a href="https://holidaypark.pl/" className="text-decoration-none">
        <button className="btn btn-responsive bg-secondary px-5 py-3 text-white mt-5 d-block mx-auto">
          <strong>Przejdź do strony holidaypark.pl</strong>
        </button>
      </a>
    </OperationResult>
  )
}
