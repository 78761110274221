import * as React from 'react'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { HolidayBenefitInvitation } from '@modules/holiday-benefit/employee-invitation/models'
import { ButtonWithLoader } from '@components/button-with-loader'

interface Props {
  onSubmit: () => void
  benefitInvitation: HolidayBenefitInvitation
  isLoading: boolean
}

export const HolidayBenefitInvitationRulesSection = ({
  onSubmit,
  benefitInvitation,
  isLoading,
}: Props): React.ReactNode => (
  <>
    <div className="mt-5 holiday-benefit__invitation__rules">
      <DynamicAgreementsSection
        title={<span className="text-holiday-benefit-primary">Akceptacja regulaminów i zasad programu:</span>}
        productRules={[...benefitInvitation.required_rules, ...benefitInvitation.optional_rules]}
      />
    </div>

    <div className="mx-auto mt-5">
      <ButtonWithLoader
        style={{ height: 50 }}
        isLoading={isLoading}
        className="btn-holiday-benefit is-primary btn-responsive px-5 d-block py-2 mx-auto"
        onClick={onSubmit}
        text={
          <>
            <span className="fw-normal font-sm">Akceptuję regulaminy</span>
            <strong className="d-block">Przystępuję do Benefitu</strong>
          </>
        }
      />
    </div>
  </>
)
