import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'

interface Props {
  isCollapsable?: boolean
  bottomElement?: React.ReactNode
}

export const PackageWholesaleSubscriptionInformation = ({ isCollapsable, bottomElement }: Props): JSX.Element => (
  <PackageWholesaleInformationWrapper isCollapsable={isCollapsable} bottomElement={bottomElement}>
    <p>
      Vouchery można zrealizować w określonym terminie z wyłączeniem najwyższego sezonu oraz Terminów Specjalnych
      dostępnych w regulaminie. Istnieje możliwość odbycia pobytu w Terminach Specjalnych z dopłatą.
    </p>
    <div className="row">
      <div className="col-6">
        <strong>W ośrodkach nadmorskich:</strong>
        <p>Pobierowo, Rowy, Ustronie Morskie, Niechorze, Mielno, Kołobrzeg (Grzybowo)</p>
      </div>

      <div className="col-6">
        <strong>
          W ośrodku górskim <span className="d-block text-nowrap">Holiday Park & Resort Cieplice Zdrój</span>
        </strong>
      </div>
      <div className="col-6">
        <ul className="ps-0">
          <li>
            - <span className="text-gold">od 1 kwietnia do 14 czerwca</span>
          </li>
          <li>
            - <span className="text-gold">cały wrzesień i październik</span> we wszystkich naszych resortach
          </li>
          <li>
            - <span className="text-gold">od listopada do marca</span> w wybranych apartamentach w Rowach i Pobierowie
          </li>
        </ul>
      </div>
      <div className="col-6">
        <ul className="ps-0">
          <li>
            - <span className="text-gold">od 1 września do 14 czerwca</span>
          </li>
        </ul>
      </div>
    </div>
    <p className="mb-0">Przy zakładaniu rezerwacji jednorazowo należy uiścić opłatę za usługę sprzątania:</p>
    <ul className="ps-0">
      <li>- apartament {formatPrice(149.99)}</li>
      <li>- domek {formatPrice(169.99)}</li>
    </ul>
    <p>
      Wymagane jest wpłacenie kaucji w wysokości {formatPrice(750)} (zwrot po zrealizowanym pobycie).
      <br />
      Przy wyjeździe rozliczane są zużyte media.
    </p>
  </PackageWholesaleInformationWrapper>
)
