import { useFormContext, useWatch } from 'react-hook-form'
import { HolidayBenefitInvitationFormInputs } from '@modules/holiday-benefit/employee-invitation/index'
import { HolidayBenefitKind } from '@modules/holiday-benefit/employee-invitation/models'

interface Response {
  color: string
  selectedKind: HolidayBenefitKind
}

export const useHolidayBenefitInvitationSelectedPlan = (): Response => {
  const { control } = useFormContext<HolidayBenefitInvitationFormInputs>()
  const selectedKind = useWatch({ control, name: 'kind' })

  const color = selectedKind === 'house' ? 'text-holiday-benefit-tertiary' : 'text-holiday-benefit-primary'

  return {
    color,
    selectedKind,
  }
}
