import * as React from 'react'
import { SideCard } from '@components/side-card'
import { Carousel } from 'react-bootstrap'
import { ReservationDetails } from '@modules/reservation/models'
import { useSelector } from 'react-redux'
import { selectResorts } from '@store/selectors/app-data-selector'
import { Resort } from '@models/app-data'
import { ReservationDetailsExpirationTimeLabel } from '@modules/reservation/details/reservation-details-expiration-time-label'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationCardSideCard = ({ reservationDetails }: Props): JSX.Element => {
  const resorts = useSelector(selectResorts)

  const resortImages = React.useMemo(
    () =>
      resorts
        .find((resort: Resort) => resort.id === reservationDetails.resort_id)
        ?.reservation_images.map(item => item.image.xs) || [],
    [reservationDetails.resort_id, resorts],
  )

  return (
    <SideCard
      className="d-lg-block d-none"
      image={
        <Carousel indicators={false} interval={null}>
          {resortImages.map(image => (
            <Carousel.Item key={image}>
              <img src={image} alt="Zdjęcie resortu" className="reservation-details__side-card__image" />
            </Carousel.Item>
          ))}
        </Carousel>
      }
    >
      {reservationDetails.offer_expiration_date && (
        <ReservationDetailsExpirationTimeLabel
          expirationDate={reservationDetails.offer_expiration_date}
          reservationNumber={reservationDetails.reservation_number}
        />
      )}
    </SideCard>
  )
}
