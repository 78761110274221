import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: React.ReactNode
  className?: string
  iconClassName?: string
  textClassName?: string
  flexClassName?: string
}

export const IconWithText = ({
  text,
  textClassName,
  iconClassName,
  flexClassName = 'd-flex',
  className,
  ...props
}: Props): JSX.Element => (
  <div
    className={clsx(
      'icon-with-text align-items-center',
      flexClassName,
      { 'cursor-pointer': !!props.onClick },
      className,
    )}
    {...props}
  >
    <i className={clsx('icon-with-text__icon', iconClassName)} />
    <span className={clsx('icon-with-text__text', textClassName)}>{text}</span>
  </div>
)
