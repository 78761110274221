import * as React from 'react'
import { OperationResult } from '@components/payment-operation-results/operation-result'

interface Props {
  email?: string
}

export const LinkExpired = ({ email = 'kontakt@holidaypark.pl' }: Props): JSX.Element => (
  <OperationResult title="Niestety, link wygasł. Oferta była ograniczona czasowo." image="clock.svg">
    <p className="text-center font-lg">
      Jeśli jesteś zainteresowany ofertą specjalną to zapraszamy do kontaktu z opiekunem klienta biznesowego{' '}
      <span className="text-nowrap">Holiday Park & Resort</span>
    </p>
    <a href={`mailto:${email}`} className="text-decoration-none">
      <strong className="d-block text-dark mt-2 font-xl text-center">{email}</strong>
    </a>
    <a href="https://holidaypark.pl/" className="text-decoration-none">
      <button className="btn btn-responsive bg-secondary px-5 py-3 text-white mt-5 d-block mx-auto">
        <strong>Przejdź do strony holidaypark.pl</strong>
      </button>
    </a>
  </OperationResult>
)
