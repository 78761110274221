import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { SubscriptionDetails } from '@modules/subscription/models'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { ResortImages } from '@modules/package-wholesale/details'
import { SubscriptionWithBenefitsDetailsHeaderSectionContent } from '@modules/subscription-with-benefit/details/subscription-with-benefits-details-header-section-content'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { SubscriptionWithBenefitsDetailsContent } from '@modules/subscription-with-benefit/details/subscription-with-benefits-details-content'
import { ResortsGallery } from '@components/resorts-gallery'
import { SubscriptionWithBenefitsCardSideCard } from '@modules/subscription-with-benefit/details/subscription-with-benefits-card-side-card'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { useProductDetails } from '@hooks/use-product-details'
import { shouldCallRulesCheck } from '@helpers/rules'
import { useGtmEcommerceBeginCheckoutGtmEvent } from '@hooks/gtm/ecommerce/use-begin-checkout-ecommerce-gtm-event'

interface FormInputs extends Agreements {
  subscription_rules: boolean
}
interface Props {
  type: 'WITH_BENEFIT' | 'WITH_BENEFIT_20'
}
export const SubscriptionWithBenefitsDetailsView: React.FC<Props> = ({ type }) => {
  const { subscription } = useSelector(selectAppDataUrls)

  const subscriptionType =
    type === 'WITH_BENEFIT' ? ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS : ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS_20

  const { PageWrapper, productDetails: subscriptionDetails } = useProductDetails<SubscriptionDetails>(
    subscription.details,
  )

  useGtmEcommerceBeginCheckoutGtmEvent(2, subscriptionType, subscriptionDetails)

  const { token } = useParams<'token'>()

  const paymentSectionRef = React.useRef<HTMLDivElement>(null)
  const detailsSectionRef = React.useRef<HTMLDivElement>(null)

  const { scrollTo } = useScrollIntoView()

  const methods = useForm<FormInputs>({ reValidateMode: 'onChange' })

  const { showPaymentSelectionView } = usePaymentSelection()

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!subscriptionDetails || !token) return

    if (shouldCallRulesCheck(subscriptionDetails.rules)) {
      await commonObjectPost(subscriptionDetails?.urls.rules, payload)
    }

    showPaymentSelectionView(token, subscriptionType)
  }, methods.setError)

  const handleScrollToPayment = () => {
    paymentSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="pakiet korzysci">
        <SubscriptionWithBenefitsDetailsHeaderSectionContent onScrollToDetails={scrollTo(detailsSectionRef)} />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {subscriptionDetails && (
                  <SubscriptionWithBenefitsDetailsContent
                    onScrollToPayment={handleScrollToPayment}
                    subscriptionDetails={subscriptionDetails}
                    ref={detailsSectionRef}
                  />
                )}
                <hr className="mx-n5" />
                {subscriptionDetails && !subscriptionDetails?.is_paid && (
                  <DynamicAgreementsSection rules={subscriptionDetails.rules} />
                )}
                <PaymentActionRow
                  isPaymentDisabled={subscriptionDetails?.is_paid}
                  ref={paymentSectionRef}
                  isLoading={isSaving}
                  className="mt-4"
                  amount={subscriptionDetails?.final_brutto || '0'}
                />
              </form>
            </FormProvider>
          </div>
          <SubscriptionWithBenefitsCardSideCard />
        </div>
      </div>
      <ResortsGallery title="Gdzie wykorzystać voucher?" />
      <AppFooter />
    </PageWrapper>
  )
}
