import * as React from 'react'
import * as clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { PackageWholesaleGastroCardVariant } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-variant'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { asDecimal } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { CustomAmount } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-cards-section'

const MAXIMUM_PRICE = 10000
const MINIMUM_PRICE = 500

interface Props {
  isActive: boolean
  onCardClick: () => void
  inputName: CustomAmount
}

export const PackageWholesaleGastroCardBoostVariantCustom = ({
  isActive,
  onCardClick,
  inputName,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext<PackageWholesaleFormInput>()

  const customPrice = useWatch({ control, name: inputName })
  const multiplied = asDecimal(customPrice).mul(1.4).toString()

  const handleInputClick = e => {
    e.stopPropagation()
  }

  const handleBlur = () => {
    setValue(inputName, Math.max(Math.min(parseInt(customPrice.toString()), MAXIMUM_PRICE), MINIMUM_PRICE))
  }

  return (
    <PackageWholesaleGastroCardVariant
      isActive={isActive}
      onCardClick={onCardClick}
      title={
        <span>
          Voucher Doładowanie Smaku - wpisz własną kwotę
          <span className="text-white font-sm d-block fw-normal">Minimalna kwota zakupu to 500 zł.</span>
        </span>
      }
    >
      <div className="package-wholesale__gastro-card__values">
        <div className="text-white package-wholesale__gastro-card__amount-paid">
          <p className="mb-1 font-sm">Wpłacana kwota:</p>
          <CustomFormGroup
            inputName={inputName}
            formControlProps={{
              defaultValue: MINIMUM_PRICE,
              className: 'package-wholesale__gastro-card__price-custom',
              type: 'number',
              onClick: handleInputClick,
              onBlur: handleBlur,
            }}
          />
        </div>
        <div className={clsx('package-wholesale__gastro-card__bonus', { 'is-active': isActive })}>
          <img
            src={require('@assets/images/gastr-coupon-bg.svg')}
            alt="Kupon gastronomiczny"
            className="package-wholesale__gastro-card__bonus__bg"
          />
          <div>
            <strong className="font-xxxl">=</strong>
          </div>
        </div>
        <div className="text-darker-gray package-wholesale__gastro-card__amount-final">
          <p className="mb-1 font-sm">Wartość kuponu:</p>
          <strong className="font-xxl text-end d-block">{formatPriceShort(multiplied)}</strong>
        </div>
      </div>
    </PackageWholesaleGastroCardVariant>
  )
}
