import * as React from 'react'
import * as clsx from 'clsx'
import { SpinInput } from '@components/spin-input'

interface Props {
  children: React.ReactNode
  isActive: boolean
  onCardClick: () => void
  title: React.ReactNode
}

export const PackageWholesaleGastroCardVariant = ({ children, isActive, onCardClick, title }: Props): JSX.Element => {
  const [amount, setAmount] = React.useState(0)

  const handleCardClick = () => {
    if (isActive) return

    setAmount(1)
    onCardClick()
  }

  const handleDecrement = value => {
    onCardClick()
    setAmount(value)
  }

  return (
    <div
      className={clsx('mb-2', { 'cursor-pointer': !isActive })}
      onClick={handleCardClick}
      data-testid="gastro-card-card"
      role="presentation"
    >
      <div className={clsx('package-wholesale__gastro-card cursor-pointer', { 'is-active': isActive })}>
        <img
          className="package-wholesale__gastro-card__image"
          src={require('@assets/images/gastro-card-slides/gastro-card-slide-5.jpg')}
          alt="Gastro kupon"
        />
        <div className="p-3 d-flex justify-content-between flex-column w-100">
          <strong className="package-wholesale__gastro-card__text font-xl">{title}</strong>
          <div className="my-2 d-flex justify-content-between flex-wrap">
            {children}
            <div className="mt-sm-auto mt-2 col-12 col-sm-auto">
              <span className="package-wholesale__gastro-card__text d-block font-sm fw-normal text-start text-sm-end">
                Ilość:
              </span>
              <SpinInput
                max={1}
                min={0}
                value={amount}
                readOnly
                onIncrement={setAmount}
                onDecrement={handleDecrement}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
