import React from 'react'

interface Response {
  elapsedTime: number
  refresh: () => void
  clear: () => void
}

export const useTimeCount = (countWhen = true): Response => {
  const [elapsedTime, setElapsedTime] = React.useState(0)
  let timeout: NodeJS.Timeout

  const clear = () => {
    clearTimeout(timeout)
    setElapsedTime(0)
  }

  React.useEffect(() => {
    if (!countWhen) {
      clear()
      return
    }

    timeout = setInterval(() => {
      setElapsedTime(time => time + 1)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [countWhen])

  const refresh = () => {
    setElapsedTime(0)
  }

  return {
    elapsedTime,
    refresh,
    clear,
  }
}
