import * as React from 'react'
import { SideCard } from '@components/side-card'
import { PackageWholesaleSideCardExpirationBanner } from '@modules/package-wholesale/details/package-wholesale-side-card-expiration-banner'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/product-package/details'
import { EndlessHolidaySideCardSubscriptionSummaryRow } from '@modules/product-package/details/side-card/subscription-summary-row'
import { EndlessHolidaySideCardSummaryPrice } from '@modules/product-package/details/side-card/side-card-summary-price'
import { EndlessHolidayDetails } from '@modules/product-package/models'
import { ProductExpirationCounter } from '@components/product-expiration-counter'
import { isAfter } from 'date-fns'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'

interface Props {
  productDetails: EndlessHolidayDetails
}

export const EndlessHolidaySideCardSummary = ({ productDetails }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()

  const products = useWatch({ control, name: 'products' })

  const gastroCardProducts = products.filter(
    product => product.product.kind === 'gastro_card_product_package_endless_holiday',
  )

  const productGroupedByYear = products.reduce((acc, product) => {
    const year = product.product.stay_in_years?.[0]
    if (!year) return acc
    if (!acc[year]) return { ...acc, [year]: [product] }

    return { ...acc, [year]: [...acc[year], product] }
  }, {} as Record<string, EndlessHolidayFormInputProduct[]>)

  const lowestOfferTillDate = parseISODate(productDetails.first_price_brutto_date)
  const offerChangeDate = formatDate(lowestOfferTillDate, DateFormats.DefaultWithTime)
  const lowestOfferPassed = isAfter(new Date(), lowestOfferTillDate)

  return (
    <SideCard className="d-lg-block d-none col-12 subscription-variant__price-side-card">
      <img
        src={require('@assets/images/sidebar.jpg')}
        alt="Holiday Park & Resort"
        width="100%"
        height={180}
        className="subscription-variant__price-side-card__image"
      />
      <div className="card subscription-variant__price-side-card__content">
        <h5 className="px-3 mt-3 font-lg">Twoje produkty:</h5>
        <ul className="ps-0 mb-0">
          {Object.entries(productGroupedByYear).map(([year, products]) => (
            <EndlessHolidaySideCardSubscriptionSummaryRow
              key={year}
              year={year}
              products={products}
              gastroCardProducts={gastroCardProducts}
              showDiscountedPrice={productDetails.can_use_first_price_brutto}
            />
          ))}

          {!products.length && <li className="font-sm px-3">Brak dodanych produktów</li>}

          <EndlessHolidaySideCardSummaryPrice showDiscountedPrice={productDetails.can_use_first_price_brutto} />
        </ul>
      </div>

      {!lowestOfferPassed && (
        <div className="endless-holiday__offer-counter">
          <ProductExpirationCounter
            expireAfter={offerChangeDate}
            text={<div className="text-darker-gray font-sm font-500 text-center">Rabat aktywny jeszcze przez:</div>}
          />
        </div>
      )}

      {productDetails.auto_cancel_after_date && (
        <PackageWholesaleSideCardExpirationBanner expireAfter={productDetails.auto_cancel_after_date} />
      )}
    </SideCard>
  )
}
