import * as React from 'react'
import { AppHeaderSection } from '@components/app-header-section'
import { AppHeaderContent } from '@components/app-header-content'
import { ResortImages } from '@modules/package-wholesale/details'
import { CodesDeactivation } from '@modules/codes-deactivation/common'

export const ZooBorysewCodesDeactivationView = (): JSX.Element => (
  <CodesDeactivation title="Dezaktywacja kodów wstępu do ZOO w Borysewie" codeMask="Z-******">
    <AppHeaderSection images={ResortImages} imageDescription="vip">
      <AppHeaderContent
        title="Zarządzanie kodami wstępu"
        subtitle={
          <span>
            Niniejsza strona pozwala na zarządzanie kodami wstępu do obiektu ZOO w Borysewie.
            <br />
            Wprowadzenie kodu w sekcji poniżej powoduję jego dezaktywację. <br />
            <strong>Uwaga!</strong> Akcja jest nieodwracalna.
          </span>
        }
      />
    </AppHeaderSection>
  </CodesDeactivation>
)
