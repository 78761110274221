import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/client-slice'
import { PersonalDataExistingUser } from '@components/personal-data/personal-data-existing-user'
import { Invoice } from '@components/invoice-form/invoice'
import { PersonalDataNewClient } from '@components/personal-data/personal-data-new-client'
import { PersonalDataLogin } from '@components/personal-data/personal-data-login'

interface Props {
  title: React.ReactNode
}

export const PersonalData = ({ title }: Props): JSX.Element => {
  const client = useSelector(selectClientDetails)
  const { setValue } = useFormContext()

  React.useEffect(() => {
    if (client) {
      setValue('email', client.email)
      setValue('first_name', client.first_name)
      setValue('last_name', client.last_name)
    }
  }, [client])

  return (
    <div className="my-5">
      {title}
      <div className="row">
        {client ? (
          <PersonalDataExistingUser />
        ) : (
          <>
            <PersonalDataNewClient />
            <PersonalDataLogin />
          </>
        )}
        <Invoice />
      </div>
    </div>
  )
}
