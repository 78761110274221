import * as React from 'react'
import { ModalBody, ModalHeader } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'

export const EndlessHolidayDescriptionModal = ({ toggleIsVisible }: BaseModalProps): React.ReactNode => (
  <>
    <ModalHeader className="font-xxl text-primary font-500 p-0 align-items-lg-start">
      <span className="py-3 px-5">Czym są Wakacje w nieskończoność?</span>
      <button
        className="bg-darker-gray btn-unstyled py-3 px-4 endless-holiday__description-modal__close-button"
        onClick={toggleIsVisible}
      >
        <i className="uil-times text-white font-xxl lh-1" />
      </button>
    </ModalHeader>
    <ModalBody className="p-0 d-flex align-items-center flex-wrap flex-column-reverse flex-lg-row flex-lg-nowrap">
      <section className="p-5 col-lg-6 font-md text-darker-gray">
        <p>
          Oferujemy możliwość zakupu dowolnej ilości voucherów pobytowych do wykorzystania w wybranych latach:{' '}
          <strong>2025, 2026, 2027, 2028 lub 2029</strong> w wyjątkowych cenach. <br />
          <span className="text-primary">Każdy pobyt to aż 6 dni wypoczynku!</span>
        </p>
        <p>
          Dodatkowo, w ramach oferty nabywasz kod pobytowy w pakiecie z voucherem gastronomicznym do wykorzystania we
          wszystkich dostępnych punktach, włącznie z Restauracją! Decydując się na zwiększenie wartości vouchera
          gastronomicznego do 2 000 zł, otrzymasz <strong>dostęp do lokali specjalnych!</strong>
        </p>
        <p>
          Kup voucher pobytowy i zrealizuj go w ciągu 5 lat od wskazanego roku, we wszystkich dostępnych resortach
          Holiday Park & Resort, spośród 7 do wyboru. Nie musisz deklarować terminu rezerwacji od razu przy zakupie
          pakietu. Otrzymasz zakupione vouchery na swój adres e-mail, które następnie użyjesz przy zakładaniu
          rezerwacji.
        </p>
        <strong>6-dniowy pobyt może kosztować mniej niż 1 doba w najwyższym sezonie!</strong>
      </section>
      <div className="col-lg-6 col-12">
        <img
          src={require('@assets/images/product-packages/description.webp')}
          alt="Wakacje w nieskończoność"
          className="endless-holiday__description-modal__image"
        />
      </div>
    </ModalBody>
  </>
)
