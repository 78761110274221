import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { asDecimal } from '@helpers/utils'
import Decimal from 'decimal.js'

export const getMaximumProductDiscount = (products: PackageWholesaleProduct[]): string => {
  const discount = products.reduce((maxDiscount, currentProduct) => {
    if (currentProduct.discount) return Decimal.max(maxDiscount, currentProduct.discount)
    return maxDiscount
  }, asDecimal(0))

  return discount.gt(0) ? discount.toFixed(0) : ''
}
