import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { CardContentProductInfo } from '@components/card-content-product-info'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const GoHolidayDetailsDetailsContentProductInfo: React.FC<Props> = ({ subscriptionDetails }) => (
  <CardContentProductInfo number={subscriptionDetails.number} price={subscriptionDetails.product.price_brutto}>
    <CardContentBaseDataRow name="Nazwa pakietu:" value={subscriptionDetails.product.name} />
  </CardContentProductInfo>
)
