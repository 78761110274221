import * as React from 'react'
import { useFormRequest } from '@hooks/use-form-request'
import { AppHeaderSection } from '@components/app-header-section'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useProductDetails } from '@hooks/use-product-details'
import { EndlessHolidayConfirmationHeaderSectionContent } from '@modules/product-package/confirmation/confirmation-header-section-content'
import { EndlessHolidayConfirmationDetails } from '@modules/product-package/models'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidayConfirmationDetailsContent } from '@modules/product-package/confirmation/confirmation-details-content'
import { EndlessHolidayConfirmationSideCard } from '@modules/product-package/confirmation/side-card'
import { ResortImages } from '@modules/package-wholesale/details'
import { commonObjectPost } from '@requests/basic-requests'
import { FormProvider, useForm } from 'react-hook-form'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { WinterHeaderSnowfallContainer } from '@components/winter-header-snowfall-container'

export const EndlessHolidayConfirmationView = (): React.ReactNode => {
  const { product_packages } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails } = useProductDetails<EndlessHolidayConfirmationDetails>(product_packages.details)

  const methods = useForm()
  const billSectionRef = React.useRef<HTMLDivElement>(null)

  const { showPaymentSelectionView } = usePaymentSelection()
  const { scrollTo } = useScrollIntoView()

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!productDetails) return

    if (productDetails.has_rules_to_accept) {
      await commonObjectPost(productDetails.urls.rules, payload)
    }

    showPaymentSelectionView(productDetails.token, ProductKinds.ENDLESS_HOLIDAY)
  }, methods.setError)

  const isPaid = asDecimal(productDetails?.required_payment).lte(0)
  const submitDisabled = !productDetails || isPaid

  const images =
    {
      endless_holiday: ResortImages,
      voucher: [require('@assets/images/christmas-voucher.webp')],
    }[productDetails?.kind ?? ''] ?? []

  const isVoucher = productDetails?.kind === 'voucher'

  const HeaderWrapper = isVoucher ? WinterHeaderSnowfallContainer : React.Fragment

  return (
    <PageWrapper>
      <HeaderWrapper>
        <AppHeaderSection
          images={images}
          imageDescription="Vouchery Holiday Park & Resort"
          imageClassName="endless-holiday__hero-image"
        >
          {productDetails && (
            <EndlessHolidayConfirmationHeaderSectionContent
              onScrollToBill={scrollTo(billSectionRef)}
              productDetails={productDetails}
            />
          )}
        </AppHeaderSection>
      </HeaderWrapper>
      <FormProvider {...methods}>
        <div className="container app-header-section__after-content">
          <div className="row gx-xl-5">
            <div className="card col-lg-9 col-12 py-4 card-shadow border-0">
              {productDetails?.kind === 'voucher' && (
                <img src={require('@assets/images/snow_layer.svg')} className="snow-layer" alt="Pakiet zimowy" />
              )}
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {productDetails && (
                  <EndlessHolidayConfirmationDetailsContent
                    productDetails={productDetails}
                    ref={billSectionRef}
                    onScrollToBill={scrollTo(billSectionRef)}
                  />
                )}
                <hr className="mx-n4" />
                {productDetails && productDetails.has_rules_to_accept && (
                  <DynamicAgreementsSection
                    productRules={[...productDetails.required_rules, ...productDetails.optional_rules]}
                  />
                )}

                <PaymentActionRow
                  isPaymentDisabled={submitDisabled}
                  isLoading={isSaving}
                  className="mt-4"
                  paymentDate={isPaid ? undefined : productDetails?.required_payment_date}
                  amount={productDetails?.required_payment ?? 0}
                />
              </form>
            </div>
            {productDetails && <EndlessHolidayConfirmationSideCard productDetails={productDetails} isPaid={isPaid} />}
          </div>
        </div>
      </FormProvider>

      <AppFooter />
    </PageWrapper>
  )
}
