import * as React from 'react'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { IconWithText } from '@components/icon-with-text'
import * as clsx from 'clsx'

interface Props {
  wrapperClassName?: string
}

export const ClientAppInformation = ({ wrapperClassName }: Props): JSX.Element => (
  <div className={wrapperClassName}>
    <strong className="text-muted">Pobierz naszą aplikację Konta Gościa</strong>
    <div className="row mt-2 opacity-75 gap-3 gap-md-0">
      <AppLink icon="uil-apple" text="App Store" link={CLIENT_APP_URLS.APP_STORE_LINK} />
      <AppLink icon="uil-google-play" text="Google Play" link={CLIENT_APP_URLS.GOOGLE_PLAY_LINK} />
    </div>
  </div>
)

const AppLink = ({ icon, text, link }) => (
  <div className="col-md-6 col-12">
    <a href={link} target="_blank" className="d-block bg-darker-gray py-2 px-3 rounded text-decoration-none">
      <IconWithText
        iconClassName={clsx('font-xxl me-2', icon)}
        text={text}
        className="text-white fw-semi-bold w-100 justify-content-center"
      />
    </a>
  </div>
)
