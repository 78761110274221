import * as React from 'react'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  number: string
  price: string
  children: React.ReactNode
  footer?: React.ReactNode
}

export const CardContentProductInfo: React.FC<Props> = ({ number, children, footer, price }) => (
  <div>
    <strong className="text-gold text-center text-sm-start d-block">Informacje o pakiecie:</strong>
    <div className="text-darker-gray mt-3 d-flex flex-column gap-2 font-md">
      <CardContentBaseDataRow name="Numer pakietu:" value={number} />
      {children}
      <CardContentBaseDataRow name="Cena pakietu:" value={formatPrice(price)} />
      {footer}
    </div>
    <hr className="mx-n5" />
  </div>
)
