import { SideCard } from '@components/side-card'
import * as React from 'react'

export const SubscriptionWithBenefitsCardSideCard = (): JSX.Element => (
  <SideCard
    className="d-lg-block d-none"
    image={
      <img src={require('@assets/images/subscription-with-benefits-label.webp')} alt="Wakacyjny Pakiet korzyści" />
    }
  />
)
