import * as React from 'react'
import { BocianVoucherDescription } from '@modules/bocian-vouchers/bocian-voucher-description'
import { BocianVoucherForm } from '@modules/bocian-vouchers/bocian-voucher-form'
import { ResortsGallery } from '@components/resorts-gallery'
import { BookingOffersSection } from '@components/booking-offers/booking-offers-section'
import { ContentLoader } from '@components/content-loader'

export const BocianVoucherView = (): JSX.Element => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const onScrollToResortSection = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <ContentLoader>
      <div className="container-lg py-4 bocian-voucher__wrapper">
        <img
          src={require('@assets/images/logo_bocian.svg')}
          alt="Vouchery Bocian"
          className="mx-auto d-block"
          height={60}
        />
        <div className="row mt-5 justify-content-between py-3 flex-column-reverse flex-lg-row">
          <BocianVoucherForm />
          <BocianVoucherDescription onScrollToResortSection={onScrollToResortSection} />
        </div>
        <ResortsGallery
          ref={resortGalleryRef}
          title="Gdzie wykorzystać Voucher?"
          wrapperClassName="bocian-voucher__gallery"
          customBreakpoints={breakpoints}
          withUpcomingInvestments={false}
          resortsIdToShow={[2, 1, 5, 8, 6, 7]}
          subtitle={
            <p className="text-darker-gray">
              Mamy aż 6 lokalizacji do wyboru <strong>nad polskim morzem</strong>
            </p>
          }
        />
        <BookingOffersSection />
      </div>
    </ContentLoader>
  )
}

const breakpoints = {
  extraLarge: { breakpoint: { max: 3000, min: 1770 }, items: 5 },
  large: { breakpoint: { max: 1770, min: 1200 }, items: 5 },
  semiLarge: { breakpoint: { max: 1200, min: 768 }, items: 3 },
  medium: { breakpoint: { max: 768, min: 468 }, items: 2 },
  small: { breakpoint: { max: 468, min: 0 }, items: 1 },
}
