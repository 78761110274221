import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleGastroCardCardsSection } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-cards-section'
import { PackageWholesaleGastroCardInformation } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-information'
import { PackageWholesaleSubscriptionContentHeader } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content-header'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { PackageWholesaleProductKind } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  kind: PackageWholesaleProductKind
  basicInformationClassName?: string
  isCollapsable?: boolean
}
export const PackageWholesaleGastroCardContent = ({
  packageWholesaleDetails,
  kind,
  basicInformationClassName,
  isCollapsable,
}: Props): JSX.Element | null => {
  const gastroProduct = useGastroProductProvider(kind)
  if (!gastroProduct) return null

  const promotionMultiplierText =
    kind === 'gastro_card'
      ? 'my podwoimy za Ciebie!'
      : `my powiększymy o ${(gastroProduct.multiplier * 100 - 100).toString()}%.`

  return (
    <>
      <hr className="mx-n5" />
      <div className="d-flex flex-column">
        <PackageWholesaleSubscriptionContentHeader
          created={packageWholesaleDetails.created}
          {...(!isCollapsable && { seller: packageWholesaleDetails.seller })}
        >
          <div className={basicInformationClassName}>
            <strong className="text-secondary font-xxl text-center text-sm-start d-block">Jak to działa?</strong>
            <p className="font-md mt-2 text-darker-gray col-12 col-sm-10 text-center text-sm-start">
              Wybierz jedną z proponowanych do wpłaty kwot lub spersonalizuj inną kwotę w opcji poniżej. Opłać
              zadeklarowaną kwotę, którą{' '}
              <strong className="text-primary text-nowrap">{promotionMultiplierText} </strong>
              Numer zakupionego vouchera każdorazowo przypnij do rezerwacji i skorzystaj w terminie{' '}
              {toDefaultDateFormat(gastroProduct.date_from || '')} - {toDefaultDateFormat(gastroProduct.date_to || '')}!
            </p>
          </div>
        </PackageWholesaleSubscriptionContentHeader>

        <div className="order-1">
          <PackageWholesaleGastroCardCardsSection
            packageWholesaleDetails={packageWholesaleDetails}
            gastroProduct={gastroProduct}
          />
        </div>

        <div className="order-3">
          <PackageWholesaleGastroCardInformation
            isCollapsable={isCollapsable}
            packageWholesaleDetails={packageWholesaleDetails}
            gastroProduct={gastroProduct}
          />
        </div>
      </div>
    </>
  )
}
