import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import * as React from 'react'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'

export const usePackageWholesaleAnalysis = (packageWholesaleDetails: PackageWholesaleDetails | undefined): void => {
  const { action: increaseViewsCounter } = useApiRequest(async () => {
    if (packageWholesaleDetails) {
      await commonObjectGet(packageWholesaleDetails?.urls.counter)
    }
  })

  React.useEffect(() => {
    increaseViewsCounter()
  }, [packageWholesaleDetails?.id])
}
