import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { useFormRequest } from '@hooks/use-form-request'
import { getCompanyData } from '@requests/invoice'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'

export const ClientInvoiceTaxNumber = (): JSX.Element => {
  const { getValues, setError, setValue, watch } = useFormContext<PackageWholesaleFormInput>()

  const invoiceType = watch('invoice_type')

  const { isLoading, action: fetchData } = useFormRequest(async () => {
    const companyData = await getCompanyData(getValues('invoice_nip'))
    setValue('invoice_city', companyData.city)
    setValue('invoice_company', companyData.name)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_street', companyData.street_address)
  }, setError)

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      fetchData()
      event.preventDefault()
    }
  }

  return (
    <div className="row align-items-start gx-2 text-darker-gray mt-3 mt-sm-0">
      <div className="col-6 ms-sm-auto font-sm">Wprowadź prawidłowe dane {invoiceType === 'company' && 'firmy'}</div>
      {invoiceType === 'company' ? (
        <div className="d-sm-flex align-items-center">
          <strong className="d-block col-12 col-sm-6 text-muted pe-0">Numer NIP:</strong>
          <div className="d-flex col-12 col-sm-6">
            <CustomFormGroup
              inputName="invoice_nip"
              formControlProps={{ type: 'text' }}
              formGroupProps={{ className: 'col-6 pe-3' }}
              maskedFormControlProps={{
                mask: '***-***-**-**',
                alwaysShowMask: true,
                className: 'letter-spacing-2',
                onKeyDown: handleEnterPress,
              }}
            />
            <button
              onClick={fetchData}
              type="button"
              disabled={isLoading}
              className="package-wholesale__invoice__gus-button col-6 mt-2"
            >
              <strong>{isLoading ? 'Pobieranie..' : 'Pobierz dane z GUS'}</strong>
            </button>
          </div>
        </div>
      ) : (
        <CustomFormGroup
          inputName="invoice_nip"
          formLabelProps={{ className: 'fw-bold col-12 col-sm-6' }}
          formControlProps={{ type: 'text', className: 'col-12 col-sm-6' }}
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          maskedFormControlProps={{
            mask: '***********',
            alwaysShowMask: true,
            className: 'letter-spacing-2',
          }}
          label="PESEL:"
        />
      )}
    </div>
  )
}
