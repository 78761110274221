import * as React from 'react'
import { PackageWholesaleSingleKindSideCard } from '@modules/package-wholesale/details/package-wholesale-single-kind-side-card'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleSideCardPrices } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleSubscriptionSideCard = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const isEveryProductGray = React.useMemo(
    () => packageWholesaleDetails.products.every(product => product.subscription_kind === 'gray'),
    [packageWholesaleDetails.products],
  )

  if (isEveryProductGray) {
    return <PackageWholesaleSingleKindSideCard packageWholesaleDetails={packageWholesaleDetails} kind="subscription" />
  }

  return <PackageWholesaleSideCardPrices packageWholesaleDetails={packageWholesaleDetails} />
}
