import { getYear } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidayConfirmationGastroCard } from '@modules/product-package/models'

interface Response {
  groupedList: [string, EndlessHolidayConfirmationGastroCard[]][]
  subscriptionGastroCards: EndlessHolidayConfirmationGastroCard[]
}

export const useGroupedEndlessGastroProducts = (
  gastroCards: EndlessHolidayConfirmationGastroCard[],
  year: string,
): Response => {
  const subscriptionGastroCards = gastroCards.filter(
    gastroCard => getYear(parseISODate(gastroCard.active_after)) === parseInt(year, 10),
  )

  const groupedByAmount = subscriptionGastroCards.reduce((acc, gastroCard) => {
    const amount = asDecimal(gastroCard.declared_sell_price).toNumber()
    if (acc[amount]) {
      return { ...acc, [amount]: [...acc[amount], gastroCard] }
    }
    return { ...acc, [amount]: [gastroCard] }
  }, {}) as { [key: string]: EndlessHolidayConfirmationGastroCard[] }

  return {
    groupedList: Object.entries(groupedByAmount),
    subscriptionGastroCards,
  }
}
