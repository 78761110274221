import * as React from 'react'
import { HolidayBenefitBenefitsPlansCostSimulation } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-benefits-plans-cost-simulation'
import { HolidayBenefitBenefitsPlans } from '@modules/holiday-benefit/common/holiday-benefit-benefits-plans'

interface Props {
  onScrollToContactSection: () => void
}

export const HolidayBenefitEmployerBenefitsPlans = React.forwardRef(
  ({ onScrollToContactSection }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => (
    <HolidayBenefitBenefitsPlans
      ref={ref}
      title="Wybierz"
      subtitle="plan"
      description="Oferujemy możliwość wyboru jednego z dwóch planów subskrypcyjnych:"
      apartmentContent={<ApartmentCardContent />}
      houseContent={<HouseCardContent />}
    >
      <HolidayBenefitBenefitsPlansCostSimulation />
      <p className="mt-5 mb-3 font-xl text-center">
        Już od pierwszego dnia uczestnictwa w programie Ty i Twój pracownik otrzymujecie wyjątkowe prezenty!
      </p>
      <button className="btn-holiday-benefit is-primary d-block mx-auto" onClick={onScrollToContactSection}>
        Chcesz poznać szczegóły?
      </button>
      <p className="font-lg text-darker-gray text-center mt-3">
        Kwota subskrypcji do elastycznego podziału między pracodawcę a pracownika
      </p>
    </HolidayBenefitBenefitsPlans>
  ),
)

const ApartmentCardContent = () => (
  <p className="text-darker-gray font-xl">
    Do wykorzystania <strong> 1x 6-dniowy</strong> oraz <strong>2x 3-dniowe pobyty w 4-osobowych apartamentach</strong>{' '}
    w wybranym Resorcie Holiday Park & Resort <strong>o łącznej wartości 2691 zł.</strong>
  </p>
)

const HouseCardContent = () => (
  <p className="text-darker-gray font-xl">
    Do wykorzystania <strong> 1x 6-dniowy</strong> oraz <strong>2x 3-dniowe pobyty w 9-osobowych apartamentach</strong>{' '}
    w wybranym Resorcie Holiday Park & Resort <strong>o łącznej wartości 4491 zł.</strong>
  </p>
)
