import { CardContentBase } from '@components/card-content-base'
import * as React from 'react'
import { CircleCheck } from '@components/circle-check'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { useSubscriptionContractPlanData } from '@modules/subscription-contract/plans/use-subscription-contract-plan-data'

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsContent = ({ subscriptionDetails }: Props): JSX.Element => {
  const { contentBenefits, contentTitle } = useSubscriptionContractPlanData(subscriptionDetails)

  return (
    <div>
      <CardContentBase
        title={contentTitle}
        client={subscriptionDetails.client}
        seller={subscriptionDetails.seller}
        created={subscriptionDetails.created || ''}
        isProductPaid={subscriptionDetails.status == 'confirmed'}
        productInvoice={subscriptionDetails}
      />
      {subscriptionDetails.repayment_date && (
        <CardContentBaseDataRow
          className="text-darker-gray font-md mt-1"
          name="Data kolejnego pobrania środków:"
          value={toDefaultDateFormat(subscriptionDetails.repayment_date)}
        />
      )}
      <hr className="mx-n5" />
      <div>
        <strong className="text-secondary font-xxl">Co otrzymujesz kupując Subskrypcję? </strong>
        <div className="mt-3 row">
          {contentBenefits.map((profit, index) => (
            <CircleCheck key={index} className="col-12 col-md-4 mb-2">
              <span className="font-md lh-initial col-12 col-md-8">
                <span className="text-muted">{profit}</span>
              </span>
            </CircleCheck>
          ))}
        </div>
      </div>
    </div>
  )
}
