import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'

export const usePaymentSuccessProductDetails = <T = unknown>(
  kind: string | undefined,
  token: string | undefined,
  action: (productDetails: T) => void,
): void => {
  const urls = useSelector(selectAppDataUrls)

  const productsDetailsUrls = {
    [ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS]: urls.subscription.details,
    [ProductKinds.GO_HOLIDAY]: urls.subscription.details,
    [ProductKinds.CODES_CONVERSION]: urls.codes.details,
    [ProductKinds.RESERVATION_DETAILS]: urls.reservation.details,
    [ProductKinds.SUBSCRIPTION_CONTRACT]: urls.subscription_contract.details,
  }

  const { action: sendPaymentSuccessOrderEvent } = useApiRequest(async () => {
    if (!kind || !token) return

    const productDetails = await commonObjectGet<T>(productsDetailsUrls[kind].replace('replace-me', String(token)))
    action(productDetails)
  })

  React.useEffect(() => {
    if (kind && token) sendPaymentSuccessOrderEvent()
  }, [])
}
