import * as React from 'react'
import * as clsx from 'clsx'
import { useToggle } from '@hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'

interface Props {
  children: React.ReactNode
  isCollapsable?: boolean
  bottomElement?: React.ReactNode
  bottomElementClassName?: string
}

export const PackageWholesaleInformationWrapper = ({
  children,
  isCollapsable = false,
  bottomElement,
  bottomElementClassName,
}: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useToggle()

  return (
    <div
      className={clsx('package-wholesale__information__wrapper', {
        'is-collapsed': isCollapsed,
        'is-full-height': !isCollapsable,
      })}
    >
      <div className="mt-2 text-darker-gray font-md col-12 col-sm-11 package-wholesale__information">{children}</div>
      {isCollapsable && (
        <div className={clsx('d-flex align-items-center', bottomElementClassName)}>
          <Button
            onClick={setIsCollapsed}
            className="btn-secondary py-2 ps-3 pe-4 package-wholesale__information__button"
          >
            <IconWithText
              text={isCollapsed ? 'Zwiń szczegóły' : 'Rozwiń szczegóły'}
              iconClassName="uil-angle-down font-xl lh-1 package-wholesale__information__toggle__icon text-white me-1 font-lg"
              textClassName="text-white font-sm fw-bold lh-1"
            />
          </Button>
          {bottomElement}
        </div>
      )}
    </div>
  )
}
