import { motion } from 'framer-motion'
import * as React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

export const FadeInView = ({ children, className }: Props): JSX.Element => (
  <motion.div
    className={className}
    initial={{ opacity: 0, transform: 'translateY(-10px)' }}
    animate={{ opacity: 1, transform: 'none' }}
    exit={{ opacity: 0, transform: 'translateY(-10px)' }}
  >
    {children}
  </motion.div>
)
