import * as React from 'react'

interface ScrollIntoViewResponse<T> {
  scrollTo: (ref: React.RefObject<T>) => () => void
}

export const useScrollIntoView = <T extends HTMLElement>(): ScrollIntoViewResponse<T> => {
  const handleScroll =
    <T extends HTMLElement>(ref: React.RefObject<T>) =>
    () => {
      if (!ref.current) return

      ref.current.scrollIntoView({ behavior: 'smooth' })
    }

  return { scrollTo: handleScroll }
}
