import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { AppHeaderSection } from '@components/app-header-section'

interface Props {
  onScrollToVipImprovementsList: () => void
}

export const VipStandardHeaderSectionContent = ({ onScrollToVipImprovementsList }: Props): JSX.Element => (
  <AppHeaderSection
    images={[require('@assets/images/header-vip-standard.webp')]}
    imageDescription="Standardowy Pakiet VIP"
  >
    <AppHeaderContent
      title={
        <>
          Potwierdzenie zakupu <span className="text-nowrap d-block">Pakietu VIP</span>
        </>
      }
      subtitle={
        <span className="font-lg">
          Pakiet VIP skierowany jest do wszystkich Gości, którzy cenią sobie{' '}
          <strong className="text-nowrap">prestiż oraz wygodę wypoczynku</strong>. Dodatkowe atrakcje, rabaty, liczne
          przywileje, a także butelka wyselekcjonowanego wina sprawią, że Twoje wakacje będą niezapomnianą przygodą i
          wypoczynkiem na najwyższym poziomie.
        </span>
      }
    >
      <IconWithText
        text="Co dostaję w pakiecie?"
        iconClassName="uil-plus font-xxxl me-2"
        className="text-gold font-size-xl cursor-pointer fw-bold justify-content-center justify-content-sm-start"
        onClick={onScrollToVipImprovementsList}
      />
    </AppHeaderContent>
  </AppHeaderSection>
)
