import React from 'react'
import { useTimeCount } from '@hooks/use-time-count'
import { useDispatch } from 'react-redux'
import { hideModals } from '@store/slices/modal-slice'
import { useDevicesSize } from '@hooks/use-devices-size'
import { useFormContext, useWatch } from 'react-hook-form'
import { RodoAgreementFormInputs } from '@modules/rodo-agreements/form/form-page'

const SECONDS_TO_CLOSE_AFTER_INACTIVITY = 60

interface Response {
  timeLeft: number | null
  refresh: () => void
}

export const useRodoAgreementFormSessionTimeout = (action: () => void, countWhen: boolean): Response => {
  const dispatch = useDispatch()

  const { isDesktop } = useDevicesSize()
  const { elapsedTime, refresh, clear } = useTimeCount(isDesktop && countWhen)

  const { control } = useFormContext<RodoAgreementFormInputs>()
  const formData = useWatch({ control })

  React.useEffect(() => {
    if (elapsedTime >= SECONDS_TO_CLOSE_AFTER_INACTIVITY - 1) {
      dispatch(hideModals())
    }

    if (elapsedTime >= SECONDS_TO_CLOSE_AFTER_INACTIVITY) {
      action()
      clear()
    }
  }, [elapsedTime])

  React.useEffect(() => {
    refresh()
  }, [formData])

  return {
    timeLeft:
      elapsedTime > SECONDS_TO_CLOSE_AFTER_INACTIVITY / 2 ? SECONDS_TO_CLOSE_AFTER_INACTIVITY - elapsedTime : null,
    refresh,
  }
}
