import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'
import { SubscriptionDetails } from '@modules/subscription/models'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionWithBenefitsDetailsContentDescription = ({ subscriptionDetails }: Props): JSX.Element => (
  <PackageWholesaleInformationWrapper
    isCollapsable={true}
    bottomElementClassName="flex-sm-row flex-column mt-3 mt-sm-0"
    bottomElement={
      <div className="ms-5 d-flex align-items-center fw-semibold mt-3 mt-sm-0">
        Cena pakietu:{' '}
        <strong className="font-xxl text-primary ps-4">
          {formatPriceShort(subscriptionDetails.product.price_brutto)}
        </strong>
      </div>
    }
  >
    <p>
      Vouchery wraz z przysługującymi im benefitami można wykorzystać w latach wskazanych powyżej, w terminach:{' '}
      <strong>{SubscriptionWithBenefitsDates[subscriptionDetails.type][subscriptionDetails.kind] ?? ''}</strong>{' '}
      {['silver', 'brown'].includes(subscriptionDetails.kind) &&
        ' z wyłączeniem terminów specjalnych określonych w regulaminie'}
      . Pobyty można zrealizować we wszystkich dostępnych Resortach Holiday Park & Resort.
    </p>
    <p>
      Rezerwacji dokonuje się samodzielnie poprzez stronę{' '}
      <a href="https://rezerwuj.holidaypark.pl" target="_blank" className="fw-semibold text-decoration-none">
        www.rezerwuj.holidaypark.pl{' '}
      </a>
      lub telefonicznie z pomocą Biura Obsługi Klienta:{' '}
      <a href="tel:735 200 800" className="fw-semibold text-decoration-none">
        735 200 800
      </a>
      .
    </p>
    <p>
      Przy zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie
      doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić
      opłatę z tytułu zużytych mediów. Wysokość opłat została wskazana w Cenniku oraz w Regulaminie Głównym Holiday Park
      & Resort.
    </p>
    <p>
      Do każdego pobytu zostanie doliczona kwota zabezpieczenia na poczet kaucji zwrotnej w wysokości{' '}
      {formatPriceShort(SubscriptionWithBenefitDepositAmounts[subscriptionDetails.type])}, płatna do 12 miesięcy przed
      rozpoczęciem rezerwacji.
    </p>
    <p>
      Dodatkowe środki na zakupy opaską można zrealizować w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku (w ramach
      dostępności punktów) podczas rezerwacji z wykorzystaniem voucherów pobytowych we wskazanych latach.
    </p>
    <p>Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
  </PackageWholesaleInformationWrapper>
)

const SubscriptionWithBenefitsDates = {
  WITH_BENEFIT: {
    gold: 'od 01.01 do 10.07 oraz od 20.08 do 31.12',
    silver: 'od 01.01 do 30.06 oraz od 01.09 do 31.12',
    brown: 'od 01.01 do 15.06 oraz od 15.09 do 31.12',
  },
  WITH_BENEFIT_20: {
    gold: 'od 01.01 do 07.07 oraz od 26.08 do 31.12',
    silver: 'od 01.01 do 30.06 oraz od 01.09 do 31.12',
    brown: 'od 01.01 do 31.05 oraz od 01.10 do 31.12',
  },
}

const SubscriptionWithBenefitDepositAmounts = {
  WITH_BENEFIT: 1000,
  WITH_BENEFIT_20: 750,
}
