import * as React from 'react'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { FormProvider, useForm } from 'react-hook-form'
import { IconWithText } from '@components/icon-with-text'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { useApiRequest, useFormRequest } from '@hooks/use-form-request'
import { commonObjectGet, commonObjectPost } from '@requests/basic-requests'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { Rule } from '@modules/reservation/models'
import { AvailableRuleKeyword } from '@models/app-data'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface BocianRule {
  keyword: string
  required: boolean
}

interface FormInputs {
  name: string
  pesel: string
  email: string
  phone: string
}

export const BocianVoucherForm = (): JSX.Element => {
  const { generic_voucher } = useSelector(selectAppDataUrls)
  const [rules, setRules] = React.useState<Rule[]>([])

  const methods = useForm<FormInputs>()
  const navigate = useNavigate()

  const { action: fetchRules } = useApiRequest(async () => {
    const rules = await commonObjectGet<BocianRule[]>(generic_voucher.bocian_rules)
    setRules(
      rules.map(rule => ({
        keyword: rule.keyword as AvailableRuleKeyword,
        required: rule.required,
        accepted: false,
        date: null,
      })),
    )
  })

  const { action: onSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(generic_voucher.bocian_create, payload)
    navigate(NavigationPath.OperationSuccess.replace(':kind', 'bocian').replace(':token', 'voucher'))
  }, methods.setError)

  React.useEffect(() => {
    fetchRules()
  }, [])

  return (
    <FormProvider {...methods}>
      <div className="col-lg-5 col-12 text-secondary bocian-text-primary bocian-voucher__form__card">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h3 className="mb-4 fw-bold text-center text-lg-start">
            Wypełnij formularz zgodnie z danymi podanymi podczas kontaktu z Bocian Finanse
          </h3>
          <div className="mt-5">
            <CustomFormGroup
              label="Imię i nazwisko:"
              inputName="name"
              formLabelProps={{ className: 'fw-bold col-lg-4 bocian-text-primary font-xl' }}
              formControlProps={{ type: 'text', className: 'col-6', placeholder: 'Wpisz swoje imię i nazwisko' }}
              formGroupProps={{ className: 'package-wholesale__data-input bocian-voucher__form__input' }}
            />
            <CustomFormGroup
              label="Numer PESEL:"
              inputName="pesel"
              formLabelProps={{ className: 'fw-bold col-12 col-lg-4 bocian-text-primary font-xl' }}
              formControlProps={{ type: 'number', className: 'col-6', placeholder: 'Wpisz 11-cyfrowy numer PESEL' }}
              formGroupProps={{ className: 'package-wholesale__data-input bocian-voucher__form__input' }}
            />
            <CustomFormGroup
              label="Adres-email:"
              inputName="email"
              formLabelProps={{ className: 'fw-bold col-lg-4 bocian-text-primary font-xl' }}
              formControlProps={{ type: 'email', className: 'col-6', placeholder: 'Wpisz swój adres e-mail' }}
              formGroupProps={{ className: 'package-wholesale__data-input bocian-voucher__form__input' }}
            />
            <CustomFormGroup
              label="Numer telefonu:"
              inputName="phone"
              formLabelProps={{ className: 'fw-bold col-lg-4 bocian-text-primary font-xl' }}
              formControlProps={{ type: 'tel', className: 'col-6', placeholder: 'Wpisz swój numer telefonu' }}
              formGroupProps={{ className: 'package-wholesale__data-input bocian-voucher__form__input' }}
            />
          </div>
          <div className="bocian-voucher__form__agreements mt-4">
            <DynamicAgreementsSection fullWidth title="" rules={rules} />
          </div>

          <strong className="font-xs mt-4 d-block text-center text-lg-start">
            Zgody marketingowe możesz wycofać w dowolnym momencie bez żadnych konsekwencji.
          </strong>
          <button
            className="bocian-voucher__form__submit mt-4 btn-responsive text-center text-lg-start"
            disabled={isLoading}
          >
            <IconWithText
              text={isLoading ? 'Proszę czekać...' : 'Biorę udział w programie'}
              iconClassName={clsx('font-xxxl me-2', isLoading ? 'spinner-border spinner-border-xs' : 'uil-check')}
              textClassName="font-lg fw-bold"
              className="d-inline-flex"
            />
          </button>
        </form>
      </div>
    </FormProvider>
  )
}
