import * as React from 'react'

interface Props {
  onScrollToBenefitsSection: () => void
  onScrollToHowToEnrollSection: () => void
  onScrollToContactSection: () => void
}

export const HolidayBenefitEmployeeHeader = ({
  onScrollToBenefitsSection,
  onScrollToHowToEnrollSection,
  onScrollToContactSection,
}: Props): React.ReactNode => (
  <nav className="d-flex align-items-center justify-content-between py-3 container">
    <div className="d-flex align-items-center gap-4">
      <img src={require('@assets/images/holiday-benefit/landing-page/logo.svg')} alt="holidaypark logo" height="35" />
      <div className="holiday-benefit__header__spacer" />
      <img src={require('@assets/images/logo_holiday_color.svg')} alt="holidaypark logo" height="40" />
    </div>
    <button className="btn-unstyled" onClick={onScrollToContactSection}>
      <div className="bg-holiday-benefit-primary d-flex align-items-center justify-content-center px-3 py-2 rounded d-lg-none">
        <i className="uil-envelope text-white font-xxl" />
      </div>
    </button>
    <div className="d-lg-flex gap-5 align-items-center d-none">
      <button
        className="text-decoration-none fw-semibold font-lg text-holiday-benefit-secondary btn-unstyled"
        onClick={onScrollToBenefitsSection}
      >
        Dlaczego warto?
      </button>
      <button
        className="text-holiday-benefit-secondary fw-semibold font-lg btn-unstyled"
        onClick={onScrollToHowToEnrollSection}
      >
        Jak przystąpić do programu?
      </button>
      <button className="btn-holiday-benefit is-primary" onClick={onScrollToContactSection}>
        Skontaktuj się
      </button>
    </div>
  </nav>
)
