import * as React from 'react'
import { NotificationEvents } from '@models/websocket'
import { PaymentRequestPayload, usePaymentRedirect } from '@components/web-socket-handler/use-payment-redirect'
import { useWebSocket } from '@hooks/use-web-socket'

export const ClientWebSocketHandler: React.FC = () => {
  const { handlePaymentRequestChange } = usePaymentRedirect()

  useWebSocket<PaymentRequestPayload, []>((message, payload) => {
    switch (message) {
      case NotificationEvents.PAYMENT_REQUEST_CHANGE:
        handlePaymentRequestChange(payload as PaymentRequestPayload)
    }
  }, [])

  return null
}
