import { GTMItemCategory } from '@models/gtm'
import { createGtmCartItem, useGtmEvents } from '@hooks/gtm/use-gtm-events'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'

interface Response {
  sendPurchaseEvent: (subscriptionContract: SubscriptionContractDetails) => void
  sendBeginCheckoutEvent: (step: number, subscription: SubscriptionContractDetails) => void
}

export const useSubscriptionContractGtmEvents = (): Response => {
  const { purchase, beginCheckout } = useGtmEvents()

  const sendPurchaseEvent = (subscriptionContract: SubscriptionContractDetails) => {
    purchase({
      clientId: subscriptionContract.client.id,
      transactionId: subscriptionContract.number,
      price: subscriptionContract.single_price_brutto,
      items: createSubscriptionContractProductCartItem(subscriptionContract),
    })
  }

  const sendBeginCheckoutEvent = (step: number, subscriptionContract: SubscriptionContractDetails) => {
    beginCheckout({
      clientId: subscriptionContract.client.id,
      price: subscriptionContract.single_price_brutto,
      items: createSubscriptionContractProductCartItem(subscriptionContract),
      step,
    })
  }

  return {
    sendPurchaseEvent,
    sendBeginCheckoutEvent,
  }
}

const createSubscriptionContractProductCartItem = (subscriptionContract: SubscriptionContractDetails) => [
  createGtmCartItem({
    item_id: subscriptionContract.number,
    item_name: subscriptionContract.plan_display,
    item_category: GTMItemCategory.Ecommerce,
    item_variant: subscriptionContract.plan,
    item_list_name: 'subskrypcja',
    price: subscriptionContract.single_price_brutto,
    quantity: 1,
  }),
]
