import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { motion } from 'framer-motion'
import { QrCodeBox } from '@components/qr-code-box'

interface Props extends BaseModalProps {
  text: string
}

export const RodoAgreementQrCodeModal = ({ toggleIsVisible, text }: Props): JSX.Element => (
  <ModalBody className="p-5">
    <motion.button
      className="btn-unstyled d-block ms-auto rodo-agreements__qr-modal__close-button"
      whileTap={{ scale: 0.9 }}
      onClick={toggleIsVisible}
    >
      <i className="uil-times text-muted" />
    </motion.button>
    <div className="d-flex align-items-center">
      <div className="position-relative">
        <img src={require('@assets/images/phone.svg')} alt="Holiday Park & Resort logo" height="380" />
        <QrCodeBox text={`${window.location.origin}/zgody/${text}`} className="rodo-agreements__qr-code-box" />
      </div>
      <p className="text-darker-gray font-xxxl font-500 ms-5">
        Zeskanuj kod QR aby uzupełnić formularz zgód marketingowych{' '}
        <strong className="text-dark text-nowrap">na swoim telefonie.</strong>
      </p>
    </div>
  </ModalBody>
)
