import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { AppHeaderContent } from '@components/app-header-content'
import { ResortImages } from '@modules/package-wholesale/details'
import { ActionRow } from '@components/action-row'
import { AcquisitionOfConsentsRules } from '@modules/acquisition-of-consents/acquisition-of-consents-rules'
import { AcquisitionOfConsentsForm } from '@modules/acquisition-of-consents/acquisition-of-consents-form'
import { useNavigate } from 'react-router-dom'
import { useProductDetails } from '@hooks/use-product-details'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { ConsentAcquisitionUser } from '@modules/acquisition-of-consents/models'
import { commonObjectPut } from '@requests/basic-requests'
import { NavigationPath } from '@models/routes'
import { useFormRequest } from '@hooks/use-form-request'

interface FormInputs {
  email: string
  first_name: string
  last_name: string
  phone: string
  privacy_policy_rules: boolean
  information_clause: boolean
  phone_call_rules: boolean
}

export const AcquisitionOfConsentsView = (): JSX.Element => {
  const { urls } = useSelector(selectAppData)

  const navigate = useNavigate()
  const {
    productDetails: userData,
    token,
    PageWrapper,
    urlWithToken,
  } = useProductDetails<ConsentAcquisitionUser>(urls.clients.update_rodo_data)

  const methods = useForm<FormInputs>({ defaultValues: { phone: '', first_name: '', last_name: '' } })

  const { action: submit, isLoading } = useFormRequest(async (formValues: FormInputs) => {
    if (!userData || !token) return

    const isModified = (field: keyof FormInputs) => formValues[field] !== userData[field]

    const payload = {
      privacy_policy_rules: formValues.privacy_policy_rules,
      information_clause: formValues.information_clause,
      phone_call_rules: formValues.phone_call_rules,
      ...(isModified('first_name') && { first_name: formValues.first_name }),
      ...(isModified('last_name') && { last_name: formValues.last_name }),
      ...(isModified('phone') && { phone: formValues.phone }),
    }

    await commonObjectPut(urlWithToken, payload)
    navigate(NavigationPath.OperationSuccess.replace(':token', token).replace(':kind', 'potwierdzenie-danych'))
  }, methods.setError)

  React.useEffect(() => {
    if (!userData) return
    methods.setValue('email', userData.email)
    methods.setValue('first_name', userData.first_name)
    methods.setValue('last_name', userData.last_name)
    methods.setValue('phone', userData.phone)
  }, [userData])

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="Holiday Park & Resort - Potwierdzenie danych">
        <AppHeaderContent
          title="Potwierdzenie danych"
          subtitle={
            <div className="col-12 col-sm-9">
              Bądź na bieżąco z naszymi najatrakcyjniejszymi ofertami. Sprawdź czy poniższe dane są nadal aktualne,
              jeśli coś się zmieniło daj nam o tym znać aktualizując swoje dane w sekcji poniżej.
            </div>
          }
        />
      </AppHeaderSection>
      <div className="container app-header-section__after-content mb-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)}>
            <div className="row">
              <div className="card col-12 col-12 card-shadow border-0 overflow-hidden mx-auto">
                <h1 className="text-primary text-center my-4 font-xxl">Potwierdzenie danych osobowych</h1>
                <hr className="mx-n5 my-0" />
                <h2 className="font-xl mx-auto text-darker-gray mt-4 text-center">
                  Aktualizujemy naszą bazę danych, sprawdź poniższe dane.
                </h2>
                <div className="col-sm-8 col-12 mx-auto my-4">
                  <div className="mb-4 text-darker-gray">
                    {userData && <AcquisitionOfConsentsForm user={userData} />}
                  </div>
                  <AcquisitionOfConsentsRules />
                  <ActionRow
                    isLoading={isLoading}
                    className="mt-4 d-flex justify-content-end"
                    buttonContent={
                      <>
                        <span className="font-500 d-block font-sm lh-initial">Gotowe</span>
                        <strong className="font-xl">Potwierdzam dane</strong>
                      </>
                    }
                  />
                </div>
                <hr className="mx-n5 my-0" />
                <img
                  src={require('@assets/images/logo_holiday_color.svg')}
                  alt="holidaypark logo"
                  className="ms-auto my-3 me-4"
                  style={{ objectFit: 'contain' }}
                  height={35}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
