import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { formatPrice } from '@helpers/price-helper'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { usePackageWholesalePrices } from '@modules/package-wholesale/use-package-wholesale-prices'

export const PackageWholesaleSubscriptionContentTotalPrice = (): JSX.Element => {
  const { control } = useFormContext<PackageWholesaleFormInput>()

  const products = useWatch({ control, name: 'products' })

  const { getTotalPrice } = usePackageWholesalePrices()

  const subscriptionProducts = products.filter(product => product.product.kind === 'subscription')

  const total = React.useMemo(() => getTotalPrice(subscriptionProducts), [subscriptionProducts])

  return (
    <div className="text-darker-gray font-700  font-lg pt-3 d-flex align-items-center">
      <div>Łączna kwota za wybrane pobyty:</div>
      <div className="font-xxl text-primary lh-1 ms-2"> {formatPrice(total)}</div>
    </div>
  )
}
