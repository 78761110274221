import * as React from 'react'
import * as clsx from 'clsx'
import { motion } from 'framer-motion'
import { Spinner } from '@components/spinner'

interface Props {
  text: string
  onClick: () => void
  className?: string
  isLoading?: boolean
  backgroundColor: string
  disabled?: boolean
}

export const RodoAgreementsButton = ({
  text,
  onClick,
  className,
  backgroundColor,
  isLoading,
  disabled,
}: Props): JSX.Element => (
  <motion.button
    style={{ backgroundColor }}
    className={clsx('rodo-agreements__description-section__button d-flex align-items-center', className)}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    {text}
    {isLoading && <Spinner color="white" className="ms-4 font-xs" kind="bordered" />}
  </motion.button>
)
