import * as React from 'react'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { ProductWithInvoice } from '@models/models'
import { useCountries } from '@hooks/use-countries'

interface Props<T> {
  product: ProductWithInvoice<T>
}

export const ClientInvoicePreview = <T,>({ product }: Props<T>): JSX.Element => {
  const { getCountryName } = useCountries()

  const InvoiceFieldLabels = {
    company: { id: 'Numer NIP:', name: 'Nazwa firmy:', street: 'Adres firmy:' },
    individual: { id: 'Numer PESEL:', name: 'Imię i nazwisko:', street: 'Adres:' },
  }[product.invoice_type ?? 'company']

  return (
    <>
      <hr className="mx-n5 text-muted" />
      <strong className="text-gold text-center text-sm-start d-block">Dane do faktury:</strong>
      <div className="text-darker-gray mt-3 d-flex flex-column gap-1 font-md">
        <CardContentBaseDataRow name={InvoiceFieldLabels.id} value={product.invoice_nip} />
        <CardContentBaseDataRow name={InvoiceFieldLabels.name} value={product.invoice_company} />
        <CardContentBaseDataRow name={InvoiceFieldLabels.street} value={product.invoice_street} />
        <CardContentBaseDataRow name="" value={`${product.invoice_postcode}, ${product.invoice_city}`} />
        <CardContentBaseDataRow name="" value={getCountryName(product.invoice_country)} />
      </div>
    </>
  )
}
