import * as React from 'react'
import * as clsx from 'clsx'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { ExpandableText } from '@components/controls/expandable-text'
import { StaticRules } from '@helpers/rules'

export const MARKETING_RULES_KEYWORDS = ['email_rules', 'sms_rules', 'phone_call_rules']

interface Props {
  fullWidth?: boolean
  allSelectionClassName?: string
}

export const MarketingAgreementSection = ({
  fullWidth,
  allSelectionClassName = 'checkbox--small font-size-sm is-muted-label align-items-start',
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext()

  const marketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = marketingRules.filter(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    const isEachSelected = marketingRules.every(Boolean)
    setValue('allMarketingRulesSelected', isEachSelected)
  }, [marketingRules])

  return (
    <>
      <Checkbox
        className={allSelectionClassName}
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        label={
          <div className="mt-1">
            Zgody marketingowe <span className="font-size-xs">(zaznacz wszystkie)</span>
            <small className={clsx('d-block mt-2 col-12', { 'col-md-7': !fullWidth })}>
              {StaticRules.marketing_rules}
            </small>
          </div>
        }
        indeterminate={isAnyMarketingRuleSelected}
      />

      <div className="ms-3 mt-1">
        <Checkbox
          name="email_rules"
          id="email_rules"
          label={
            <div className={clsx('mt-1 col-12', { 'col-sm-9': !fullWidth })}>
              <div className="pb-1">Zgoda na dostarczanie powiadomień i ofert poprzez pocztę elektroniczną.</div>

              <ExpandableText text={StaticRules.email_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
        <Checkbox
          name="sms_rules"
          id="sms_rules"
          label={
            <div className={clsx('mt-1 col-12', { 'col-sm-8': !fullWidth })}>
              <div className="pb-1">Zgoda na dostarczanie powiadomień i ofert poprzez SMS.</div>
              <ExpandableText text={StaticRules.sms_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
        <Checkbox
          name="phone_call_rules"
          id="phone_call_rules"
          label={
            <div className={clsx('mt-1 col-12', { 'col-sm-8': !fullWidth })}>
              <div className="pb-1">Zgoda na przedstawianie ofert poprzez kontakt telefoniczny.</div>
              <ExpandableText text={StaticRules.phone_call_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
      </div>
    </>
  )
}
