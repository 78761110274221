import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleCardsSubscriptionSection } from '@modules/package-wholesale/details/subscription/package-wholesale-cards-subscription-section'
import { PackageWholesaleSubscriptionInformation } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-information'
import { PackageWholesaleSubscriptionVariants } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-variants'
import { PackageWholesaleSubscriptionContentTotalPrice } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content-total-price'
import { PackageWholesaleLeafletButton } from '@modules/package-wholesale/details/subscription/package-wholesale-leaflet-button'
import { PackageWholesaleSubscriptionContentHeader } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content-header'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  isCollapsable: boolean
}

export const PackageWholesaleSubscriptionContent = ({ packageWholesaleDetails, isCollapsable }: Props): JSX.Element => {
  const isEveryProductGray = React.useMemo(
    () => packageWholesaleDetails.products.every(product => product.subscription_kind === 'gray'),
    [packageWholesaleDetails.products],
  )

  const subscriptionProducts = React.useMemo(
    () => packageWholesaleDetails.products.filter(product => product.kind === 'subscription'),
    [packageWholesaleDetails.products],
  )

  return (
    <div>
      <PackageWholesaleSubscriptionContentHeader
        seller={packageWholesaleDetails.seller}
        created={packageWholesaleDetails.created}
      >
        <strong className="text-secondary font-xxl text-center text-sm-start d-block pt-sm-5">
          Oferujemy vouchery na pobyt{' '}
          <span className="underline-shadow text-gold text-nowrap">w specjalnych cenach:</span>
        </strong>
      </PackageWholesaleSubscriptionContentHeader>

      <strong className="text-darker-gray d-block mt-3 mb-2">Wybierz rodzaj vouchera:</strong>

      {isEveryProductGray ? (
        <>
          <PackageWholesaleCardsSubscriptionSection packageWholesaleProducts={subscriptionProducts}>
            <PackageWholesaleSubscriptionContentTotalPrice />
          </PackageWholesaleCardsSubscriptionSection>
          <PackageWholesaleSubscriptionInformation
            isCollapsable={isCollapsable}
            bottomElement={
              <PackageWholesaleLeafletButton
                leafletUrl={packageWholesaleDetails.leaflet_subscription}
                wrapperClassName="ms-3"
              />
            }
          />
        </>
      ) : (
        <PackageWholesaleSubscriptionVariants
          packageWholesaleProducts={subscriptionProducts}
          packageWholesaleDetails={packageWholesaleDetails}
          isCollapsable={isCollapsable}
        />
      )}
    </div>
  )
}
