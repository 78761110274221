import * as React from 'react'
import { useAxiosErrorHandler } from '@hooks/use-axios-error-handler'

type state = 'pending' | 'resolved' | 'rejected' | 'idle'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useApiRequest = <T>(asyncCallable: (...params) => Promise<T>) => {
  const [state, setState] = React.useState<state>('idle')
  const [data, setData] = React.useState<T | undefined>(undefined)

  const action = async (...params) => {
    setState('pending')
    try {
      const result = await asyncCallable(...params)
      setData(result)
      setState('resolved')
      return result
    } catch (error) {
      console.warn(error)
      setState('rejected')
    }
  }

  return {
    state,
    action,
    data,
    isLoading: state === 'pending',
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormRequest = <T>(asyncCallable, setError, errorHandlerParams?) => {
  const handleAxiosFormErrors = useAxiosErrorHandler()

  return useApiRequest<T>(async (...params) => {
    try {
      return await asyncCallable(...params)
    } catch (error) {
      handleAxiosFormErrors(error, setError, { showGlobalError: false, ...errorHandlerParams })
    }
  })
}
