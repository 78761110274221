import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CLIENT_APP_URLS } from '@helpers/consts'

export const MobileAppBanner = (): JSX.Element => (
  <div className="overflow-hidden">
    <div className="operation-result__mobile-banner">
      <div className="opacity-75 col-5">
        <h1 className="fw-bold opacity-75 mb-0">Aplikacja mobilna</h1>
        <h1 className="fw-bold opacity-75">Konta Gościa</h1>
        <p>Pobierz aplikację mobilną Konta Gościa i korzystaj jeszcze wygodniej</p>
        <IconWithText
          text="Dostęp do pełnej historii rezerwacji"
          iconClassName="uil-check font-xxl me-2"
          className="text-gold font-md"
        />
        <IconWithText
          text="Meldowanie i wymeldowanie on-line"
          iconClassName="uil-check font-xxl me-2"
          className="text-gold font-md"
        />
        <IconWithText
          text="Kontrola rachunku podczas pobytu"
          iconClassName="uil-check font-xxl me-2"
          className="text-gold font-md"
        />
        <div className="d-flex gap-3 mt-3">
          <a href={CLIENT_APP_URLS.APP_STORE_LINK}>
            <img src={require('@assets/images/app-store.svg')} alt="App store" className="cursor-pointer" />
          </a>

          <a href={CLIENT_APP_URLS.GOOGLE_PLAY_LINK}>
            <img src={require('@assets/images/google-play.svg')} alt="Google play" className="cursor-pointer" />
          </a>
        </div>
      </div>
      <img
        src={require('@assets/images/mobile-app-banner.png')}
        alt="Aplikacja Konto gościa"
        className="operation-result__mobile-banner__image"
      />
    </div>
  </div>
)
