import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  className?: string
  children?: React.ReactElement
  kind?: 'bordered' | 'grow'
  role?: string
  color?: string
}

export const Spinner = ({
  className,
  children,
  kind = 'bordered',
  color = 'primary',
  role = 'spinner',
}: Props): React.ReactElement => (
  <div
    role={role}
    className={clsx(
      { 'spinner-border': kind === 'bordered', 'spinner-grow': kind === 'grow' },
      `text-${color}`,
      className,
    )}
  >
    {children}
  </div>
)
