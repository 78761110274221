import * as React from 'react'
import { HolidayBenefitStickyLabelButtonShape } from '@modules/holiday-benefit/common/holiday-benefit-sticky-label-button-shape'

interface Props {
  text: string
  onClick: () => void
  gradientStartColor: string
  gradientEndColor: string
}

export const HolidayBenefitStickyLabel = ({
  text,
  onClick,
  gradientEndColor,
  gradientStartColor,
}: Props): React.ReactNode => (
  <button className="btn-unstyled holiday-benefit__sticky-label d-none d-lg-block" onClick={onClick}>
    <HolidayBenefitStickyLabelButtonShape gradientEndColor={gradientEndColor} gradientStartColor={gradientStartColor} />
    <div className="holiday-benefit__sticky-label__text">{text}</div>
  </button>
)
