import * as React from 'react'
import clsx from 'clsx'

interface Props {
  className?: string
  kind: string
  title: string
  children: React.ReactNode
  subtitle: React.ReactNode
  image: string
}

export const EndlessHolidayConfirmationProductBaseRow = ({
  kind,
  children,
  className,
  subtitle,
  title,
  image,
}: Props): React.ReactNode => (
  <li
    className={clsx(
      'endless-holiday__confirmation__product-card col-11 rounded overflow-hidden',
      `is-${kind}`,
      className,
    )}
  >
    <div className="d-flex flex-wrap">
      <img className="col-sm-3 col-12 endless-holiday__confirmation__product-card__image" src={image} alt={title} />
      <div className="col-sm-9 col-12 endless-holiday__confirmation__product-card__content d-flex flex-wrap justify-content-between">
        <div className="border-end p-3 col-sm-5 col-12 d-flex flex-column justify-content-between text-center text-sm-start">
          <h6 className="font-xxl endless-holiday__confirmation__product-card__content__title">{title}</h6>
          {subtitle}
        </div>
        <div className="col-sm-7 col-12 p-3 d-flex flex-column justify-content-between">{children}</div>
      </div>
    </div>
  </li>
)
