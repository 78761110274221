import * as React from 'react'
import { CircleCheck } from '@components/circle-check'

export const EndlessHolidayDetailsContentBenefits = (): React.ReactNode => (
  <div className="mt-2 row col-9">
    {benefitsPoints.map(benefit => (
      <CircleCheck key={benefit} className="col-lg-6 mb-1 mb-lg-0">
        <span className="font-md lh-initial">
          <span className="text-muted">{benefit} </span>
        </span>
      </CircleCheck>
    ))}
  </div>
)

const benefitsPoints = [
  '6-dniowe pobyty w domku lub w apartamencie  ',
  'Voucher na zakupy we wszystkich punktach gastronomicznych ',
  '3 wersje kolorystyczne pakietów do wyboru  ',
  '7 ośrodków do wyboru (nad morzem i w górach)',
]
