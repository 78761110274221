import * as React from 'react'

interface Props {
  amount: number
  text: React.ReactElement
}

export const TodaysPurchaseIndicator = ({ amount, text }: Props): JSX.Element => (
  <div className="card gastro-card-details__label border-0 card-shadow">
    <i className="uil-tag-alt font-xxl text-gold d-block" />
    <strong className="text-gold font-xxxl d-block">{amount}</strong>
    <span className="d-block font-sm text-darker-gray">{text}</span>
  </div>
)
