import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { IconWithText } from '@components/icon-with-text'
import * as clsx from 'clsx'
import { CLIENT_APP_URLS } from '@helpers/consts'

interface Props extends BaseModalProps {
  text: string
}

export const ClientAppDetailsModal = ({ toggleIsVisible }: Props): JSX.Element => (
  <ModalBody className="d-flex p-0 flex-wrap flex-lg-nowrap flex-column-reverse flex-lg-row">
    <div className="p-5 col-lg-6 col-12 text-muted text-center text-lg-start">
      <img
        src={require('@assets/images/logo_holiday_color.svg')}
        alt="Holiday Park & Resort"
        height={50}
        className="mx-auto mx-lg-0 d-block"
      />
      <h5 className="fw-bold font-xxl mt-5 mb-3 text-darker-gray">Czym jest Konto Gościa</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consectetur, consequatur consequuntur cum,
        dolor fuga fugiat id illum nam nostrum perferendis placeat porro quam quas, quibusdam quidem quos rem tempora
        unde voluptate. Consequuntur impedit laudantium rerum voluptatem. A aliquid eos facere inventore ipsum itaque
        labore molestiae odio quis veniam, voluptate!
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid, autem blanditiis consequatur, corporis
        cupiditate deleniti doloribus eaque libero modi quia quis ratione rem saepe sapiente sed unde ut! Sequi.
      </p>
      <div>
        <strong className="text-muted">Pobierz naszą aplikację Konta Gościa</strong>
        <div className="d-flex gap-4 mt-1">
          <AppLink
            className="btn-holiday-benefit opacity-50"
            href={CLIENT_APP_URLS.GOOGLE_PLAY_LINK}
            icon="uil-google-play"
            text="Google Play"
          />
          <AppLink
            className="btn-holiday-benefit opacity-50"
            href={CLIENT_APP_URLS.APP_STORE_LINK}
            icon="uil-apple"
            text="App Store"
          />
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-12">
      <div className="d-flex position-relative align-items-end justify-content-end h-100">
        <button
          onClick={toggleIsVisible}
          className="btn-holiday-benefit is-dark btn-unstyled holiday-benefit__invitation__app__modal__close-btn"
        >
          <i className="uil-times font-xxl" />
        </button>
        <img
          className="holiday-benefit__invitation__app__modal__image"
          src={require('@assets/images/mobile-app-banner-with-bg.webp')}
          alt="Aplikacja Konto gościa"
          width="100%"
        />
      </div>
    </div>
  </ModalBody>
)

interface AppLinkProps {
  href: string
  icon: string
  text: string
  className?: string
}

export const AppLink = ({ href, icon, text, className }: AppLinkProps): React.ReactNode => (
  <a
    href={href}
    target="_blank"
    className={clsx('is-dark btn-unstyled text-white px-4 py-2 rounded text-decoration-none cursor-pointer', className)}
  >
    <IconWithText text={text} iconClassName={clsx('font-xxxl me-2', icon)} textClassName="fw-bold" />
  </a>
)
