import { NavigationPath, OfferNavigationPath } from '@models/routes'

export const RouterKind = {
  OFFER: 'oferta',
  DEFAULT: 'default',
}

export const getPackageWholesalePath = (): string => {
  if (window.location.hostname.startsWith(RouterKind.OFFER)) {
    return OfferNavigationPath.PackageWholesaleDetails
  }

  return NavigationPath.PackageWholesaleDetails
}
