import * as React from 'react'
import { ManualBoxPoint } from '@components/manual-box/manual-box-point'
import { ManualBox } from '@components/manual-box/manual-box'

export const EndlessHolidayManualBox = (): React.ReactNode => (
  <ManualBox productName="Wakacje w nieskończoność">
    <ManualBoxPoint number={1}>
      <strong className="text-primary">Wybierz</strong> wariant oferty <br />
      przygotowanej specjalnie <br />
      dla Ciebie.
    </ManualBoxPoint>
    <ManualBoxPoint number={2}>
      <strong className="text-primary">Zaloguj się </strong>
      lub uzupełnij dane,
      <br /> jeśli jesteś nowym klientem.
    </ManualBoxPoint>
    <ManualBoxPoint number={3}>
      <strong className="text-primary">Zaakceptuj</strong> regulaminy <br /> i złóż zamówienie.
    </ManualBoxPoint>
    <ManualBoxPoint number={4}>
      <strong className="text-primary">Opłać </strong>
      zamówienie, <br />a otrzymasz vouchery.
    </ManualBoxPoint>
  </ManualBox>
)
