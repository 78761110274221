import { motion } from 'framer-motion'
import * as React from 'react'
import { useRodoAgreementFormSessionTimeout } from '@modules/rodo-agreements/use-rodo-agreement-form-session-timeout'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  clicked: boolean
  onBack: () => void
}

export const RodoAgreementsFormPageBackButton = ({ clicked, onBack }: Props): JSX.Element => {
  const { refresh, timeLeft } = useRodoAgreementFormSessionTimeout(onBack, clicked)

  return (
    <motion.div
      className="rodo-agreements__back-button__wrapper d-sm-block d-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: clicked ? 1 : 0 }}
    >
      <motion.button
        className="rodo-agreements__back-button"
        whileTap={{ scale: 0.95 }}
        initial={{ left: 0, opacity: 1 }}
        animate={{ left: clicked ? -63 : 0, opacity: clicked ? 1 : 0 }}
        onClick={onBack}
      >
        <i className="uil-arrow-left text-white" />
      </motion.button>
      {timeLeft && (
        <div className="rodo-agreements__back-button__counter">
          <span>Formularz zniknie za {timeLeft}s</span>
          <motion.button className="btn-unstyled" whileTap={{ scale: 0.95 }} onClick={refresh}>
            <IconWithText text="przedłuż sesję" iconClassName="uil-refresh me-2 font-xxl" className="text-dark ms-4" />
          </motion.button>
        </div>
      )}
    </motion.div>
  )
}
