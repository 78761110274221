import * as React from 'react'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { GastroCardDetails } from '@modules/gastro-card/models'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { GastroCardContent } from '@modules/gastro-card/details/gastro-card-content'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { FormProvider, useForm } from 'react-hook-form'
import { GastroCardHeaderSectionContent } from '@modules/gastro-card/details/gastro-card-header-section-content'
import { GastroCardAgreements } from '@modules/gastro-card/details/gastro-card-agreements'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { GastroCardSideCard } from '@modules/gastro-card/details/gastro-card-side-card'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { useProductDetails } from '@hooks/use-product-details'

interface GastroCardDetailsFormInputs extends Agreements {
  gastro_voucher_rules: boolean
}

export const GastroCardDetailsView = (): JSX.Element => {
  const { showPaymentSelectionView } = usePaymentSelection()
  const { gastro_card } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails: gastroCardDetails } = useProductDetails<GastroCardDetails>(gastro_card.details)

  const paymentSectionRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<GastroCardDetailsFormInputs>({ reValidateMode: 'onChange' })

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (gastroCardDetails) {
      methods.clearErrors()
      await commonObjectPost<GastroCardDetails>(gastroCardDetails.urls.rules, payload)
      showPaymentSelectionView(gastroCardDetails.token, ProductKinds.GASTRO_CARD)
    }
  }, methods.setError)

  const handleScrollToPayment = () => {
    paymentSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <PageWrapper>
      <AppHeaderSection images={[require('@assets/images/header-gastro.png')]} imageDescription="gastro voucher">
        <GastroCardHeaderSectionContent />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              {gastroCardDetails && (
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <GastroCardContent onScrollToPayment={handleScrollToPayment} gastroCardDetails={gastroCardDetails} />
                  {!gastroCardDetails?.is_paid && (
                    <>
                      <hr className="mx-n5" />
                      <GastroCardAgreements gastroCardDetails={gastroCardDetails} />
                      <PaymentActionRow
                        isPaymentDisabled={gastroCardDetails?.is_paid}
                        ref={paymentSectionRef}
                        isLoading={isSaving}
                        className="mt-4"
                        amount={gastroCardDetails?.declared_sell_price || ''}
                      />
                    </>
                  )}
                </form>
              )}
            </FormProvider>
          </div>
          <GastroCardSideCard />
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
