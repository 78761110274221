import * as React from 'react'

interface Props {
  href: string
}

export const OfferDetailsHeaderButton = ({ href }: Props): JSX.Element => (
  <a
    href={href}
    target="_blank"
    className="text-decoration-none mt-5 d-inline-block package-wholesale__details-button__wrapper"
  >
    <button className="btn package-wholesale__details-button">
      <img src={require('@assets/images/adobe-acrobat.svg')} alt="Szczegóły oferty" />
      <strong className="text-white">Szczegóły oferty</strong>
    </button>
  </a>
)
