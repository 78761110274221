import { motion } from 'framer-motion'
import * as React from 'react'
import { useTimeCount } from '@hooks/use-time-count'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ContentLoader } from '@components/content-loader'
import { useAcquiringPointDetails } from '@modules/rodo-agreements/use-acquiring-point-details'

const SECONDS_TO_CLOSE = 30

type Params = 'slug'

export const RodoAgreementsSuccessView = (): React.ReactNode => {
  const { slug } = useParams<Params>()
  const { elapsedTime } = useTimeCount()
  const navigate = useNavigate()

  const { isLoading, rodoAcquiringPoint } = useAcquiringPointDetails(slug)

  if (elapsedTime >= SECONDS_TO_CLOSE && slug) {
    navigate(NavigationPath.RodoAgreements.replace(':slug', slug))
  }

  return (
    <ContentLoader isLoading={isLoading}>
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <div className="d-flex flex-sm-row flex-column text-nowrap rodo-agreements__description-section container">
          <img
            src={rodoAcquiringPoint?.logo}
            alt="holidaypark logo"
            className="d-block d-sm-none mt-3 mb-3 mx-auto"
            height={40}
            style={{ objectFit: 'contain' }}
          />
          <div className=" col-sm-6 col-12 order-1 order-sm-0 px-3 px-sm-0">
            <div className="ps-sm-5 d-flex flex-column justify-content-between align-items-sm-start align-items-center pb-5 h-100 w-100">
              <img
                src={rodoAcquiringPoint?.logo}
                alt="holidaypark logo"
                className="rodo-agreements__description-section__icon d-sm-block d-none mb-sm-5 mx-auto mx-sm-0"
              />

              <h2 className="rodo-agreements__description-section__title mb-0 lh-1 text-center text-sm-start mt-4 mt-sm-5">
                <span style={{ color: rodoAcquiringPoint?.color_primary }}>
                  Dziękujemy za <br /> wypełnienie <br /> formularza.{' '}
                </span>
                <div
                  className="mt-5"
                  style={{ color: rodoAcquiringPoint?.color_secondary }}
                  dangerouslySetInnerHTML={{ __html: rodoAcquiringPoint?.thank_you_page_text_1 ?? '' }}
                />
              </h2>
              <p className="font-xxl text-darker-gray lh-initial my-5">
                Powrót do ekranu startowego za {SECONDS_TO_CLOSE - elapsedTime} sek.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-12 order-sm-1 order-0 mt-sm-5">
            <img
              src={rodoAcquiringPoint?.image}
              alt="Vouchery Holiday Park & Resort"
              className="rodo-agreements__description-section__image px-3"
            />
          </div>
        </div>
      </motion.div>
    </ContentLoader>
  )
}

const variants = {
  hidden: { marginLeft: -350, opacity: 0 },
  visible: { marginLeft: 0, opacity: 1 },
}
