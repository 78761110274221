import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import * as clsx from 'clsx'
import { ElementRenderer } from '@components/element-renderer'

interface Props {
  isPaid: boolean
  onLabelClick?: () => void
  className?: string
}

export const ProductStatusLabel = ({ isPaid, onLabelClick, className }: Props): JSX.Element => (
  <ElementRenderer
    renderAs={onLabelClick ? 'button' : 'div'}
    className={clsx('reservation-details__payment-status px-2 btn-unstyled', isPaid ? 'is-paid' : 'to-pay', className, {
      'cursor-pointer': onLabelClick,
    })}
    onClick={onLabelClick}
  >
    <IconWithText
      textClassName="font-sm font-500"
      text={isPaid ? 'opłacona' : 'do opłacenia'}
      iconClassName={clsx({ 'me-2 font-xl lh-initial uil-check': isPaid })}
    />
  </ElementRenderer>
)
