import * as React from 'react'
import { ModalConfig, ModalParams, ModalTypes } from '@components/modals/types'
import { useDispatch, useSelector } from 'react-redux'
import { findModalSelector, setModalIsOpen, setShowModal } from '@store/slices/modal-slice'
import { RootState } from '@store/index'

type Response = [(event?, optionalParams?: ModalParams, showModal?: boolean) => void, () => void]

export const useModal = (modalName: ModalTypes, modalParams?: ModalParams, config?: ModalConfig): Response => {
  const dispatch = useDispatch()
  const hasModal = !!useSelector((state: RootState) => findModalSelector(state, modalName))

  React.useEffect(() => {
    return () => {
      config?.persist ? null : dispatch(setModalIsOpen({ modal: modalName, isOpen: false }))
    }
  }, [])

  const toggleIsOpen = React.useCallback(
    (event?, optionalParams?: ModalParams, showModal?: boolean) => {
      if (hasModal && !showModal) {
        return hideModal()
      } else {
        return dispatch(
          setShowModal({ modal: modalName, params: { ...optionalParams, ...modalParams }, isOpen: true, config }),
        )
      }
    },
    [modalName, hasModal, modalParams],
  )

  const hideModal = React.useCallback(() => dispatch(setModalIsOpen({ modal: modalName, isOpen: false })), [modalName])

  return [toggleIsOpen, hideModal]
}
