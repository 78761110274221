import * as React from 'react'
import { SideCard } from '@components/side-card'
import { EndlessHolidayConfirmationDetails } from '@modules/product-package/models'
import { ExpirationTimeLabel } from '@components/expiration-time-label'
import { endOfDay, isAfter } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
  isPaid: boolean
}

export const EndlessHolidayConfirmationSideCard = ({ productDetails, isPaid }: Props): React.ReactNode => {
  const isAfterOfferChange =
    !productDetails.increase_price_date || isAfter(new Date(), parseISODate(productDetails.increase_price_date))

  return (
    <SideCard
      className="d-lg-block d-none"
      image={
        <img
          src={require('@assets/images/resorts/cieplice.jpg')}
          alt="Holiday Park & Resort"
          width="100%"
          height={300}
          className="subscription-variant__price-side-card__image"
        />
      }
    >
      {isAfterOfferChange
        ? productDetails.required_payment_date &&
          !isPaid && (
            <ExpirationTimeLabel
              expirationDate={endOfDay(parseISODate(productDetails.required_payment_date)).toISOString()}
              messageText={<>pozostało na opłacenie zamówienia {productDetails.number}</>}
              descriptionText="Po tym czasie zamówienie zostanie anulowane."
            />
          )
        : productDetails.increase_price_date && (
            <ExpirationTimeLabel
              expirationDate={productDetails.increase_price_date}
              messageText={<>pozostało do wykorzystania korzystniejszej oferty</>}
              descriptionText="Po tym czasie ceny zostaną podwyższone."
            />
          )}
    </SideCard>
  )
}
