import * as React from 'react'
import { PackageVipDetails, VipImprovement } from '@modules/vip/models'
import { CircleCheck } from '@components/circle-check'
import { VipContentBase } from '@modules/vip/details/vip-content-base'

interface Props {
  packageVipDetails: PackageVipDetails
  onScrollToPayment: () => void
}

export const VipWinterContent = React.forwardRef(
  ({ packageVipDetails, onScrollToPayment }: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const winterSnowImageRef = React.useRef<HTMLImageElement | null>(null)

    return (
      <VipContentBase
        improvementsTitle="Co zyskujesz kupując zimowy pakiet VIP?"
        packageVipDetails={packageVipDetails}
        onScrollToPayment={onScrollToPayment}
        titleClassName="mt-4"
        fineText="usługa dostępna wyłącznie w Mielnie i Ustroniu Morskim - w okresie otwarcia ośrodków"
        ref={ref}
      >
        <img
          src={require('@assets/images/snow_layer.svg')}
          className="snow-layer"
          alt="Pakiet zimowy"
          ref={winterSnowImageRef}
        />
        {vipImprovements.map((improvement: VipImprovement) => (
          <CircleCheck key={improvement.title}>
            <span className="font-md lh-initial">
              <span className="text-muted">{improvement.title} </span>
              {!!improvement.discount && <span className="text-primary">{improvement.discount}</span>}
            </span>
          </CircleCheck>
        ))}
      </VipContentBase>
    )
  },
)

const vipImprovements: VipImprovement[] = [
  { title: 'Bezpłatna możliwość wyboru lokalu' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Wynajem rowerów (10 wypożyczeń, w miarę dostępności)' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce – banany, pomarańcze, owoce sezonowe' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Ręczniki VIP' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Strefa VIP w kawiarni (w miarę dostępności)' },
  { title: '2 miejsca parkingowe VIP (w miarę dostępności)' },
  { title: 'Loża VIP w kawiarni (rabat 50%, raz na pobyt)' },
  { title: 'Rabat -50% w bistro HPR przez pierwsze 1,5h od otwarcia (w miarę dostępności, max. 150 zł)' },
  { title: 'Rabat -20% w sklepiku HPR (max. 300 zł)' },
  { title: 'Recepcja VIP poza standardową kolejką*' },
]
