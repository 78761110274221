import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { GastroProductKind, GastroProduct } from '@modules/gastro-generic/models'
import { PackageWholesaleProductKind } from '@modules/package-wholesale/models'

export const useGastroProductProvider = (
  kind: GastroProductKind | PackageWholesaleProductKind | undefined,
): GastroProduct | null => {
  const appData = useSelector(selectAppData)
  if (kind === 'gastro_card') {
    kind = 'normal'
  }

  return kind ? appData.gastro_products[kind] : null
}
