import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useWebsocketActions } from '@components/web-socket-handler/use-websocket-actions'
import { setWebSocketData } from '@store/actions/websocket-actions'

const protocol = document.location.protocol === 'http:' ? 'ws' : 'wss'
const port = document.location.port ? `:${document.location.port}` : ''

export const socketUrl = `${protocol}://${document.location.hostname}${port}/ws/website/`

export const WebSocketHandler = (): null => {
  const dispatch = useDispatch()
  const { openHandler } = useWebsocketActions()

  React.useEffect(() => {
    const webSocket = connect()

    return () => webSocket?.close()
  }, [])

  const connect = () => {
    const webSocket = new WebSocket(socketUrl)

    webSocket.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data)
      if (data.event) {
        dispatch(setWebSocketData(data.event))
      }
    }

    webSocket.onclose = () => {
      setTimeout(connect, 3000)
    }

    webSocket.addEventListener('open', openHandler(webSocket))
    return webSocket
  }

  return null
}
