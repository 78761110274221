import { GastroGenericProductDetails, GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatToWordsWithUppercaseLetterStart } from '@helpers/utils'
import * as React from 'react'

export const getGastroCard2025Data = (
  productDetails: GastroGenericProductDetails,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const isSubscriptionOffer = productDetails.tags.includes('subscription_offer')

  const labelImageSrc = 'gastro-voucher-label.jpg'

  const capitalizedProductName = formatToWordsWithUppercaseLetterStart(gastroProduct.product_name)

  const productName = isSubscriptionOffer ? `${capitalizedProductName} dla Subskrybentów` : capitalizedProductName

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher “{productName}”</strong>do
      wykorzystania w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w Resortach.
    </span>
  )

  const benefitsPoints = isSubscriptionOffer
    ? [
        'Dzięki aktywnej Subskrypcji, zakup vouchera umożliwia dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o 100%',
        'Voucher jest wielokrotnego użytku, w różnych rezerwacjach.',
        'Ilość voucherów jakie możesz kupić jest nieograniczona!',
      ]
    : [
        'Zakup vouchera umożliwia dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o 90%.',
        'Voucher jest wielokrotnego użytku, w różnych rezerwacjach.',
        'Ilość voucherów jakie możesz kupić jest nieograniczona!',
      ]

  const benefitsList = [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2025 - 31.12.2025',
  ]

  const alertText =
    'Voucher nie łączy się z promocją Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Podwójna Radość 2025 można podpiąć kolejny dowolny voucher gastronomiczny.'

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
