import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  activeStep: number
}

export const HolidayBenefitInvitationSteps = ({ activeStep }: Props): JSX.Element => {
  const isSecondStepActive = activeStep === 2

  return (
    <div className="holiday-benefit__invitation__steps">
      <Step
        activeStep={activeStep}
        stepNumber={1}
        wrapperClassName="holiday-benefit__invitation__steps__first"
        circleClassName={clsx({ 'is-completed': isSecondStepActive })}
        text="Wybór oferty"
        showText={!isSecondStepActive}
      />

      <Step
        activeStep={activeStep}
        stepNumber={2}
        text="Uzupełnij informacje"
        showText={isSecondStepActive}
        circleClassName={clsx({ 'is-inactive': !isSecondStepActive })}
      />
    </div>
  )
}

interface StepProps {
  activeStep: number
  stepNumber: number
  text: string
  wrapperClassName?: string
  circleClassName: string
  showText: boolean
}

const Step = ({ activeStep, stepNumber, text, wrapperClassName, circleClassName, showText }: StepProps) => (
  <div className={clsx('d-flex align-items-center gap-3', wrapperClassName)}>
    <strong className={clsx('holiday-benefit__invitation__steps__circle', circleClassName)}>
      {activeStep === stepNumber ? <i className="uil-check font-xxl" /> : stepNumber}
    </strong>
    {showText && <span className="fw-semibold text-holiday-benefit-primary">{text}</span>}
  </div>
)
