import * as React from 'react'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { SubscriptionDetails } from '@modules/subscription/models'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { SubscriptionDetailsHeaderSectionContent } from '@modules/subscription/details/subscription-details-header-section-content'
import { SubscriptionDetailsContent } from '@modules/subscription/details/subscription-details-content'
import { SubscriptionCardSideCard } from '@modules/subscription/details/subscription-card-side-card'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { ResortImages } from '@modules/package-wholesale/details'
import { useSubscriptionProductData } from '@modules/subscription/use-subscription-product-data'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useProductDetails } from '@hooks/use-product-details'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { shouldCallRulesCheck } from '@helpers/rules'

interface FormInputs extends Agreements {
  subscription_rules: boolean
}

export const SubscriptionDetailsView = (): JSX.Element => {
  const paymentSectionRef = React.useRef<HTMLDivElement>(null)
  const { scrollTo } = useScrollIntoView()

  const { subscription } = useSelector(selectAppDataUrls)

  const {
    productDetails: subscriptionDetails,
    token,
    PageWrapper,
  } = useProductDetails<SubscriptionDetails>(subscription.details)

  const methods = useForm<FormInputs>({ reValidateMode: 'onChange' })

  const { showPaymentSelectionView } = usePaymentSelection()

  const { expiration, staysCount, expirationPrefix, accommodationTypeName, accommodationType } =
    useSubscriptionProductData(subscriptionDetails)

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!subscriptionDetails || !token) return

    if (shouldCallRulesCheck(subscriptionDetails.rules)) {
      await commonObjectPost(subscriptionDetails?.urls.rules, payload)
    }

    showPaymentSelectionView(token, ProductKinds.SUBSCRIPTIONS)
  }, methods.setError)

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="pakiety prywatne">
        <SubscriptionDetailsHeaderSectionContent
          staysCount={staysCount}
          defaultsDays={subscriptionDetails?.defaults_days || 0}
          accommodationType={accommodationType}
          accommodationTypeName={accommodationTypeName}
          expiration={`${expirationPrefix} ${expiration}`}
        />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {subscriptionDetails && (
                  <SubscriptionDetailsContent
                    onScrollToPayment={scrollTo(paymentSectionRef)}
                    maxGuests={accommodationType?.display_max_guests ?? 0}
                    staysCount={staysCount}
                    subscriptionDetails={subscriptionDetails}
                    accommodationTypeName={accommodationTypeName}
                    expiration={`${expirationPrefix} ${expiration}`}
                  />
                )}
                <hr className="mx-n5" />
                {subscriptionDetails && !subscriptionDetails?.is_paid && (
                  <DynamicAgreementsSection rules={subscriptionDetails.rules} />
                )}
                <PaymentActionRow
                  isPaymentDisabled={subscriptionDetails?.is_paid}
                  ref={paymentSectionRef}
                  isLoading={isSaving}
                  className="mt-4"
                  amount={subscriptionDetails?.final_brutto || '0'}
                />
              </form>
            </FormProvider>
          </div>
          <SubscriptionCardSideCard />
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
