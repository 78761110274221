import { ProductKinds } from '@components/payments/payment-selection-view'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { Seller } from '@models/base'
import { useSubscriptionProductGtmEvents } from '@modules/subscription-with-benefit/use-subscription-with-benefit-gtm-events'
import { useSubscriptionContractGtmEvents } from '@modules/subscription-contract/use-subscription-contract-gtm-events'
import * as React from 'react'
import { useCodesConversionGtmEvents } from '@modules/codes-conversion/use-codes-conversion-gtm-events'
import { CodeOption } from '@modules/codes-conversion/models'

export const useGtmEcommerceBeginCheckoutGtmEvent = (
  step: number,
  kind: string | undefined,
  productDetails: SubscriptionDetails | SubscriptionContractDetails | CodeOption | undefined,
): void => {
  const { sendBeginCheckoutEvent: sendSubscriptionProductBeginCheckoutEvent } = useSubscriptionProductGtmEvents()
  const { sendBeginCheckoutEvent: sendSubscriptionContractBeginCheckoutEvent } = useSubscriptionContractGtmEvents()
  const { sendBeginCheckoutEvent: sendCodesConversionBeginCheckoutEvent } = useCodesConversionGtmEvents()

  const sendBeginCheckoutEvent = () => {
    if (productDetails && kind === ProductKinds.CODES_CONVERSION) {
      sendCodesConversionBeginCheckoutEvent(step, productDetails as CodeOption)
    }

    if ((productDetails as { seller: Seller | null }).seller?.department !== 'ecommerce') return

    if (
      [ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS, ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS_20].includes(
        kind as ProductKinds,
      )
    ) {
      sendSubscriptionProductBeginCheckoutEvent(step, productDetails as SubscriptionDetails, 'pakiet-korzysci')
    }

    if (kind === ProductKinds.GO_HOLIDAY) {
      sendSubscriptionProductBeginCheckoutEvent(step, productDetails as SubscriptionDetails, 'go-holiday')
    }

    if (kind === ProductKinds.SUBSCRIPTION_CONTRACT) {
      sendSubscriptionContractBeginCheckoutEvent(step, productDetails as SubscriptionContractDetails)
    }
  }

  React.useEffect(() => {
    if (kind && productDetails) sendBeginCheckoutEvent()
  }, [productDetails])
}
