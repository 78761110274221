import * as React from 'react'
import * as clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { PackageWholesaleGastroCardVariant } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-variant'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { asDecimal } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { CustomAmount } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-cards-section'
import { GastroProduct } from '@modules/gastro-generic/models'

const MAXIMUM_PRICE = 10000

interface Props {
  isActive: boolean
  onCardClick: () => void
  inputName: CustomAmount
  gastroProduct: GastroProduct | null
}

export const PackageWholesaleGastroCardVariantCustom = ({
  isActive,
  onCardClick,
  inputName,
  gastroProduct,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext<PackageWholesaleFormInput>()

  const customPrice = useWatch({ control, name: inputName })
  const multipliedPrice = asDecimal(customPrice)
    .mul(gastroProduct?.multiplier || 1)
    .toString()
  const minimal_price = Number(gastroProduct?.minimal_value)

  const handleInputClick = e => {
    e.stopPropagation()
  }

  const handleBlur = () => {
    if (customPrice < minimal_price) {
      setValue(inputName, minimal_price)
    }
  }

  React.useEffect(() => {
    if (customPrice > MAXIMUM_PRICE) {
      setValue(inputName, MAXIMUM_PRICE)
    }
  }, [customPrice])

  return (
    <PackageWholesaleGastroCardVariant
      isActive={isActive}
      onCardClick={onCardClick}
      title={
        <span>
          Voucher {gastroProduct?.product_name} - wpisz własną kwotę
          <span className="font-sm d-block fw-normal">
            Minimalna kwota zakupu to {formatPriceShort(minimal_price)}.
          </span>
        </span>
      }
    >
      <div className="package-wholesale__gastro-card__values">
        <div className="text-white package-wholesale__gastro-card__amount-paid">
          <p className="mb-1 font-sm">Wpłacana kwota:</p>
          <CustomFormGroup
            inputName={inputName}
            formControlProps={{
              defaultValue: minimal_price,
              className: 'package-wholesale__gastro-card__price-custom',
              type: 'number',
              onClick: handleInputClick,
              onBlur: handleBlur,
            }}
          />
        </div>
        <div className={clsx('cursor-pointer package-wholesale__gastro-card__bonus', { 'is-active': isActive })}>
          <img
            src={require('@assets/images/gastr-coupon-bg.svg')}
            alt="Kupon gastronomiczny"
            className="package-wholesale__gastro-card__bonus__bg"
          />
          <div>
            <strong className="font-xxxl">=</strong>
          </div>
        </div>
        <div className="text-darker-gray package-wholesale__gastro-card__amount-final">
          <p className="mb-1 font-sm">Wartość vouchera:</p>
          <strong className="font-xxl text-end d-block">{formatPriceShort(multipliedPrice)}</strong>
        </div>
      </div>
    </PackageWholesaleGastroCardVariant>
  )
}
