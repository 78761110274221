import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { IconWithText } from '@components/icon-with-text'
import { declination } from '@helpers/declination'
import { AccommodationType } from '@models/app-data'

interface Props {
  staysCount: number
  defaultsDays: number
  accommodationType: AccommodationType | undefined
  accommodationTypeName: string | undefined
  expiration: string
}

export const SubscriptionDetailsHeaderSectionContent = ({
  staysCount,
  defaultsDays,
  accommodationType,
  accommodationTypeName,
  expiration,
}: Props): JSX.Element => (
  <AppHeaderContent
    title={`Potwierdzenie zakupu pakietu ${staysCount} ${declination.stayDays(staysCount)} w ${accommodationTypeName}`}
    subtitle={
      <span className="font-lg">
        Pakiet obejmuje {staysCount} {declination.stayDays(staysCount)} dla {accommodationType?.display_max_guests}{' '}
        {declination.person(accommodationType?.display_max_guests ?? 0)} w {accommodationTypeName || ''}. Każdy z
        pobytów możesz wykorzystać osobiście lub przekazać w prezencie.
      </span>
    }
  >
    <div className="mt-4 d-flex flex-wrap col-sm-12 col-10 mx-auto">
      <IconWithText
        text={`${staysCount} pobytów ${defaultsDays + 1}-dniowych dla ${accommodationType?.display_max_guests} osób`}
        iconClassName="uil-check font-xxxl me-2"
        className="text-primary fw-bold me-5"
      />
      <IconWithText
        text="pobyt nawet -80% taniej"
        iconClassName="uil-check font-xxxl me-2"
        className="text-primary fw-bold"
      />
      <IconWithText
        text={`do wykorzystania ${expiration}`}
        iconClassName="uil-check font-xxxl me-2"
        className="text-primary fw-bold w-100"
      />
    </div>
  </AppHeaderContent>
)
