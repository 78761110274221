import * as React from 'react'
import QRCode from 'qrcode'
import * as clsx from 'clsx'

interface Props {
  text: string
  className?: string
}

export const QrCodeBox = ({ text, className }: Props): JSX.Element => {
  const [qrCodeUrl, setQrCodeUrl] = React.useState('')

  React.useEffect(() => {
    QRCode.toDataURL(text, { errorCorrectionLevel: 'high', width: 170 }, (_, url) => {
      setQrCodeUrl(url)
    })
  }, [text])

  return (
    <div className={clsx(className)}>
      {qrCodeUrl && <img className="rounded" src={qrCodeUrl} alt={`Kod ${text}`} />}
    </div>
  )
}
