import * as React from 'react'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { SubscriptionDetails } from '@modules/subscription/models'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm } from 'react-hook-form'
import { AppFooter } from '@components/app-footer'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { ResortImages } from '@modules/package-wholesale/details'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useProductDetails } from '@hooks/use-product-details'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { SubscriptionBookingImprovementHeaderSectionContent } from '@modules/subscription-booking-improvement/subscription-booking-improvement-header-section-content'
import { SubscriptionBookingImprovementContent } from '@modules/subscription-booking-improvement/subscription-booking-improvement-content'
import { SubscriptionBookingImprovementSideCard } from '@modules/subscription-booking-improvement/subscription-booking-improvement-side-card'
import { isEachRequiredRuleAccepted } from '@helpers/rules'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { SubmitActionRow } from '@components/submit-action-row'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'

interface FormInputs extends Agreements {
  subscription_rules: boolean
}

export const SubscriptionBookingImprovementView = (): JSX.Element => {
  const contentSectionRef = React.useRef<HTMLDivElement>(null)
  const { scrollTo } = useScrollIntoView()

  const { showPaymentSelectionView } = usePaymentSelection()
  const navigate = useNavigate()

  const { subscription } = useSelector(selectAppDataUrls)

  const {
    productDetails: subscriptionDetails,
    token,
    PageWrapper,
  } = useProductDetails<SubscriptionDetails>(subscription.details)

  const methods = useForm<FormInputs>({ reValidateMode: 'onChange' })

  const isBookingOwner = subscriptionDetails?.related_booking?.client_id === subscriptionDetails?.client.id
  const rulesAccepted = isEachRequiredRuleAccepted(subscriptionDetails?.rules ?? [])

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (!subscriptionDetails || !token) return

    if (!rulesAccepted) {
      await commonObjectPost(subscriptionDetails?.urls.rules, payload)
    }

    if (subscriptionDetails.is_paid || isBookingOwner) {
      navigate(NavigationPath.OperationSuccess.replace(':token', token).replace(':kind', 'holiday-start'))
    } else {
      showPaymentSelectionView(token, ProductKinds.GO_HOLIDAY)
    }
  }, methods.setError)

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="voucher">
        <SubscriptionBookingImprovementHeaderSectionContent onScrollToDetails={scrollTo(contentSectionRef)} />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {subscriptionDetails && (
                  <>
                    <SubscriptionBookingImprovementContent
                      isBookingOwner={isBookingOwner}
                      ref={contentSectionRef}
                      subscriptionDetails={subscriptionDetails}
                    />

                    <hr className="mx-n5" />
                    {(!rulesAccepted || !isBookingOwner) && (
                      <DynamicAgreementsSection rules={subscriptionDetails?.rules} />
                    )}

                    {subscriptionDetails.is_paid || isBookingOwner ? (
                      <SubmitActionRow
                        isLoading={isSaving}
                        disabled={(isBookingOwner && rulesAccepted) || subscriptionDetails.is_paid}
                      />
                    ) : (
                      <PaymentActionRow
                        isLoading={isSaving}
                        amount={subscriptionDetails.final_brutto}
                        className="mt-4"
                        isPaymentDisabled={subscriptionDetails.is_paid}
                      />
                    )}
                  </>
                )}
              </form>
            </FormProvider>
          </div>
          {subscriptionDetails && (
            <SubscriptionBookingImprovementSideCard relatedBooking={subscriptionDetails?.related_booking} />
          )}
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
