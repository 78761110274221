import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { IconWithText } from '@components/icon-with-text'
import { declination, yearsVariation } from '@helpers/declination'

interface Props {
  staysCount: number
  expireYears: number
  accommodationTypeName: string | undefined
  onScrollToDetails: () => void
}

export const GoHolidayDetailsHeaderSectionContent = ({
  staysCount,
  accommodationTypeName,
  onScrollToDetails,
  expireYears,
}: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <span>
        Pakiet {staysCount} {declination.stayDays(staysCount)} w {accommodationTypeName}{' '}
        <span className="text-nowrap"> w GO!Holiday</span>
      </span>
    }
    subtitle={
      <span className="font-lg">
        Skorzystaj z oferty Pakietów Pobytów do wykorzystania w ciągu{' '}
        <strong>
          {expireYears} {yearsVariation(expireYears)}
        </strong>{' '}
        we wszystkich dostępnych resortach <strong className="text-nowrap">Holiday Park & Resort</strong>.
      </span>
    }
  >
    <button
      className="btn btn-unstyled mt-5 d-flex justify-content-center justify-content-sm-start w-100 ps-0"
      onClick={onScrollToDetails}
    >
      <IconWithText
        text="Szczegóły oferty"
        iconClassName="uil-plus me-2 font-xxl"
        className="text-primary fw-bold"
        textClassName="font-xl"
      />
    </button>
  </AppHeaderContent>
)
