import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  amount?: number
  name: React.ReactNode
  title?: string
  wrapperClassName?: string
  price: string | number
  children?: React.ReactNode
}

export const PackageWholesaleSideCardPricesRow = ({
  amount,
  title,
  price,
  children,
  name,
  wrapperClassName,
}: Props): JSX.Element => (
  <li className={wrapperClassName}>
    <div className="d-flex justify-content-between align-items-center mt-1 px-3">
      <span>
        {title && <span className="d-block font-sm opacity-75">{title}</span>}
        <span className="font-500">
          {amount && `${amount}x `}
          {name}
        </span>
      </span>
      <span className="col-4 text-end font-500">{formatPrice(price)}</span>
    </div>
    {children}
  </li>
)
