import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { ClientDetails } from '@models/client'

export interface ClientState {
  clientDetails: ClientDetails | null
}

const initialState: ClientState = {
  clientDetails: null,
}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientDetails(state: ClientState, action: PayloadAction<ClientDetails | null>) {
      state.clientDetails = action.payload
    },
  },
})

export const { setClientDetails } = clientSlice.actions

export const selectClientDetails = (state: RootState): ClientDetails | null => state.clientState.clientDetails

export default clientSlice.reducer
