import * as React from 'react'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'
import { SubscriptionDetails } from '@modules/subscription/models'
import { formatPriceShort } from '@helpers/price-helper'

interface Props {
  isCollapsable?: boolean
  subscriptionDetails: SubscriptionDetails
}

export const GoHolidayDetailsContentInformation = ({ isCollapsable, subscriptionDetails }: Props): JSX.Element => (
  <PackageWholesaleInformationWrapper
    isCollapsable={isCollapsable}
    bottomElementClassName="d-flex flex-wrap"
    bottomElement={
      <div className="ms-sm-5 d-flex align-items-center justify-content-center fw-semibold col-12 col-sm-auto mt-3 mt-sm-0">
        Cena pakietu:{' '}
        <strong className="font-xxl text-primary ps-4">
          {formatPriceShort(subscriptionDetails.product.price_brutto)}
        </strong>
      </div>
    }
  >
    <p>
      Pobyty z Pakietu można realizować przez {subscriptionDetails.product.default_expire_years} lat w terminach{' '}
      <strong>od 01.01 do 31.05 oraz od 01.10 do 31.12</strong> z wyłączeniem Terminów Specjalnych, we wszystkich
      dostępnych Resortach Holiday Park & Resort.
    </p>
    <p>
      Istnieje również możliwość odbycia krótszego pobytu (3 doby) w terminach od 25.08 do 28.08 lub od 28.08 do 31.08 z
      wykorzystaniem całego kodu.
    </p>
    <p>
      Każdy voucher (kod) jest możliwy do wykorzystania w dowolnym roku (w ramach terminu ważności pakietu), przy czym
      można odbyć maksymalnie jeden pobyt w danym roku kalendarzowym.
    </p>
    <p>
      Rezerwacji dokonuje się samodzielnie poprzez stronę{' '}
      <a className="fw-bold text-decoration-none text-nowrap" href="https://rezerwuj.holidaypark.pl/" target="_blank">
        rezerwuj.holidaypark.pl
      </a>{' '}
      lub telefonicznie z pomocą Biura Obsługi Klienta:{' '}
      <a href="tel:735 200 800" className="fw-semibold text-decoration-none text-nowrap">
        tel:735 200 800
      </a>
      .
    </p>
    <p>
      Przy zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie
      doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić
      opłatę z tytułu zużytych mediów. Wysokość opłat została wskazana w Cenniku oraz w Regulaminie Głównym Holiday Park
      & Resort. Do każdego pobytu zostanie doliczona kwota zabezpieczenia na poczet kaucji zwrotnej w wysokości 750 zł,
      płatna do 12 miesięcy przed przyjazdem.
    </p>
    <p>Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
  </PackageWholesaleInformationWrapper>
)
