import { CardContentBase } from '@components/card-content-base'
import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { CircleCheck } from '@components/circle-check'
import { formatPriceShort } from '@helpers/price-helper'
import { Alert } from '@components/alert'
import { SubscriptionBookingImprovementAcceptanceDate } from '@modules/subscription-booking-improvement/subscription-booking-improvement-acceptance-date'

interface Props {
  subscriptionDetails: SubscriptionDetails
  isBookingOwner: boolean
}

export const SubscriptionBookingImprovementContent = React.forwardRef(
  ({ subscriptionDetails, isBookingOwner }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    const profits = [
      '6-dniowy pobyt w domku lub apartamencie w ramach jednego kodu',
      'Data ważności: 3 lata od dnia wymeldowania',
      'do wykorzystania w każdym resorcie od 01.01.2025 roku',
      <span key="dates">
        termin realizacji 01.10 - 31.05 <span className="text-nowrap">(z wyłączeniem Terminów Specjalnych)</span>
      </span>,
    ]

    const totalVoucherAmount = subscriptionDetails.local_mountains + subscriptionDetails.local_sea

    return (
      <div ref={ref}>
        <CardContentBase
          title={
            <strong className="text-secondary font-xxl text-center text-sm-start d-block">
              Potwierdzenie <span className="underline-shadow is-gold text-primary">zakupu vouchera</span>
            </strong>
          }
          client={subscriptionDetails.client}
          seller={subscriptionDetails.seller}
          created={subscriptionDetails.created || ''}
          productInvoice={subscriptionDetails}
        >
          <SubscriptionBookingImprovementAcceptanceDate subscriptionDetails={subscriptionDetails} />
        </CardContentBase>
        <hr className="mx-n5" />
        <div>
          <h5 className="text-secondary font-xxl text-center text-sm-start fw-bold">Twój pakiet „Super Start”</h5>
          <div className="d-flex align-items-center mt-3">
            <strong className="text-muted pe-2">Zamówionych produktów:</strong>
            <strong className="font-lg text-primary">{totalVoucherAmount}x voucher</strong>
          </div>
          <div className="d-flex align-items-center">
            <strong className="text-muted pe-2">Łączna kwota do zapłaty:</strong>
            <strong className="font-lg text-primary">{formatPriceShort(subscriptionDetails.final_brutto)}</strong>
          </div>
          <strong className="text-darker-gray d-block mt-3">Cechy produktu:</strong>
          <div className="mt-3 row">
            {profits.map((profit, index) => (
              <CircleCheck key={index} className="col-12 col-md-6 mb-2">
                <span className="font-md lh-initial col-12 col-md-7">
                  <span className="text-muted">{profit}</span>
                </span>
              </CircleCheck>
            ))}
          </div>
          <Alert
            variant="info"
            icon="uil-exclamation-circle font-xl align-self-start"
            className="my-4 text-darker-gray p-2 font-md p-3 col-md-11 col-12"
          >
            Aby zakupić voucher “Super Start”, należy zaakceptować poniższe regulaminy i zasady promocji.
            {isBookingOwner ? ' Opłata zostanie pobrana podczas wymeldowania obecnie trwającej rezerwacji.' : ''} Kody
            promocyjne zostaną przesłane w osobnej wiadomości po sfinalizowaniu transakcji.
          </Alert>
        </div>
      </div>
    )
  },
)
