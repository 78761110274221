import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { HolidayBenefitInvitationFormInputs } from '@modules/holiday-benefit/employee-invitation/index'
import { HolidayBenefitProgram } from '@modules/holiday-benefit/employee-invitation/models'
import { motion } from 'framer-motion'
import { HolidayBenefitInvitationKindSelectionCard } from '@modules/holiday-benefit/employee-invitation/kind-selection-card'

interface Props {
  onNextStepClick: () => void
  benefitProgram: HolidayBenefitProgram
}

export const HolidayBenefitInvitationKindSelection = ({ onNextStepClick, benefitProgram }: Props): JSX.Element => {
  const { control } = useFormContext<HolidayBenefitInvitationFormInputs>()
  const selectedKind = useWatch({ control, name: 'kind' })

  return (
    <motion.div
      className="my-lg-5 d-flex flex-column align-items-center"
      initial={{ opacity: 0, transform: 'translateY(-10px)' }}
      animate={{ opacity: 1, transform: 'none' }}
      exit={{ opacity: 0, transform: 'translateY(-10px)' }}
    >
      <p className="font-xl text-holiday-benefit-secondary">Wybierz swój plan abonamentowy:</p>
      <div className="d-flex justify-content-center gap-4 flex-wrap flex-lg-nowrap">
        <HolidayBenefitInvitationKindSelectionCard
          variant="is-primary"
          title="Plan Apartament"
          image={require('@assets/images/resorts/cieplice.jpg')}
          price={benefitProgram.benefit_apartment_price_worker}
          plan="apartment"
        >
          Do wykorzystania <strong>1x 6-dniowy</strong> oraz <strong>2x 3-dniowe pobyty w 9-osobowych domkach</strong> w
          wybranym Resorcie <span className="text-nowrap">Holiday Park & Resort</span>{' '}
          <strong>o łącznej wartości 4491 zł.</strong>
        </HolidayBenefitInvitationKindSelectionCard>
        <HolidayBenefitInvitationKindSelectionCard
          plan="house"
          variant="is-tertiary"
          title="Plan Domek"
          image={require('@assets/images/resorts/ustronie.jpg')}
          price={benefitProgram.benefit_house_price_worker}
        >
          Do wykorzystania <strong>1x 6-dniowy</strong> oraz <strong>2x 3-dniowe pobyty w 9-osobowych domkach</strong> w
          wybranym Resorcie <span className="text-nowrap">Holiday Park & Resort</span>{' '}
          <strong>o łącznej wartości 4491 zł.</strong>
        </HolidayBenefitInvitationKindSelectionCard>
      </div>
      <button
        className="btn-holiday-benefit is-primary px-5 mt-5 btn-responsive"
        onClick={onNextStepClick}
        disabled={!selectedKind}
      >
        Następny krok
      </button>
    </motion.div>
  )
}
