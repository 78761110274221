import * as React from 'react'
import { HolidayBenefitBenefitsPlans } from '@modules/holiday-benefit/common/holiday-benefit-benefits-plans'
import { useModal } from '@components/modals/use-modal'

interface Props {
  children: React.ReactNode
}

export const HolidayBenefitEmployeeBenefitsPlans = React.forwardRef(
  ({ children }: Props, ref: React.RefObject<HTMLDivElement>): React.ReactNode => {
    const [showAccommodationTypeModal] = useModal('AccommodationTypeDetailsModal')

    const handleShowAccommodationClick = (accommodationId: number, price: string) => () => {
      showAccommodationTypeModal(null, { accommodationId, price })
    }

    return (
      <HolidayBenefitBenefitsPlans
        ref={ref}
        forEmployee
        title="Wybierz plan"
        subtitle="swojego benefitu"
        description="Wybierz plan swojego benefitu. Zdecyduj czy Twój pobyt odbędzie się w Domku czy Apartamencie i ciesz się wypoczynkiem dwa razy w roku."
        apartmentContent={<ApartmentCardContent onShowDetails={handleShowAccommodationClick(2, '129')} />}
        houseContent={<HouseCardContent onShowDetails={handleShowAccommodationClick(1, '149')} />}
        apartmentImage={require('@assets/images/apartment.webp')}
        houseImage={require('@assets/images/house.webp')}
      >
        {children}
      </HolidayBenefitBenefitsPlans>
    )
  },
)

interface AccommodationCardContentProps {
  onShowDetails: () => void
}

const ApartmentCardContent = ({ onShowDetails }: AccommodationCardContentProps) => (
  <div className="text-darker-gray font-lg">
    <p>
      Każdego roku otrzymasz <strong>6-dniowy</strong> oraz <strong>3-dniowy pobyt w 4-osobowych apartamentach</strong>{' '}
      sieci resortów <span className="text-nowrap">Holiday Park & Resort</span> o{' '}
      <strong>łącznej wartości 2691 zł.</strong> Dodatkowo w prezencie <strong>otrzymasz 3-dniowy pobyt</strong> w
      apartamencie tuż po przystąpieniu do benefitu.
    </p>
    <button onClick={onShowDetails} className="font-500 btn-unstyled text-start">
      Zobacz jak wyglądają nasze apartamenty
    </button>
  </div>
)

const HouseCardContent = ({ onShowDetails }: AccommodationCardContentProps) => (
  <div className="text-darker-gray font-lg">
    <p>
      Każdego roku otrzymasz <strong>6-dniowy</strong> oraz <strong>3-dniowy pobyt w 9-osobowych domkach</strong> sieci
      resortów <span className="text-nowrap">Holiday Park & Resort</span> o <strong>łącznej wartości 2691 zł.</strong>{' '}
      Dodatkowo w prezencie <strong>otrzymasz 3-dniowy pobyt</strong> w domku tuż po przystąpieniu do benefitu.
    </p>
    <button onClick={onShowDetails} className="font-500 btn-unstyled">
      Zobacz jak wyglądają nasze domki
    </button>
  </div>
)
