import * as React from 'react'

interface Props {
  title: React.ReactElement | string
  subtitle?: React.ReactNode
  children?: React.ReactNode
}
export const AppHeaderContent = ({ title, subtitle, children }: Props): JSX.Element => {
  const handleScrollToBottom = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
  }

  return (
    <div className="app-header-section__content">
      <div className="container d-flex align-items-center flex-column flex-sm-row">
        <div className="col-sm-9 col-12">
          <div className="col-lg-10 col-12">
            <h1 className="text-white app-header-section__title text-center text-sm-start">{title}</h1>
            {subtitle && (
              <h5 className="text-white fw-normal mt-4 text-center text-sm-start col-10 col-sm-12 mx-auto">
                {subtitle}
              </h5>
            )}
          </div>
          {children}
        </div>
        <button
          className="d-flex flex-column gap-2 me-sm-5 mb-5 mb-sm-0 cursor-pointer align-items-center mt-5 mt-sm-0 btn-unstyled"
          onClick={handleScrollToBottom}
        >
          <p className="scroll" />
          <span className="text-white-50 d-block">przewiń</span>
        </button>
      </div>
    </div>
  )
}
