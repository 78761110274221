import * as React from 'react'
import { OfferSeller } from '@components/offer-seller'
import { Seller } from '@models/base'

interface Props {
  children: React.ReactNode
  seller?: Seller | null
  created: string
}

export const PackageWholesaleSubscriptionContentHeader = ({ children, seller, created }: Props): JSX.Element => (
  <div className="d-flex flex-wrap">
    <div className="col-sm-9 d-flex">{children}</div>
    {seller && <OfferSeller seller={seller} created={created} className="col-12 col-sm-3 mt-4 mt-sm-0" />}
  </div>
)
