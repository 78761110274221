import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import { SubscriptionKind } from '@modules/package-wholesale/models'

interface Props {
  icon: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
  variant: SubscriptionKind
  isDefaultExpanded?: boolean
}

export const PackageWholesaleSubscriptionExpandableVariant = ({
  icon,
  title,
  subtitle,
  children,
  variant,
  isDefaultExpanded = false,
}: Props): JSX.Element => {
  return (
    <Accordion className="subscription-variant__expandable-row" {...(isDefaultExpanded && { defaultActiveKey: '0' })}>
      <Accordion.Item eventKey="0" className={`is-${variant}`}>
        <Accordion.Header>
          <div className="d-flex align-items-center w-100">
            <div className="col-md-1 col-3">{icon}</div>
            <div className="d-flex flex-column justify-content-center align-items-start">
              {title}
              {subtitle}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <section className="col-12 col-md-11 ms-auto">{children}</section>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
