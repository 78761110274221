import * as React from 'react'

interface Props {
  number: number
  children: React.ReactNode
}

export const ManualBoxPoint = ({ number, children }: Props): React.ReactNode => (
  <div className="d-flex flex-sm-column flex-row align-items-center mb-sm-2 mb-4">
    <span className="manual-box__point">{number}</span>
    <p className="mb-0 mt-sm-3 font-xs">{children}</p>
  </div>
)
