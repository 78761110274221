import * as React from 'react'
import { Spinner } from '@components/spinner'

interface Props {
  isLoading: boolean
  text: React.ReactNode
  loadingText?: string
  className: string
  disabled?: boolean
  onClick: () => void
  style?: React.CSSProperties
}

export const ButtonWithLoader = ({
  onClick,
  disabled,
  loadingText = 'Proszę czekać...',
  isLoading,
  text,
  className,
  style,
}: Props): React.ReactNode => (
  <button className={className} onClick={onClick} disabled={disabled} style={style}>
    {isLoading ? (
      <>
        <Spinner className="text-white spinner-border-sm" /> <span className="ms-1">{loadingText}</span>
      </>
    ) : (
      text
    )}
  </button>
)
