import * as React from 'react'
import Carousel from 'react-multi-carousel'

export const HolidayBenefitInvitationSlides = (): JSX.Element => (
  <div className="col-6 d-none d-lg-block">
    <div className="holiday-benefit__invitation__slides">
      <div className="package-wholesale__resorts">
        <Carousel
          showDots
          infinite
          pauseOnHover
          responsive={GalleryBreakpoints}
          autoPlay={true}
          autoPlaySpeed={8000}
          shouldResetAutoplay={false}
          arrows={false}
          itemClass="package-wholesale__resorts__item"
        >
          {GalleryItems.map(item => (
            <div className="position-relative" key={item.img}>
              <img
                src={item.img}
                alt="Holiday Park & Resort"
                height={300}
                className="mx-auto rounded-4 holiday-benefit__invitation__slides__image"
              />
              <span className="holiday-benefit__invitation__slides__text">{item.text}</span>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  </div>
)

const GalleryItems = [
  {
    text: 'Otrzymujesz dostęp do wysokiej jakości ośrodków wypoczynkowych',
    img: require('@assets/images/holiday-benefit/invitation/gallery/01.webp'),
  },
  {
    text: 'Osiągniesz lepszą równowagę między życiem zawodowym a prywatnym',
    img: require('@assets/images/holiday-benefit/invitation/gallery/02.webp'),
  },
  {
    text: 'Zyskujesz możliwość współfinansowania swoich pobytów z pracodawcą',
    img: require('@assets/images/holiday-benefit/invitation/gallery/03.webp'),
  },
]

const GalleryBreakpoints = { desktop: { breakpoint: { max: 3000, min: 0 }, items: 1 } }
