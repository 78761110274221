import React from 'react'
import { SubscriptionContractPlanData } from '@modules/subscription-contract/plans/use-subscription-contract-plan-data'

export const getSubscriptionContractSeventhPlanData = (isConfirmed: boolean): SubscriptionContractPlanData => ({
  headerTitle: isConfirmed
    ? 'Potwierdzenie zakupu Subskrypcji Holiday Park & Resort'
    : 'Podsumowanie zakupu Subskrypcji Holiday Park & Resort',
  headerSubtitle: (
    <span>
      W ramach <strong>Subskrypcji</strong> oferujemy szereg bonusów i udogodnień specjalnie dla Gości Holiday Park &
      Resort!
    </span>
  ),
  contentTitle: isConfirmed
    ? 'Potwierdzenie zakupu Subskrypcji Złotej Holiday Park & Resort'
    : 'Podsumowanie zakupu Subskrypcji Złotej Holiday Park & Resort',
  headerBenefits: [
    'Wyższe rabaty na rezerwacje',
    'Rabaty na wyżywienie',
    'Dodatkowy kod promocyjny po 12 miesiącach',
    'Pobyt w Terminach Specjalnych z mniejszą ilością kodów pakietowych',
  ],
  contentBenefits: [
    <span key="additional-promocode">
      <strong>kod na 5-dniowy pobyt</strong> w domku w terminach pakietu złotego
    </span>,
    <span key="stay_discount">
      do <strong>65% rabatu</strong> na pobyt w Promocji Voucher Wyjazdowy
    </span>,
    <span key="discount">
      ceny pobytów w sezonie <strong>tańsze nawet o 60%</strong>
    </span>,
    <span key="special-terms">
      <strong>możliwość rezerwacji </strong>
      pobytu pakietowego <strong>w Terminach Specjalnych</strong> z wykorzystaniem mniejszej ilości kodów
    </span>,
    <span key="feeding-discount">
      do <strong>25% rabatu</strong> na wyżywienie (przy zakupie rezerwacji w ramach promocji Voucher Wyjazdowy)
    </span>,
  ],
})
