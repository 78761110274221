import * as React from 'react'
import { useRules } from '@hooks/use-rules'
import { AvailableRuleKeyword } from '@models/app-data'
import { AgreementSection } from '@components/agreement/agreement-section'
import { AgreementCheckbox } from '@components/agreement/agreement-checkbox'
import { Checkbox } from '@components/controls/checkbox'
import { ExpandableText } from '@components/controls/expandable-text'
import { StaticRules } from '@helpers/rules'

export const AcquisitionOfConsentsRules = (): JSX.Element => {
  const { getRules } = useRules()

  const rulesKeywords = ['information_clause', 'privacy_policy_rules'] as AvailableRuleKeyword[]
  const rules = getRules(rulesKeywords)

  return (
    <AgreementSection className="mt-3" additionalRulesNames={rulesKeywords} withMarketingRules={false}>
      {rules.map(rule => (
        <AgreementCheckbox key={rule?.keyword} rule={rule} />
      ))}

      <Checkbox
        name="phone_call_rules"
        id="phone_call_rules"
        label={
          <div className="mt-1 col-12 col-sm-8">
            <div className="pb-1">Zgoda na przedstawianie ofert poprzez kontakt telefoniczny.</div>
            <ExpandableText text={StaticRules.phone_call_rules} initialHeight={0} />
          </div>
        }
        className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
      />
    </AgreementSection>
  )
}
