import * as React from 'react'
import { GastroGenericProductDetails, GastroProductMultiplier } from '@modules/gastro-generic/models'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'
import { isWithinInterval, parseISO } from 'date-fns'

interface Response {
  currentOffer: GastroProductMultiplier | null
}

export const useGastroProductMultipliers = (gastroCardDetails: GastroGenericProductDetails): Response => {
  const gastroProduct = useGastroProductProvider(gastroCardDetails.kind)

  const currentOffer = React.useMemo(
    () =>
      (gastroProduct?.multipliers ?? []).find((multiplier: GastroProductMultiplier) =>
        isWithinInterval(new Date(), {
          start: parseISO(multiplier.active_from),
          end: parseISO(multiplier.active_to),
        }),
      ),
    [gastroProduct?.multipliers],
  )

  return { currentOffer: currentOffer ?? null }
}
