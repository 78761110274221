import { AvailableRuleKeyword, SizedImage } from '@models/app-data'
import { Seller } from '@models/base'

export const PackageWholesaleProductKinds = [
  'subscription',
  'gastro_card',
  'gastro_card_boost',
  'gastro_card_2024',
  'gastro_card_2025',
] as const

export type PackageWholesaleProductKind = (typeof PackageWholesaleProductKinds)[number]

export type SubscriptionKind = 'gold' | 'silver' | 'brown' | 'gray'

export type PackageWholesaleProductOptionKind = 'zoo_borysew'

export interface PackageWholesaleProductOption {
  description: string
  id: number
  kind: PackageWholesaleProductOptionKind
  kind_display: string
  price_brutto: string
}
export type DepositPaymentDeadline = 'now' | '12_months' | '30_days'

export interface PackageWholesaleProduct {
  accommodation_type_id: number
  stay_date_from: string | null
  available_from: string
  available_to: string
  defaults_days: number
  deposit_amount: number
  discount: number | null
  expire_after_years: number
  id: number
  image: SizedImage
  kind: PackageWholesaleProductKind
  localization_kind: 'sea' | 'mountains'
  localization_kind_display: string
  name: string
  options: PackageWholesaleProductOption[]
  price_brutto: string | null
  required_deposit_payment: boolean
  deposit_payment_deadline: DepositPaymentDeadline
  standard_price_brutto: string
  subscription_kind: SubscriptionKind
  with_cleaning_option: boolean
  with_client_change_data_option: boolean
  with_grill: boolean
  with_parking: boolean
  with_towel: boolean
  with_zoo_borysew_tickets: boolean
}

interface PackageWholesaleSubscriptionUrls {
  payment: string
}

export interface PackageWholesaleSubscription {
  token: string
  urls: PackageWholesaleSubscriptionUrls
}

interface PackageWholesaleDetailsUrls {
  create: string
  counter: string
}

interface PackageWholesaleExtraField {
  id: string
  type: 'text'
  label: string
  key: string
}

export interface PackageWholesaleDetails {
  extra_fields: PackageWholesaleExtraField[]
  created: string
  display_name: string
  expire_after: string
  id: number
  invoice_company: string
  leaflet_subscription: string | null
  leaflet_subscription_brown: string | null
  leaflet_subscription_silver: string | null
  leaflet_subscription_gold: string | null
  leaflet_gastro_card: string | null
  leaflet_gastro_card_boost: string | null
  leaflet_gastro_card_2024: string | null
  leaflet_gastro_card_2025: string | null
  optional_rules: AvailableRuleKeyword[]
  products: PackageWholesaleProduct[]
  required_rules: AvailableRuleKeyword[]
  seller: Seller | null
  sold_amount: number
  token: string
  urls: PackageWholesaleDetailsUrls
}
