import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'

export const PackageWholesaleSubscriptionVariantSpecialDatesInformation = (): React.ReactNode => (
  <div>
    <div className="subscription-variant__special-dates text-white rounded-top p-3 mt-2">
      W trakcie wskazanych powyżej terminów występują <strong>Terminy Specjalne</strong>. Aby odbyć pobyt z vouchera
      zakupionego w ramach{' '}
      <strong>
        <span className="text-nowrap">Pakietu Srebrnego lub Brązowego</span> w Terminie Specjalnym
      </strong>
      , należy dokonać jednorazowej dopłaty przy rezerwacji:
    </div>
    <div className="overflow-auto">
      <table className="table w-100 table-responsive mt-0 table-striped border">
        <thead className="text-center font-xs">
          <tr>
            <Cell>Termin Specjalny</Cell>
            <Cell>
              Okres <br />
              Nowego Roku
            </Cell>
            <Cell>
              Okres <br /> ferii zimowych
            </Cell>
            <Cell>
              Okres <br />
              Świąt Wielkanocnych
            </Cell>
            <Cell>
              Okres <br />
              przed Majówką
            </Cell>
            <Cell>
              Okres <br />
              Majówki
            </Cell>
            <Cell>
              Okres <br />
              Bożego Ciała
            </Cell>
            <Cell>
              Okres <br />
              Bożego Narodzenia
            </Cell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Cell>
              Dopłata do <br />
              <span className="text-nowrap">Voucherów morskich</span>
            </Cell>
            {Prices.sea.map(price => (
              <Cell key={price}>{formatPriceShort(price)}</Cell>
            ))}
          </tr>
          <tr>
            <Cell>
              Dopłata do <br /> <span className="text-nowrap">Voucherów górskich</span>
            </Cell>
            {Prices.mountain.map(price => (
              <Cell key={price}>{formatPriceShort(price)}</Cell>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

const Prices = {
  sea: [849, 299, 849, 299, 849, 849, 849],
  mountain: [849, 849, 849, 299, 849, 849, 1149],
}

const Cell = ({ children }) => <th className="align-middle font-sm text-center">{children}</th>
