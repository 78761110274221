import * as React from 'react'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { IconWithText } from '@components/icon-with-text'
import { FormError } from '@components/form-controls/form-error'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/client-slice'
import { ClientAppInformation } from '@components/personal-data/clent-app-information'
import { useLogin } from '@hooks/use-login'

export const PersonalDataExistingUser = (): JSX.Element => {
  const client = useSelector(selectClientDetails)

  const { logout } = useLogin()

  return (
    <div className="col-12">
      <div className="border rounded bg-lighter-gray p-4 text-darker-gray">
        <div className="row">
          <div className="col-md-7 col-12">
            <strong className="text-primary font-xxl">Sprawdź poprawność swoich danych</strong>
            <p>
              Dane możesz zmienić w swoim{' '}
              <a
                className="fw-bold text-decoration-none text-darker-gray"
                href={CLIENT_APP_URLS.MAIN_PAGE}
                target="_blank"
              >
                Koncie Gościa
              </a>{' '}
            </p>
            <Item
              title="Imię i nazwisko:"
              value={
                <span>
                  {client?.first_name} {client?.last_name}
                  <button onClick={logout} className="font-xs btn-unstyled ms-1 font-500" type="button">
                    (Wyloguj)
                  </button>
                </span>
              }
            />
            <Item title="Email:" value={client?.email} />
            <Item title="Numer telefonu:" value={client?.phone ?? '-'} />
            <FormError inputName="email" />
            <IconWithText
              text={
                <span className="font-xs">
                  <strong>Pamiętaj!</strong> Sprawdź poprawność adresu e-mail, ponieważ wyślemy na nie potwierdzenie
                  Twojego zakupu.
                </span>
              }
              iconClassName="uil-exclamation-circle text-primary font-xl me-2 align-self-start lh-initial"
              className="font-sm text-darker-gray px-0 mt-3 col-sm-7"
            />
          </div>
          <div className="col-md-5 col-12 d-flex flex-column justify-content-between">
            <img
              src={require('@assets/images/app-icon.webp')}
              alt="Aplikacja Konto Gościa Holiday Park & Resort"
              className="mx-auto mt-4"
              height={90}
              width={90}
            />
            <ClientAppInformation wrapperClassName="align-self-end w-100" />
          </div>
        </div>
      </div>
    </div>
  )
}

const Item = ({ title, value }) => (
  <div className="d-flex mt-1">
    <span className="col-5 font-500">{title}</span>
    <span className="col-7 font-500">{value}</span>
  </div>
)
