import * as React from 'react'
import { HolidayBenefitHero } from '@modules/holiday-benefit/common/holiday-benefit-hero'

interface Props {
  onScrollToBenefitsSection: () => void
  onScrollToPlansSection: () => void
}

export const HolidayBenefitEmployeeHero = ({
  onScrollToBenefitsSection,
  onScrollToPlansSection,
}: Props): React.ReactNode => (
  <HolidayBenefitHero
    className="is-employee"
    onScrollToPlansSection={onScrollToBenefitsSection}
    title={
      <>
        Pracuj z pasją,
        <br />
        odpoczywaj z klasą <br />- wakacje w abonamencie
      </>
    }
    subtitle="Wyjątkowe podejście do benefitów pracowniczych. Sprawdź już teraz jak wypoczywać kilka razu w roku!"
    points={[
      'Łącznie 9 dni podczas dwóch pobytów wypoczynkowych każdego roku ',
      '7 lokalizacji do wyboru ',
      'Niski koszt miesięczny dzięki współfinansowaniu przez Twojego Pracodawcę',
    ]}
    actionButton={
      <button
        className="btn btn-outline-light btn-holiday-benefit font-500 btn-responsive"
        onClick={onScrollToPlansSection}
      >
        Dostępne plany
      </button>
    }
  />
)
