import * as React from 'react'

const MAXIMUM_STEP = 2
const THUMB_SIZE = 25
const HALF_THUMB_SIZE = THUMB_SIZE / 2

export const HolidayBenefitBenefitsPlansCostSimulation = (): React.ReactNode => {
  const [priceRange, setPriceRange] = React.useState(0)
  const tooltipRef = React.useRef<HTMLOutputElement>(null)

  const setTooltipPosition = (leftOffset: number) => {
    const tooltip = tooltipRef.current
    if (!tooltip) return 'initial'

    tooltip.style.left = `calc(${leftOffset}px - ${tooltip.scrollWidth / 2}px)`
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    const targetScrollWidth = event.target.scrollWidth
    setPriceRange(value)

    let position = HALF_THUMB_SIZE
    if (value === 1) position = targetScrollWidth / 2
    if (value === 2) position = targetScrollWidth - HALF_THUMB_SIZE

    setTooltipPosition(position)
  }

  React.useEffect(() => {
    setTooltipPosition(HALF_THUMB_SIZE)
  }, [])

  return (
    <div className="mt-5 holiday-benefit__costs">
      <div className="text-center font-xxl pt-5">
        <strong className="text-holiday-benefit-primary">Jak może wyglądać</strong>
        <strong className="text-holiday-benefit-secondary d-block">elastyczny podział kosztów?</strong>
      </div>
      <div className="position-relative mt-5 d-flex flex-column-reverse d-lg-block">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <strong className="text-holiday-benefit-secondary font-xxxl">
              {PriceRanges[priceRange].employee}
              <span className="font-xl"> zł</span>
            </strong>
            <div className="font-500 mt-n2 font-lg">płaci pracodawca</div>
          </div>

          <div className="text-end">
            <strong className="text-holiday-benefit-primary font-xxxl">
              {PriceRanges[priceRange].employer}
              <span className="font-xl"> zł</span>
            </strong>
            <div className="font-500 mt-n2 font-lg">płaci pracownik</div>
          </div>
        </div>
        <div className="holiday-benefit__costs__slider__wrapper mt-5 mt-lg-0">
          <div className="holiday-benefit__costs__slider position-relative">
            <output htmlFor="range" ref={tooltipRef} className="holiday-benefit__costs__slider__tooltip ">
              <span className="holiday-benefit__costs__slider__value text-holiday-benefit-primary">
                symulacja opłat
              </span>
            </output>
            {PriceRangePoints.map((_, index) => (
              <div
                key={index}
                className="holiday-benefit__costs__slider__step-point"
                style={{ left: index === 0 ? '10px' : index === 1 ? 'calc(50% - 3px)' : 'calc(100% - 15px)' }}
              />
            ))}
            <input
              name="range"
              type="range"
              defaultValue={priceRange}
              step={1}
              min={0}
              max={MAXIMUM_STEP}
              className="w-100 holiday-benefit__costs__slider__input"
              style={{ cursor: 'grab' }}
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      <p className="fw-light text-center mt-3">
        przykładowe opcje elastyczne podziału opłat <span className="text-nowrap">w Planie Apartament</span>
      </p>
    </div>
  )
}

const PriceRanges = {
  0: { employee: '40', employer: '89' },
  1: { employee: '60', employer: '69' },
  2: { employee: '80', employer: '49' },
}

const PriceRangePoints = Array.from({ length: Object.values(PriceRanges).length })
