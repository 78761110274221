import * as React from 'react'
import { Button, ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  title?: string
  message?: string
  btnClass?: string
}

export const ConfirmationModal = ({
  toggleIsVisible,
  message,
  title = 'Gotowe',
  btnClass = 'btn-primary mt-3 py-3 text-white fw-semibold col-9 mx-auto',
}: Props): JSX.Element => (
  <ModalBody className="d-flex flex-column justify-content-center py-5">
    <img src={require('@assets/images/success.svg')} alt="Sukces" height="100" />
    <h3 className="text-success text-center my-4">{title}</h3>

    <p className="text-muted font-lg col-sm-9 mx-auto text-center mb-1">{message}</p>

    <Button className={btnClass} onClick={toggleIsVisible}>
      Zamknij
    </Button>
  </ModalBody>
)
