import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  name: string | React.ReactElement
  value: string | React.ReactElement
  className?: string
  valueClassName?: string
  nameClassName?: string
}

export const CardContentBaseDataRow = ({
  name,
  value,
  className,
  valueClassName,
  nameClassName,
}: Props): JSX.Element => (
  <div className={clsx('d-flex flex-wrap align-items-center', className)}>
    <span className={clsx('d-block col-sm-4 col-6', nameClassName)}>{name}</span>
    <strong className={clsx('col-sm-5 col-6', valueClassName)}>{value}</strong>
  </div>
)
