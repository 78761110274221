import { PackageWholesaleProductFormInput } from '@modules/package-wholesale/details'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { getAddedProductOptions } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-options'

interface Response {
  getSubscriptionPrice: (product: PackageWholesaleProductFormInput) => string
  getTotalPrice: (product: PackageWholesaleProductFormInput[]) => string
}

export const usePackageWholesalePrices = (): Response => {
  const getSubscriptionPrice = (product: PackageWholesaleProductFormInput) =>
    asDecimal(product.amount)
      .mul(product.product.price_brutto ?? '0')
      .toString()

  const getOptionsPrice = (product: PackageWholesaleProductFormInput) => {
    const options = getAddedProductOptions(product)
    return sumDecimalArray(options.map(option => asDecimal(option.price_brutto))).mul(product.amount)
  }

  const getTotalPrice = (products: PackageWholesaleProductFormInput[]) =>
    sumDecimalArray(
      products
        .filter(row => row.active)
        .map(row =>
          row.product.kind === 'subscription'
            ? asDecimal(row.product.price_brutto).mul(row.amount).plus(getOptionsPrice(row))
            : asDecimal(row.amount),
        ),
    ).toString()

  return {
    getSubscriptionPrice,
    getTotalPrice,
  }
}
