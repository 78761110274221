import * as React from 'react'
import { motion } from 'framer-motion'
import { RodoAgreementsQrCodeButton } from '@modules/rodo-agreements/qr-code-button'
import { RodoAgreementsButton } from '@modules/rodo-agreements/button'
import { useDevicesSize } from '@hooks/use-devices-size'
import { RodoAcquiringPoint } from '@modules/rodo-agreements/models'

interface Props {
  isFormVisible: boolean
  onVoucherSubmitClick: () => void
  rodoAcquiringPoint: RodoAcquiringPoint
}

export const RodoAgreementsDescriptionPage = ({
  isFormVisible,
  onVoucherSubmitClick,
  rodoAcquiringPoint,
}: Props): React.ReactNode => {
  const { isReady, isDesktop } = useDevicesSize()

  if (!isReady) return null

  const variants = {
    hidden: isDesktop ? { maxWidth: '50%', overflow: 'hidden' } : { marginLeft: '-100vw' },
    visible: isDesktop ? { maxWidth: isFormVisible ? '0' : '50%' } : { marginLeft: isFormVisible ? '-110vw' : 0 },
  }

  return (
    <motion.div
      className="col-sm-6 col-12 rodo-agreements__description-section px-3 px-sm-0 "
      style={{ whiteSpace: isDesktop ? 'nowrap' : 'initial' }}
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <img
        src={rodoAcquiringPoint.logo}
        alt="holidaypark logo"
        className="rodo-agreements__description-section__icon ms-sm-5 mx-auto"
      />

      <div className="d-sm-none w-100 my-3">
        <img
          src={rodoAcquiringPoint.image}
          className="rodo-agreements__description-section__image px-0"
          alt="Vouchery Holida Park & Resort"
        />
      </div>

      <div className="ms-sm-5 ms-3 me-3 me-sm-0 text-center text-sm-start">
        <h2
          className="text-primary rodo-agreements__description-section__title mb-0 lh-1 mt-2 mt-sm-0"
          dangerouslySetInnerHTML={{ __html: rodoAcquiringPoint.form_title }}
        />
        <div className="rodo-agreements__description-section__subtitle">
          <p
            className="text-darker-gray lh-initial font-500 mt-sm-5 mt-3"
            dangerouslySetInnerHTML={{ __html: rodoAcquiringPoint.introductory_text }}
          />
          <p
            className="text-darker-gray mt-sm-5 mt-3"
            dangerouslySetInnerHTML={{ __html: rodoAcquiringPoint.accompanying_text }}
          />
        </div>
      </div>
      <div className="ms-sm-5 ms-auto me-auto">
        <div className="d-flex flex-column align-items-center mt-2 mt-sm-0">
          <RodoAgreementsButton
            text={rodoAcquiringPoint.button_text}
            onClick={onVoucherSubmitClick}
            backgroundColor={rodoAcquiringPoint.color_primary}
          />
          <RodoAgreementsQrCodeButton url={rodoAcquiringPoint.slug} />
        </div>
      </div>
    </motion.div>
  )
}
