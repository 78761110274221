import { ActionRow } from '@components/action-row'
import * as React from 'react'

interface Props {
  isLoading: boolean
  disabled?: boolean
  mainText?: string
  subText?: string
}

export const SubmitActionRow = ({ isLoading, disabled, mainText, subText }: Props): JSX.Element => (
  <ActionRow
    className="mt-4 justify-content-end"
    isLoading={isLoading}
    buttonContent={<SubmitActionButtonContent mainText={mainText} subText={subText} />}
    isActionDisabled={disabled}
  />
)

interface SubmitActionButtonContentProps {
  mainText?: string
  subText?: string
}

export const SubmitActionButtonContent = ({
  mainText = 'Akceptuję',
  subText = 'regulaminy',
}: SubmitActionButtonContentProps): React.ReactNode => (
  <>
    <span className="font-500 d-block font-xl lh-initial">{mainText}</span>
    <strong className="font-sm">{subText}</strong>
  </>
)
