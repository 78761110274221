import { ReservationDetails } from '@modules/reservation/models'
import { useReservationGtmEvents } from '@modules/reservation/use-reservation-gtm-events'
import { PaymentMethod } from '@models/app-data'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useSubscriptionProductGtmEvents } from '@modules/subscription-with-benefit/use-subscription-with-benefit-gtm-events'
import { useCodesConversionGtmEvents } from '@modules/codes-conversion/use-codes-conversion-gtm-events'
import { CodeOption } from '@modules/codes-conversion/models'

interface Response<T> {
  sendAddPaymentInfoEvent: (kind: string, paymentMethod: PaymentMethod, productDetails: T) => void
}

export const useGtmPaymentSelectionEvent = <T>(): Response<T> => {
  const { sendAddPaymentInfoEvent: reservationPaymentSelect } = useReservationGtmEvents()
  const { sendAddPaymentInfoEvent: subscriptionProductPaymentSelect } = useSubscriptionProductGtmEvents()
  const { sendAddPaymentInfoEvent: codesConversionPaymentSelect } = useCodesConversionGtmEvents()

  const sendAddPaymentInfoEvent = (kind: string, paymentMethod: PaymentMethod, productDetails: T) => {
    if (kind === ProductKinds.RESERVATION_DETAILS) {
      reservationPaymentSelect(paymentMethod, productDetails as ReservationDetails)
    }

    if (kind === ProductKinds.SUBSCRIPTIONS_WITH_BENEFITS) {
      subscriptionProductPaymentSelect(paymentMethod, productDetails as SubscriptionDetails, 'pakiet-korzysci')
    }

    if (kind === ProductKinds.GO_HOLIDAY) {
      subscriptionProductPaymentSelect(paymentMethod, productDetails as SubscriptionDetails, 'go-holiday')
    }

    if (kind === ProductKinds.CODES_CONVERSION) {
      codesConversionPaymentSelect(paymentMethod, productDetails as CodeOption)
    }
  }

  return {
    sendAddPaymentInfoEvent,
  }
}
