import * as React from 'react'

export const HolidayBenefitEmployeeVoucher = (): JSX.Element => (
  <div className="holiday-benefit__employee-voucher py-5 mt-5">
    <div className="container d-flex gap-5 text-white my-5 flex-wrap flex-lg-nowrap py-lg-5">
      <div className="col-lg-4 col-12 mt-5 text-center text-lg-start">
        <strong className="holiday-benefit__section-title mt-5">
          Specjalna Oferta <br />
          dla pracodawcy
        </strong>
        <p className="font-xl mt-5">
          Za każde 10 podpisanych umów z Twoimi pracownikami, jako pracodawca otrzymujesz{' '}
          <strong>voucher na 3-dniowy pobyt</strong> w jednym z naszych ośrodków. To doskonała okazja, abyś sam
          sprawdził korzyści naszego programu!
        </p>
        <p className="font-lg font-500">
          Otrzymany prezent możesz wykorzystać samodzielnie lub go dowolnie rozdysponować.
        </p>
      </div>
      <div className="col-lg-8 col-12 text-center position-relative">
        <div className="holiday-benefit__employee-voucher__label">
          <div className="holiday-benefit__employee-voucher__label__circle">
            <i className="uil-check text-holiday-benefit-secondary font-xl pt-1" />
          </div>
          <div>
            Benefit również <br /> dla pracodawcy
          </div>
        </div>

        <img
          src={require('@assets/images/holiday-benefit/landing-page/vouchers.webp')}
          alt="holidaypark logo"
          height={400}
          style={{ maxWidth: '100%', objectFit: 'cover', borderRadius: 8 }}
        />
      </div>
    </div>
  </div>
)
