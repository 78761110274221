import * as React from 'react'
import { CheckButton } from '@components/controls/check-button'
import { useFormContext, useWatch } from 'react-hook-form'

export type Accommodation = 'house' | 'apartment'

export const ProductAccommodationSelection = (): React.ReactNode => {
  const { setValue, control } = useFormContext()

  const selectedAccommodation = useWatch({ control, name: 'accommodation' })

  const handleAccommodationTypeChange = (accommodation: Accommodation) => (event: React.MouseEvent) => {
    event.stopPropagation()
    setValue('accommodation', accommodation)
  }
  return (
    <div>
      <p className="font-xxl text-secondary">
        Wybierz <span className="font-500 text-nowrap">Typ lokalu:</span>
      </p>
      <div className="d-flex flex-wrap gap-3">
        <CheckButton
          className="flex-row-reverse col-lg-5 col-12 p-0 justify-content-between pe-4"
          isActive={selectedAccommodation === 'apartment'}
          onClick={handleAccommodationTypeChange('apartment')}
          title="Apartament"
          description={<span className="fw-semi-bold">Zobacz dostępne zestawy</span>}
          contentClassName="d-flex flex-row-reverse align-items-center"
        >
          <img
            src={require('@assets/images/apartment.webp')}
            alt="Holiday Park & Resort"
            height={78}
            width={120}
            className="me-4 check-button__image"
          />
        </CheckButton>

        <CheckButton
          className="flex-row-reverse col-lg-5 col-12 p-0 justify-content-between pe-4"
          isActive={selectedAccommodation === 'house'}
          onClick={handleAccommodationTypeChange('house')}
          title="Domek"
          description={<span className="fw-semi-bold">Zobacz dostępne zestawy</span>}
          contentClassName="d-flex flex-row-reverse align-items-center"
        >
          <img
            src={require('@assets/images/house.webp')}
            alt="Holiday Park & Resort"
            height={78}
            width={120}
            className="me-4 check-button__image"
          />
        </CheckButton>
      </div>
    </div>
  )
}
