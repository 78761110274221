import { ReservationDetailsPricesSection } from '@modules/reservation/details/bill/prices/reservation-details-prices-section'
import { ReservationDetailsPriesSectionItem } from '@modules/reservation/details/bill/prices/reservation-details-pries-section-item'
import * as React from 'react'
import { Discount, PriceItem } from '@modules/reservation/models'

interface Props {
  discounts: PriceItem<Discount>
}

export const ReservationDetailsPricesDiscounts = ({ discounts }: Props): JSX.Element => (
  <>
    <ReservationDetailsPricesSection title="Rabaty:" totalPrice={discounts.total_price_brutto} pricePrefix="-">
      {discounts.items.map((discount: Discount) => (
        <ReservationDetailsPriesSectionItem
          key={discount.name}
          title={discount.name}
          price={discount.price_brutto}
          pricePrefix="-"
        />
      ))}
    </ReservationDetailsPricesSection>
    <hr className="text-muted reservation-details__payment-bill__separator" />
  </>
)
