import * as React from 'react'
import { PackageWholesaleDetails, PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { PackageWholesaleSubscriptionVariantGold } from '@modules/package-wholesale/details/subscription/variants/gold/package-wholesale-subscription-variant-gold'
import { PackageWholesaleSubscriptionVariantSilver } from '@modules/package-wholesale/details/subscription/variants/silver/package-wholesale-subscription-variant-silver'
import { PackageWholesaleSubscriptionVariantBrown } from '@modules/package-wholesale/details/subscription/variants/brown/package-wholesale-subscription-variant-brown'
import { PackageWholesaleSubscriptionVariantInformation } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-variant-information'

type SubscriptionMainVariant = 'silver' | 'gold' | 'brown'

const segregateProduct = (product: PackageWholesaleProduct, cumulated) => {
  const kind = product.subscription_kind
  return { ...cumulated, [kind]: [...cumulated[kind], product] }
}

interface Props {
  packageWholesaleProducts: PackageWholesaleProduct[]
  packageWholesaleDetails: PackageWholesaleDetails
  isCollapsable: boolean
}

export const PackageWholesaleSubscriptionVariants = ({
  packageWholesaleProducts,
  packageWholesaleDetails,
  isCollapsable,
}: Props): JSX.Element => {
  const { brown, silver, gold }: Record<SubscriptionMainVariant, PackageWholesaleProduct[]> = React.useMemo(
    () =>
      packageWholesaleProducts.reduce((cumulated, product) => segregateProduct(product, cumulated), {
        silver: [],
        brown: [],
        gold: [],
      }),
    [packageWholesaleProducts],
  )

  const getLeafletUrl = (
    leaflet: 'leaflet_subscription_brown' | 'leaflet_subscription_silver' | 'leaflet_subscription_gold',
  ) => packageWholesaleDetails[leaflet] ?? packageWholesaleDetails.leaflet_subscription

  return (
    <>
      {!!gold.length && (
        <PackageWholesaleSubscriptionVariantGold
          products={gold}
          leafletUrl={getLeafletUrl('leaflet_subscription_gold')}
          isDefaultExpanded={silver.length === 0 && brown.length === 0}
        />
      )}
      {!!silver.length && (
        <PackageWholesaleSubscriptionVariantSilver
          products={silver}
          leafletUrl={getLeafletUrl('leaflet_subscription_silver')}
          isDefaultExpanded={gold.length === 0 && brown.length === 0}
        />
      )}
      {!!brown.length && (
        <PackageWholesaleSubscriptionVariantBrown
          products={brown}
          leafletUrl={getLeafletUrl('leaflet_subscription_brown')}
          isDefaultExpanded={gold.length === 0 && silver.length === 0}
        />
      )}
      <PackageWholesaleSubscriptionVariantInformation
        isCollapsable={isCollapsable}
        hasSpecialDatesPayment={!!silver.length || !!brown.length}
      />
    </>
  )
}
