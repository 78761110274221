import * as React from 'react'
import * as clsx from 'clsx'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'

export const HolidayBenefitEmployeeEnrolmentSteps = React.forwardRef(
  (_, ref: React.RefObject<HTMLDivElement>): React.ReactNode => (
    <div className="mt-5 col-lg-10 col-11 mx-auto pt-5" ref={ref}>
      <div className="holiday-benefit__section-title text-center mb-5">
        <strong className="text-holiday-benefit-primary me-2">Jak przystąpić</strong>
        <strong className="text-holiday-benefit-secondary"> do programu?</strong>
      </div>

      <div className="mb-5 holiday-benefit__employee__enrolment">
        <div className="position-relative">
          <EnrolmentCard
            stepNumber={1}
            title="Zapytaj w dziale Kadr"
            description="Zgłoś chęć przystąpienia do benefitu do działu Kadr w Twojej firmie. Uzyskasz niezbędne informacje i poznasz miesięczną część składki, którą opłaca Pracownik."
            opacity={0.05}
          />
          <img
            src={require('@assets/images/holiday-benefit/landing-page/arrow-right-to-left.svg')}
            height={90}
            alt="arrow"
            className="holiday-benefit__employee__enrolment__step-1-2"
          />
        </div>
        <div className="position-relative pt-lg-5">
          <EnrolmentCard
            stepNumber={2}
            title="Wybierz swój Plan i dołącz"
            description="Zdecyduj, który plan jest stworzony dla Ciebie. 4-osobowy przestronny Apartament czy może 9-osobowy rodzinny Domek? Wybierz i zarezerwuj pierwszy pobyt!"
            opacity={0.1}
          />
          <img
            src={require('@assets/images/holiday-benefit/landing-page/arrow-right-to-left.svg')}
            height={90}
            alt="arrow"
            className="holiday-benefit__employee__enrolment__step-2-3"
          />
        </div>
        <div>
          <EnrolmentCard
            stepNumber={3}
            title="Podróżuj i wypoczywaj!"
            description="Odwiedzaj nasze resorty i wypoczywaj kilka razy w roku. Jesienny spacer po plaży? Morsowanie? Czy klasyczne plażowanie? – teraz to już możliwe teraz za zaledwie kilkadziesiąt złotych miesięcznie."
            opacity={0.15}
          />
        </div>
      </div>
    </div>
  ),
)

interface EnrolmentCardProps {
  stepNumber: number
  title: string
  description: string
  opacity: number
  className?: string
}

const EnrolmentCard = ({ stepNumber, title, description, opacity, className }: EnrolmentCardProps) => (
  <div
    className={clsx('rounded-3 p-5 holiday-benefit__employee__enrolment__card', className)}
    style={{ backgroundColor: `rgba(149, 27, 129, ${opacity})` }}
  >
    <HolidayBenefitBenefitUnderlineText
      text={`Krok ${stepNumber}`}
      underlineColor="#951B81"
      textClass="text-holiday-benefit-tertiary font-xxxl fw-bold lh-1"
    />
    <p className="font-xxl font-500 mb-0 text-holiday-benefit-secondary mt-3">{title}</p>
    <p className="font-lg mt-3">{description}</p>
  </div>
)
