import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleSubscriptionHeaderSectionContent } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-header-section-content'
import { PackageWholesaleGastroCardHeaderSectionContent } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-header-section-content'
import { PackageWholesaleGastroCardBoostHeaderSectionContent } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-header-section-content'
import { PackageWholesaleCombinedHeaderSectionContent } from '@modules/package-wholesale/details/combined/package-wholesale-combined-card-header-section-content'
import { PackageWholesaleSubscriptionContent } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content'
import { PackageWholesaleGastroCardContent } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-content'
import { PackageWholesaleGastroCardBoostContent } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-content'
import { PackageWholesaleCombinedContent } from '@modules/package-wholesale/details/combined/package-wholesale-combined-content'
import { PackageWholesaleSubscriptionSideCard } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-side-card'
import { PackageWholesaleGastroCardSideCard } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-side-card'
import { PackageWholesaleGastroCardBoostSideCard } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-side-card'
import { PackageWholesaleSideCardPrices } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices'
import { PackageWholesaleProductKinds } from '@modules/package-wholesale/models'

interface Response {
  headerSectionContent: React.ReactNode
  content: React.ReactNode
  sideCard: React.ReactNode
}

const getProductKind = products => {
  for (const kind of PackageWholesaleProductKinds) {
    if (products.every(product => product.kind === kind)) {
      return kind
    }
  }

  return 'combined'
}

export const usePackageWholesaleVariant = (packageWholesaleDetails: PackageWholesaleDetails | undefined): Response => {
  if (!packageWholesaleDetails)
    return {
      headerSectionContent: null,
      content: null,
      sideCard: null,
    }

  const productKind = getProductKind(packageWholesaleDetails.products)

  const renderComponent = (HeaderSectionContent, Content, SideCard) => {
    const headerSectionContent = <HeaderSectionContent packageWholesaleDetails={packageWholesaleDetails} />
    const content = <Content packageWholesaleDetails={packageWholesaleDetails} />
    const sideCard = <SideCard packageWholesaleDetails={packageWholesaleDetails} />

    return { headerSectionContent, content, sideCard }
  }

  const renderGastroProductComponent = (HeaderSectionContent, Content, SideCard, kind) => {
    const headerSectionContent = <HeaderSectionContent packageWholesaleDetails={packageWholesaleDetails} kind={kind} />
    const content = <Content packageWholesaleDetails={packageWholesaleDetails} kind={kind} />
    const sideCard = <SideCard packageWholesaleDetails={packageWholesaleDetails} kind={kind} />

    return { headerSectionContent, content, sideCard }
  }

  if (productKind === 'subscription')
    return renderComponent(
      PackageWholesaleSubscriptionHeaderSectionContent,
      PackageWholesaleSubscriptionContent,
      PackageWholesaleSubscriptionSideCard,
    )

  if (['gastro_card', 'gastro_card_2024', 'gastro_card_2025'].includes(productKind))
    return renderGastroProductComponent(
      PackageWholesaleGastroCardHeaderSectionContent,
      PackageWholesaleGastroCardContent,
      PackageWholesaleGastroCardSideCard,
      productKind,
    )

  if (productKind === 'gastro_card_boost')
    return renderComponent(
      PackageWholesaleGastroCardBoostHeaderSectionContent,
      PackageWholesaleGastroCardBoostContent,
      PackageWholesaleGastroCardBoostSideCard,
    )

  return renderComponent(
    PackageWholesaleCombinedHeaderSectionContent,
    PackageWholesaleCombinedContent,
    PackageWholesaleSideCardPrices,
  )
}
