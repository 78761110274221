import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import * as clsx from 'clsx'

interface Props {
  onScrollToContactSection: () => void
}

export const HolidayBenefitBenefitsFaq = ({ onScrollToContactSection }: Props): React.ReactNode => {
  const [activeKey, setActiveKey] = React.useState<string | null>('0')

  const isActive = (index: number): boolean => activeKey === index.toString()

  const handleAccordionClick = (index: number) => () => {
    const newIndex = index.toString()
    setActiveKey(state => (state === newIndex ? null : newIndex))
  }

  return (
    <div className="holiday-benefit__faq my-5">
      <div className="container">
        <h4 className="holiday-benefit__section-title text-holiday-benefit-primary me-2 text-center mb-5">
          Najczęściej zadawane pytania
        </h4>

        <Accordion
          className="holiday-benefit__faq__accordion col-lg-9 mx-auto"
          defaultActiveKey="1"
          activeKey={activeKey}
        >
          {FAQ.map((faq, index) => (
            <Accordion.Item
              key={index}
              eventKey={index.toString()}
              className={clsx({ 'border-top': !isActive(index), 'border-bottom': index === FAQ.length - 1 })}
            >
              <Accordion.Header className="fw-500" onClick={handleAccordionClick(index)}>
                <div className="font-xl">{faq.question}</div>
                <i
                  className={clsx(
                    'me-2 font-xxl',
                    isActive(index)
                      ? 'text-holiday-benefit-primary uil-minus'
                      : 'text-holiday-benefit-secondary uil-plus',
                  )}
                />
              </Accordion.Header>
              <Accordion.Body className="pt-0">{faq.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        <p className="font-xl text-center mb-0 mt-5">
          Jeśli nie znalazłeś odpowiedzi na Twoje pytanie,{' '}
          <button
            className="text-nowrap text-holiday-benefit-secondary text-decoration-none fw-semibold btn-unstyled"
            onClick={onScrollToContactSection}
          >
            napisz do nas
          </button>
        </p>
      </div>
    </div>
  )
}

const FAQ = [
  {
    question: 'Czym są benefity pracownicze?',
    answer:
      'Są to dodatkowe świadczenia oferowane przez pracodawców swoim pracownikom poza standardowym wynagrodzeniem. Celem benefitów jest zwiększenie satysfakcji, lojalności oraz motywacji pracowników.',
  },
  {
    question: 'Co to jest My Holiday Benefits?',
    answer:
      'To innowacyjne rozwiązanie, które podniesie morale i zaangażowanie Twoich pracowników, a jednocześnie wzmocni markę Twojej firmy. Nasz produkt to voucher na pobyt wypoczynkowy, opłacany w modelu abonamentowym.',
  },
  {
    question: 'Jak działa model abonamentowy?',
    answer:
      'To opłacanie benefitu w stałej comiesięcznej kwocie. Część pokrywa firma, a pracownik dopłaca resztę. Dzięki temu koszt jest rozłożony i łatwiejszy do zarządzania.',
  },
  {
    question: 'Jakie korzyści przynosi ten program pracownikom?',
    answer:
      'Pracownicy zyskują możliwość odbycia wysokiej jakości pobytu wypoczynkowego na preferencyjnych warunkach, co pozytywnie przekłada się na ich motywację, równowagę i ogólny dobrostan.',
  },
  {
    question: 'Jakie są korzyści dla pracodawcy?',
    answer:
      'Program zwiększa zaangażowanie pracowników, poprawia ich zdrowie i produktywność, a także wzmacnia atrakcyjność firmy jako pracodawcy. Dodatkowo, pracodawca może również skorzystać z voucherów prezentowych za podpisanie umowy.',
  },
  {
    question: 'Jakie są warunki otrzymania dodatkowych voucherów dla pracodawcy?',
    answer:
      'To proste! Za każde 10 podpisanych umów przez pracowników, firma otrzymuje dodatkowy voucher na 3-dniowy pobyt do dowolnego rozdysponowania.',
  },
  {
    question: 'Czy program można dostosować do indywidualnych potrzeb firmy?',
    answer:
      'Zdecydowanie tak. Oferujemy elastyczne podejście do klienta, dostosowane do potrzeb i budżetu Twojej firmy. Skontaktuj się z nami, aby omówić dostępne możliwości.',
  },
  {
    question: 'Jak mogę zarejestrować swoją firmę w programie?',
    answer:
      'Przystąpienie do programu odbywa się z pomocą naszych przedstawicieli. Umów się na rozmowę poprzez formularz kontaktowy lub od razu skontaktuj się z doradcą.',
  },
  {
    question: 'W jaki sposób przebiega obsługa uczestników programu?',
    answer:
      'Otrzymasz od nas szereg spersonalizowanych materiałów na start, dzięki którym łatwo rozpowszechnisz informacje o programie wśród pracowników. Kluczowym narzędziem jest specjalny Panel do samodzielnego zarządzania subskrypcjami w strukturach Twojej organizacji, do którego również przekażemy Ci dostęp.',
  },
  {
    question: 'Jakie są zasady realizacji voucherów?',
    answer:
      'Każdy voucher jest ważny 3 lata od dnia przystąpienia do programu i uprawnia do odbycia pobytu w jednym z naszych 7 ośrodków, nad morzem lub w górach.',
  },
  {
    question: 'Co, jeśli pracownik ma dodatkowe pytania dotyczące swojego vouchera?',
    answer:
      'Pracownik może skontaktować się bezpośrednio z naszym Biurem Obsługi Klienta lub samodzielnie sprawdzić szczegóły po zarejestrowaniu się do Konta Gościa.',
  },
]
