import * as React from 'react'
import { GastroGenericProductDetails } from '@modules/gastro-generic/models'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'

interface Props {
  gastroGenericDetails: GastroGenericProductDetails
}

export const GastroGenericAgreements = ({ gastroGenericDetails }: Props): JSX.Element => {
  const rules = [...gastroGenericDetails.optional_rules, ...gastroGenericDetails.required_rules]

  return <DynamicAgreementsSection productRules={rules} />
}
