import { GastroGenericProductDetails, GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatToWordsWithUppercaseLetterStart } from '@helpers/utils'
import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-formatter'

export const getGastroCardDynamicData = (
  productDetails: GastroGenericProductDetails,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const isSubscriptionOffer = productDetails.tags.includes('subscription_offer')

  const labelImageSrc = 'gastro-voucher-label.jpg'

  const capitalizedProductName = formatToWordsWithUppercaseLetterStart(gastroProduct.product_name)

  const productName = isSubscriptionOffer ? `${capitalizedProductName} dla Subskrybentów` : capitalizedProductName

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher Podwójna Radość 2025 Plus </strong>{' '}
      do wykorzystania w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w roku 2025 w Resortach Holiday
      Park & Resort.
      <strong className="d-block mt-2">Kupuj wcześniej, zyskuj więcej!</strong>
    </span>
  )

  const benefitsPoints = [
    'Wcześniejszy i większy zakup powoduje naliczenie większego bonusu',
    'Wartość vouchera może zostać powiększona o nawet 100% Twojej wpłaty',
    'Ilość voucherów, jakie możesz kupić jest nieograniczona!',
    'Voucher możesz wykorzystać w otwartych punktach gastronomicznych na terenie Resortu',
    'Voucher jest wielokrotnego użytku (częściowo, w różnych rezerwacjach)',
    `Voucher jest ważny do końca ${2025} roku`,
  ]

  const benefitsList = [
    'Minimalna wartość zamówienia to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    `Voucher można wykorzystać podczas pobytów w terminie ${toDefaultDateFormat(
      productDetails.active_after,
    )} - ${toDefaultDateFormat(productDetails.expire_after)}.`,
  ]

  const alertText =
    'Voucher nie łączy się z promocją Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Podwójna Radość 2025 Plus można podpiąć kolejny dowolny kupon gastronomiczny.'

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
