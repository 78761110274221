import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'

interface Props {
  onScrollToDetails: () => void
  productName: string
  productHeader: React.ReactNode
}

export const GastroGenericHeaderSectionContent = ({
  onScrollToDetails,
  productName,
  productHeader,
}: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <span>
        Voucher <span className="text-nowrap">"{productName}"</span>
      </span>
    }
    subtitle={productHeader}
  >
    <button className="btn package-wholesale__details-button mt-5" onClick={onScrollToDetails}>
      <strong className="text-white">Szczegóły oferty</strong>
    </button>
  </AppHeaderContent>
)
