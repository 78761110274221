import { useEffect, useState } from 'react'

export enum DeviceSize {
  desktop = '(min-width: 1200px)',
  tablet = '(min-width: 758px) and (max-width: 1199px)',
  mobile = '(max-width: 757px)',
}

interface Devices {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
}

interface Response extends Devices {
  isReady: boolean
}

export const useDevicesSize = (): Response => {
  const [device, setDevice] = useState<DeviceSize>()

  useEffect(() => {
    const mediaXs = window.matchMedia(DeviceSize.mobile)
    const mediaSm = window.matchMedia(DeviceSize.tablet)
    const mediaMd = window.matchMedia(DeviceSize.desktop)

    const listener = () => {
      if (mediaXs.matches && device !== DeviceSize.mobile) {
        setDevice(DeviceSize.mobile)
      }

      if (mediaSm.matches && device !== DeviceSize.tablet) {
        setDevice(DeviceSize.tablet)
      }

      if (mediaMd.matches && device !== DeviceSize.desktop) {
        setDevice(DeviceSize.desktop)
      }
    }
    window.addEventListener('resize', listener)

    listener()
    return () => window.removeEventListener('resize', listener)
  }, [device])

  return {
    isDesktop: device === DeviceSize.desktop,
    isTablet: device === DeviceSize.tablet,
    isMobile: device === DeviceSize.mobile,
    isReady: !!device,
  }
}
