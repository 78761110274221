import * as React from 'react'

import { CustomFormGroup, CustomFormGroupProps } from '@components/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  withDataCheckDisclaimer?: boolean
  title?: string
  nameInputProps?: Partial<CustomFormGroupProps>
  phoneInputProps?: Partial<CustomFormGroupProps>
  emailInputProps?: Partial<CustomFormGroupProps>
  additionalClientData?: React.ReactNode
}
export const ClientInfoForm = ({
  withDataCheckDisclaimer = true,
  title = 'Dane osoby kupującej:',
  nameInputProps,
  phoneInputProps,
  emailInputProps,
  additionalClientData,
}: Props): JSX.Element => (
  <div className="col-12 col-sm-10">
    {title && <strong className="text-gold">{title}</strong>}
    <div className="d-flex flex-column gap-2 row mx-0">
      <CustomFormGroup
        label="Imię i nazwisko:"
        inputName="name"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'text', className: 'col-6', placeholder: 'np. Andrzej Nowak' }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
        {...nameInputProps}
      />

      <CustomFormGroup
        label="Numer telefonu:"
        inputName="phone"
        maskedFormControlProps={{
          mask: '(+48) ***-***-***',
          className: 'letter-spacing-2',
          alwaysShowMask: true,
        }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'tel', className: 'col-6' }}
        {...phoneInputProps}
      />
      <CustomFormGroup
        label="E-mail:"
        inputName="email"
        formLabelProps={{ className: 'fw-bold col-6' }}
        formControlProps={{ type: 'email', className: 'col-6', placeholder: 'adres@email.pl' }}
        formGroupProps={{ className: 'package-wholesale__data-input' }}
        {...emailInputProps}
      />
      {withDataCheckDisclaimer && (
        <IconWithText
          text={
            <>
              <strong className="text-dark">Pamiętaj!</strong> Sprawdź poprawność adresu e-mail i numeru telefonu,
              ponieważ wyślemy na nie numery zakupionych kodów niezbędnych do założenia rezerwacji.
            </>
          }
          iconClassName="uil-exclamation-circle text-primary font-xxl me-2 align-self-start lh-initial  "
          className="col-sm-6 col-12 ms-sm-auto font-sm text-darker-gray px-0"
        />
      )}
      {additionalClientData}
    </div>
  </div>
)
