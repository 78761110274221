import { AccommodationType, PaymentMethod, Resort } from '@models/app-data'
import { EcommerceEventItem, GTMItemCategory } from '@models/gtm'
import { Feeding, Improvement, ReservationDetails } from '@modules/reservation/models'
import { formatToGtmEventDate } from '@helpers/date-formatter'
import { differenceInDays, parseISO } from 'date-fns'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { createGtmCartItem, useGtmEvents } from '@hooks/gtm/use-gtm-events'

interface Response {
  sendPurchaseEvent: (reservationDetails: ReservationDetails) => void
  sendAddPaymentInfoEvent: (paymentMethod: PaymentMethod, productDetails: ReservationDetails) => void
}

export const useReservationGtmEvents = (): Response => {
  const { resorts, accommodation_types } = useSelector(selectAppData)
  const { purchase, addPaymentInfo } = useGtmEvents()

  const getReservationAccommodationType = (reservationDetails: ReservationDetails) =>
    accommodation_types.find(accommodationType => accommodationType.id === reservationDetails.accommodation_type_id)

  const getReservationResort = (reservationDetails: ReservationDetails) =>
    resorts.find(resort => resort.id === reservationDetails.resort_id)

  const sendAddPaymentInfoEvent = (paymentMethod: PaymentMethod, reservationDetails: ReservationDetails) => {
    addPaymentInfo({
      paymentMethod,
      clientId: reservationDetails.client_id,
      price: reservationDetails.prices.stay_charge_without_climatic,
      items: getAllReservationCartItems(
        reservationDetails,
        getReservationResort(reservationDetails),
        getReservationAccommodationType(reservationDetails),
      ),
    })
  }

  const sendPurchaseEvent = (reservationDetails: ReservationDetails) => {
    purchase({
      clientId: reservationDetails.client_id,
      numberOfDays: differenceInDays(parseISO(reservationDetails.date_to), parseISO(reservationDetails.date_from)),
      dateRange: formatToGtmEventDate(reservationDetails),
      transactionId: reservationDetails.reservation_number,
      price: reservationDetails.prices.stay_charge_without_climatic,
      items: getAllReservationCartItems(
        reservationDetails,
        getReservationResort(reservationDetails),
        getReservationAccommodationType(reservationDetails),
      ),
    })
  }

  return {
    sendPurchaseEvent,
    sendAddPaymentInfoEvent,
  }
}

const getAllReservationCartItems = (
  reservation: ReservationDetails,
  resort: Resort | undefined,
  accommodationType: AccommodationType | undefined,
) => {
  const reservationStayItem = createReservationCartItem(
    reservation.selected_apartment_id,
    resort,
    accommodationType,
    reservation.apartment_name,
    reservation,
  )

  const hasFeeding = reservation.prices.feeding.items.length

  const feeding = hasFeeding ? [createImprovementCartItem(reservation.prices.feeding.items[0], 1)] : []
  const improvements = reservation.prices.improvements.items.map((improvement, index) =>
    createImprovementCartItem(improvement, hasFeeding ? index + 2 : index + 1),
  )

  return [reservationStayItem, ...feeding, ...improvements]
}

export const createImprovementCartItem = (improvement: Feeding | Improvement, index?: number): EcommerceEventItem => {
  const isFeeding = improvement.code.startsWith('feeding__')
  const [, kind] = improvement.code.split('__')

  const isFeedingImprovement = 'code' in improvement ? isFeeding : true

  return createGtmCartItem({
    quantity: 'amount' in improvement ? improvement.amount : 1,
    index: index ?? 0,
    price: improvement.price_brutto,
    item_category: isFeedingImprovement ? GTMItemCategory.Feeding : GTMItemCategory.Improvement,
    item_variant: isFeedingImprovement ? kind : improvement.code,
    item_id: isFeedingImprovement ? kind : improvement.code,
    item_list_name: 'ulepszenia',
    item_name: improvement.name,
  })
}

export const createReservationCartItem = (
  apartmentId: number | null,
  resort: Resort | undefined,
  accommodationType: AccommodationType | undefined,
  apartmentName: string | null,
  reservation: ReservationDetails,
): EcommerceEventItem => ({
  ...createGtmCartItem({
    item_id: resort?.id ?? '',
    item_name: resort?.name ?? '',
    item_category: reservation.seller?.department === 'ecommerce' ? GTMItemCategory.Ecommerce : GTMItemCategory.Resort,
    item_category2: apartmentId ? `Lokal numer ${apartmentName}` : 'rezygnacja',
    item_variant: `${accommodationType?.name} ${accommodationType?.size}m²`,
    item_list_name: 'rezerwuj',
    price: reservation.prices.stay_charge_without_climatic,
    quantity: 1,
  }),
})
