import * as React from 'react'
import { Button } from 'react-bootstrap'

interface Props {
  leafletUrl: string | null
  wrapperClassName?: string
}

export const PackageWholesaleLeafletButton = ({ leafletUrl, wrapperClassName }: Props): JSX.Element | null => {
  if (!leafletUrl) return null

  return (
    <a href={leafletUrl} target="_blank" className={wrapperClassName}>
      <Button className="btn-primary py-2 px-3">
        <img src={require('@assets/images/adobe-acrobat.svg')} alt="pdf" height={20} />
        <strong className="text-white font-xs fw-bold lh-1 ms-2">Otwórz ulotkę</strong>
      </Button>
    </a>
  )
}
