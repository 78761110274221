import { CardContentBase } from '@components/card-content-base'
import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { CircleCheck } from '@components/circle-check'
import { declination } from '@helpers/declination'
import { SubscriptionDetailsContentOptions } from '@modules/subscription/details/subscription-details-content-options'
import { GoHolidayDetailsDetailsContentProductInfo } from '@modules/go-holiday/details/go-holiday-details-content-product-info'
import { GoHolidayDetailsContentInformation } from '@modules/go-holiday/details/go-holiday-details-content-information'
import { AccommodationType } from '@models/app-data'

interface Props {
  staysCount: number
  subscriptionDetails: SubscriptionDetails
  accommodationTypeName: string | undefined
  accommodationType: AccommodationType | undefined
  onScrollToPayment: () => void
  expiration: string
}

export const GoHolidayDetailsContent = React.forwardRef(
  (
    { subscriptionDetails, accommodationTypeName, staysCount, accommodationType, onScrollToPayment, expiration }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => {
    const profits = [
      <span key="stays">
        <strong>
          {staysCount} {declination.stayDays(staysCount)} {subscriptionDetails.defaults_days + 1}-dniowe
        </strong>{' '}
        dla {accommodationType?.display_max_guests} osób ({accommodationType?.display_max_adult_guests} os. dorosł.)
      </span>,
      <span key="new-locals">
        dodatkowa <strong>specjalna pula lokali</strong>
      </span>,
      <span key="maturity">
        możliwość wykorzystania pakietu <strong>{expiration}</strong>
      </span>,
      <span key="resorts">
        <strong>7 resortów</strong> do wyboru
      </span>,
      <span key="high-season">
        możliwość odbycia krótszego pobytu
        <strong> w najwyższym sezonie</strong>
      </span>,
    ]

    return (
      <div>
        <CardContentBase
          title={
            <span>
              Potwierdzenie zakupu pakietu {staysCount} {declination.stayDays(staysCount)} w {accommodationTypeName} w
              <span className="text-nowrap"> GO!Holiday</span>
            </span>
          }
          client={subscriptionDetails.client}
          seller={subscriptionDetails.seller}
          created={subscriptionDetails.created || ''}
          isProductPaid={subscriptionDetails.is_paid}
          onScrollToPayment={onScrollToPayment}
          productInvoice={subscriptionDetails}
        >
          <GoHolidayDetailsDetailsContentProductInfo subscriptionDetails={subscriptionDetails} />
        </CardContentBase>
        <hr className="mx-n5" />
        <div className="mb-5" ref={ref}>
          <strong className="text-secondary font-xxl text-center text-sm-start d-block">
            Co otrzymujesz korzystając z oferty{' '}
            <span className="underline-shadow is-gold text-primary">GO!Holiday?</span>
          </strong>
          <div className="mt-3 row">
            {profits.map((profit, index) => (
              <CircleCheck key={index} className="col-12 col-md-4 mb-2">
                <span className="font-md lh-initial col-12 col-md-8">
                  <span className="text-muted">{profit}</span>
                </span>
              </CircleCheck>
            ))}
          </div>
          {!!subscriptionDetails.options.length && (
            <SubscriptionDetailsContentOptions
              options={subscriptionDetails.options}
              status={subscriptionDetails.status}
            />
          )}
        </div>
        <GoHolidayDetailsContentInformation subscriptionDetails={subscriptionDetails} isCollapsable />
      </div>
    )
  },
)
