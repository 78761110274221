import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { FieldPath, useFormContext } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { ClientDetails } from '@models/client'
import { setClientDetails } from '@store/slices/client-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'

interface Response {
  redirectToPasswordReset: () => void
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  isLoginPending: boolean
}

export const useLogin = (): Response => {
  const { urls } = useSelector(selectAppData)
  const { setError, clearErrors } = useFormContext()

  const dispatch = useDispatch()

  const { action: login, isLoading: isLoginPending } = useFormRequest<void>(
    async <T extends FieldValues>(username: string, password: string) => {
      clearErrors('password' as FieldPath<T>)

      try {
        const { client_details } = await commonObjectPost<{ client_details: ClientDetails }>(urls.account.login, {
          username,
          password,
        })

        dispatch(setClientDetails(client_details))
      } catch (error) {
        if (error.response?.status === 403) {
          setError('password' as FieldPath<T>, { message: 'Nieprawidłowe dane' })
        } else {
          throw error
        }
      }
    },
    setError,
  )

  const redirectToPasswordReset = () => {
    window.open(CLIENT_APP_URLS.RESET_PASSWORD, '_blank')
  }

  const logout = async () => {
    await commonObjectPost(urls.account.logout)
    dispatch(setClientDetails(null))
  }

  return { redirectToPasswordReset, login, isLoginPending, logout }
}
