import React from 'react'
import { SubscriptionContractDetails, SubscriptionContractPlan } from '@modules/subscription-contract/models'
import { getSubscriptionContractFirstPlanData } from '@modules/subscription-contract/plans/subscription-contract-first-plan-data'
import { getSubscriptionContractSecondPlanData } from '@modules/subscription-contract/plans/subscription-contract-second-plan-data'
import { getSubscriptionContractThirdPlanData } from '@modules/subscription-contract/plans/subscription-contract-third-plan-data'
import { getSubscriptionContractFourthPlanData } from '@modules/subscription-contract/plans/subscription-contract-fourth-plan-data'
import { getSubscriptionContractFifthPlanData } from '@modules/subscription-contract/plans/subscription-contract-fifth-plan-data'
import { getSubscriptionContractSixthPlanData } from '@modules/subscription-contract/plans/subscription-contract-sixth-plan-data'
import { getSubscriptionContractSeventhPlanData } from '@modules/subscription-contract/plans/subscription-contract-seventh-plan-data'
import { getSubscriptionContractEighthPlanData } from '@modules/subscription-contract/plans/subscription-contract-eighth-plan-data'

export interface SubscriptionContractPlanData {
  headerTitle: string
  headerSubtitle: React.ReactNode
  headerBenefits: string[]
  contentTitle: string
  contentBenefits: React.ReactNode[]
}

export const useSubscriptionContractPlanData = (
  subscriptionContractDetails: SubscriptionContractDetails | undefined,
): SubscriptionContractPlanData => {
  if (!subscriptionContractDetails) {
    return {
      headerTitle: '',
      headerSubtitle: '',
      headerBenefits: [],
      contentTitle: '',
      contentBenefits: [],
    }
  }

  const isConfirmed = subscriptionContractDetails.status === 'confirmed'

  const data: Record<SubscriptionContractPlan, SubscriptionContractPlanData> = {
    plan_1_old: getSubscriptionContractFirstPlanData(isConfirmed),
    plan_1: getSubscriptionContractFirstPlanData(isConfirmed),
    plan_2: getSubscriptionContractSecondPlanData(isConfirmed),
    plan_3: getSubscriptionContractThirdPlanData(isConfirmed),
    plan_4: getSubscriptionContractFourthPlanData(isConfirmed),
    plan_5: getSubscriptionContractFifthPlanData(isConfirmed),
    plan_6: getSubscriptionContractSixthPlanData(isConfirmed),
    plan_7: getSubscriptionContractSeventhPlanData(isConfirmed),
    plan_8: getSubscriptionContractEighthPlanData(isConfirmed),
  }

  return data[subscriptionContractDetails.plan]
}
