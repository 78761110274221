import * as React from 'react'
import { useProductDetails } from '@hooks/use-product-details'
import { HolidayBenefitInvitation, HolidayBenefitKind } from '@modules/holiday-benefit/employee-invitation/models'
import { IconWithText } from '@components/icon-with-text'
import { HolidayBenefitInvitationData } from '@modules/holiday-benefit/employee-invitation/data'
import { HolidayBenefitInvitationSteps } from '@modules/holiday-benefit/employee-invitation/steps'
import { HolidayBenefitInvitationSlides } from '@modules/holiday-benefit/employee-invitation/slides'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { selectAppData } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { FadeInView } from '@components/fade-in-view'
import { HolidayBenefitInvitationKindSelection } from '@modules/holiday-benefit/employee-invitation/kind-selection'

export interface HolidayBenefitInvitationFormInputs {
  first_name: string
  last_name: string
  username: string
  phone: string
  postcode: string
  street: string
  city: string
  password: string
  kind: HolidayBenefitKind
}

export const HolidayBenefitInvitationView = (): JSX.Element => {
  const [step, setStep] = React.useState(1)
  const { urls } = useSelector(selectAppData)

  const { PageWrapper, productDetails, token } = useProductDetails<HolidayBenefitInvitation>(
    urls.benefit_program.agreement_invite,
  )

  const methods = useForm<HolidayBenefitInvitationFormInputs>({
    mode: 'onChange',
    defaultValues: {},
  })

  return (
    <PageWrapper>
      <FadeInView className="container py-lg-5 py-4 d-flex gap-xl-4">
        <HolidayBenefitInvitationSlides />
        <div className="col-lg-6 col-12">
          <div className={clsx('col-12', { 'holiday-benefit__invitation__header': step === 2 })}>
            {step === 2 && (
              <IconWithText
                text="Wróć do oferty"
                iconClassName="uil-angle-left font-xxxl"
                className="holiday-benefit__invitation__back-button text-darker-gray"
                textClassName="fw-semibold font-lg d-none d-lg-inline"
                onClick={() => setStep(1)}
              />
            )}
            <img
              src={require('@assets/images/holiday-benefit/landing-page/logo.svg')}
              alt="My Holiday Benefit"
              height={35}
              className="d-block mx-auto"
            />
          </div>

          {step === 1 && (
            <h1 className="font-xxxl mt-5 fw-bold text-center">
              <span className="text-holiday-benefit-primary d-block">
                {productDetails?.benefit_program.name} zaprasza Cię
              </span>
              <span className="text-holiday-benefit-secondary">do MyHoliday Benefit</span>
            </h1>
          )}

          <HolidayBenefitInvitationSteps activeStep={step} />
          {productDetails && token && (
            <FormProvider {...methods}>
              {step === 1 && (
                <AnimatePresence>
                  <HolidayBenefitInvitationKindSelection
                    benefitProgram={productDetails?.benefit_program}
                    onNextStepClick={() => setStep(2)}
                  />
                </AnimatePresence>
              )}
              {step === 2 && (
                <AnimatePresence>
                  <HolidayBenefitInvitationData
                    token={token}
                    benefitInvitation={productDetails}
                    onPreviousStepClick={() => setStep(1)}
                  />
                </AnimatePresence>
              )}
            </FormProvider>
          )}
        </div>
      </FadeInView>
    </PageWrapper>
  )
}
