import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const CodeLabel = (): JSX.Element => (
  <div className="d-flex justify-content-between w-100">
    <span className="d-block">Wprowadź kod</span>
    <IconWithText
      text="Wciśnij ENTER aby zatwierdzić."
      iconClassName="uil-exclamation-circle me-2 text-primary font-lg lh-1"
      className="ms-auto font-xs mb-1"
    />
  </div>
)
