import * as React from 'react'
import { EndlessHolidayConfirmationGastroCard } from '@modules/product-package/models'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import clsx from 'clsx'
import { useGroupedEndlessGastroProducts } from '@modules/product-package/confirmation/use-grouped-endless-gastro-products'

interface Props {
  gastroCards: EndlessHolidayConfirmationGastroCard[]
  year: string
}

export const EndlessHolidayConfirmationBillGastroCards = ({ gastroCards, year }: Props): React.ReactNode => {
  const { groupedList, subscriptionGastroCards } = useGroupedEndlessGastroProducts(gastroCards, year)

  const getTotalPrice = (gastroCards: EndlessHolidayConfirmationGastroCard[]) =>
    sumDecimalArray(gastroCards.map(gastroCard => asDecimal(gastroCard.declared_sell_price))).toNumber()

  if (!subscriptionGastroCards.length) return null

  return (
    <div className="mt-3">
      {groupedList.map(([amount, gastroCards], index) => (
        <div
          key={amount}
          className={clsx('d-flex justify-content-between align-items-center text-darker-gray', {
            'mt-1': index !== 0,
          })}
        >
          <div className="fw-bold">
            {gastroCards.length > 1 ? 'Vouchery gastronomiczne' : 'Voucher gastronomiczny'} {formatPriceShort(amount)}{' '}
            {gastroCards.length > 1 ? `x${gastroCards.length}` : ''}
          </div>
          <div className="fw-bold">{formatPrice(getTotalPrice(gastroCards))}</div>
        </div>
      ))}
    </div>
  )
}
