import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ProductKinds } from '@components/payments/payment-selection-view'

interface PaymentSelectionKinds {
  showPaymentSelectionView: (token: string, kind: ProductKinds) => void
}

export const usePaymentSelection = (): PaymentSelectionKinds => {
  const navigate = useNavigate()

  const showPaymentSelectionView = (token: string, kind: ProductKinds) =>
    navigate(NavigationPath.PaymentSelectionView.replace(':token', token).replace(':kind', kind))

  return {
    showPaymentSelectionView,
  }
}
