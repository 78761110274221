import * as React from 'react'
import { PaymentFailureContact } from '@components/payment-operation-results/payment-failure-contact'
import * as clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'

export const ContactData = (): JSX.Element => {
  const appData = useSelector(selectAppData)

  return (
    <>
      <a href={`tel:${appData.company_info.call_center_phone_number.pl}`} className="payment-failure__phone">
        <PaymentFailureContact actionText="zadzwoń">
          <div className="position-relative">
            <i className="uil-phone font-xxl d-block me-2" />
            <span
              className={clsx('payment-failure__phone__call-center-indicator', {
                'payment-failure__phone__call-center-indicator--red': false,
              })}
            />
          </div>

          <div className="font-lg">
            <strong className="d-block">Kontakt telefoniczny</strong>
            <span className="d-block">{appData.company_info.call_center_phone_number.pl} (8:00 - 19:00)</span>
          </div>
        </PaymentFailureContact>
      </a>
      <a href={`mailto:${appData.company_info.call_center_email}`} className="text-decoration-none">
        <PaymentFailureContact actionText="wyślij e-mail">
          <i className="uil-phone font-xxl d-block me-2" />
          <div className=" font-lg">
            <strong className="d-block">Kontakt mailowy</strong>
            <span className="d-block">{appData.company_info.call_center_email}</span>
          </div>
        </PaymentFailureContact>
      </a>
    </>
  )
}
