import * as React from 'react'
import { PackageWholesaleSideCardPricesRow } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-row'
import { PackageWholesaleProductFormInput } from '@modules/package-wholesale/details'
import { PackageWholesaleProductOption } from '@modules/package-wholesale/models'
import { asDecimal } from '@helpers/utils'

interface Props {
  product: PackageWholesaleProductFormInput
}

export const PackageWholesaleSideCardPricesOptions = ({ product }: Props): JSX.Element => {
  const productOptions = React.useMemo(() => getAddedProductOptions(product), [product])

  const getOptionPrice = (option: PackageWholesaleProductOption) =>
    asDecimal(product.amount).mul(asDecimal(option.price_brutto)).toString()

  return (
    <div className="ps-2">
      {productOptions.map((option: PackageWholesaleProductOption) => (
        <PackageWholesaleSideCardPricesRow
          key={option.id}
          name={option.kind_display}
          price={getOptionPrice(option)}
          wrapperClassName="opacity-75 font-sm"
        />
      ))}
    </div>
  )
}

export const getAddedProductOptions = (product: PackageWholesaleProductFormInput): PackageWholesaleProductOption[] =>
  Object.entries(product.options).reduce((added, [key, value]) => {
    const option = product.product.options.find(el => el.kind === key)
    if (value && option) return [...added, option]
    return added
  }, [])
