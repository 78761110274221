import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  isActive: boolean
  children?: React.ReactNode
  contentClassName?: string
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

export const CheckButton = ({
  title,
  description,
  isActive,
  children,
  contentClassName,
  className,
  onClick,
}: Props): React.ReactNode => (
  <button
    type="button"
    onClick={onClick}
    className={clsx('d-flex align-items-center check-button', { 'is-active': isActive }, className)}
  >
    <div className="check-button__icon-wrapper">
      <i className="uil-check check-button__icon" />
    </div>
    <div className={clsx('check-button__text-wrapper', contentClassName)}>
      <div>
        <strong className="d-block">{title}</strong>
        {description && <span className="font-size-sm">{description}</span>}
      </div>
      {children}
    </div>
  </button>
)
