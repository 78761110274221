import { CircleProgress } from '@components/circle-progress'
import * as React from 'react'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { Spinner } from '@components/spinner'
import { add, differenceInHours } from 'date-fns'
import { asDecimal } from '@helpers/utils'

interface Props {
  expirationDate: string
  messageText?: React.ReactNode
  descriptionText?: React.ReactNode
}

export const ExpirationTimeLabel = ({ expirationDate, messageText, descriptionText }: Props): React.ReactNode => {
  const { remainingTime, isLoading } = useRemainingTime(expirationDate, remainingTimeDateFormatter)

  return (
    <div className="bg-danger d-flex gap-3 p-3 mt-4 rounded align-items-center">
      <CircleProgress progress={20} className="position-relative">
        <span className="reservation-details__side-card__remaining-time">
          {isLoading ? <Spinner className="text-white spinner-border-sm" /> : remainingTime}
        </span>
      </CircleProgress>
      <div className="font-md text-white">
        <strong className="d-block lh-1">{messageText}</strong>
        <span className="d-block font-sm lh-1 mt-1">{descriptionText}</span>
      </div>
    </div>
  )
}

const remainingTimeDateFormatter = duration => {
  const MAXIMUM_HOURS_TO_SHOW = 999

  const { minutes } = duration

  const hoursLeft = differenceInHours(add(new Date(), duration), new Date())

  if (asDecimal(hoursLeft).gt(0)) {
    const hoursToShow = asDecimal(hoursLeft)
    return `${hoursToShow.gt(MAXIMUM_HOURS_TO_SHOW) ? `+${MAXIMUM_HOURS_TO_SHOW}` : hoursLeft}h`
  }

  if (asDecimal(hoursLeft).eq(0)) {
    return `${minutes}m`
  }

  return '0h'
}
