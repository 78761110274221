/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModalConfig, ModalParams, ModalTypes } from '@components/modals/types'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'

interface ModalItem {
  modal: ModalTypes
  params: ModalParams
  isOpen: boolean
  config?: ModalConfig
}

const modalAdapter = createEntityAdapter<ModalItem>({ selectId: modalItem => modalItem.modal })

export interface ModalState {
  modals: EntityState<ModalItem>
}

const initialState: ModalState = {
  modals: modalAdapter.getInitialState(),
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal(state: any, action: PayloadAction<ModalItem>) {
      modalAdapter.addOne(state.modals, action.payload)
    },
    setHideModal(state: any, action: PayloadAction<Pick<ModalItem, 'modal'>>) {
      modalAdapter.removeOne(state.modals, action.payload.modal)
    },
    setModalIsOpen(state: any, action: PayloadAction<Pick<ModalItem, 'modal' | 'isOpen'>>) {
      const modal = modalAdapter.getSelectors().selectById(state.modals, action.payload.modal)

      if (modal) {
        modalAdapter.upsertOne(state.modals, { ...modal, isOpen: action.payload.isOpen })
      }
    },
    hideModals(state: any) {
      modalAdapter.updateMany(
        state.modals,
        state.modals.ids.map(id => ({ id, changes: { isOpen: false } })),
      )
    },
  },
})

export const { setModalIsOpen, setHideModal, setShowModal, hideModals } = modalSlice.actions

export const { selectAll: modalsSelector, selectById: findModalSelector } = modalAdapter.getSelectors(
  (state: RootState) => state.modalState.modals,
)
export default modalSlice.reducer
