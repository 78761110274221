import * as React from 'react'
import { Button, ModalBody } from 'react-bootstrap'
import { BaseModalProps } from '@components/modals/types'
import { ContactData } from '@components/contact-data'

export const GlobalErrorModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => (
  <ModalBody className="d-flex flex-column justify-content-center py-5">
    <img src={require('@assets/images/error.svg')} alt="Błąd" height="100" />
    <h3 className="text-danger text-center my-4">Wystąpił błąd</h3>

    <p className="text-muted font-lg col-sm-9 mx-auto text-center mb-1">Przepraszamy. Wystąpił nieoczekiwany błąd.</p>

    <p className="text-muted font-lg col-sm-7 mx-auto text-center">
      Spróbuj ponownie za chwilę lub skontaktuj się z
      <span className="fw-semibold text-nowrap"> Biurem Obsługi Klienta</span>.
    </p>

    <div className="col-sm-9 col-11 mx-auto mb-3">
      <ContactData />
    </div>

    <Button className="btn-primary mt-3 py-3 text-white fw-semibold col-9 mx-auto" onClick={toggleIsVisible}>
      Rozumiem
    </Button>
  </ModalBody>
)
