import * as React from 'react'
import { EndlessHolidayProduct } from '@modules/product-package/models'
import { Checkbox } from '@components/controls/checkbox'
import { formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/product-package/details'
import { useFormContext, useWatch } from 'react-hook-form'

export const STAY_DATE_CHANGE_OPTION_PRICE = 99

interface Props {
  subscriptionProduct: EndlessHolidayProduct
}

export const ProductVariantStayOptions = ({ subscriptionProduct }: Props): React.ReactNode => {
  const { control, setValue } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const isChecked = products.some(
    (product: EndlessHolidayFormInputProduct) =>
      product.product.id === subscriptionProduct.id &&
      product.options.some(option => option.kind === 'single_client_date'),
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      'products',
      products.map((product: EndlessHolidayFormInputProduct) => {
        if (product.product.id === subscriptionProduct.id) {
          return {
            ...product,
            options: event.target.checked
              ? [{ kind: 'single_client_date' as const, price: STAY_DATE_CHANGE_OPTION_PRICE }]
              : [],
          }
        }

        return product
      }),
    )
  }

  return (
    <div className="border rounded bg-white p-2 mt-3">
      <Checkbox
        controlled
        className="checkbox--small user-select-none"
        name={`options.${subscriptionProduct.id}`}
        checked={isChecked}
        onChange={handleChange}
        label={
          <span className="font-sm font-500 d-flex align-items-center">
            Jednorazowa możliwość zmiany terminu rezerwacji z użyciem ww. kodu pobytowego za{' '}
            {formatPriceShort(STAY_DATE_CHANGE_OPTION_PRICE)}
          </span>
        }
      />
    </div>
  )
}
