import { ClientInvoiceData } from '@models/models'

export interface NipSearchResponse {
  city: string
  name: string
  postal_code: string
  street_address: string
  tax_id: string
}

export const createCompanyFormDataFromNipSearch = (searchResponse: NipSearchResponse): Partial<ClientInvoiceData> => ({
  invoice_city: searchResponse.city,
  invoice_nip: searchResponse.tax_id,
  invoice_company: searchResponse.name,
  invoice_postcode: searchResponse.postal_code,
  invoice_street: searchResponse.street_address,
})

interface InvoiceFormLabels {
  nip: string
  address: string
  name: string
  header: string
}

export const getFormInputLabelsForType = (invoiceType: 'individual' | 'company'): InvoiceFormLabels =>
  ({
    individual: {
      nip: 'Numer PESEL',
      address: 'Adres',
      header: 'Wprowadź swoje prawidłowe dane osobowe',
      name: 'Imię i Nazwisko',
    },
    company: {
      nip: 'Numer NIP',
      address: 'Adres firmy',
      header: 'Wprowadź prawidłowe dane firmy',
      name: 'Nazwa firmy',
    },
  }[invoiceType])
