import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { AppHeaderContent } from '@components/app-header-content'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import { EndlessHolidayConfirmationDetails } from '@modules/product-package/models'

interface Props {
  onScrollToBill: () => void
  productDetails: EndlessHolidayConfirmationDetails
}

export const EndlessHolidayConfirmationHeaderSectionContent = ({
  onScrollToBill,
  productDetails,
}: Props): React.ReactNode => (
  <AppHeaderContent
    title={
      <>
        Podsumowanie zamówienia <br />{' '}
        {productDetails.kind === 'endless_holiday' ? productDetails.number : 'Świątecznych prezentów w Holiday'}
      </>
    }
    subtitle={
      <span className="font-lg">
        <strong className="d-block">Dziękujemy za złożenie zamówienia</strong>
        <span>w dniu {formatDate(productDetails.created, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}</span>
      </span>
    }
  >
    <div className="mt-4 d-flex justify-content-center justify-content-sm-start flex-wrap col-12">
      <IconWithText
        text="Zobacz podsumowanie"
        iconClassName="uil-plus font-xxxl me-2"
        className="text-gold font-size-xl cursor-pointer fw-bold"
        onClick={onScrollToBill}
      />
    </div>
  </AppHeaderContent>
)
