import { PaymentMethod } from '@models/app-data'
import { GTMItemCategory } from '@models/gtm'

import { createGtmCartItem, useGtmEvents } from '@hooks/gtm/use-gtm-events'
import { SubscriptionDetails } from '@modules/subscription/models'

type ItemListName = 'pakiet-korzysci' | 'go-holiday'

interface Response {
  sendPurchaseEvent: (subscription: SubscriptionDetails, itemListName: ItemListName) => void
  sendAddPaymentInfoEvent: (
    paymentMethod: PaymentMethod,
    subscription: SubscriptionDetails,
    itemListName: ItemListName,
  ) => void
  sendBeginCheckoutEvent: (step: number, subscription: SubscriptionDetails, itemListName: ItemListName) => void
}

export const useSubscriptionProductGtmEvents = (): Response => {
  const { purchase, addPaymentInfo, beginCheckout } = useGtmEvents()

  const sendAddPaymentInfoEvent = (
    paymentMethod: PaymentMethod,
    subscription: SubscriptionDetails,
    itemLIstName: ItemListName,
  ) => {
    addPaymentInfo({
      paymentMethod,
      clientId: subscription.client.id,
      price: subscription.product.price_brutto,
      items: createSubscriptionProductCartItem(subscription, itemLIstName),
    })
  }

  const sendPurchaseEvent = (subscription: SubscriptionDetails, itemListName: ItemListName) => {
    purchase({
      clientId: subscription.client.id,
      transactionId: subscription.number,
      price: subscription.product.price_brutto,
      items: createSubscriptionProductCartItem(subscription, itemListName),
    })
  }

  const sendBeginCheckoutEvent = (step: number, subscription: SubscriptionDetails, itemListName: ItemListName) => {
    beginCheckout({
      clientId: subscription.client.id,
      price: subscription.product.price_brutto,
      items: createSubscriptionProductCartItem(subscription, itemListName),
      step,
    })
  }

  return {
    sendPurchaseEvent,
    sendAddPaymentInfoEvent,
    sendBeginCheckoutEvent,
  }
}

const createSubscriptionProductCartItem = (subscription: SubscriptionDetails, itemListName: ItemListName) => [
  createGtmCartItem({
    item_id: subscription.id,
    item_name: subscription.product.name,
    item_category: GTMItemCategory.Ecommerce,
    item_variant: subscription.type,
    item_list_name: itemListName,
    price: subscription.product.price_brutto,
    quantity: 1,
  }),
]
