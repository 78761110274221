import { AxiosError } from 'axios'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { useModal } from '@components/modals/use-modal'

interface RequestError extends Error {
  non_field_errors: string[]
}

export interface AxiosErrorHandlerConfig<T> {
  excludeFields?: string[]
  nonFieldErrorsAs?: Path<T>
  showGlobalError?: boolean
}

export const useAxiosErrorHandler = <TFieldValues extends FieldValues>(): ((...params) => void) => {
  const initialConfiguration: AxiosErrorHandlerConfig<TFieldValues> = { excludeFields: [], showGlobalError: true }

  const [showGlobalErrorModal] = useModal('GlobalErrorModal')

  return (
    error: AxiosError<RequestError>,
    setErrors: UseFormSetError<TFieldValues>,
    configuration: AxiosErrorHandlerConfig<TFieldValues> = initialConfiguration,
  ) => {
    if (!configuration.excludeFields) {
      configuration.excludeFields = []
    }

    if (configuration.nonFieldErrorsAs) {
      configuration.excludeFields.push('non_field_errors')
    }

    if (error.response?.status === 400) {
      if (error.response.data.non_field_errors && configuration.nonFieldErrorsAs) {
        setErrors(configuration.nonFieldErrorsAs, {
          type: 'server',
          message: error.response.data.non_field_errors.join('. '),
        })
      }

      Object.keys(error.response.data).map((key: Path<TFieldValues>) => {
        if (!configuration.excludeFields?.includes(key)) {
          const message = error.response?.data[key as string]
          if (typeof message[0] === 'object') {
            handleAxiosFormErrorsNestedObject(key, message, setErrors)
          } else {
            setErrors(key, {
              type: 'server',
              message: (message || []).join('. '),
            })
          }
        }
      })
    }

    if (error.response?.status === 500) {
      showGlobalErrorModal()
    }
  }
}

const handleAxiosFormErrorsNestedObject = (fieldName, message, setErrors) => {
  message.map((validation, index) => {
    Object.keys(validation).map(key => {
      const newKey = `${fieldName}.${index}.${key}`
      setErrors(newKey, {
        type: 'server',
        message: validation[key].join('. '),
      })
    })
  })
}
