import * as React from 'react'
import { SideCard } from '@components/side-card'
import { PackageWholesaleFormInput, PackageWholesaleProductFormInput } from '@modules/package-wholesale/details'
import { formatPrice } from '@helpers/price-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { usePackageWholesalePrices } from '@modules/package-wholesale/use-package-wholesale-prices'
import { useSubscriptionProductName } from '@modules/package-wholesale/details/subscription/use-subscription-product-name'
import { PackageWholesaleDetails, PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { PackageWholesaleSideCardExpirationBanner } from '@modules/package-wholesale/details/package-wholesale-side-card-expiration-banner'
import { PackageWholesaleSideCardPricesRow } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-row'
import { PackageWholesaleSideCardPricesOptions } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-options'
import { PackageWholesaleSideCardPricesVariants } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-variants'

interface ActiveProducts {
  gastro_card: PackageWholesaleProductFormInput[]
  gastro_card_2024: PackageWholesaleProductFormInput[]
  gastro_card_2025: PackageWholesaleProductFormInput[]
  gastro_card_boost: PackageWholesaleProductFormInput[]
  subscription: PackageWholesaleProductFormInput[]
}

const addSpecificKind = (product: PackageWholesaleProductFormInput, items: ActiveProducts) => ({
  ...items,
  [product.product.kind]: [...items[product.product.kind], product],
})

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleSideCardPrices = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const { control } = useFormContext<PackageWholesaleFormInput>()

  const { getSubscriptionPrice, getTotalPrice } = usePackageWholesalePrices()
  const { getSubscriptionProductName } = useSubscriptionProductName()

  const items = useWatch({ control, name: 'products' })

  const activeProducts: ActiveProducts = React.useMemo(
    () =>
      items.reduce((cumulated, item) => (item.active ? addSpecificKind(item, cumulated) : cumulated), {
        gastro_card: [],
        gastro_card_2024: [],
        gastro_card_2025: [],
        gastro_card_boost: [],
        subscription: [],
      }),
    [items],
  )

  const hasSubscription = packageWholesaleDetails.products.some(
    (product: PackageWholesaleProduct) => product.kind === 'subscription',
  )

  const hasGastroVoucher = packageWholesaleDetails.products.some(
    (product: PackageWholesaleProduct) => product.kind === 'gastro_card',
  )

  const hasGastro2024Voucher = packageWholesaleDetails.products.some(
    (product: PackageWholesaleProduct) => product.kind === 'gastro_card_2024',
  )

  const hasGastro2025Voucher = packageWholesaleDetails.products.some(
    (product: PackageWholesaleProduct) => product.kind === 'gastro_card_2025',
  )

  const hasGastroBoostVoucher = packageWholesaleDetails.products.some(
    (product: PackageWholesaleProduct) => product.kind === 'gastro_card_boost',
  )

  const isEveryProductGray = activeProducts.subscription.every(product => product.product.subscription_kind === 'gray')

  return (
    <SideCard className="d-lg-block d-none col-12 subscription-variant__price-side-card">
      <img
        src={require('@assets/images/sidebar.jpg')}
        alt="Holiday Park & Resort"
        width="100%"
        height={180}
        className="subscription-variant__price-side-card__image"
      />
      <div className="card subscription-variant__price-side-card__content">
        <h5 className="px-3 mt-3 font-lg">Twoje vouchery:</h5>
        <ul className="ps-0 mt-2 mb-0">
          {isEveryProductGray ? (
            activeProducts.subscription.map(product => (
              <PackageWholesaleSideCardPricesRow
                key={product.product.id}
                amount={product.amount}
                title="Voucher pobytowy"
                name={getSubscriptionProductName(product.product)}
                price={getSubscriptionPrice(product)}
                wrapperClassName="font-md mb-3"
              >
                {!!product.options && <PackageWholesaleSideCardPricesOptions product={product} />}
              </PackageWholesaleSideCardPricesRow>
            ))
          ) : (
            <PackageWholesaleSideCardPricesVariants products={activeProducts.subscription} />
          )}

          {!activeProducts.subscription.length && hasSubscription && (
            <li className="font-sm px-3">Brak dodanych voucherów pobytowych</li>
          )}

          {hasGastroVoucher && (
            <>
              <hr />
              {activeProducts.gastro_card.length ? (
                activeProducts.gastro_card.map(product => (
                  <PackageWholesaleSideCardPricesRow
                    key={product.product.id}
                    amount={1}
                    title="Voucher podwójna radość"
                    name={product.product.name}
                    price={product.amount}
                    wrapperClassName="font-md mb-3"
                  />
                ))
              ) : (
                <li className="font-sm px-3">Brak dodanych voucherów podwójna radość</li>
              )}
            </>
          )}

          {hasGastro2024Voucher && (
            <>
              <hr />

              {activeProducts.gastro_card_2024.length ? (
                activeProducts.gastro_card_2024.map(product => (
                  <PackageWholesaleSideCardPricesRow
                    key={product.product.id}
                    amount={1}
                    title="Voucher podwójna radość 2024"
                    name={product.product.name}
                    price={product.amount}
                    wrapperClassName="font-md mb-3"
                  />
                ))
              ) : (
                <li className="font-sm px-3">Brak dodanych voucherów podwójna radość 2024</li>
              )}
            </>
          )}

          {hasGastro2025Voucher && (
            <>
              <hr />

              {activeProducts.gastro_card_2025.length ? (
                activeProducts.gastro_card_2025.map(product => (
                  <PackageWholesaleSideCardPricesRow
                    key={product.product.id}
                    amount={1}
                    title="Voucher podwójna radość"
                    name={product.product.name}
                    price={product.amount}
                    wrapperClassName="font-md mb-3"
                  />
                ))
              ) : (
                <li className="font-sm px-3">Brak dodanych voucherów podwójna radość 2025</li>
              )}
            </>
          )}

          {hasGastroBoostVoucher && (
            <>
              <hr />
              {activeProducts.gastro_card_boost.length ? (
                activeProducts.gastro_card_boost.map(product => (
                  <PackageWholesaleSideCardPricesRow
                    key={product.product.id}
                    amount={1}
                    title="Voucher doładowanie smaku"
                    name={product.product.name}
                    price={product.amount}
                    wrapperClassName="font-md mb-3"
                  />
                ))
              ) : (
                <li className="font-sm px-3">Brak dodanych voucherów doładowanie smaku</li>
              )}
            </>
          )}

          <hr className="mb-0" />
          <li className="font-lg p-3 subscription-variant__price-side-card__summary">
            <span className="font-500">Łącznie do zapłaty</span>
            <span className="col-5">
              <span className="text-end font-500 d-block">{formatPrice(getTotalPrice(items))}</span>
            </span>
          </li>
        </ul>
      </div>

      <PackageWholesaleSideCardExpirationBanner expireAfter={packageWholesaleDetails.expire_after} />
    </SideCard>
  )
}
