import * as React from 'react'
import { Resort } from '@models/app-data'
import { IconWithText } from '@components/icon-with-text'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { Gallery } from '@components/gallery'

interface Props {
  resort: Resort
}

export const ResortSectionRow = ({ resort }: Props): JSX.Element => (
  <div className="bg-white flex-fill package-wholesale__resort ">
    <Gallery
      showIndex={resort.reservation_images.length > 1}
      items={useResponsivenessImages(resort.reservation_images)}
      additionalClass="image-gallery--rounded-lg-left"
    />
    <div className="p-3">
      <div className="font-xl text-dark-blue font-700"> {resort.name}</div>
      <div className="d-flex flex-column font-700 font-xs pt-1" style={{ minHeight: 35 }}>
        {!!resort.house_amount && (
          <IconWithText
            text={`${resort.house_amount} domków`}
            iconClassName="uil-estate text-primary me-2"
            className="text-darker-gray font-500"
          />
        )}
        {!!resort.apartment_amount && (
          <IconWithText
            text={`${resort.apartment_amount} apartamentów`}
            iconClassName="uil-building text-primary me-2"
            className="text-darker-gray font-500"
          />
        )}
      </div>
    </div>
  </div>
)
