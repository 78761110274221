import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { OfferDetailsHeaderButton } from '@components/offer-details-button'
import { GastroProductKind } from '@modules/gastro-generic/models'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  kind: GastroProductKind
}

export const PackageWholesaleGastroCardHeaderSectionContent = ({
  packageWholesaleDetails,
  kind,
}: Props): JSX.Element | null => {
  const gastroProduct = useGastroProductProvider(kind)
  if (!gastroProduct) return null
  return (
    <AppHeaderContent
      title={
        <>
          Podwójna Radość od <span className="text-nowrap">Holiday Park & Resort</span> tylko dla{' '}
          {packageWholesaleDetails.display_name || packageWholesaleDetails.invoice_company}
        </>
      }
      subtitle={
        <span className="font-lg col-12 col-sm-8 d-block lh-sm">
          Skorzystaj ze specjalnej promocji na <strong>Voucher {gastroProduct.product_name}</strong> do wykorzystania w
          Restauracji, Bistro, Kawiarni, Słodkim Kąciku oraz Beach Barze w Holiday Park & Resort!
          <p>
            Wpłać minimum 500 zł, a my
            <strong> podwoimy wpłaconą przez Ciebie kwotę</strong>, którą wykorzystasz w strefie gastronomicznej w
            terminie {gastroProduct.date_from} - {gastroProduct.date_to}!
          </p>
        </span>
      }
    >
      <OfferDetailsHeaderButton
        href={
          packageWholesaleDetails[`leaflet_${gastroProduct.kind}`] ??
          'https://holidaypark.pl/asset-redirect/ulotka-hurt/'
        }
      />
    </AppHeaderContent>
  )
}
