import * as React from 'react'
import { HolidayBenefitBenefitUnderlineText } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-underline-text'

export const HolidayBenefitBenefitsSection = React.forwardRef(
  (_, ref: React.Ref<HTMLDivElement>): React.ReactNode => (
    <div className="holiday-benefit__benefits" ref={ref}>
      <div className="container d-flex gap-5">
        <div className="col-lg-5 col-12 mt-lg-5">
          <div className="holiday-benefit__section-title text-center text-sm-start">
            <strong className="d-block text-holiday-benefit-secondary">Co wyróżnia</strong>
            <strong className="text-holiday-benefit-primary d-block mt-1">My Holiday Benefits?</strong>
          </div>
          <div className="mt-5">
            <span className="font-xxl font-500 text-holiday-benefit-secondary">
              Korzyści dla{' '}
              <HolidayBenefitBenefitUnderlineText
                text="pracodawcy:"
                underlineColor="#d50c51"
                textClass="text-holiday-benefit-primary"
              />
            </span>

            <ul className="list-unstyled">
              {employeeBenefits.map((benefit, index) => (
                <li key={index} className="d-flex align-items-center mt-2">
                  <span className="uil-check holiday-benefit__benefits__point bg-holiday-benefit-primary" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <span className="font-xxl font-500 text-holiday-benefit-secondary">
              Korzyści dla{' '}
              <HolidayBenefitBenefitUnderlineText
                text="pracownika:"
                underlineColor="#951c81"
                textClass="text-holiday-benefit-tertiary"
              />
            </span>

            <ul className="list-unstyled">
              {employerBenefits.map((benefit, index) => (
                <li key={index} className="d-flex align-items-center mt-2">
                  <span className="uil-check holiday-benefit__benefits__point bg-holiday-benefit-tertiary" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-7 d-none d-lg-block">
          <img
            src={require('@assets/images/holiday-benefit/landing-page/benefits.webp')}
            alt="holidaypark logo"
            height="650"
            className="mw-100"
          />
        </div>
      </div>
    </div>
  ),
)

const employeeBenefits = [
  'Wzmacniasz motywację w Zespole',
  'Zwiększasz produktywność pracowników',
  'Przyczyniasz się do poprawy zdrowia psychicznego i fizycznego',
  'Zyskujesz lojalność i silniejsze więzi',
  'Rozwijasz atrakcyjność pakietu benefitów pozapłacowych',
  'Korzystasz z elastycznego modelu zarządzania kosztami, dostosowanego do budżetu firmy',
]

const employerBenefits = [
  'Osiąga lepszą równowagę między życiem zawodowym a prywatnym',
  'Zwiększa się jego zaangażowanie i chęć do pracy',
  'Otrzymuje dostęp do wysokiej jakości ośrodków wypoczynkowych',
  'Zyskuje możliwość współfinansowania pobytów z pracodawcą',
]
