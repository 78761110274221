import * as React from 'react'
import { SideCard } from '@components/side-card'
import { GastroCardDynamicSideCardOfferExpirationBanner } from '@modules/gastro-generic/details/gastro-dynamic/gastro-card-dynamnic-side-card-offer-expiration-banner'
import { GastroGenericProductDetails } from '@modules/gastro-generic/models'
import { useGastroProductMultipliers } from '@modules/gastro-generic/details/gastro-dynamic/use-gastro-product-multipliers'

interface Props {
  imgSource: string
  gastroCardDetails: GastroGenericProductDetails
}

export const GastroGenericSideCard = ({ imgSource, gastroCardDetails }: Props): JSX.Element => {
  const { currentOffer } = useGastroProductMultipliers(gastroCardDetails)

  const currentGastroDynamicOffer = gastroCardDetails.kind === 'gastro_card_dynamic' ? currentOffer : null

  return (
    <>
      <SideCard
        className="d-lg-block d-none w-100 position-relative"
        image={<img src={require(`@assets/images/${imgSource}`)} alt="Kupon gastronomiczny" />}
      />

      {currentGastroDynamicOffer && (
        <GastroCardDynamicSideCardOfferExpirationBanner
          gastroCardDetails={gastroCardDetails}
          gastroProductMultiplier={currentGastroDynamicOffer}
        />
      )}
    </>
  )
}
