import * as React from 'react'
import { OperationResult } from '@components/payment-operation-results/operation-result'
import { useParams } from 'react-router-dom'

type Params = 'kind'

export const OperationSuccess = (): JSX.Element => {
  const { kind } = useParams<Params>()

  return (
    <OperationResult title="Dziękujemy, proces zakończony sukcesem" image="success.svg">
      <p className="text-center font-lg text-darker-gray">
        {(kind && Title[kind]) ?? 'Szybko poszło! Cały proces przebiegł poprawnie'}
      </p>
      <a href="https://holidaypark.pl/" className="text-decoration-none">
        <button className="btn btn-responsive bg-secondary px-5 py-3 text-white mt-5 mx-auto d-block">
          <strong>Przejdź do strony holidaypark.pl</strong>
        </button>
      </a>
    </OperationResult>
  )
}

const Title = {
  'kupon-gastronomiczny': (
    <span>
      Szybko poszło! <strong>Voucher "Strzał w dziesiątkę"</strong> będzie aktywny po potwierdzeniu płatności.
    </span>
  ),
  'doladowanie-smaku': (
    <span>
      Szybko poszło! <strong>Voucher "Doładowanie smaku"</strong> będzie aktywny po potwierdzeniu płatności.
    </span>
  ),
}
