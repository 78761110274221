import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { AvailableRule, AvailableRuleKeyword } from '@models/app-data'

interface Response {
  getRules: (keyword: AvailableRuleKeyword[]) => (AvailableRule | undefined)[]
}

export const useRules = (): Response => {
  const { rules } = useSelector(selectAppData)

  const getRules = (keywords: AvailableRuleKeyword[]): (AvailableRule | undefined)[] =>
    keywords.map((keyword: AvailableRuleKeyword) => rules.find((rule: AvailableRule) => rule.keyword === keyword))

  return {
    getRules,
  }
}
