import * as React from 'react'
import * as clsx from 'clsx'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { RodoAcquiringPointConfirmation } from '@modules/rodo-agreements/models'
import { IconWithText } from '@components/icon-with-text'
import { ContentLoader } from '@components/content-loader'

type Params = 'token' | 'device'

export const RodoAgreementsConfirmationView = (): React.ReactNode => {
  const { token, device } = useParams<Params>()
  const navigate = useNavigate()

  const { rodo_acquiring_point } = useSelector(selectAppDataUrls)

  const [details, setDetails] = React.useState<RodoAcquiringPointConfirmation | null>(null)

  const { action: confirm } = useApiRequest(async () => {
    if (!token || !device) return
    try {
      setDetails(
        await commonObjectPost<RodoAcquiringPointConfirmation>(rodo_acquiring_point.confirm, { token, device }),
      )
    } catch (e) {
      if (e.response?.status === 404) navigate(NavigationPath.NotFound)
    }
  })

  React.useEffect(() => {
    confirm()
  }, [token, device])

  const isCompleted = details?.email_confirmed && details.phone_confirmed

  return (
    <ContentLoader isLoading={!details} className="is-full-vh">
      <div className="container pt-5">
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <img
            src={details?.rodo_acquiring_point.logo}
            alt="holidaypark logo"
            className="rodo-agreements__description-section__icon mx-auto mx-sm-0 d-block"
            height={50}
          />
          <div className="d-flex flex-sm-row flex-column align-items-center mt-3">
            <div className="col-sm-6 col-12 order-1 order-sm-0 px-3 px-sm-0">
              <div className="d-flex flex-column justify-content-between align-items-sm-start pb-5 mt-3 mt-sm-0">
                {isCompleted ? (
                  <h2
                    className="rodo-agreements__summary-page__title mb-0 lh-1 text-center text-sm-start fw-bold lh-sm"
                    style={{ color: details?.rodo_acquiring_point.color_primary }}
                  >
                    To już wszystko!
                    <div className="mt-1" style={{ color: details?.rodo_acquiring_point.color_secondary }}>
                      Dziękujemy za poprawne <br />
                      wypełnienie formularza
                    </div>
                  </h2>
                ) : (
                  <h2
                    className="font-xxxl mb-0 lh-1 text-center text-sm-start"
                    dangerouslySetInnerHTML={{ __html: details?.rodo_acquiring_point.confirm_page_title ?? '' }}
                  />
                )}
                <p
                  className="font-xl text-darker-gray lh-initial my-sm-5 my-3 col-sm-7 col-12 text-center text-sm-start"
                  dangerouslySetInnerHTML={{
                    __html: isCompleted
                      ? details?.rodo_acquiring_point.completed_page_text_1 ?? ''
                      : details?.rodo_acquiring_point.confirm_page_text_1 ?? '',
                  }}
                />
                <div className="mb-5">
                  <SourceRow
                    value={details?.email}
                    label="Adres e-mail"
                    confirmedValue="e-mail potwierdzony"
                    isConfirmed={details?.email_confirmed}
                  />
                  <SourceRow
                    value={details?.phone}
                    label="Numer telefonu"
                    confirmedValue="telefon potwierdzony"
                    isConfirmed={details?.phone_confirmed}
                  />
                </div>

                <p
                  className="mt-sm-5 mt-3 font-md text-darker-gray col-sm-8 col-12"
                  dangerouslySetInnerHTML={{
                    __html: isCompleted
                      ? details?.rodo_acquiring_point.completed_page_text_bottom
                      : details?.rodo_acquiring_point.confirm_page_text_bottom ?? '',
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-12 order-sm-1 order-0">
              <img
                src={details?.rodo_acquiring_point.image}
                alt="Vouchery Holiday Park & Resort"
                className="rodo-agreements__description-section__image px-3 pt-0"
              />
            </div>
          </div>
        </motion.div>
      </div>
    </ContentLoader>
  )
}

const SourceRow = ({ label, confirmedValue, isConfirmed, value }) => (
  <div className="mt-4 font-lg text-darker-gray">
    {label}:<strong className="d-sm-block font-xl ms-3 ms-sm-0">{value}</strong>
    <IconWithText
      text={isConfirmed ? confirmedValue : 'czeka na potwierdzenie'}
      iconClassName={clsx(
        'lh-1',
        isConfirmed ? 'uil-check text-success font-xxl me-1' : 'uil-hourglass text-danger font-xl me-2',
      )}
      textClassName="font-md font-500"
      className={clsx('mt-1', isConfirmed ? 'badge-success' : 'badge-danger')}
    />
  </div>
)

const variants = {
  hidden: { marginLeft: -350, opacity: 0 },
  visible: { marginLeft: 0, opacity: 1 },
}
