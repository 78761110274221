import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'

interface Props {
  inputName: string
  className?: string
  textClassName?: string
}

export const FormError = ({ inputName, className, textClassName }: Props): React.ReactNode => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[inputName]

  return hasError ? (
    <ErrorMessage
      message={errors[inputName]?.message?.toString() ?? ''}
      className={className}
      textClassName={textClassName}
    />
  ) : null
}

export const ErrorMessage = ({
  className,
  flexClassName,
  textClassName,
  message,
}: {
  className?: string
  flexClassName?: string
  textClassName?: string
  message: string
}): React.ReactNode => (
  <IconWithText
    className={clsx('text-danger align-items-center mt-2', className)}
    flexClassName={flexClassName}
    textClassName={clsx('font-xs fw-normal form-error-text', textClassName)}
    iconClassName="uil-info-circle me-2 align-self-start form-error-icon"
    text={message}
  />
)
