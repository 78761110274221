import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleSubscriptionContent } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content'
import { PackageWholesaleGastroCardContent } from '@modules/package-wholesale/details/gastro-card/package-wholesale-gastro-card-content'
import { PackageWholesaleGastroCardBoostContent } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-content'
import { PackageWholesaleProductKind, PackageWholesaleProductKinds } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleCombinedContent = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const hasProductKind = (kind: PackageWholesaleProductKind) =>
    packageWholesaleDetails.products.some(product => product.kind === kind && product.kind !== 'gastro_card_boost')

  const gastroCardContents = PackageWholesaleProductKinds.map(kind => {
    if (hasProductKind(kind)) {
      return (
        <PackageWholesaleGastroCardContent
          isCollapsable={true}
          kind={kind}
          basicInformationClassName="order-2 mt-3"
          packageWholesaleDetails={packageWholesaleDetails}
          key={kind}
        />
      )
    }
    return null
  })

  return (
    <div>
      {packageWholesaleDetails.products.some(product => product.kind === 'subscription') && (
        <PackageWholesaleSubscriptionContent isCollapsable={true} packageWholesaleDetails={packageWholesaleDetails} />
      )}
      {gastroCardContents}
      {packageWholesaleDetails.products.some(product => product.kind === 'gastro_card_boost') && (
        <>
          <hr className="mx-n5" />
          <PackageWholesaleGastroCardBoostContent
            isCollapsable={true}
            basicInformationClassName="order-2 mt-3"
            packageWholesaleDetails={packageWholesaleDetails}
          />
        </>
      )}
    </div>
  )
}
