import * as React from 'react'
import { FormControlProps } from 'react-bootstrap'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithLabel } from '@components/form-controls/form-control-with-label'
import { CustomFormControl } from '@components/form-controls/form-control'
import { MaskedFormControl, MaskedFormControlProps } from './masked-form-control'
import { FieldError } from 'react-hook-form'

export interface CustomFormGroupProps {
  label?: React.ReactNode
  inputName: string
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  maskedFormControlProps?: Omit<MaskedFormControlProps, 'name'>
  type?: string
  renderError?: (error: FieldError) => React.ReactNode
  children?: React.ReactNode
  formControlWrapperClassName?: string
}

export const CustomFormGroup = ({
  label,
  inputName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  maskedFormControlProps,
  renderError,
  formControlWrapperClassName,
  children,
}: CustomFormGroupProps): React.ReactElement => (
  <FormControlWithLabel
    inputName={inputName}
    label={label}
    formLabelProps={formLabelProps}
    formGroupProps={formGroupProps}
    isLoading={isLoading}
    isSucceed={isSucceed}
    isFailed={isFailed}
    inputSuffix={inputSuffix}
    renderError={renderError}
    formControlWrapperClassName={formControlWrapperClassName}
  >
    {maskedFormControlProps ? (
      <MaskedFormControl name={inputName} className={formControlProps?.className} {...maskedFormControlProps} />
    ) : (
      <CustomFormControl inputName={inputName} formControlProps={formControlProps} />
    )}
    {children}
  </FormControlWithLabel>
)
