import * as React from 'react'
import { HolidayBenefitHero } from '@modules/holiday-benefit/common/holiday-benefit-hero'

interface Props {
  onScrollToBenefitsSection: () => void
  onScrollToPlansSection: () => void
}

export const HolidayBenefitEmployerHero = ({
  onScrollToBenefitsSection,
  onScrollToPlansSection,
}: Props): React.ReactNode => (
  <HolidayBenefitHero
    onScrollToPlansSection={onScrollToPlansSection}
    title="Inwestuj w wypoczynek, zyskaj na zaangażowaniu"
    subtitle="Oferujemy innowacyjne rozwiązanie, które podniesie morale i zaangażowanie Twoich pracowników, a jednocześnie
            wzmocni markę Twojej firmy jako pracodawcy dbającego o dobrostan zespołu."
    points={[
      <>
        Vouchery na pobyt wypoczynkowy w obiektach <span className="text-nowrap">Holiday Park & Resort</span>
      </>,
      'Abonamentowy model płatności',
      'Elastyczny podział kosztów między pracodawcą a pracownikiem',
    ]}
    actionButton={
      <button
        className="btn btn-outline-light btn-holiday-benefit font-500 btn-responsive"
        onClick={onScrollToBenefitsSection}
      >
        Co nas wyróżnia?
      </button>
    }
  />
)
