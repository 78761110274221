import { GastroGenericProductDetails, GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatToWordsWithUppercaseLetterStart } from '@helpers/utils'
import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-formatter'

export const getGastroCardBaseData = (
  productDetails: GastroGenericProductDetails,
  gastroProduct: GastroProduct,
  offerName: string,
): GastroProductData => {
  const labelImageSrc = 'gastro-voucher-label.jpg'

  const productName = formatToWordsWithUppercaseLetterStart(gastroProduct.product_name)

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher “{offerName}”</strong> do
      wykorzystania we wszystkich otwartych punktach gastronomicznych (włącznie z restauracją) podczas pobytów w
      Resortach.
    </span>
  )

  const benefitsPoints = [
    'Zakup vouchera umożliwia dokonanie zakupów z oferty gastronomicznej do wysokości wpłaconej kwoty powiększonej o 100%.',
    'Ilość voucherów, jakie możesz kupić jest nieograniczona!',
    'Voucher możesz wykorzystać we wszystkich otwartych punktach gastronomicznych na terenie Resortu.',
    'Voucher jest wielokrotnego użytku, w różnych rezerwacjach.',
  ]

  const benefitsList = [
    'Minimalna wartość zamówienia to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku oraz w Restauracji!',
    `Voucher można wykorzystać podczas pobytów w terminie ${toDefaultDateFormat(
      productDetails.active_after,
    )} - ${toDefaultDateFormat(productDetails.expire_after)}.`,
  ]

  const alertText = `Voucher nie łączy się z promocją Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera ${offerName} można podpiąć kolejny dowolny kupon gastronomiczny.`

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
