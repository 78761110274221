import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  title: string
  icon: string
  value: React.ReactElement | string
}

export const ReservationDetailsInformationMobileRow = ({ title, icon, value }: Props): JSX.Element => (
  <div className="d-flex justify-content-between align-items-center gap-3 border-top py-3 mx-n4 px-4">
    <IconWithText text={title} iconClassName={clsx('me-2 font-xl', icon)} className="text-darker-gray" />
    <strong className="font-sm text-darker-gray">{value}</strong>
  </div>
)
