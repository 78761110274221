import * as React from 'react'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'
import { add, endOfYear, startOfToday, startOfYear } from 'date-fns'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleLeafletButton } from '@modules/package-wholesale/details/subscription/package-wholesale-leaflet-button'

interface Props {
  isCollapsable?: boolean
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleGastroCardBoostInformation = ({
  isCollapsable,
  packageWholesaleDetails,
}: Props): JSX.Element => {
  const dateFrom = add(startOfYear(startOfToday()), { years: 1 })
  const gastroProduct = packageWholesaleDetails.products.find(row => row.kind === 'gastro_card_boost')
  const dateTo = add(endOfYear(startOfToday()), { years: gastroProduct?.expire_after_years || 2 })

  return (
    <PackageWholesaleInformationWrapper
      isCollapsable={isCollapsable}
      bottomElement={
        <PackageWholesaleLeafletButton
          leafletUrl={packageWholesaleDetails.leaflet_gastro_card_boost}
          wrapperClassName="ms-3"
        />
      }
    >
      <p>
        Środki z vouchera można wykorzystywać podczas dowolnej ilości rezerwacji podczas pobytów w terminie{' '}
        <strong className="text-nowrap text-primary">
          od {toDefaultDateFormat(dateFrom)} do {toDefaultDateFormat(dateTo)}.
        </strong>
      </p>
      <p>
        Jeżeli jesteś subskrybentem i chcesz skorzystać z dodatkowego rabatu dla produktu Doładowanie Smaku, skontaktuj
        się z opiekunem.
      </p>
      <p>
        W przypadku chęci wręczenia zakupionych voucherów osobom trzecim, zaleca się wpłacić kilka mniejszych kwot, a
        nie jedną sumaryczną kwotę. Wpłata kilku mniejszych kwot spowoduje wygenerowanie osobnych kodów na wpłacone
        kwoty, które będzie można wręczyć osobom trzecim. Wpłata jednej sumarycznej kwoty spowoduje wygenerowanie
        jednego kodu na całość.
      </p>
      <p>
        Dla przykładu, jeśli kupujący pragnie obdarować 5 osób voucherem na 700 zł, musi zakupić 5 sztuk po 500 zł,
        wpłaca wtedy 2500 zł, a otrzymuje 5 kodów na 700 zł każdy.
      </p>
      <p>
        <strong>Uwaga!</strong> Jeśli potrzebujesz fakturę, koniecznie podaj NIP poniżej.
      </p>
    </PackageWholesaleInformationWrapper>
  )
}
