import * as React from 'react'
import clsx from 'clsx'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { parseISO } from 'date-fns'
import { GastroProductMultiplier } from '@modules/gastro-generic/models'
import { IconWithText } from '@components/icon-with-text'
import { Spinner } from '@components/spinner'

interface Props {
  gastroProductMultiplier: GastroProductMultiplier
  spinnerClassName?: string
}

export const GastroDynamicOfferExpirationTime = ({
  gastroProductMultiplier,
  spinnerClassName,
}: Props): React.ReactNode => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(
    parseISO(gastroProductMultiplier.active_to),
  )

  return (
    <IconWithText
      text={
        isRemainingTimeCalculating ? <Spinner className={clsx('spinner-border-sm', spinnerClassName)} /> : remainingTime
      }
      iconClassName={isRemainingTimeCalculating ? '' : 'uil-clock-five me-2 font-xl'}
      className="mt-1 ms-1"
    />
  )
}
