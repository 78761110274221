import { formatPrice } from '@helpers/price-helper'
import * as React from 'react'

interface Props {
  name: string | JSX.Element
  price: string
  pricePrefix?: string
  removeSeparator?: boolean
}

export const ReservationDetailsPricesItem = ({
  name,
  price,
  pricePrefix = '+',
  removeSeparator,
}: Props): JSX.Element => (
  <>
    <div className="d-flex justify-content-between gap-3 font-md my-2">
      <strong className="text-darker-gray d-block">{name}</strong>
      <strong className="text-darker-gray text-nowrap">
        {pricePrefix} {formatPrice(price)}
      </strong>
    </div>
    {!removeSeparator && <hr className="reservation-details__payment-bill__separator" />}
  </>
)
