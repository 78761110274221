import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { IconWithText } from '@components/icon-with-text'
import { useSubscriptionContractPlanData } from '@modules/subscription-contract/plans/use-subscription-contract-plan-data'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails | undefined
}

export const SubscriptionContractHeaderSectionContent = ({ subscriptionContractDetails }: Props): JSX.Element => {
  const { headerBenefits, headerTitle, headerSubtitle } = useSubscriptionContractPlanData(subscriptionContractDetails)

  return (
    <AppHeaderContent
      title={headerTitle}
      subtitle={<span className="font-lg  col-12 col-sm-8 d-block lh-sm">{headerSubtitle}</span>}
    >
      <div className="mt-4 d-flex flex-wrap col-sm-12 col-10 mx-auto">
        {headerBenefits.map(benefit => (
          <IconWithText
            key={benefit}
            text={benefit}
            iconClassName="uil-check font-xxxl me-2"
            className="text-primary fw-bold w-50"
          />
        ))}
      </div>
    </AppHeaderContent>
  )
}
