import * as React from 'react'
import { EcommerceLastMinuteLink } from '@components/booking-offers/booking-offers-section'
import { RESERVATION_APP_URL } from '@helpers/consts'
import { useBookingOffers } from '@components/booking-offers/use-booking-offers'
import { BookingOffersCard } from '@components/booking-offers/booking-offers-card'

const MAXIMUM_OFFERS_TO_SHOW = 6

export const HolidayBenefitEmployeeBookingOffers = (): React.ReactNode => {
  const { offers, isLoading } = useBookingOffers()

  const offersToShow = [...offers, ...offers, ...offers, ...offers, ...offers, ...offers].slice(
    0,
    MAXIMUM_OFFERS_TO_SHOW,
  )

  if (isLoading || !offers.length) return null

  return (
    <div className="container my-5 py-5 d-flex flex-lg-nowrap flex-wrap gap-5 justify-content-center">
      <div className="col-lg-4 col-12 text-center text-lg-start">
        <strong className="text-darker-gray font-xxxl lh-1">
          Sprawdź najbliższe <br /> wolne terminy
        </strong>
        <p className="mt-3 font-xl text-darker-gray opacity-75">
          Zarezerwuj swój pobyt lub <br /> sprawdź gotowe propozycje <br />w E-sklepie
        </p>
        <div className="d-flex gap-3 justify-content-center flex-column flex-sm-row flex-lg-column">
          <a href={RESERVATION_APP_URL} className="text-decoration-none">
            <button className="btn-holiday-benefit is-primary btn-responsive">Zarezerwuj pobyt</button>
          </a>
          <EcommerceLastMinuteLink>
            <button className="btn-holiday-benefit is-tertiary btn-responsive">Sprawdź w E-sklepie</button>
          </EcommerceLastMinuteLink>
        </div>
      </div>
      <div className="row g-4 col-lg-8 col-12">
        {offersToShow.map(offer => (
          <BookingOffersCard key={offer.id} offer={offer} />
        ))}
      </div>
    </div>
  )
}
