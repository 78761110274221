import { useGtm } from '@hooks/gtm/use-gtm'
import sha256 from 'crypto-js/sha256'
import { TradeDoublerEventData } from '@models/gtm'

interface Response {
  sendOrderData: (orderNumber: string, orderValue: string, email: string) => void
}

export const useOrderGtmEvents = (): Response => {
  const { sendEvent } = useGtm()

  const sendOrderData = (orderNumber: string, orderValue: string, email: string) => {
    sendEvent<TradeDoublerEventData>({
      event: 'payment_success',
      order: {
        number: orderNumber,
        value: orderValue,
        extid: sha256(email).toString(),
      },
    })
  }

  return {
    sendOrderData,
  }
}
