import * as React from 'react'
import { PackageVipDetails } from '@modules/vip/models'
import { VipStandardHeaderSectionContent } from '@modules/vip/details/kind/standard/vip-standard-header-section-content'
import { VipStandardContent } from '@modules/vip/details/kind/standard/vip-standard-content'
import { VipWinterContent } from '@modules/vip/details/kind/winter/vip-winter-content'
import { VipWinterHeaderSectionContent } from '@modules/vip/details/kind/winter/vip-winter-header-section-content'

interface Response {
  headerSectionContent: React.ReactNode
  content: React.ReactNode
}

export const useVipKind = (
  packageVipDetails: PackageVipDetails | undefined,
  onScrollTo: (destination: 'improvements' | 'payment') => () => void,
  improvementListRef: React.Ref<HTMLDivElement>,
): Response => {
  if (!packageVipDetails)
    return {
      headerSectionContent: null,
      content: null,
    }

  const renderComponent = (HeaderSectionContent, Content) => ({
    headerSectionContent: (
      <HeaderSectionContent
        packageVipDetails={packageVipDetails}
        onScrollToVipImprovementsList={onScrollTo('improvements')}
      />
    ),
    content: (
      <Content
        packageVipDetails={packageVipDetails}
        onScrollToPayment={onScrollTo('payment')}
        ref={improvementListRef}
      />
    ),
  })

  if (packageVipDetails.type === 'standard') return renderComponent(VipStandardHeaderSectionContent, VipStandardContent)

  return renderComponent(VipWinterHeaderSectionContent, VipWinterContent)
}
