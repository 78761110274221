import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleGastroCardBoostCardsSection } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-cards-section'
import { PackageWholesaleGastroCardBoostInformation } from '@modules/package-wholesale/details/gastro-card-boost/package-wholesale-gastro-card-boost-information'
import { PackageWholesaleSubscriptionContentHeader } from '@modules/package-wholesale/details/subscription/package-wholesale-subscription-content-header'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  basicInformationClassName?: string
  isCollapsable?: boolean
}

export const PackageWholesaleGastroCardBoostContent = ({
  packageWholesaleDetails,
  basicInformationClassName,
  isCollapsable,
}: Props): JSX.Element => (
  <div className="d-flex flex-column">
    <PackageWholesaleSubscriptionContentHeader
      created={packageWholesaleDetails.created}
      {...(!isCollapsable && { seller: packageWholesaleDetails.seller })}
    >
      <div className={basicInformationClassName}>
        <strong className="text-secondary font-xxl">Jak to działa?</strong>
        <p className="font-md mt-2 text-darker-gray col-12 col-sm-10">
          Wybierz jedną z proponowanych do wpłaty kwot lub spersonalizuj inną kwotę w opcji poniżej. Opłać zadeklarowaną
          kwotę, którą <strong className="text-primary text-nowrap">my powiększymy o 40%</strong>. Numer zakupionego
          vouchera każdorazowo przypnij do rezerwacji i skorzystaj przez 2 lata podczas pobytów!
        </p>
      </div>
    </PackageWholesaleSubscriptionContentHeader>

    <div className="order-1">
      <PackageWholesaleGastroCardBoostCardsSection packageWholesaleDetails={packageWholesaleDetails} />
    </div>

    <div className="order-3">
      <PackageWholesaleGastroCardBoostInformation
        isCollapsable={isCollapsable}
        packageWholesaleDetails={packageWholesaleDetails}
      />
    </div>
  </div>
)
