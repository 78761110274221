import * as React from 'react'
import { PackageWholesaleInformationWrapper } from '@modules/package-wholesale/details/package-wholesale-expandable-information-wrapper'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleProductFormInput } from '@modules/package-wholesale/details'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { useSubscriptionProductName } from '@modules/package-wholesale/details/subscription/use-subscription-product-name'
import { PackageWholesaleSubscriptionVariantSpecialDatesInformation } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-variant-special-dates-information'

interface Props {
  isCollapsable: boolean
  hasSpecialDatesPayment: boolean
}

export const PackageWholesaleSubscriptionVariantInformation = ({
  isCollapsable,
  hasSpecialDatesPayment,
}: Props): JSX.Element => {
  const { control } = useFormContext()

  const products = useWatch({ control, name: 'products' })

  const addedItems = products.filter(
    (item: PackageWholesaleProductFormInput) => item.active && item.product.kind === 'subscription',
  )

  const { getDepositInformation } = useDepositPayment()

  return (
    <div className="d-flex flex-column">
      <div className="mt-3">
        <strong className="text-secondary font-xxl">Jak to działa?</strong>
      </div>

      <p className="font-md mt-2 text-darker-gray col-12 col-sm-11">
        Pobyty z <strong className="text-primary">voucherów morskich</strong> zrealizować można w dowolnym nadmorskim
        Resorcie Holiday Park & Resort:{' '}
        <strong className="text-primary">Pobierowo, Rowy, Ustronie Morskie, Niechorze, Mielno, Kołobrzeg.</strong>
      </p>

      <div className="order-3">
        <PackageWholesaleInformationWrapper isCollapsable={isCollapsable}>
          <p>
            Pobyty z <strong className="text-primary">voucherów górskich</strong> zrealizować można w Holiday Park &
            Resort <strong className="text-primary">Uzdrowisko Cieplice Zdrój.</strong>
          </p>
          <p>
            Na etapie dokonywania zakupu vouchera morskiego nie wybiera się konkretnego Resortu. <br />
            Wyboru Resortu dokonuje się w momencie zakładania rezerwacji. Rezerwacji dokonuje się samodzielnie poprzez
            stronę{' '}
            <a
              className="fw-bold text-decoration-none text-nowrap"
              href="https://rezerwuj.holidaypark.pl/"
              target="_blank"
            >
              www.rezerwuj.holidaypark.pl
            </a>{' '}
            lub telefonicznie za pomocą{' '}
            <a className="fw-bold text-decoration-none text-nowrap" href="tel:735200800">
              Biura Obsługi Klienta: 735-200-800
            </a>
            . W momencie dokonywania rezerwacji dokonuje się wyboru: terminu, konkretnego lokalu (opcja dobrowolna),
            udoskonaleń pobytu (opcja dobrowolna). Przy zakładaniu rezerwacji należy opłacić: jednorazową usługę
            przygotowania lokalu, kwotę zabezpieczenia na poczet kaucji zwrotnej. <br />
          </p>
          <p>
            <strong>
              Nieuiszczenie opłaty w ciągu 48 godzin od założenia rezerwacji, powoduje jej anulację.
              <br />
              {!addedItems.length ? (
                <div>Kwota zabezpieczenia na poczet kaucji powinna być opłacona do 12 miesięcy przed przyjazdem</div>
              ) : (
                addedItems.map((item: PackageWholesaleProductFormInput) => (
                  <div key={item.product.id}>{getDepositInformation(item.product)}</div>
                ))
              )}
              Podczas zameldowania należy uiścić opłatę eksploatacyjną (szczegóły w regulaminie pakietów hurtowych).
              <br />
              Przy wymeldowaniu zostanie doliczona kwota do zapłaty za media zużyte podczas pobytu.
            </strong>
          </p>

          {hasSpecialDatesPayment && <PackageWholesaleSubscriptionVariantSpecialDatesInformation />}
        </PackageWholesaleInformationWrapper>
      </div>
    </div>
  )
}

const useDepositPayment = () => {
  const defaultDepositPaymentMessage =
    'zabezpieczenia na poczet kaucji powinna zostać opłacona w ciągu 48 godzin od założenia rezerwacji.'

  const { getSubscriptionProductName } = useSubscriptionProductName()

  const variant = (product: PackageWholesaleProduct) =>
    ({
      brown: 'pakietu brązowego',
      silver: 'pakietu srebrnego',
      gold: 'pakietu złotego',
      gray: 'pakietu szarego',
    }[product.subscription_kind] || '')

  const getDepositInformation = (product: PackageWholesaleProduct) =>
    ({
      now: (
        <>
          Dla oferty „{getSubscriptionProductName(product)}" {variant(product)} kwota {defaultDepositPaymentMessage}
        </>
      ),
      '30_days': (
        <>
          Dla oferty „{getSubscriptionProductName(product)}" {variant(product)} kwota zabezpieczenia na poczet kaucji
          powinna zostać opłacona do 30 dni przed przyjazdem.
        </>
      ),
      '12_months': (
        <>
          Dla oferty „{getSubscriptionProductName(product)}" {variant(product)} kwota zabezpieczenia na poczet kaucji
          powinna zostać opłacona do 12 miesięcy przed przyjazdem.
        </>
      ),
    }[product.deposit_payment_deadline] ?? `Kwota ${defaultDepositPaymentMessage}`)

  return { getDepositInformation }
}
