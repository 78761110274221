import * as React from 'react'
import * as clsx from 'clsx'
import { HolidayBenefitBenefitsPlansPrice } from '@modules/holiday-benefit/common/holiday-benefit-benefits-plans-price'

interface Props {
  children?: React.ReactNode
  apartmentContent: React.ReactNode
  houseContent: React.ReactNode
  apartmentImage?: string
  houseImage?: string
  title: string
  subtitle: string
  description: string
  forEmployee?: boolean
}

export const HolidayBenefitBenefitsPlans = React.forwardRef(
  (
    {
      children,
      title,
      description,
      subtitle,
      apartmentContent,
      houseContent,
      forEmployee,
      houseImage,
      apartmentImage,
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactNode => (
    <div className="container mt-5 py-5" ref={ref}>
      <div className="holiday-benefit__section-title text-center">
        <strong className="text-holiday-benefit-primary me-2">{title}</strong>
        <strong className="text-holiday-benefit-secondary">{subtitle}</strong>
      </div>
      <p className="my-5 font-xl text-center mx-auto col-lg-6 col-12">{description}</p>
      <div className="d-flex text-white gap-5 justify-content-center flex-wrap">
        <Card
          icon="uil-building font-xxxl"
          price="129"
          title="Plan Apartament"
          color="tertiary"
          forEmployee={forEmployee}
          image={houseImage}
        >
          {apartmentContent}
        </Card>
        <Card
          icon="uil-estate font-xxxl"
          price="149"
          title="Plan Domek"
          color="primary"
          forEmployee={forEmployee}
          image={apartmentImage}
        >
          {houseContent}
        </Card>
      </div>
      {children}
    </div>
  ),
)

const Card = ({ icon, price, title, children, color, forEmployee, image }) => (
  <div className="col-lg-5 col-12 holiday-benefit__costs__card rounded-4">
    {image && <img src={image} alt="Zakwaterowanie" className="holiday-benefit__costs__card__image" />}
    <div className="p-5">
      <div className={clsx('d-flex justify-content-between', forEmployee ? 'align-items-start' : 'align-items-center')}>
        <div
          className={clsx(
            'rounded-4 d-flex align-items-center justify-content-center px-4 py-3',
            `bg-holiday-benefit-${color}`,
          )}
        >
          <i className={clsx('text-white', icon)} />
        </div>
        <HolidayBenefitBenefitsPlansPrice price={price} color={color} forEmployee={forEmployee} />
      </div>
      <strong className={clsx('holiday-benefit__section-title my-5 d-block', `text-holiday-benefit-${color}`)}>
        {title}
      </strong>
      {children}
    </div>
  </div>
)
