import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { NavigationPath } from '@models/routes'
import { GastroGenericProductDetails } from '@modules/gastro-generic/models'
import { GastroGenericHeaderSectionContent } from '@modules/gastro-generic/details/gastro-generic-header-section-content'
import { GastroGenericContent } from '@modules/gastro-generic/details/gastro-generic-content'
import { GastroGenericAgreements } from '@modules/gastro-generic/details/gastro-generic-agreements'
import { SubmitActionRow } from '@components/submit-action-row'
import { GastroGenericSideCard } from '@modules/gastro-generic/details/gastro-generic-side-card'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'
import { getProductData } from '@modules/gastro-generic/gastro-generic-data'
import { useProductDetails } from '@hooks/use-product-details'

interface GastroGenericFormInputs extends Agreements {
  gastro_coupon_rules: boolean
}

interface Params {
  kind: ProductKinds
}

export const GastroGenericDetailsView = ({ kind }: Params): JSX.Element | null => {
  const { showPaymentSelectionView } = usePaymentSelection()

  const { gastro_card } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails: gastroGenericDetails } = useProductDetails<GastroGenericProductDetails>(
    gastro_card.details,
  )

  const paymentSectionRef = React.useRef<HTMLDivElement>(null)
  const detailsRef = React.useRef<HTMLDivElement>(null)

  const gastroProduct = useGastroProductProvider(gastroGenericDetails?.kind)

  const { scrollTo } = useScrollIntoView()
  const navigate = useNavigate()

  const methods = useForm<GastroGenericFormInputs>({ reValidateMode: 'onChange' })

  const isOnlinePayment = gastroGenericDetails?.payment_method === 'online'

  const hasRulesToAccept = gastroGenericDetails?.has_rules_to_accept

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (gastroGenericDetails) {
      methods.clearErrors()
      if (hasRulesToAccept) {
        await commonObjectPost<GastroGenericProductDetails>(gastroGenericDetails.urls.rules, payload)
      }

      if (isOnlinePayment) {
        showPaymentSelectionView(gastroGenericDetails.token, kind)
      } else {
        navigate(NavigationPath.OperationSuccess.replace(':token', gastroGenericDetails.token).replace(':kind', kind))
      }
    }
  }, methods.setError)

  if (!gastroProduct) return null

  const { labelImageSrc, productName, productHeader } = getProductData(gastroGenericDetails, gastroProduct)

  return (
    <PageWrapper>
      <AppHeaderSection images={[require('@assets/images/header-gastro.png')]} imageDescription={productName}>
        <GastroGenericHeaderSectionContent
          productName={productName}
          productHeader={productHeader}
          onScrollToDetails={scrollTo(detailsRef)}
        />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <FormProvider {...methods}>
          {gastroGenericDetails && (
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row gx-xl-5">
                <div className="card col-lg-9 col-12 py-4  card-shadow border-0 overflow-hidden">
                  <GastroGenericContent
                    ref={detailsRef}
                    gastroGenericDetails={gastroGenericDetails}
                    gastroProduct={gastroProduct}
                    onScrollToPayment={scrollTo(paymentSectionRef)}
                  />

                  {isOnlinePayment
                    ? !gastroGenericDetails.is_paid && (
                        <>
                          <hr className="mx-n5" />
                          {hasRulesToAccept && <GastroGenericAgreements gastroGenericDetails={gastroGenericDetails} />}
                          <PaymentActionRow
                            isPaymentDisabled={gastroGenericDetails?.is_paid}
                            ref={paymentSectionRef}
                            isLoading={isSaving}
                            className="mt-4"
                            amount={gastroGenericDetails?.declared_sell_price || ''}
                          />
                        </>
                      )
                    : hasRulesToAccept && (
                        <>
                          <hr className="mx-n5" />
                          <GastroGenericAgreements gastroGenericDetails={gastroGenericDetails} />
                          <SubmitActionRow isLoading={isSaving} />
                        </>
                      )}
                </div>

                <div className="d-flex flex-column col-3">
                  <GastroGenericSideCard imgSource={labelImageSrc} gastroCardDetails={gastroGenericDetails} />
                </div>
              </div>
            </form>
          )}
        </FormProvider>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
