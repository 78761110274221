import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleSubscriptionHeaderVariantsSectionContent } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-header-variants-section-content'
import { getMaximumProductDiscount } from '@modules/package-wholesale/utils'
import { OfferDetailsHeaderButton } from '@components/offer-details-button'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleSubscriptionHeaderSectionContent = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const isEveryProductGray = React.useMemo(
    () => packageWholesaleDetails.products.every(product => product.subscription_kind === 'gray'),
    [packageWholesaleDetails.products],
  )

  const maximumAvailableDiscount = React.useMemo(
    () => getMaximumProductDiscount(packageWholesaleDetails.products),
    [packageWholesaleDetails.products],
  )

  if (!isEveryProductGray) {
    return (
      <PackageWholesaleSubscriptionHeaderVariantsSectionContent packageWholesaleDetails={packageWholesaleDetails} />
    )
  }

  return (
    <AppHeaderContent
      title={
        <>
          Oferta specjalna tylko dla {packageWholesaleDetails.display_name || packageWholesaleDetails.invoice_company}
        </>
      }
      subtitle={
        <span className="font-lg  col-12 col-sm-8 d-block lh-sm">
          Oferujemy Państwu możliwość zakupu <strong>voucherów na 7-dniowe pobyty </strong>w naszych 6-ciu nadmorskich
          rodzinnych ośrodkach Holiday Park & Resort oraz górskim resorcie Holiday Park & Resort Uzdrowisko Cieplice
          Zdrój z{' '}
          {!!maximumAvailableDiscount && (
            <strong className="text-nowrap">z rabatem do {maximumAvailableDiscount}%</strong>
          )}
          .
        </span>
      }
    >
      <OfferDetailsHeaderButton
        href={packageWholesaleDetails.leaflet_subscription ?? 'https://holidaypark.pl/asset-redirect/ulotka-hurt/'}
      />
    </AppHeaderContent>
  )
}
