import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@hooks/use-toggle'

interface Props {
  text: React.ReactNode
  wrapperClassName?: string
  initialHeight?: number
}

export const ExpandableText = ({ text, wrapperClassName, initialHeight }: Props): JSX.Element => {
  const [isExpanded, toggleIsExpended] = useToggle()

  const handleIsExpanded = (event: React.MouseEvent) => {
    event.preventDefault()
    toggleIsExpended()
  }

  return (
    <div className={wrapperClassName}>
      <small className={clsx('expandable-text', { 'is-expanded': isExpanded })} style={{ maxHeight: initialHeight }}>
        {text}
      </small>
      <ExpandIcon isExpanded={isExpanded} onIsExpandedChange={handleIsExpanded} />
    </div>
  )
}

interface ExpandIconParams {
  isExpanded: boolean
  onIsExpandedChange: (event: React.MouseEvent) => void
  wrapperClassName?: string
}

export const ExpandIcon = ({ isExpanded, onIsExpandedChange, wrapperClassName }: ExpandIconParams): JSX.Element => (
  <IconWithText
    text={isExpanded ? 'zwiń treść' : 'rozwiń treść'}
    iconClassName="uil-angle-down expandable-text__arrow"
    textClassName="font-xs font-500"
    onClick={onIsExpandedChange}
    className={clsx('expandable-text__action', wrapperClassName, { 'is-expanded': isExpanded })}
  />
)
