import * as React from 'react'
import clsx from 'clsx'
import { EndlessHolidayConfirmationGastroCard } from '@modules/product-package/models'
import { formatPrice } from '@helpers/price-helper'
import { EndlessHolidayConfirmationProductBaseRow } from '@modules/product-package/confirmation/products/confirmation-product-base-row'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { useGroupedEndlessGastroProducts } from '@modules/product-package/confirmation/use-grouped-endless-gastro-products'

interface Props {
  year: string
  gastroCards: EndlessHolidayConfirmationGastroCard[]
}

export const EndlessHolidayConfirmationGastroProducts = ({ gastroCards, year }: Props): React.ReactNode => {
  const { groupedList } = useGroupedEndlessGastroProducts(gastroCards, year)

  const getTotalPrice = (gastroCards: EndlessHolidayConfirmationGastroCard[]) =>
    sumDecimalArray(gastroCards.map(gastroCard => asDecimal(gastroCard.declared_sell_price))).toString()

  return (
    <ul>
      {groupedList.map(([amount, gastroCards], index) => (
        <EndlessHolidayConfirmationProductBaseRow
          className={clsx({ 'mt-2': index > 0 })}
          key={gastroCards[0].id}
          kind="gastro-card"
          subtitle={
            <span className="text-muted font-sm lh-base">
              Wykorzystaj w terminie: <br />
              {formatDate(gastroCards[0].active_after, DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)} -{' '}
              {formatDate(gastroCards[0].expire_after, DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)}
            </span>
          }
          title="Voucher gastronomiczny"
          image={require('@assets/images/gastro-voucher.jpg')}
        >
          <div>
            <Row title="Ilość voucherów" content={gastroCards.length} />
            <Row title="Kwota vouchera" content={formatPrice(amount)} />
          </div>
          <Row
            title="Łączny koszt"
            content={formatPrice(getTotalPrice(gastroCards))}
            className="fw-bold text-darker-gray mt-2 font-lg"
          />
        </EndlessHolidayConfirmationProductBaseRow>
      ))}
    </ul>
  )
}

const Row = ({ title, content, className }: { title: string; content: string | number; className?: string }) => (
  <div className={clsx('d-flex flex-wrap font-sm text-muted', className)}>
    <div className="col-sm-8 col-12 text-sm-end text-center">{title}:</div>
    <div className="col-sm-4 col-12 text-sm-end text-center">{content}</div>
  </div>
)
