import { AvailableRule } from '@models/app-data'
import { Checkbox } from '@components/controls/checkbox'
import * as React from 'react'

interface Props {
  rule: AvailableRule | undefined
  getOverwrittenRule?: (rule: AvailableRule) => AvailableRule
}

export const AgreementCheckbox = ({ rule, getOverwrittenRule }: Props): JSX.Element | null => {
  if (!rule) return null

  const { keyword, url, name } = getOverwrittenRule ? getOverwrittenRule(rule) : rule

  return (
    <Checkbox
      name={keyword}
      className="checkbox--small"
      label={
        <span>
          Oświadczam, że zapoznałem się z treścią dokumentu
          <a className="text-darker-gray text-decoration-none fw-bold mx-1" href={url} target="_blank">
            {name}
          </a>
          i akceptuję jego postanowienia.
        </span>
      }
    />
  )
}
