import * as React from 'react'
import * as clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask, { Props } from 'react-input-mask'

export interface MaskedFormControlProps extends Props {
  name: string
  className?: string
  formatValue?: (value) => string
}

export const MaskedFormControl = ({ name, className, formatValue, ...props }: MaskedFormControlProps): JSX.Element => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <InputMask
          id={name}
          className={clsx(
            'shadow-none py-3 font-size-sm form-control form-control-height-lg form-control-with-status',
            { 'is-empty': !value },
            className,
          )}
          name={name}
          value={(formatValue ? formatValue(value) : value) ?? ''}
          onChange={onChange}
          data-testid={`${name}-masked-form-control`}
          {...props}
        />
      )}
      control={control}
      name={name}
    />
  )
}
