import * as React from 'react'
import { PackageWholesaleSubscriptionExpandableVariant } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-expandable-variant'
import { PackageWholesaleSubscriptionExpandableVariantSubtitle } from '@modules/package-wholesale/details/subscription/variants/package-wholesale-subscription-expandable-variant-subtitle'
import { PackageWholesaleCardsSubscriptionSection } from '@modules/package-wholesale/details/subscription/package-wholesale-cards-subscription-section'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { PackageWholesaleLeafletButton } from '@modules/package-wholesale/details/subscription/package-wholesale-leaflet-button'

interface Props {
  products: PackageWholesaleProduct[]
  leafletUrl: string | null
  isDefaultExpanded?: boolean
}

export const PackageWholesaleSubscriptionVariantBrown = ({
  products,
  leafletUrl,
  isDefaultExpanded,
}: Props): JSX.Element => (
  <PackageWholesaleSubscriptionExpandableVariant
    isDefaultExpanded={isDefaultExpanded}
    variant="brown"
    title={<h5 className="subscription-variant__header is-brown fw-bolder">Pakiet brązowy</h5>}
    icon={<img src={require('@assets/images/subscription-variants/bronze.svg')} alt="Pakiet brązowy" height="40" />}
    subtitle={
      <PackageWholesaleSubscriptionExpandableVariantSubtitle
        dates={
          <span>
            od 01.01 do 31.05 oraz <span className="text-nowrap">od 01.10 do 31.12*</span>
          </span>
        }
      />
    }
  >
    <small className="mt-n3 d-block mb-2 font-500 text-darker-gray">* Terminy specjalne z dopłatą</small>
    <PackageWholesaleCardsSubscriptionSection packageWholesaleProducts={products} />
    <PackageWholesaleLeafletButton leafletUrl={leafletUrl} wrapperClassName="mt-3 d-block" />
  </PackageWholesaleSubscriptionExpandableVariant>
)
