import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'
import { NavigationPath } from '@models/routes'
import * as React from 'react'
import { RodoAcquiringPoint } from '@modules/rodo-agreements/models'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { useNavigate } from 'react-router-dom'

interface Response {
  rodoAcquiringPoint: RodoAcquiringPoint | null
  isLoading: boolean
}

export const useAcquiringPointDetails = (slug: string | undefined): Response => {
  const [rodoAcquiringPoint, setRodoAcquiringPoint] = React.useState<RodoAcquiringPoint | null>(null)

  const { rodo_acquiring_point } = useSelector(selectAppDataUrls)

  const navigate = useNavigate()

  const { action: fetchPointData } = useApiRequest(async () => {
    if (!slug) return
    try {
      setRodoAcquiringPoint(await commonObjectGet(rodo_acquiring_point.details.replace('replace-me', slug)))
    } catch (error) {
      if (error.response?.status === 404) navigate(NavigationPath.NotFound)
    }
  })

  React.useEffect(() => {
    fetchPointData()
  }, [slug])

  return {
    rodoAcquiringPoint: rodoAcquiringPoint,
    isLoading: !rodoAcquiringPoint,
  }
}
