import * as React from 'react'
import { motion } from 'framer-motion'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { FieldPath, FormProvider, useForm, useWatch } from 'react-hook-form'
import { RodoAgreementsFormPageBackButton } from '@modules/rodo-agreements/form/form-page-back-button'
import { RodoAgreementsFormPageAgreements } from '@modules/rodo-agreements/form/form-page-agreements'
import { RodoAgreementsQrCodeButton } from '@modules/rodo-agreements/qr-code-button'
import { RodoAgreementsButton } from '@modules/rodo-agreements/button'
import { useDevicesSize } from '@hooks/use-devices-size'
import { RodoAcquiringPoint } from '@modules/rodo-agreements/models'
import { useRodoAgreementFormSubmit } from '@modules/rodo-agreements/form/use-form-submit'

const DEFAULT_FORM_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'information_clause',
  'sms_rules',
  'phone_call_rules',
  'email_rules',
] as const

export interface RodoAgreementFormInputs {
  first_name: string
  last_name: string
  email: string
  phone: string
  information_clause: boolean
  sms_rules: boolean
  email_rules: boolean
  phone_call_rules: boolean
}

interface Props {
  isFormVisible: boolean
  onBack: () => void
  rodoAcquiringPoint: RodoAcquiringPoint
}

export const RodoAgreementsFormPage = ({ isFormVisible, onBack, rodoAcquiringPoint }: Props): React.ReactNode => {
  const { isDesktop, isReady } = useDevicesSize()

  const methods = useForm<RodoAgreementFormInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      information_clause: false,
      sms_rules: false,
      email_rules: false,
      phone_call_rules: false,
    },
  })

  const { isLoading, submit } = useRodoAgreementFormSubmit(methods, rodoAcquiringPoint)

  const formData = useWatch({
    control: methods.control,
    name: [...DEFAULT_FORM_FIELDS, ...(rodoAcquiringPoint.promotion_rules as FieldPath<RodoAgreementFormInputs>[])],
  })

  const hasFilledFormInputs = formData.every(Boolean)

  React.useEffect(() => {
    if (!isFormVisible) methods.reset()
  }, [isFormVisible])

  if (!isReady || (!isDesktop && !isFormVisible)) return null

  const variants = {
    hidden: isDesktop ? { maxWidth: 0 } : { maxWidth: 0, marginTop: -100 },
    visible: isDesktop
      ? { maxWidth: isFormVisible ? '100%' : 0 }
      : { marginTop: 0, maxWidth: isFormVisible ? '100%' : 0 },
  }

  const handleTextFieldChange =
    (inputName: FieldPath<RodoAgreementFormInputs>) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[^\p{Letter}\p{Mark}\-}\s]+/gu, '')
      methods.setValue(inputName, value)
    }

  return (
    <FormProvider {...methods}>
      <motion.div
        className="col-sm-6 col-12 rodo-agreements__description-section text-nowrap position-relative justify-content-start pb-5 pb-sm-0"
        initial="hidden"
        animate="visible"
        variants={variants}
      >
        <RodoAgreementsFormPageBackButton onBack={onBack} clicked={isFormVisible} />
        <div className="ps-sm-5 mx-3 mx-sm-0 col-sm-12">
          <img
            src={rodoAcquiringPoint.logo}
            alt="holidaypark logo"
            className="rodo-agreements__description-section__icon ms-sm-auto mx-auto me-sm-5 d-block mb-5 mb-sm-0"
          />

          <h2
            className="rodo-agreements__description-section__title mb-0 lh-1 d-flex justify-content-center justify-content-sm-start"
            dangerouslySetInnerHTML={{ __html: rodoAcquiringPoint.form_title }}
          />
          <p className="font-xxl text-darker-gray lh-initial mt-2 text-wrap text-center text-sm-start">
            Wystarczy, że wypełnisz formularz, wyrazisz zgody i zaakceptujesz oświadczenia.{' '}
            <span className="text-muted d-block d-sm-inline mt-3 mt-sm-0">(Wszystkie pola są wymagane)</span>
          </p>
        </div>
        <div className="d-flex flex-column justify-content-between px-sm-5 px-3 w-100">
          <div className="d-flex mt-4 flex-column flex-sm-row justify-content-between">
            <div className="col-sm-5 col-12">
              <CustomFormGroup
                label="Imię:"
                inputName="first_name"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{ onChange: handleTextFieldChange('first_name') }}
              />
              <CustomFormGroup
                label="Nazwisko:"
                inputName="last_name"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{ onChange: handleTextFieldChange('last_name') }}
              />
              <CustomFormGroup
                label="Adres e-mail:"
                inputName="email"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{ inputMode: 'email' }}
              />
              <CustomFormGroup
                label="Numer telefonu:"
                inputName="phone"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{ inputMode: 'tel' }}
              />
            </div>
            <RodoAgreementsFormPageAgreements isVisible={isFormVisible} rules={rodoAcquiringPoint.promotion_rules} />
          </div>

          <div className="d-flex align-items-center">
            <RodoAgreementsButton
              text={rodoAcquiringPoint.button_text}
              backgroundColor={rodoAcquiringPoint.color_secondary}
              onClick={submit}
              className="mx-auto mx-sm-0"
              isLoading={isLoading}
              disabled={!hasFilledFormInputs}
            />
            <RodoAgreementsQrCodeButton className="ms-5" url={rodoAcquiringPoint.slug} isFormVisible={isFormVisible} />
          </div>
        </div>
      </motion.div>
    </FormProvider>
  )
}
