import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { EndlessHolidayProduct } from '@modules/product-package/models'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  amount: number
  gastroProduct: EndlessHolidayProduct
  showDiscountedPrice: boolean
}

export const EndlessHolidaySideCardGastroCardSummaryRow = ({
  amount,
  gastroProduct,
  showDiscountedPrice,
}: Props): React.ReactNode => (
  <div className="d-flex justify-content-between align-items-center opacity-75 font-sm">
    <div className="font-xs font-500 col-8 pe-4">
      <IconWithText
        text="Voucher gastronomiczny"
        iconClassName="uil-check font-md me-1"
        textClassName="text-truncate font-xs"
      />
    </div>
    <div className="col-1">{amount}x</div>
    <div className="col-3 text-end font-xs font-500">
      {formatPrice(showDiscountedPrice ? gastroProduct.first_price_brutto : gastroProduct.price_brutto)}
    </div>
  </div>
)
