import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ContentLoader } from '@components/content-loader'
import { LinkExpired } from '@components/payment-operation-results/link-expired'

interface Response<T> {
  productDetails: T | undefined
  token: string | undefined
  PageWrapper: ({ children }) => React.ReactNode
  urlWithToken: string
}

export const useProductDetails = <T,>(detailsUrl: string): Response<T> => {
  const [isLoading, setIsLoading] = React.useState(true)
  const { token } = useParams<'token'>()

  const urlWithToken = detailsUrl.replace('replace-me', String(token))

  const [productDetails, setProductDetails] = React.useState<T>()
  const [isPageExpired, setIsPageExpired] = React.useState(false)

  const { action: fetchProductDetails } = useApiRequest(async () => {
    try {
      setProductDetails(await commonObjectGet<T>(urlWithToken))
    } catch (e) {
      setIsPageExpired(true)
    } finally {
      setIsLoading(false)
    }
  })

  React.useEffect(() => {
    fetchProductDetails()
  }, [])

  const PageWrapper = React.useCallback(
    ({ children }) => (
      <ContentLoader isLoading={isLoading} className="is-positioned-to-top">
        {isPageExpired ? <LinkExpired /> : children}
      </ContentLoader>
    ),
    [isLoading, isPageExpired],
  )

  return {
    productDetails,
    token,
    PageWrapper,
    urlWithToken,
  }
}
