import * as React from 'react'
import { CustomFormGroup, CustomFormGroupProps } from '@components/form-controls/custom-form-group'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@hooks/use-toggle'
import { useFormContext } from 'react-hook-form'

interface Props extends CustomFormGroupProps {
  passwordHintClassName?: string
}

export const FormPasswordInput = ({ passwordHintClassName, ...props }: Props): React.ReactNode => {
  const [isPasswordVisible, toggleIsPasswordVisible] = useToggle()

  const { formState } = useFormContext()

  const hasError = formState.errors && !!formState.errors[props.inputName]

  return (
    <div>
      <div className="position-relative">
        <CustomFormGroup
          label="Hasło"
          {...props}
          formControlProps={{ ...props.formControlProps, type: isPasswordVisible ? 'text' : 'password' }}
        >
          <button className="btn-unstyled form-control-password__icon" onClick={toggleIsPasswordVisible}>
            <i
              className={clsx('text-muted', isPasswordVisible ? 'uil-eye-slash' : 'uil-eye', { 'd-none': hasError })}
            />
          </button>
        </CustomFormGroup>
      </div>
      <IconWithText
        text="Hasło powinno składać się z minimum 8 znaków"
        iconClassName="uil-info-circle font-lg me-1"
        className={clsx('text-muted font-sm mt-1', passwordHintClassName)}
      />
    </div>
  )
}
