import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  basicPrice: string
  bonusPrice: string
}

export const GastroCardPrices = ({ basicPrice, bonusPrice }: Props): JSX.Element => (
  <div>
    <div className="text-darker-gray font-700 font-lg pt-3 d-flex flex-wrap justify-content-center justify-content-sm-start">
      <div className="me-1 me-lg-0">Łączna kwota do zapłaty:</div>
      <div className="font-xxl text-primary lh-1 ms-2 text-nowrap mt-2 mt-sm-0 col-12 col-sm-auto text-center">
        {' '}
        {formatPrice(basicPrice)}
      </div>
    </div>
    <div className="text-darker-gray font-700 font-lg d-flex flex-wrap mt-4 mt-lg-0 justify-content-center justify-content-sm-start">
      <div className="me-1 me-lg-0 text-center">
        Łączna kwota do wykorzystania na ofertę gastronomiczną z zastosowaniem promocji:
      </div>
      <div className="font-xxl text-primary lh-1 ms-2 text-nowrap mt-2 mt-sm-0 col-12 col-sm-auto text-center">
        {' '}
        {formatPrice(bonusPrice)}
      </div>
    </div>
  </div>
)
