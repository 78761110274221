import * as React from 'react'
import clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useHolidayBenefitInvitationSelectedPlan } from '@modules/holiday-benefit/employee-invitation/use-holiday-benefit-invitation-selected-plan'
import { HolidayBenefitInvitation } from '@modules/holiday-benefit/employee-invitation/models'

interface Props {
  onEditClick: () => void
  benefitInvitation: HolidayBenefitInvitation
}

export const SelectedPlanBanner = ({ onEditClick, benefitInvitation }: Props): React.ReactNode => {
  const { color, selectedKind } = useHolidayBenefitInvitationSelectedPlan()

  const price =
    selectedKind === 'house'
      ? benefitInvitation.benefit_program.benefit_house_price_worker
      : benefitInvitation.benefit_program.benefit_apartment_price_worker

  return (
    <div className="holiday-benefit__invitation__selected-plan-banner">
      <div className="col-lg-5 col-3">
        <img
          src={require('@assets/images/resorts/ustronie.jpg')}
          alt="Wybrany plan"
          width="100%"
          className="holiday-benefit__invitation__selected-plan-banner__image"
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className={clsx('col-lg-7 col-9 p-3 holiday-benefit__invitation__selected-plan-banner__data', color)}>
        <div className="d-flex justify-content-between">
          <div>
            <strong className="font-xl lh-1">Wybrany</strong> <br />
            <strong className="font-xl">Plan {selectedKind === 'house' ? 'Domek' : 'Apartament'}</strong>
          </div>
          <div className="d-flex align-items-center">
            <img
              src={require('@assets/images/holiday-benefit/landing-page/bar.svg')}
              alt="Holiday Benefit"
              height={35}
            />
            <div className="ms-2 lh-1">
              <strong className="font-xxxl">{price}</strong>
              <span className="font-lg fw-semibold"> zł</span>
              <span className="text-muted d-block font-sm fw-semibold">miesięcznie</span>
            </div>
          </div>
        </div>
        <IconWithText
          onClick={onEditClick}
          text="zmień wybór"
          iconClassName="uil-edit-alt font-xl me-2"
          className="text-muted mt-2 opacity-75 font-sm"
        />
      </div>
    </div>
  )
}
