import * as React from 'react'
import * as clsx from 'clsx'
import { SideCard } from '@components/side-card'
import { TodaysPurchaseIndicator } from '@components/todays-purchase-indicator'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleSideCardExpirationBanner } from '@modules/package-wholesale/details/package-wholesale-side-card-expiration-banner'
import { useGastroProductProvider } from '@modules/gastro-generic/use-gastro-product-provider'
import { PackageWholesaleProductKind } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  kind: PackageWholesaleProductKind
  wrapperClassName?: string
}

export const PackageWholesaleSingleKindSideCard = ({
  packageWholesaleDetails,
  wrapperClassName,
  kind,
}: Props): JSX.Element | null => {
  const gastroProduct = useGastroProductProvider(kind)
  if (!gastroProduct) return null
  const leafletUrl = packageWholesaleDetails[`leaflet_${gastroProduct.kind}`]
  const labelFileName = ['gastro_card_2024', 'gastro_card_2025'].includes(gastroProduct.kind || '')
    ? 'gastro-voucher-label.jpg'
    : 'package-wholesale-gastro_card-label.png'

  return (
    <SideCard
      className={clsx('d-lg-block d-none position-relative col-12', wrapperClassName)}
      image={<img src={require(`@assets/images/${labelFileName}`)} alt="Pakiety hurtowe ulotka" />}
    >
      {leafletUrl && <LeafletLink href={leafletUrl} />}

      <PackageWholesaleSideCardExpirationBanner expireAfter={packageWholesaleDetails.expire_after} />

      {!!packageWholesaleDetails.sold_amount && (
        <TodaysPurchaseIndicator
          amount={packageWholesaleDetails.sold_amount}
          text={
            <>
              osób z Twojej firmy <span className="font-500 d-block">kupiło dziś voucher pobytowy</span>
            </>
          }
        />
      )}
    </SideCard>
  )
}

const LeafletLink = ({ href }) => (
  <a href={href} target="_blank" className="d-block">
    <div className="btn package-wholesale__details-button is-label">
      <img src={require('@assets/images/adobe-acrobat.svg')} alt="pdf" />
      <strong className="text-white">Otwórz ulotkę</strong>
    </div>
  </a>
)
