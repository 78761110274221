import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { AgreementSection } from '@components/agreement/agreement-section'
import { useRules } from '@hooks/use-rules'
import { GastroCardDetails } from '@modules/gastro-card/models'

interface Props {
  gastroCardDetails: GastroCardDetails
}

export const GastroCardAgreements = ({ gastroCardDetails }: Props): JSX.Element => {
  const { getRules } = useRules()

  const [gastroCardRules, departureVoucherRules] = getRules(['gastro_card_rules', 'departure_voucher_rules'])

  const rules = [...gastroCardDetails.optional_rules, ...gastroCardDetails.required_rules]

  return (
    <AgreementSection className="mt-3" productRules={rules}>
      {rules.includes('gastro_card_rules') && (
        <Checkbox
          className="checkbox--small"
          name="gastro_card_rules"
          label={
            <span>
              Oświadczam, że zapoznałem się z treścią dokumentu
              <a
                className="text-darker-gray text-decoration-none fw-bold mx-1"
                href={gastroCardRules?.url}
                target="_blank"
              >
                {gastroCardRules?.name}
              </a>
              i akceptuję jej postanowienia.
            </span>
          }
        />
      )}
      {rules.includes('departure_voucher_rules') && (
        <Checkbox
          className="checkbox--small"
          name="departure_voucher_rules"
          label={
            <span>
              Oświadczam, że zapoznałem się z treścią dokumentu
              <a
                className="text-darker-gray text-decoration-none fw-bold mx-1"
                href={departureVoucherRules?.url}
                target="_blank"
              >
                {departureVoucherRules?.name}
              </a>
              i akceptuję jego postanowienia.
            </span>
          }
        />
      )}
    </AgreementSection>
  )
}
