import * as React from 'react'
import { HolidayBenefitResorts } from '@modules/holiday-benefit/common/holiday-benefit-resorts'
import { HolidayBenefitFooter } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-footer'
import { HolidayBenefitStickyLabel } from '@modules/holiday-benefit/common/holiday-benefit-sticky-label'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { HolidayBenefitEmployeeHeader } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-header'
import { HolidayBenefitEmployeeHero } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-hero'
import { HolidayBenefitEmployeeBenefitsPlans } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-benefits-plans'
import { HolidayBenefitEmployeeBenefitsSection } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-benefits-section'
import { HolidayBenefitEmployeeContactForm } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-contact-form'
import { HolidayBenefitCompanies } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-companies'
import { HolidayBenefitEmployeeBookingOffers } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-booking-offers'
import { HolidayBenefitMobileAppBanner } from '@modules/holiday-benefit/common/holiday-benefit-mobile-app-banner'
import { HolidayBenefitEmployeeEnrolmentSteps } from '@modules/holiday-benefit/employee-landing-page/holiday-benefit-employee-enrolment-steps'
import { FadeInView } from '@components/fade-in-view'

export const HolidayBenefitEmployeeView = (): React.ReactNode => {
  const navigate = useNavigate()

  const benefitSectionRef = React.useRef<HTMLDivElement>(null)
  const howToEnrollSection = React.useRef<HTMLDivElement>(null)
  const contactSectionRef = React.useRef<HTMLFormElement>(null)
  const plansSectionRef = React.useRef<HTMLDivElement>(null)

  const scrollToSection = (section: React.RefObject<HTMLElement>) => () => {
    section.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const navigateToEmployeeLandingPage = () => {
    navigate(NavigationPath.HolidayBenefitsEmployer)
  }

  return (
    <div>
      <HolidayBenefitStickyLabel
        text="Oferta dla Pracodawcy"
        onClick={navigateToEmployeeLandingPage}
        gradientStartColor="#947648"
        gradientEndColor="#C6AE7E"
      />
      <FadeInView className="holiday-benefit">
        <HolidayBenefitEmployeeHeader
          onScrollToContactSection={scrollToSection(contactSectionRef)}
          onScrollToHowToEnrollSection={scrollToSection(howToEnrollSection)}
          onScrollToBenefitsSection={scrollToSection(benefitSectionRef)}
        />
        <HolidayBenefitEmployeeHero
          onScrollToBenefitsSection={scrollToSection(benefitSectionRef)}
          onScrollToPlansSection={scrollToSection(plansSectionRef)}
        />
        <HolidayBenefitEmployeeBenefitsSection ref={benefitSectionRef} />
        <HolidayBenefitEmployeeBenefitsPlans ref={plansSectionRef}>
          <HolidayBenefitEmployeeEnrolmentSteps ref={howToEnrollSection} />
        </HolidayBenefitEmployeeBenefitsPlans>
        <HolidayBenefitResorts
          galleryImages={GalleryItems}
          title="Jesteśmy częścią"
          subtitle={<span className="text-nowrap">Holiday Park & Resort</span>}
          galleryTitle="Gdzie zrealizujesz"
          gallerySubtitle="swoje benefity?"
        />
        <HolidayBenefitEmployeeBookingOffers />
        <HolidayBenefitEmployeeContactForm ref={contactSectionRef} />
        <HolidayBenefitCompanies showOpinions />
        <HolidayBenefitMobileAppBanner />
        <HolidayBenefitFooter />
      </FadeInView>
    </div>
  )
}

const GalleryItems = [
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/01.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/02.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/03.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/14.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/05.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/11.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/12.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/08.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/09.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/13.webp') },
]
