import * as React from 'react'
import { BookingOffer } from '@models/booking-offers'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'

const SHOP_BOOKING_OFFERS = '/api/booking-offers/booking-offers/'

interface Response {
  offers: BookingOffer[]
  isLoading: boolean
}

export const useBookingOffers = (): Response => {
  const [offers, setOffers] = React.useState<BookingOffer[]>([])

  const { action: fetchBookingOffers, isLoading } = useApiRequest(async () => {
    const bookingOffers = await commonObjectGet<{ results: BookingOffer[] }>(SHOP_BOOKING_OFFERS, {
      params: { days: 2, season: 'summer', page_size: 3 },
    })
    setOffers(bookingOffers.results)
  })

  React.useEffect(() => {
    fetchBookingOffers()
  }, [])

  return {
    offers,
    isLoading,
  }
}
