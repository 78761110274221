import * as React from 'react'
import { BookingOffersCard } from '@components/booking-offers/booking-offers-card'
import { useBookingOffers } from '@components/booking-offers/use-booking-offers'

export const BookingOffersSection = (): React.ReactNode => {
  const { isLoading, offers } = useBookingOffers()
  if (!isLoading && !offers.length) return null

  return (
    <div className="row bocian-voucher__last-minute-section justify-content-between mt-n4 mx-2 mx-lg-0">
      <div className="col-lg-2 col-12 text-center text-lg-start mb-4 mb-lg-0">
        <h5 className="text-primary">Sprawdź najbliższe terminy</h5>

        <p className="bocian-text-primary mb-0">
          Więcej propozycji pobytów w <span className="text-nowrap">Holiday Park & Resort</span> znajdziesz na{' '}
          <EcommerceLastMinuteLink>sklep.holidaypark.pl</EcommerceLastMinuteLink>
        </p>
        <EcommerceLastMinuteLink>
          <button className="rounded btn-darker-gray d-inline-block py-3 px-4 text-white fw-bold mt-4 btn-responsive">
            Sprawdź terminy
          </button>
        </EcommerceLastMinuteLink>
      </div>

      <div className="col-lg-8 col-12">
        <div className="row gap-4 gap-md-0">
          {offers.map(offer => (
            <BookingOffersCard key={offer.id} offer={offer} />
          ))}
        </div>
      </div>
    </div>
  )
}

export const EcommerceLastMinuteLink = ({ children }: { children: React.ReactNode }): React.ReactNode => (
  <a
    href="https://sklep.holidaypark.pl/#last-minute"
    target="_blank"
    className="text-decoration-none bocian-text-primary fw-bold"
  >
    {children}
  </a>
)
