import * as React from 'react'
import { EndlessHolidayDetails } from '@modules/product-package/models'
import { EndlessHolidayDetailsContentBenefits } from '@modules/product-package/details/content-benefits'
import { EndlessHolidayDetailsContentDescription } from '@modules/product-package/details/content-description'
import { EndlessHolidayDetailsProductsSelection } from '@modules/product-package/details/products-selection/products-selection'

interface Props {
  productDetails: EndlessHolidayDetails
  onScrollToResorts: () => void
}

export const EndlessHolidayDetailsContent = React.forwardRef(
  ({ productDetails, onScrollToResorts }: Props, ref: React.RefObject<HTMLParagraphElement>): React.ReactNode => (
    <div className="text-darker-gray">
      <h2 className="text-primary text-center text-lg-start">Wakacje w nieskończoność</h2>
      <p className="mt-3 col-lg-8 col-12 text-center text-lg-start" ref={ref}>
        Wybierz Wakacje w Nieskończoność i odwiedź nas na najlepszych możliwych warunkach. Skorzystaj{' '}
        <span className="text-nowrap">i roześlij </span>
        znajomym! Nie spóźnij się – Twój rabat jest aktywny tylko przez pierwsze 3 dni!{' '}
        <span className="text-nowrap">Po tym terminie ceny wzrosną.</span>
        Oferta wygasa po 14 dniach od momentu jej otrzymania.
      </p>
      <hr className="mx-n5 my-4" />
      <EndlessHolidayDetailsContentBenefits />
      <hr className="mx-n5 my-4" />
      <EndlessHolidayDetailsContentDescription onScrollToResorts={onScrollToResorts} />
      <EndlessHolidayDetailsProductsSelection productDetails={productDetails} />
    </div>
  ),
)
