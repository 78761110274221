import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onScrollToDetails: () => void
}

export const EndlessHolidayDetailsHeaderSectionContent = ({ onScrollToDetails }: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <span>
        Wakacje w Nieskończoność 2.0 <br />
        od Holiday Park & Resort
      </span>
    }
    subtitle={
      <span className="mt-5">
        Planuj urlop z wyprzedzeniem i ciesz się <br /> wyjątkowymi cenami za 6-dniowy pobyt.
      </span>
    }
  >
    <button
      className="btn btn-unstyled mt-5 d-flex justify-content-center justify-content-sm-start w-100 ps-0"
      onClick={onScrollToDetails}
    >
      <IconWithText
        text="Zobacz szczegóły"
        iconClassName="uil-plus me-2 font-xxl"
        className="text-primary font-500"
        textClassName="font-xl"
      />
    </button>
  </AppHeaderContent>
)
