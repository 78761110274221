import { ReservationDetailsPricesSection } from '@modules/reservation/details/bill/prices/reservation-details-prices-section'
import { ReservationDetailsPriesSectionItem } from '@modules/reservation/details/bill/prices/reservation-details-pries-section-item'
import * as React from 'react'
import { sortByPosition } from '@helpers/utils'
import { Improvement, PriceItem } from '@modules/reservation/models'

interface Props {
  improvements: PriceItem<Improvement>
}

export const ReservationDetailsPricesImprovements = ({ improvements }: Props): JSX.Element => (
  <>
    <ReservationDetailsPricesSection title="Ulepszenia:" totalPrice={improvements.total_price_brutto}>
      {sortByPosition(improvements.items)
        .filter((improvement: Improvement) => !improvement.is_paid_by_package_vip)
        .map((improvement: Improvement) => (
          <ReservationDetailsPriesSectionItem
            key={improvement.id}
            title={
              <>
                {improvement.name} {improvement.amount > 1 && <span>x{improvement.amount}</span>}
              </>
            }
            price={improvement.price_brutto}
          />
        ))}
    </ReservationDetailsPricesSection>
    <hr className="reservation-details__payment-bill__separator" />
  </>
)
