import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { OfferDetailsHeaderButton } from '@components/offer-details-button'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleGastroCardBoostHeaderSectionContent = ({
  packageWholesaleDetails,
}: Props): JSX.Element => (
  <AppHeaderContent
    title={
      <>
        Doładowanie Smaku od <span className="text-nowrap">Holiday Park & Resort</span> tylko dla{' '}
        {packageWholesaleDetails.display_name || packageWholesaleDetails.invoice_company}
      </>
    }
    subtitle={
      <span className="font-lg col-12 col-sm-8 d-block lh-sm">
        Skorzystaj ze specjalnej promocji na <strong>Voucher Doładowanie Smaku</strong> do wykorzystania w Restauracji,
        Bistro, Kawiarni, Słodkim Kąciku oraz Beach Barze w Holiday Park & Resort!
        <p>
          Wpłać minimum 500 zł, a my
          <strong> powiększymy o 40% wpłaconą przez Ciebie kwotę</strong>, którą wykorzystasz w strefie gastronomicznej
          podczas kolejnych pobytów do 31.12.2025
        </p>
      </span>
    }
  >
    {packageWholesaleDetails.leaflet_gastro_card_boost && (
      <OfferDetailsHeaderButton href={packageWholesaleDetails.leaflet_gastro_card_boost} />
    )}
  </AppHeaderContent>
)
