import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { AgreementSection } from '@components/agreement/agreement-section'
import { useRules } from '@hooks/use-rules'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { AvailableRuleKeyword } from '@models/app-data'
import { MARKETING_RULES_KEYWORDS } from '@components/agreement/marketing-agreement-section'

const GRAY_SUBSCRIPTIONS_RULE_URL =
  'https://www.holidaypark.pl/media/regulations/pl/Regulamin_vouchery_firmowe_zakupione_od_1_wrze%C5%9Bnia_2022_w%C5%82%C4%85cznie_MEelLpN.pdf'

export interface Agreements {
  main_rules: boolean
  privacy_policy_rules: boolean
  email_rules: boolean
  sms_rules: boolean
}

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleAgreements = ({ packageWholesaleDetails }: Props): JSX.Element => {
  const { getRules } = useRules()

  const rules = [...packageWholesaleDetails.optional_rules, ...packageWholesaleDetails.required_rules]

  const rulesContent = getRules(rules)

  const rulesContentFor = (rule: AvailableRuleKeyword) =>
    rulesContent.find(ruleContent => ruleContent?.keyword === rule)

  const rulesToCheck = rules.filter(rule => !MARKETING_RULES_KEYWORDS.includes(rule))

  const isEverySubscriptionProductGray = packageWholesaleDetails.products
    .filter(product => product.kind === 'subscription')
    .every(product => product.subscription_kind === 'gray')

  return (
    <AgreementSection className="mt-3" additionalRulesNames={rules}>
      {rulesToCheck.map(rule => (
        <Checkbox
          key={rule}
          className="checkbox--small"
          name={rule}
          label={
            <span>
              Oświadczam, że zapoznałem się z treścią dokumentu
              <a
                className="text-darker-gray text-decoration-none fw-bold mx-1"
                href={isEverySubscriptionProductGray ? GRAY_SUBSCRIPTIONS_RULE_URL : rulesContentFor(rule)?.url}
                target="_blank"
              >
                {rulesContentFor(rule)?.name}
              </a>
              i akceptuję jego postanowienia.
            </span>
          }
        />
      ))}
    </AgreementSection>
  )
}
