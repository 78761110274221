import * as React from 'react'
import { PackageWholesaleProductFormInput } from '@modules/package-wholesale/details'
import { PackageWholesaleSideCardPricesRow } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-row'
import { PackageWholesaleSideCardPricesOptions } from '@modules/package-wholesale/details/side-card-prices/package-wholesale-side-card-prices-options'
import { usePackageWholesalePrices } from '@modules/package-wholesale/use-package-wholesale-prices'
import { useSubscriptionProductName } from '@modules/package-wholesale/details/subscription/use-subscription-product-name'

interface Variants {
  gold: PackageWholesaleProductFormInput[]
  silver: PackageWholesaleProductFormInput[]
  brown: PackageWholesaleProductFormInput[]
}

interface Props {
  products: PackageWholesaleProductFormInput[]
}

export const PackageWholesaleSideCardPricesVariants = ({ products }: Props): JSX.Element => {
  const { getSubscriptionPrice } = usePackageWholesalePrices()
  const { getSubscriptionProductName } = useSubscriptionProductName()

  const items = React.useMemo(
    () =>
      products.reduce((accumulated, curr) => createSpecificKind(curr, accumulated), {
        gold: [],
        silver: [],
        brown: [],
      }),
    [products],
  )

  const sectionName = { gold: 'Pakiety złote:', silver: 'Pakiety srebrne:', brown: 'Pakiety brązowe' }

  return (
    <div>
      {Object.entries(items).map(
        ([kind, elements]) =>
          !!elements.length && (
            <>
              <hr className="mt-0" />
              <h5 className="px-3 font-md mb-2">{sectionName[kind]}</h5>
              {elements.map(product => (
                <PackageWholesaleSideCardPricesRow
                  key={product.product.id}
                  amount={product.amount}
                  title="Voucher pobytowy"
                  name={getSubscriptionProductName(product.product)}
                  price={getSubscriptionPrice(product)}
                  wrapperClassName="font-md mb-3"
                >
                  {!!product.options && <PackageWholesaleSideCardPricesOptions product={product} />}
                </PackageWholesaleSideCardPricesRow>
              ))}
            </>
          ),
      )}
    </div>
  )
}

const createSpecificKind = (product: PackageWholesaleProductFormInput, accumulated: Variants) =>
  ['gold', 'silver', 'brown'].includes(product.product.subscription_kind)
    ? {
        ...accumulated,
        [product.product.subscription_kind]: [...accumulated[product.product.subscription_kind], product],
      }
    : accumulated
