import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { AppHeaderSection } from '@components/app-header-section'
import { WinterHeaderSnowfallContainer } from '@components/winter-header-snowfall-container'

interface Props {
  onScrollToVipImprovementsList: () => void
}

export const VipWinterHeaderSectionContent = ({ onScrollToVipImprovementsList }: Props): JSX.Element => (
  <WinterHeaderSnowfallContainer>
    <AppHeaderSection images={[require('@assets/images/header-vip-winter.webp')]} imageDescription="Zimowy Pakiet VIP">
      <AppHeaderContent
        title={
          <>
            Potwierdzenie zakupu <span className="text-nowrap d-block">Zimowego Pakietu VIP</span>
          </>
        }
        subtitle={
          <span className="font-lg">
            Zimowy Pakiet VIP skierowany jest do wszystkich Gości, którzy cenią sobie{' '}
            <strong>prestiż oraz wygodę wypoczynku.</strong> Dodatkowe atrakcje, rabaty, liczne przywileje, a także
            butelka wyselekcjonowanego wina sprawią, że <strong>Twój zimowy pobyt</strong> będzie niezapomnianą przygodą
            i wypoczynkiem na najwyższym poziomie.
          </span>
        }
      >
        <IconWithText
          text="Co dostaję w pakiecie?"
          iconClassName="uil-plus font-xxxl me-2"
          className="text-gold font-size-xl cursor-pointer fw-bold justify-content-center justify-content-sm-start"
          onClick={onScrollToVipImprovementsList}
        />
      </AppHeaderContent>
    </AppHeaderSection>
  </WinterHeaderSnowfallContainer>
)
