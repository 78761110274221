import * as React from 'react'
import Carousel from 'react-multi-carousel'
import * as clsx from 'clsx'

interface Props {
  showOpinions?: boolean
}

export const HolidayBenefitCompanies = ({ showOpinions }: Props): JSX.Element => (
  <div className="container mt-lg-5 pt-lg-5">
    <span className="text-holiday-benefit-secondary font-xxl text-center d-block mt-5 mb-3 fw-semibold">
      Te firmy spełniają marzenia swoich pracowników
    </span>
    <div className="d-flex justify-content-between align-items-center overflow-auto gap-5 hidden-scrollbar">
      <img src={require('@assets/images/holiday-benefit/landing-page/bilbox.jpg')} alt="holidaypark logo" height="36" />
      <img src={require('@assets/images/holiday-benefit/landing-page/inpost.jpg')} alt="holidaypark logo" height="46" />
      <img src={require('@assets/images/holiday-benefit/landing-page/bocian.jpg')} alt="holidaypark logo" height="80" />
      <img src={require('@assets/images/holiday-benefit/landing-page/bilbox.jpg')} alt="holidaypark logo" height="36" />
      <img src={require('@assets/images/holiday-benefit/landing-page/inpost.jpg')} alt="holidaypark logo" height="46" />
      <img src={require('@assets/images/holiday-benefit/landing-page/bocian.jpg')} alt="holidaypark logo" height="80" />
    </div>
    {showOpinions && (
      <div className="mt-5 pt-5">
        <div className="holiday-benefit__section-title text-center">
          <strong className="text-holiday-benefit-primary me-2">Opinie</strong>
          <strong className="text-holiday-benefit-secondary">pracowników i pracodawców</strong>
        </div>
        <p className="mt-3 mb-5 font-xl text-center">
          Poznaj opinie pracowników i pracodawców, <br />
          którzy już korzystają z naszego benefitu
        </p>
        <div className="bocian-voucher__gallery">
          <div className="package-wholesale__resorts">
            <Carousel
              responsive={breakpoints}
              showDots
              arrows={false}
              autoPlay={false}
              shouldResetAutoplay={false}
              itemClass="package-wholesale__resorts__item"
            >
              {GalleryItems.map(item => (
                <div
                  key={item.name}
                  className={clsx(
                    'rounded-3 p-5 text-holiday-benefit-primary h-100 d-flex flex-column justify-content-between',
                  )}
                  style={{ backgroundColor: `rgba(149, 27, 129, ${0.05})` }}
                >
                  <p className="font-lg mb-0 mt-3 fw-light">{item.opinion}</p>
                  <p className="font-lg mt-3 font-500">- {item.name}</p>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    )}
  </div>
)
const breakpoints = {
  extraLarge: { breakpoint: { max: 3000, min: 1770 }, items: 5 },
  large: { breakpoint: { max: 1770, min: 1200 }, items: 5 },
  semiLarge: { breakpoint: { max: 1200, min: 768 }, items: 3 },
  medium: { breakpoint: { max: 768, min: 468 }, items: 2 },
  small: { breakpoint: { max: 468, min: 0 }, items: 1 },
}

const GalleryItems = [
  {
    name: 'Anna Kowalska',
    opinion:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci eligendi itaque odio sit vitae, voluptate voluptates. ',
  },
  {
    name: 'Jan Kowalski',
    opinion:
      'Lorem ipsum dolor sit amet, oribus ipsa modi molestiae provident quasi, quisquam recusandae, reiciendis rem sit.',
  },
  {
    name: 'Krzysztof Nowak',
    opinion: 'Lorem ipsum dolates. iciendis rem sit.',
  },
  {
    name: 'Katarzyna Nowak',
    opinion: 'Lorem ipsum dolor sit amet,ndis rem sit.',
  },
  {
    name: 'Anna Kowalska',
    opinion: 'Lorem ipsum dolit. Accusantium adipisci eligendi itaque odio sit vitae, voluptate voluptates. ',
  },
  {
    name: 'Jan Kowalski',
    opinion:
      'Lorem ipsum dolor sit amet, conssamus aliquid doloribus ipsa modi molestiae provident quasi, quisquam recusandae, reiciendis rem sit.',
  },
  {
    name: 'Krzysztof Nowak',
    opinion: 'Lorem ipsum etu ipsa modi molestiae provident quasi, quisquam recusandae, reiciendis rem sit.',
  },
  {
    name: 'Katarzyna Nowak',
    opinion: 'Lorem ipsum dolor sit provident quasi, quisquam recusandae, reiciendis rem sit.',
  },
]
