import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { Seller } from '@models/base'
import { useSelector } from 'react-redux'
import * as clsx from 'clsx'
import { RootState } from '@store/index'
import { formatPhoneNumber } from '@helpers/utils'

interface Props {
  seller: Seller | undefined
  created: string
  className?: string
  showBasePhoneNumber?: boolean
}

export const OfferSeller = ({ seller, created, className, showBasePhoneNumber }: Props): JSX.Element | null => {
  const phoneNumber = useSelector(
    (state: RootState) => state.appDataState.appData.company_info.call_center_phone_number.pl,
  )

  const department =
    {
      BOK: 'Biuro Obsługi Klienta',
      BOS: 'Biuro Obsługi Sprzedaży',
    }[seller?.department_display || ''] ?? seller?.department_display

  const phone = formatPhoneNumber(showBasePhoneNumber ? phoneNumber : seller?.phone || phoneNumber)

  if (seller?.department === 'ecommerce') return null

  return (
    <div className={clsx('text-darker-gray font-xs', className)}>
      <span className="ms-auto col-lg-8 opacity-75 d-block text-center text-lg-end">
        Oferta przygotowana w dniu {toDefaultDateFormat(created)} przez
      </span>
      <div className="d-flex gap-3 mt-3 justify-content-lg-end justify-content-center mb-3 mb-lg-0">
        <div className="opacity-75 text-end">
          <strong className="d-block font-sm">{seller?.name}</strong>
          <span className="font-xxs">{department}</span>
          <a className="d-block font-xxs text-decoration-none text-darker-gray" href={`tel:${phone}`}>
            {phone}
          </a>
        </div>
        <div>
          <img
            src={require(`@assets/images/avatar-${seller?.gender}.jpg`)}
            alt="Zdjęcie sprzedawcy"
            className="rounded-circle"
            height="36"
          />
        </div>
      </div>
    </div>
  )
}
