import { ThunkResult } from '@models/models'
import { AppData } from '@models/app-data'
import { setAppData } from '@store/actions/app-data-actions'
import { commonObjectGet } from '@requests/basic-requests'
import { SetAppDataAction } from '@store/action-types/app-data-action-types'

export function fetchAppData(): ThunkResult<Promise<AppData>, SetAppDataAction> {
  return async dispatch => {
    const appData = await commonObjectGet<AppData>('/api/promotions/app-data/')
    dispatch(setAppData(appData))

    return appData
  }
}
