import * as React from 'react'
import { ClientInfoForm } from '@components/client-data/client-info-form'
import { ClientInvoiceForm } from '@components/client-invoice/client-invoice-form'

interface Props {
  additionalClientData?: React.ReactNode
}
export const ClientDataForms: React.FC<Props> = ({ additionalClientData }) => (
  <>
    <hr className="mx-n5 my-4" />
    <ClientInfoForm additionalClientData={additionalClientData} />
    <hr className="mx-n5 my-4" />
    <ClientInvoiceForm />
  </>
)
