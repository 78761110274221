import * as React from 'react'
import { useFormRequest } from '@hooks/use-form-request'
import { AppFooter } from '@components/app-footer'
import { ContentLoader } from '@components/content-loader'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { commonObjectPost } from '@requests/basic-requests'
import { handleKeyDown } from '@helpers/utils'
import { CodesDeactivationList } from '@modules/codes-deactivation/common/codes-deactivation-list'
import { CodeLabel } from '@modules/codes-deactivation/common/code-label'
import { Key } from 'ts-key-enum'
import { Button } from 'react-bootstrap'
import * as clsx from 'clsx'

interface FormInputs {
  code: string
}

interface Props {
  children: React.ReactNode
  title: string
  codeMask: string
}

export const CodesDeactivation = ({ title, codeMask, children }: Props): JSX.Element => {
  const url = '/api/entrance-tickets/validate/'
  const [codes, setCodes] = React.useState<string[]>([])

  const inputRef = React.useRef<HTMLInputElement | null>(null)

  const methods = useForm<FormInputs>({
    defaultValues: {
      code: '',
    },
  })

  const { action: onSubmit, isLoading } = useFormRequest(async () => {
    const { code } = methods.getValues()
    const upperCaseCode = code.toUpperCase()
    await commonObjectPost(url, { code: upperCaseCode })
    setCodes(state => [...state, upperCaseCode])
    setTimeout(() => inputRef.current?.setSelectionRange(2, 2), 50)
    methods.reset()
  }, methods.setError)

  const inputIndicatorVisible = isLoading || !!methods.formState.errors.code

  return (
    <ContentLoader>
      {children}
      <div className="container app-header-section__after-content mb-5">
        <div className="row">
          <div className="card col-lg-8 col-12 py-4 card-shadow border-0 overflow-hidden mx-auto">
            <h4 className="text-primary text-center mb-5 mt-3">{title}</h4>
            <FormProvider {...methods}>
              <div className="position-relative">
                <CustomFormGroup
                  label={<CodeLabel />}
                  inputName="code"
                  isLoading={isLoading}
                  formControlProps={{ type: 'text' }}
                  maskedFormControlProps={{
                    inputRef: ref => {
                      inputRef.current = ref
                    },
                    mask: codeMask,
                    alwaysShowMask: true,
                    className: 'bg-white letter-spacing-2',
                    formatValue: value => value.toUpperCase(),
                    onKeyDown: handleKeyDown(Key.Enter, methods.handleSubmit(onSubmit)),
                  }}
                  formGroupProps={{
                    className: 'col-10 mx-auto',
                  }}
                />
                <Button
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={isLoading}
                  className={clsx('btn btn-secondary codes-deactivation__submit-button', {
                    'is-moved': inputIndicatorVisible,
                  })}
                >
                  Zatwierdź
                </Button>
              </div>
              <hr className="mx-n5 mt-5" />
              <div className="col-lg-10 mx-auto">
                <h5 className="text-primary mt-4 mb-4">Lista dezaktywowanych kodów</h5>
                {codes.length ? (
                  <CodesDeactivationList codes={codes} />
                ) : (
                  <p className="text-darker-gray">Żaden z kodów nie został jeszcze dezaktywowany.</p>
                )}
              </div>
              <hr className="mx-n5 mt-4" />
              <span className="text-darker-gray font-sm text-end d-block mt-1">Holiday Park & Resort</span>
            </FormProvider>
          </div>
        </div>
      </div>
      <AppFooter />
    </ContentLoader>
  )
}
