import * as React from 'react'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { AvailableRule } from '@models/app-data'

const OLD_BROWN_SUBSCRIPTION_CONTRACT_RULE_URL =
  'https://holidaypark.pl/regulaminy/pobierz/regulamin-subskrypcji/archiwum/78/'

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsCardAgreements = ({ subscriptionContractDetails }: Props): JSX.Element => {
  const getOverwrittenRule = React.useCallback((rule: AvailableRule) => {
    if (rule.keyword === 'subscription_contract_rules' && subscriptionContractDetails.plan === 'plan_1_old') {
      return { ...rule, url: OLD_BROWN_SUBSCRIPTION_CONTRACT_RULE_URL }
    }
    return rule
  }, [])

  return (
    <DynamicAgreementsSection
      getOverwrittenRule={getOverwrittenRule}
      productRules={[...subscriptionContractDetails.required_rules, ...subscriptionContractDetails.optional_rules]}
    />
  )
}
