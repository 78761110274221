import { GastroGenericProductDetails, GastroProduct, GastroProductData } from '@modules/gastro-generic/models'
import { formatPrice } from '@helpers/price-helper'
import * as React from 'react'

export const getGastroCouponData = (
  productDetails: GastroGenericProductDetails,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const isSubscriptionOffer = productDetails.tags.includes('subscription_offer')

  const labelImageSrc = 'gastro-coupon-label.jpg'

  const productName = isSubscriptionOffer
    ? `${gastroProduct.product_name} dla Subskrybentów`
    : gastroProduct.product_name

  const productHeader = (
    <span className="font-lg">
      Skorzystaj ze specjalnej promocji na <strong className="text-nowrap">Voucher “{productName}”</strong>do
      wykorzystania we wszystkich otwartych punktach gastronomicznych.
    </span>
  )

  const benefitsPoints = [
    'Do wykorzystania we wszystkich punktach gastronomicznych na terenie Resortu',
    'Kupon można nabyć wyłącznie w trakcie trwania rezerwacji',
    'Ilość kuponów jakie możesz kupić jest nieograniczona!',
  ]

  const benefitsList = [
    `Minimalna kwota, za jaką możesz kupić voucher to ${formatPrice(gastroProduct.minimal_value)}`,
    'Kupon ma charakter jednorazowy.',
    'Voucher można wykorzystać w ramach bieżącego pobytu w Resorcie.',
  ]

  const alertText =
    'Kupon nie łączy się z voucherem Podwójna Radość. Nie można go wykorzystać w rezerwacji, do której przypisany jest taki voucher.'

  return {
    labelImageSrc,
    productName,
    productHeader,
    benefitsPoints,
    benefitsList,
    alertText,
  }
}
