import * as React from 'react'
import { useSelector } from 'react-redux'
import { ModalComponentRow } from '@components/modals/modal-component-row'
import { modalsSelector } from '@store/slices/modal-slice'

export const ModalComponent: React.FC = () => {
  const modals = useSelector(modalsSelector)

  return (
    <>
      {modals.map(({ modal, params, config, isOpen }, index) => (
        <ModalComponentRow config={config} key={index} isOpen={isOpen} modal={modal} params={params} />
      ))}
    </>
  )
}
