import * as React from 'react'

interface Props {
  actionText: string
  onActionClick?: () => void
  children: React.ReactElement[]
}

export const PaymentFailureContact = ({ actionText, onActionClick, children }: Props): JSX.Element => (
  <div className="d-flex align-items-center justify-content-between font-size-lg text-darker-gray mt-4 w-100">
    <div className="d-flex">{children}</div>
    <button className="font-lg align-self-start cursor-pointer fw-bold btn-unstyled" onClick={onActionClick}>
      {actionText}
    </button>
  </div>
)
