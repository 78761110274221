import { WebSocketActionsTypes, WebSocketActionTypesKeys } from '@store/action-types/websocket-actions-types'
import { NotificationEvents } from '@models/websocket'

export interface WebSocketState {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  payload: any
  message: NotificationEvents | undefined
}

const initialState: WebSocketState = {
  payload: null,
  message: undefined,
}

const webSocketState = (state: WebSocketState = initialState, action: WebSocketActionsTypes): WebSocketState => {
  switch (action.type) {
    case WebSocketActionTypesKeys.SET_WEBSOCKET_DATA:
      return { ...state, ...{ payload: action.payload.payload, message: action.payload.message } }
    default:
      return state
  }
}

export default webSocketState
