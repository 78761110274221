import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { motion } from 'framer-motion'
import { AgreementSection } from '@components/agreement/agreement-section'
import { AvailableRule, AvailableRuleKeyword } from '@models/app-data'
import { useDevicesSize } from '@hooks/use-devices-size'
import { useRules } from '@hooks/use-rules'
import { useModal } from '@components/modals/use-modal'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'

const RulesKeywords = [
  'information_clause',
  'email_rules',
  'sms_rules',
  'phone_call_rules',
  'marketing_rules',
] as AvailableRuleKeyword[]

interface Props {
  isVisible: boolean
  rules: AvailableRuleKeyword[]
}

export const RodoAgreementsFormPageAgreements = ({ isVisible, rules }: Props): JSX.Element => {
  const { other } = useSelector(selectAppDataUrls)
  const { isDesktop } = useDevicesSize()
  const { getRules } = useRules()
  const rulesToShow = getRules(rules)

  return (
    <motion.div
      className="mt-4 ms-sm-5"
      initial={{ whiteSpace: 'initial', overflow: 'hidden', opacity: 0, maxHeight: 300 }}
      animate={{
        whiteSpace: isVisible ? 'initial' : 'nowrap',
        opacity: isVisible ? 1 : 0,
        maxHeight: isDesktop ? 500 : 1000,
      }}
      transition={{ duration: 0.5, ease: 'linear' }}
    >
      <AgreementSection
        productRules={RulesKeywords}
        title=""
        selectAllLabel="Akceptuję wszystkie oświadczenia i zgody"
        withMarketingRules={false}
        additionalRulesNames={[...rules, 'information_clause']}
      >
        <div className="ps-sm-4 mt-3 font-sm">
          <RuleCheckbox
            rule={{
              keyword: 'information_clause',
              url: `${other.main_site}/regulaminy/pobierz/klauzula-informacyjna/`,
              name: 'Klauzula informacyjna',
            }}
          />
          {rulesToShow.map(rule => (
            <RuleCheckbox key={rule?.keyword} rule={rule} />
          ))}
          <Checkbox
            className="checkbox--small"
            name="marketing_rules"
            label="Wyrażam zgodę na przetwarzanie przez Holiday Park & Resort sp. z o.o. sp. k. - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J - w celach marketingowych następujących dotyczących mnie danych osobowych: imienia, nazwiska, adresu poczty elektronicznej, numeru telefonu."
          />
          <Checkbox
            className="checkbox--small"
            name="email_rules"
            label="Wyrażam zgodę na przesyłanie mi przez Holiday Park & Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J informacji handlowej za pomocą poczty elektronicznej, zgodnie z przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. nr 144, poz. 1204 z późn. zm.)."
          />
          <Checkbox
            className="checkbox--small"
            name="phone_call_rules"
            label="Wyrażam zgodę na kontaktowanie się ze mną przez Holiday Park & Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J za pośrednictwem telefonu w celu marketingu bezpośredniego dotyczącego świadczonych usług, zgodnie z przepisami ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (tj. Dz. U. z 2016 r. poz. 1489))."
          />
          <Checkbox
            className="checkbox--small"
            name="sms_rules"
            label="Wyrażam zgodę na przesyłanie mi przez Holiday Park & Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J informacji handlowej za pomocą wiadomości SMS, zgodnie z przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. nr 144, poz. 1204 z późn. zm.)."
          />
        </div>
      </AgreementSection>
      <p className="text-darker-gray mt-4 ps-5">
        Zgody marketingowe możesz wycofać w dowolnym momencie bez żadnych konsekwencji.
      </p>
    </motion.div>
  )
}

const RuleCheckbox = ({ rule }: { rule: AvailableRule | undefined }) => {
  const [showAgreementModal] = useModal('RodoAgreementContentModal', { url: rule?.url })

  if (!rule) return null

  return (
    <Checkbox
      name={rule.keyword}
      className="checkbox--small"
      label={
        <span>
          Oświadczam, że zapoznałem się z treścią dokumentu
          <button className="text-darker-gray btn-unstyled fw-bold mx-1" onClick={showAgreementModal}>
            {rule.name}
          </button>
          i akceptuję jego postanowienia.
        </span>
      }
    />
  )
}
