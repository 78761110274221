import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { GastroCardDetails } from '@modules/gastro-card/models'
import { CardContentBase } from '@components/card-content-base'
import { asDecimal } from '@helpers/utils'
import { getGastroProductPrice } from '@modules/gastro-card/details/utils'

interface Props {
  gastroCardDetails: GastroCardDetails
  onScrollToPayment: () => void
}

export const GastroCardContent = ({ gastroCardDetails, onScrollToPayment }: Props): JSX.Element => {
  const { sellPrice, bonusPrice } = getGastroProductPrice(gastroCardDetails)

  return (
    <div>
      <CardContentBase
        title="Potwierdzenie zakupu vouchera Podwójna Radość"
        client={gastroCardDetails?.client}
        seller={gastroCardDetails?.seller}
        created={gastroCardDetails?.created || ''}
        isProductPaid={gastroCardDetails.is_paid}
        onScrollToPayment={onScrollToPayment}
        productInvoice={gastroCardDetails}
      />
      <div className="col-sm-8 d-flex flex-sm-row flex-column gap-2 mt-5 mb-4 text-darker-gray">
        <div className="rounded bg-alice-blue p-4 col-sm-6 col-12 d-flex flex-wrap flex-sm-column align-items-sm-start align-items-center justify-content-between">
          <strong>{sellPrice.gt(asDecimal(0)) ? 'Wpłacana kwota:' : 'Zadeklarowana kwota'}</strong>
          <span className="d-block font-500 font-xxxl text-gold">
            {formatPriceShort(
              sellPrice.gt(asDecimal(0)) ? gastroCardDetails.sell_price : gastroCardDetails.declared_sell_price,
            )}
          </span>
          <span className="opacity-75 w-100">
            która w ramach <br className="d-sm-block d-none" /> promocji <strong>podwajamy</strong>
          </span>
        </div>
        <div className="rounded bg-tropical-blue p-4 col-6 text-sm-end text-start col-sm-6 col-12 d-flex flex-wrap flex-sm-column align-items-center align-items-sm-end justify-content-between position-relative">
          <div className="rounded gastro-card-details__factor">x2</div>
          <strong>Wartość vouchera:</strong>
          <strong className="d-block font-xxxl text-secondary"> = {formatPriceShort(bonusPrice)}</strong>
          <span className="opacity-75">
            do wykorzystania <br className="d-sm-block d-none" /> w <strong>Kawiarni, Bistro i Restauracji</strong>
          </span>
        </div>
      </div>
    </div>
  )
}
