import * as React from 'react'
import { HolidayBenefitHeader } from './holiday-benefit-header'
import { HolidayBenefitBenefitsSection } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-benefits-section'
import { HolidayBenefitBenefitsNumbers } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-benefits-numbers'
import { HolidayBenefitResorts } from '@modules/holiday-benefit/common/holiday-benefit-resorts'
import { HolidayBenefitFooter } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-footer'
import { HolidayBenefitEmployeeVoucher } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-employee-voucher'
import { HolidayBenefitBenefitsContactForm } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-benefits-contact-form'
import { HolidayBenefitDeployment } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-deployment'
import { HolidayBenefitBenefitsFaq } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-benefits-faq'
import { HolidayBenefitStickyLabel } from '@modules/holiday-benefit/common/holiday-benefit-sticky-label'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { HolidayBenefitEmployerHero } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-employer-hero'
import { HolidayBenefitEmployerBenefitsPlans } from '@modules/holiday-benefit/employer-landing-page/holiday-benefit-employer-benefits-plans'
import { FadeInView } from '@components/fade-in-view'

export const HolidayBenefitView = (): React.ReactNode => {
  const benefitSectionRef = React.useRef<HTMLDivElement>(null)
  const plansSectionRef = React.useRef<HTMLDivElement>(null)
  const contactSectionRef = React.useRef<HTMLFormElement>(null)

  const scrollToSection = (section: React.RefObject<HTMLElement>) => () => {
    section.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const navigate = useNavigate()

  const navigateToEmployerLandingPage = () => {
    navigate(NavigationPath.HolidayBenefitsEmployee)
  }

  return (
    <div>
      <HolidayBenefitStickyLabel
        text="Oferta dla Pracownika"
        onClick={navigateToEmployerLandingPage}
        gradientStartColor="#22254B"
        gradientEndColor="#3842C3"
      />
      <FadeInView className="holiday-benefit">
        <HolidayBenefitHeader
          onScrollToBenefitsSection={scrollToSection(benefitSectionRef)}
          onScrollToPlansSection={scrollToSection(plansSectionRef)}
          onScrollToContactSection={scrollToSection(contactSectionRef)}
        />
        <HolidayBenefitEmployerHero
          onScrollToBenefitsSection={scrollToSection(benefitSectionRef)}
          onScrollToPlansSection={scrollToSection(plansSectionRef)}
        />
        <HolidayBenefitBenefitsSection ref={benefitSectionRef} />
        <HolidayBenefitBenefitsNumbers />
        <HolidayBenefitResorts
          title="Holiday Park & Resort"
          subtitle="to coś więcej niż zwykły wypoczynek"
          galleryTitle="Gdzie mogą wypoczywać"
          gallerySubtitle="Twoi pracownicy?"
          galleryImages={GalleryItems}
        />
        <HolidayBenefitEmployerBenefitsPlans
          ref={plansSectionRef}
          onScrollToContactSection={scrollToSection(contactSectionRef)}
        />
        <HolidayBenefitEmployeeVoucher />
        <HolidayBenefitBenefitsContactForm ref={contactSectionRef} />
        <HolidayBenefitDeployment />
        <HolidayBenefitBenefitsFaq onScrollToContactSection={scrollToSection(contactSectionRef)} />
        <HolidayBenefitFooter />
      </FadeInView>
    </div>
  )
}

const GalleryItems = [
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/01.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/02.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/03.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/04.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/05.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/06.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/07.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/08.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/09.webp') },
  { original: require('@assets/images/holiday-benefit/landing-page/gallery/10.webp') },
]
